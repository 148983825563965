import { ProductStatus } from 'domain/product/productStatus'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface QuickEditProductRequest {
  id: string
  status: ProductStatus
  stock: number
  price: number
  weight: number
  stockKeepingUnit: string
}

export const saveQuickEditProduct = (product: QuickEditProductRequest): Promise<void> => {
  return adminHttpClient.put('/admindashboard/products/quickEdit', product)
}
