import React, { useState, useRef } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Col, Row, Tooltip } from 'antd'

import { useTranslation } from 'react-i18next'

import Modal from 'common/components/feedback/Modal'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'

import { QuickEditProductRequest } from 'core/application/dashboard/products/quickEditProduct'
import { Product } from 'core/application/dashboard/products/products'
import { ProductStatus } from 'core/domain/product/productStatus'
import { getProductStatuses } from './ManageProduct'
import { User } from 'core/application/account/loginUser'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import useLoginUser from '../../common/hooks/useLoginUser'

const QuickEditForm: React.FC<QuickEditFormProps> = ({ initialValues, visible, onCancel, onSubmit, submitting }) => {
  let { t: translate } = useTranslation()
  const [formIsValid, setFormIsValid] = useState<boolean>(false)
  const [quickEditProductRequest, setQuickEditProductRequest] = useState<QuickEditProductRequest>(
    {} as QuickEditProductRequest,
  )
  const [user] = useLoginUser()

  const formFooter = (
    <FormSaveCancelButtons
      disabledSave={!formIsValid}
      onButtonClick={() => onSubmit(quickEditProductRequest)}
      loading={submitting}
    />
  )

  const getCurrency = (user: User | null) => {
    return user ? user.currency : ''
  }

  const getWeightUnit = (user: User | null) => {
    return user ? user.weightUnit : ''
  }

  return (
    <Modal onCancel={onCancel} visible={visible} title={translate('dashboard.products.quick_edit')} footer={formFooter}>
      <Formik
        enableReinitialize={true}
        validationSchema={formValidator(translate, initialValues)}
        initialValues={initialValues}
        onSubmit={(values) => {}}
        validate={(values: Product) => {
          setFormIsValid(formValidator(translate, initialValues).isValidSync(values))

          setQuickEditProductRequest({
            id: values.id,
            stock: values.stock,
            status: values.status,
            weight: values.weight,
            price: values.price,
            stockKeepingUnit: values.stockKeepingUnit,
          })
        }}
      >
        {(formik) => (
          <FormikForm>
            <Row>
              <Col xs={24}>
                <Select
                  label={translate('dashboard.products.status')}
                  name="status"
                  placeholder={translate('dashboard.products.status')}
                  showArrow={true}
                  defaultValue={[initialValues.status]}
                >
                  {getProductStatuses(translate).map((status) => (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {initialValues.hasAttributes && !initialValues.hasStaticAttributes ? (
                  <Tooltip title={translate('dashboard.products.stock_quick_edit_tooltip')}>
                    <InputNumber
                      disabled
                      label={translate('dashboard.products.stock')}
                      name="stock"
                      placeholder={translate('dashboard.products.stock')}
                    />
                  </Tooltip>
                ) : (
                  <InputNumber
                    label={translate('dashboard.products.stock')}
                    name="stock"
                    placeholder={translate('dashboard.products.stock')}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {initialValues.hasAttributes && !initialValues.hasStaticAttributes ? (
                  <Tooltip title={translate('dashboard.products.price_quick_edit_tooltip')}>
                    <InputNumber
                      disabled
                      label={`${translate('dashboard.products.price')} (${getCurrency(user)})`}
                      name="price"
                      placeholder={translate('dashboard.products.price')}
                    />
                  </Tooltip>
                ) : (
                  <InputNumber
                    label={`${translate('dashboard.products.price')} (${getCurrency(user)})`}
                    name="price"
                    placeholder={translate('dashboard.products.price')}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                {initialValues.isPhysicalProduct ? (
                  <InputNumber
                    label={`${translate('dashboard.products.weight')} (${getWeightUnit(user)})`}
                    name="weight"
                    placeholder={translate('dashboard.products.weight')}
                  />
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Input
                  label={translate('dashboard.products.stock_keeping_unit')}
                  name="stockKeepingUnit"
                  placeholder={translate('dashboard.products.stock_keeping_unit')}
                />
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

interface QuickEditFormProps {
  initialValues: Product
  visible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  onSubmit: (values: QuickEditProductRequest) => void | Promise<any>
  submitting: boolean
}

const formValidator = (translate: any, product: Product) =>
  Yup.object({
    stock: Yup.number().notRequired().nullable().min(0, translate('general.minimun_required')),
    price: Yup.number().notRequired().nullable().min(0, translate('general.minimun_required')),
    weight: Yup.number().notRequired().nullable().min(0, translate('general.minimun_required')),
    status: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .oneOf([ProductStatus.Active, ProductStatus.Archived, ProductStatus.Draft], translate('general.required')),
  })

export default QuickEditForm
