import React from 'react'
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

import { AnalyticsReport } from 'core/application/dashboard/home/analytics/userCount'

interface UserCountChartProps {
  report?: AnalyticsReport
}

const AudienceChart: React.FC<UserCountChartProps> = ({ report }) => {
  return (
    <div style={{ width: '100%', height: 235, margin: 0 }} className="width-500">
      <ResponsiveContainer width="100%" height={222} className="width-500">
        <LineChart
          className="width-500"
          width={730}
          height={250}
          data={report?.metrics}
          margin={{ top: 10, left: -15, right: 10, bottom: 0 }}
        >
          <CartesianGrid vertical={false} stroke="#F0F0F0" />
          <XAxis dataKey="key" stroke="#F0F0F0" tick={{ fill: '#707377' }} />
          <YAxis stroke="white" tick={{ fill: '#707377' }} domain={[0, report?.max ?? 0]} />
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke="#25378b" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default AudienceChart
