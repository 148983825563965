import React from 'react'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import EmailSubscriptionsWrapper from './EmailSubscriptionsWrapper'
import EmailSubscriptions, { SUBSCRIPTIONS_PATH } from './subscriptions'

import NotFound from 'errorPages/500Page'
import Emails, { EMAILS_PATH } from './emails'
import ManageEmail, { EMAILS_PATH_EDIT } from './emails/ManageEmail'

export const EMAIL_SUBSCRIPTIONS_PATH = 'email-subscriptions'

export const NavigationMapping = {
  Subscriptions: 1,
  Emails: 2,
  ManageEmail: 3,
}

export const navigationPathMapping = new Map([
  [SUBSCRIPTIONS_PATH, NavigationMapping.Subscriptions],
  [EMAILS_PATH, NavigationMapping.Emails],
  [EMAILS_PATH_EDIT, NavigationMapping.ManageEmail],
])

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${SUBSCRIPTIONS_PATH}`}>
        <EmailSubscriptionsWrapper>
          <EmailSubscriptions />
        </EmailSubscriptionsWrapper>
      </Route>
      <Route path={`${match.path}/${EMAILS_PATH_EDIT}/:id?`}>
        <EmailSubscriptionsWrapper>
          <ManageEmail />
        </EmailSubscriptionsWrapper>
      </Route>
      <Route path={`${match.path}/${EMAILS_PATH}`}>
        <EmailSubscriptionsWrapper>
          <Emails />
        </EmailSubscriptionsWrapper>
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
