import React, { useState, useEffect } from 'react'
import { Row, Col, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import ProductsTable from './ProductsTable'
import { queryRequest } from '../../common/RequestUtils'
import Button from '../../common/components/general/Button'
import Card from '../../common/components/dataDisplay/Card'
import ProductSearch, { SearchValues } from './ProductSearch'

import getProducts, { PagedProducts, ProductFilter } from 'core/application/dashboard/products/products'
import deleteProduct from 'core/application/dashboard/products/deleteProduct'

import { MANAGE_PRODUCTS_PATH } from './ManageProduct'

import './Products.less'

import { User } from 'core/application/account/loginUser'
import store from 'core/application/commons/localStorage'
import { LanguageDto, getSupportedLanguages } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import useLoginUser from '../../common/hooks/useLoginUser'

export const PRODUCTS_PATH = 'products'

interface ProductsProps {
  user: User | null
}

const Products: React.FC<ProductsProps> = () => {
  const [user] = useLoginUser()
  const lastFilterStoreKey = `LastFilter_${user?.userId}`
  const storedFilter = store.getObject(lastFilterStoreKey)

  const { t: translate } = useTranslation()
  const history = useHistory()
  const [searchFilter, setSearchFilter] = useState<ProductFilter>(
    storedFilter ||
      ({
        pageNumber: 1,
        pageSize: 20,
        orderColumn: 'createdAt',
        orderDescending: true,
      } as ProductFilter),
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [products, setProducts] = useState<PagedProducts>({} as PagedProducts)
  const [languages, setLanguages] = useState<LanguageDto[]>([])
  const productSearchInitialValues = {
    inputSearch: searchFilter.searchValue,
    outOfStock: searchFilter.outOfStock,
    showOnlyArchived: searchFilter.showOnlyArchived,
  } as SearchValues

  useEffect(() => {
    ;(async () => {
      await getLanguages()
      await updateProductsTable()
    })()
  }, [searchFilter])

  const updateProductsTable = async () => {
    setLoading(true)
    setProducts(await queryRequest(() => getProducts(searchFilter)))
    setLoading(false)

    store.saveObject(lastFilterStoreKey, searchFilter)
  }

  const getLanguages = async () => {
    const result = await queryRequest(() => getSupportedLanguages())
    setLanguages(result)
  }

  const onSearch = (searchValue: SearchValues) => {
    if (searchValue.inputSearch && searchValue.inputSearch?.length < 2) {
      notification.open({ type: 'error', message: translate('general.search_length') })
    } else {
      setSearchFilter({
        ...searchFilter,
        searchValue: searchValue.inputSearch,
        outOfStock: searchValue.outOfStock,
        showOnlyArchived: searchValue.showOnlyArchived,
        pageNumber: 1,
      } as ProductFilter)
    }
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={12} md={14} span={16}>
              <h4>{translate('dashboard.products.title')}</h4>
            </Col>
            <Col xs={10} md={10}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`${history.location.pathname}/${MANAGE_PRODUCTS_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Row>
          <Col xs={24} md={24} lg={24} className="mb-8">
            <ProductSearch initialValues={productSearchInitialValues} onSearch={onSearch} loading={loading} />
          </Col>
        </Row>

        <Row>
          <Col xs={24} className="mt-16">
            <ProductsTable
              setOrderFilter={setSearchFilter}
              filter={searchFilter}
              products={products}
              loadingOrders={loading}
              history={history}
              translate={translate}
              onDelete={deleteProduct}
              updateProductsTable={updateProductsTable}
              languages={languages}
            />
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default Products
