import moment, { Moment } from 'moment'
import { NamedItemInt } from 'application/commons/namedItem'
import { ProductStatus } from 'domain/product/productStatus'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface PagedProducts {
  items: Product[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}

export interface Product {
  id: string
  name: string
  price: number
  status: ProductStatus
  currency: string
  stock: number
  hasAttributes: boolean
  hasStaticAttributes: boolean
  favourite: boolean
  availableStatuses: NamedItemInt[]
  createdAt: Moment
  weight: number
  isPhysicalProduct: boolean
  stockKeepingUnit: string
}

export interface ProductFilter {
  searchValue?: string
  pageNumber: number
  pageSize: number
  orderColumn: string
  orderDescending: boolean
  outOfStock: boolean
  showOnlyArchived: boolean
}

const getProducts = (filter: ProductFilter): Promise<any> => adminHttpClient.get('/admindashboard/products', filter)

export default getProducts
