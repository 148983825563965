import { PagedFilter, PagedList } from 'application/commons'
import { adminHttpClient } from '../commons/adminHttpClient'

export type GetPagedBidsRequest = PagedFilter & { productId: string }

export type PagedBidResponse = {
  bidAmount: number
  isWinning: boolean
  bidderEmail: string
  createdA: string
}

const getProductAuctionBids = (request: GetPagedBidsRequest): Promise<PagedList<PagedBidResponse>> =>
  adminHttpClient.get('Products/AuctionBids', request)

export default getProductAuctionBids
