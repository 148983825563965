import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface GalleryItemForSaveDto {
  id: string
  title: string
  fileId: string
  fileThumbnailId: string
}

export const getGalleryItemForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/AdminDashboard/GalleryItem/ForSave/${id}`)
  }
  return adminHttpClient.get(`/AdminDashboard/GalleryItem/ForSave`)
}

const saveGalleryItem = (data: any): Promise<any> => {
  return adminHttpClient.post(`/AdminDashboard/GalleryItem`, data)
}

export default saveGalleryItem
