import { adminHttpClient } from '../../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from '../../commons/orderedPageFilter'
import PagedList from '../../commons/pagedList'

export interface PagedDiscountCodes extends PagedList<DiscountCode> {}

export interface DiscountCode {
  id: string
  code: string
  discount: string
  createdAt: Moment
}

const getDiscountCodes = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/discountcodes', filter)
}

export default getDiscountCodes
