import React, { useEffect, useMemo, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'

import { useHistory, useParams } from 'react-router-dom'
import saveArticle, {
  getArticlesForSave,
  SaveArticleInputDto,
  ArticleStatus,
} from 'core/application/dashboard/blog/saveArticles'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Col, Row, Skeleton } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import { Notification } from 'common/genericNotification'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import Button from 'common/components/general/Button'
import { FormikTreeSelect } from 'common/components/dataEntry/formik/FormikTreeSelectField'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'

export const MANAGE_ARTICLE_PATH = 'manage-article'

const formValidator = (translate: any) =>
  Yup.object({
    title: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    photoId: Yup.string().nullable().trim().required(translate('general.required')),
    content: Yup.string().nullable().trim().required(translate('general.required')),
    categoryIds: Yup.string().nullable().trim().required(translate('general.required')),
  })

const ManageArticle: React.FC = () => {
  const { t: translate } = useTranslation()
  let history = useHistory()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<any>({})
  const { id } = useParams<any>()
  const [_, setCategoriesOptions] = useState([])
  const availableCategories = useMemo(() => (item && item.availableCategories ? item.availableCategories : []), [item])

  const updateForm = React.useCallback(async () => {
    setLoading(true)
    const article = await queryRequest(() => getArticlesForSave(id))
    setCategoriesOptions(article.availableCategories)
    setItem(article)
    setLoading(false)
  }, [id])

  useEffect(() => {
    updateForm()
  }, [updateForm])

  const onSubmit = async (values: SaveArticleInputDto, articleStatus: ArticleStatus) => {
    const request: SaveArticleInputDto = {
      id: values.id,
      content: values.content,
      title: values.title,
      friendlyUrl: values.friendlyUrl,
      photoId: values.photoId,
      leading: values.leading,
      categoryIds: values.categoryIds,
      photoCaption: values.photoCaption,
      articleStatus: articleStatus,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
      seoImage: values.seoImage,
      shortDescription: values.shortDescription,
      link: values.link,
      source: values.source,
    }
    const result = await commandRequest(() => saveArticle(request))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.blog.article')))
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 mb-24 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={{ ...item, categoryIds: item.categoryIds || [] }}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center inner">
              <Card
                bordered={true}
                title={id ? translate('dashboard.blog.articles.edit') : translate('dashboard.blog.articles.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.categories.name')}
                        name="title"
                        placeholder={translate('dashboard.categories.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.blog.categories.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.blog.categories.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'content'}
                        placeholder={translate('dashboard.description')}
                        value={item?.content}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <FormikTextAreaField
                        label={translate('dashboard.short_description')}
                        name="shortDescription"
                        placeholder={translate('dashboard.short_description')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <FormikTreeSelect
                        label={translate('dashboard.blog.articles.category')}
                        name="categoryIds"
                        placeholder={translate('dashboard.blog.articles.category')}
                        allowClear
                        showArrow
                        multiple
                        showSearch
                        treeData={availableCategories}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Input name="link" label="Link" placeholder="Link" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Input name="source" label="Source" placeholder="Source" />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <FormikSeoFields />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Checkbox
                        label={translate('dashboard.blog.articles.leading')}
                        name="leading"
                        defaultChecked={item.leading}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center pb-24'}>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => onSubmit(formik.values, ArticleStatus.Draft)}
                  disabled={!formValidator(translate).isValidSync(formik.values)}
                  className={'ml-4'}
                >
                  {translate('dashboard.draft')}
                </Button>
                <Button
                  type="primary"
                  loading={loading}
                  onClick={() => onSubmit(formik.values, ArticleStatus.Published)}
                  disabled={!formValidator(translate).isValidSync(formik.values)}
                  className={'ml-4'}
                >
                  {translate('dashboard.save')}
                </Button>
                <Button
                  type="default"
                  loading={loading}
                  onClick={() => history.goBack()}
                  disabled={!formValidator(translate).isValidSync(formik.values)}
                  className={'ml-4'}
                >
                  {translate('dashboard.cancel')}
                </Button>
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageArticle
