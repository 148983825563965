import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Form as FormikForm, Formik } from 'formik'

import saveFile, { FileForSaveDto, FileTypeEnum, getFileForSave } from 'core/application/dashboard/files/manageFile'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Notification } from 'common/genericNotification'
import { Col, Row, Skeleton } from 'antd'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Card from 'common/components/dataDisplay/Card'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { formValidator } from './FileValidator'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import {
  isAudio,
  isImage,
  isPdf,
  isRar,
  isVideo,
  isWord,
  isZip,
} from 'common/components/dataEntry/formik/upload/fileUtils'

export const MANAGE_FILE_PATH = 'manage-file'
const ManageFile: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [item, setItem] = useState<FileForSaveDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getFileForSave(id)))
    setLoading(false)
  }
  const onSubmit = async (values: any) => {
    const fileType = isVideo(values.filename)
      ? FileTypeEnum.Video
      : isAudio(values.filename)
      ? FileTypeEnum.Audio
      : isPdf(values.filename)
      ? FileTypeEnum.Pdf
      : isWord(values.filename)
      ? FileTypeEnum.Word
      : isZip(values.filename)
      ? FileTypeEnum.Zip
      : isRar(values.filename)
      ? FileTypeEnum.Rar
      : isImage(values.filename)
      ? FileTypeEnum.Image
      : FileTypeEnum.File
    var valuesToSubmit = { ...values, type: fileType }
    const result = await commandRequest(() => saveFile(valuesToSubmit))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.sites.files.the_file')))
  }
  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.sites.files.edit') : translate('dashboard.sites.files.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Uploader
                        label={`${translate('dashboard.sites.files.the_file')}`}
                        initialValues={[item.filename]}
                        name="filename"
                        resizeToWidth={1940}
                        resizeToHeight={1940}
                        fieldName="filename"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.title')}
                        name="title"
                        placeholder={translate('dashboard.title')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageFile
