import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import useQueryRequest from 'common/hooks/useQueryRequest'
import { EntityStatus, getMenuForSave } from 'core'
import { Spin, Button } from 'antd'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { NamedItem } from 'core/application/commons/namedItem'
import { useTranslation } from 'react-i18next'
import useUrlQueryParam from 'common/hooks/useUrlQueryParam'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import generateFriendlyUrl from '../../common/generateFriendlyUrl'

const initalValues = {
  id: null,
  name: '',
  availableParents: [],
}
export interface Props {
  submitHandler: (request: any, ...props: any) => void
  isSubmitting: boolean
  reload: boolean
}
const entityStatus = [
  { id: EntityStatus.Active, name: 'Active' },
  { id: EntityStatus.Archived, name: 'Archived' },
  { id: EntityStatus.Draft, name: 'Draft' },
]
export default function CreateMenuForm({ submitHandler, isSubmitting, reload }: Props) {
  const { t } = useTranslation()
  const id: any = useUrlQueryParam('id')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [menu, setMenu] = useState<any>(null)
  const [, , query] = useQueryRequest<any>(initalValues, async () => await getMenuForSave(id), { variables: id })

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const result = await query(() => getMenuForSave(id))
      setMenu(result)
      setIsLoading(false)
    })()
  }, [reload, id])
  if (isLoading) return <Spin />

  return (
    <Formik initialValues={menu} onSubmit={submitHandler}>
      {(formik) => (
        <Form>
          <Input
            name="name"
            label={t('general.name')}
            placeholder={t('general.name')}
            onBlur={(e: any) => {
              if (id) return
              formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
            }}
          />
          <Input label={t('dashboard.friendly_url')} name="friendlyUrl" placeholder={t('dashboard.friendly_url')} />
          <Select
            allowClear={true}
            name="parentId"
            label={t('dashboard.menues.parent')}
            placeholder={t('dashboard.menues.parent')}
          >
            {menu.availableParents.map((c: NamedItem) => (
              <option value={c.id}>{c.name}</option>
            ))}
          </Select>
          <Select name="status" label={t('dashboard.status')} placeholder={t('dashboard.status')}>
            {entityStatus.map((c) => (
              <option value={c.id}>{c.name}</option>
            ))}
          </Select>
          <InputNumber name="priority" placeholder={t('dashboard.priority')} label={t('dashboard.priority')} />
          <Button htmlType="submit" type="primary" loading={isSubmitting}>
            {t('general.save')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
