import React, { useState } from 'react'
import {
  StoreAdminPanelSettingsProps,
  getStoreAdminPanelSettings,
  updateStoreAdminPanelSettings,
} from 'core/application/dashboard/storeSettings/storeAdminPanelSettings'
import { Col, Row, Skeleton } from 'antd'
import { Formik, Form as FormikForm } from 'formik'
import Card from '../../../common/components/dataDisplay/Card'
import Input from '../../../common/components/dataEntry/formik/FormikInputField'
import * as Yup from 'yup'
import useQueryRequest from '../../../common/hooks/useQueryRequest'
import { commandRequest } from '../../../common/RequestUtils'
import getLoginUser, { User } from 'core/application/account/loginUser'
import notification from '../../../common/components/feedback/Notification'
import useLoginUser from '../../../common/hooks/useLoginUser'
import { useTranslation } from 'react-i18next'
import FormikCheckboxField from '../../../common/components/dataEntry/formik/FormikCheckboxField'
import FormSaveCancelButtons from '../../../common/components/forms/SaveCancelFormButtons'

export const STORE_ADMIN_PANEL_SETTINGS_PATH = 'store-admin-panel-settings'

const formValidator = (translate: any) =>
  Yup.object({
    showStatistics: Yup.boolean().required(translate('general.required')),
  })

const StoreAdminPanelSettingsForm: React.FC = () => {
  const [item] = useQueryRequest<StoreAdminPanelSettingsProps | null>(null, () => getStoreAdminPanelSettings())
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [, setUser] = useLoginUser()
  const { t } = useTranslation()
  const [, , sendGetUserLoginRequest] = useQueryRequest<User | null>(null)

  const onSubmit = async (values: StoreAdminPanelSettingsProps) => {
    setLoadingSave(true)

    const adminPanelSettings = {
      ...values,
    }

    const result = await commandRequest(() => updateStoreAdminPanelSettings(adminPanelSettings))

    if (!result.errors) {
      const user = await sendGetUserLoginRequest(() => getLoginUser())
      setUser(user)

      notification.open({
        message: t('general.saved_plural').replace('[]', t('dashboard.store_settings.settings')),
        type: 'success',
      })
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
    setLoadingSave(false)
  }

  return (
    <Skeleton active loading={!item} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik enableReinitialize={true} initialValues={item} validationSchema={formValidator(t)} onSubmit={() => {}}>
          {(formik) => (
            <div>
              <FormikForm>
                <Card bordered={false} title={t('dashboard.store_settings.admin_panel_settings')}>
                  <Row gutter={16}>
                    <Col md={16} xs={24}>
                      <FormikCheckboxField
                        name="showStatistics"
                        id="showStatistics"
                        defaultChecked={item?.showStatistics}
                        label={t('dashboard.store_settings.admin_panel_settings_form.show_statistics')}
                      />
                    </Col>
                    <Col md={8} xs={24} style={{ display: 'none' }}>
                      <Row>
                        <Col span={18}>
                          <Input
                            label={t('dashboard.store_settings.general_settings_form.subdomain')}
                            name="subDomain"
                            placeholder={t('dashboard.store_settings.general_settings_form.subdomain')}
                          />
                        </Col>
                        <Col span={6}>
                          <div style={{ paddingTop: '27px' }}>.hermex.al</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(t).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loadingSave}
                  showCancelButton={false}
                ></FormSaveCancelButtons>
              </FormikForm>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default StoreAdminPanelSettingsForm
