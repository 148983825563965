import React from 'react'
import { Col, PageHeader, Row, Select as AntSelect, Skeleton, Card, notification } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import Button from 'common/components/general/Button'
import useQueryRequest from 'common/hooks/useQueryRequest'
import useCommandRequest from 'common/hooks/useCommandRequest'
import { getThemesForSave, saveThemes, ThemeForSave, ThemeInitialThemeValues, ThemeIsPublicMap } from 'core'
import { bool, boolean } from 'yup'
import Checkbox from '../../../common/components/dataEntry/formik/FormikCheckboxField'

const formValidator = (translate: any) =>
  Yup.object({
    isPublic: Yup.bool().required(translate('general.required')),
    name: Yup.string().required(translate('general.required')),
    id: Yup.string().required(translate('general.required')),
  })
const { Option } = AntSelect

export default function ManageTheme() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [theme, isLoading] = useQueryRequest<ThemeForSave>(ThemeInitialThemeValues, () => getThemesForSave(id))
  const [mutation, isSubmitting] = useCommandRequest()

  if (isLoading) return <Skeleton loading />

  const handleSubmit = async (request: ThemeForSave) => {
    const result = await mutation(() => saveThemes(request))
    if (result.completed) {
      notification.success({ message: `Theme ${id ? 'edited' : 'saved'} successfully!` })
      history.goBack()
    }
  }
  return (
    <Card
      title={
        <PageHeader
          className="p-0"
          title={id ? `Edit ${theme?.name}` : `Create theme`}
          onBack={() => history.goBack()}
        />
      }
    >
      <Row gutter={20}>
        <Col xs={24} md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={theme as ThemeForSave}
            validationSchema={() => formValidator(t)}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Input className="mb-0" label="Id" name="id" placeholder="Id" />
                <Input className="mb-0" label="Name" name="name" placeholder="Name" />
                <Col xs={24} className="mb-8 ps-0">
                  <Checkbox label={'IsPublic'} name="isPublic" defaultChecked={theme?.isPublic} />
                </Col>
                <Button
                  disabled={!formValidator(t).isValidSync(values)}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Card>
  )
}
