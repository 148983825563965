import React from 'react'
import { Formik, Form } from 'formik'
import { Row, Col, notification, PageHeader } from 'antd'
import Input from '../common/components/dataEntry/formik/FormikInputField'
import useCommandRequest from '../common/hooks/useCommandRequest'
import forgotPassword from 'core/application/account/forgotPassword'
import { useHistory } from 'react-router-dom'
import SubmitButton from '../common/components/general/Button'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export const FORGOT_PASSROWD_PATH = 'forgot-password'

const formValidator = (t: any) =>
  Yup.object({
    email: Yup.string().email(t('general.invalid_email')).required(t('general.required')),
  })
const ForgotPassword: React.FC = () => {
  const history = useHistory()
  const { t: translate } = useTranslation()
  const [sendCommandRequest, isSaving] = useCommandRequest<any>()

  const handleSubmit = async (values: any) => {
    const url = document.location.origin
    const { completed } = await sendCommandRequest(() => forgotPassword(values.email, url))
    if (completed) {
      notification.success({ message: 'We have sent a confirmation to your email address.' })
      history.goBack()
    }
  }

  return (
    <Row>
      <Col md={10} sm={24} lg={12}>
        <Row className="mt-4" justify="center">
          <Col md={12} sm={24}>
            <div>
              <img
                src="https://hermex.s3.eu-central-1.amazonaws.com/assets/hermex-logo-admin.svg"
                alt="Logo"
                width={150}
              />
            </div>
            <br />
            <PageHeader
              className="px-0"
              onBack={() => history.goBack()}
              title={translate('account.login.forgot_password')}
            />
            <Formik
              validationSchema={() => formValidator(translate)}
              initialValues={{ email: '' }}
              onSubmit={handleSubmit}
            >
              <Form>
                <Row className={'w-100'}>
                  <Col md={24}>
                    <Input label={translate('account.login.email')} name="email"></Input>
                  </Col>
                </Row>
                <Row>
                  <SubmitButton htmlType="submit" loading={isSaving}>
                    {translate('dashboard.send_email')}
                  </SubmitButton>
                </Row>
              </Form>
            </Formik>
          </Col>
        </Row>
      </Col>
      <Col lg={12} md={0}>
        <div className="background_primary"></div>
      </Col>
    </Row>
  )
}

export default ForgotPassword
