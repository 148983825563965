import { adminHttpClient } from '../commons/adminHttpClient'
import { CompanyStatus, NumberOfEmployeesOptions, NumberOfStoresOptions } from '../../domain'
import TitleValueMultipleSelect from '../commons/titleValueMultipleSelect'
import { NamedItemInt } from '../commons/namedItem'
import SocialSettings from '../commons/socialSettings'

export type CompanyForSaveResponse = {
  name: string
  friendlyUrl: string
  identificationCode: string
  phoneNumber: string
  description?: string
  advantages?: string
  vision?: string
  logo?: string
  website?: string
  time?: string
  coordinates?: string
  servicesSummary?: string
  clientBenefits?: string
  address?: string
  postalCode?: string
  city?: string
  countryId?: number
  regionId?: number
  email?: string
  id?: string
  status: CompanyStatus
  numberOfStores: NumberOfStoresOptions
  numberOfEmployees: NumberOfEmployeesOptions
  seoTitle?: string
  seoDescription?: string
  seoImage?: string
  serviceIds: string[]
  industryIds: string[]
  availableServices: TitleValueMultipleSelect[]
  availableIndustries: TitleValueMultipleSelect[]
  availableCountries: NamedItemInt[]
  availableRegions: Region[]
  socialSettings: SocialSettings
  secondaryPhoneNumber: string
  isVerified: boolean
  yearFounded: number
  whyUs: string
  googleMapsEmbed: string
  bannerImage: string
}

type Region = {
  id: number
  name: string
  countryId: number
}

const getCompanyForSave = (id: string): Promise<CompanyForSaveResponse> =>
  adminHttpClient.get('/Companies/ForSave', { id })

export default getCompanyForSave
