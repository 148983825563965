import React from 'react'
import { Table } from 'antd'

import { formatDateTime } from 'common/dateFormatters'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import TableEditButton from 'common/components/tables/TableEditButton'
import { MANAGE_OFFER_PATH } from './ManageOffer'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedOffers } from 'core/application/dashboard/offers/offers'
import { DISCOUNTS_PATH } from '../Routes'

interface OffersTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  offers: PagedOffers
  loading: boolean
  history: any
  translate: (key: string) => string
  onDelete: (id: string) => void
  updateOffersTable: () => void
}

const OffersTable: React.FC<OffersTableProps> = ({
  setOrderFilter,
  offers,
  loading,
  history,
  translate,
  filter,
  onDelete,
  updateOffersTable,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        width: '40%',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.offers.discount'),
        dataIndex: 'discount',
        key: 'discount',
        sorter: false,
        align: 'right',
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.discount}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="d-flex">
              <TableEditButton className="mr-16" editUrl={`/${DISCOUNTS_PATH}/${MANAGE_OFFER_PATH}/${record.id}`} />
              <TableDeleteButton onDelete={() => onDelete(record.id)} onDeleted={() => updateOffersTable()} />
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      dataSource={offers.items}
      onChange={onTableChange}
      loading={loading}
      columns={getColumns()}
      scroll={{ x: true }}
      pagination={{
        total: offers.totalItemCount,
        pageSize: offers.pageSize,
        current: offers.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default OffersTable
