import React from 'react'
import { Switch as AntSwitch } from 'antd'

export declare type SwitchChangeEventHandler = (checked: boolean, event: MouseEvent) => void

interface SwitchProps {
  checked?: true
  defaultChecked?: true
  checkedChildren?: React.ReactNode
  unCheckedChildren?: React.ReactNode
  disabled?: boolean
  size?: 'default' | 'small'
  onChange?: SwitchChangeEventHandler
}

const Switch: React.FC<SwitchProps> = (props) => {
  return <AntSwitch {...props} />
}

export default Switch
