import { adminHttpClient } from '../../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from 'application/commons/orderedPageFilter'

export interface PagedContactRequests {
  items: ContactRequestInfo[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}

export interface ContactRequestInfo {
  id: string
  name: string
  email: string
  createdAt: Moment
}

export interface ContactRequest {
  id: string
  name: string
  email: string
  phone: string | null
  subject: string
  message: string
  additionalFields: Map<string, string>
  emailSentAt: Moment
}

export const getContactRequests = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/contactrequests', filter)
}

export const getContactRequestById = (id: string): Promise<ContactRequest> => {
  return adminHttpClient.get(`/admindashboard/contactrequests/${id}`)
}
