import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import NotFound from '../../errorPages/NotFound'
import ChangePassword, { CHANGE_PASSWORD_PATH } from '../../account/profile/ChangePassword'
import { ManagementHome } from '../management/stores'
import ManageStore from 'dashboard/management/stores/ManageStore'
import ManagementThemes from '../management/themes'
import ManageModule from 'dashboard/management/modules/ManageModule'
import Modules from 'dashboard/management/modules/Modules'
import ManageTheme from '../management/themes/ManageTheme'
export const ADMIN_DASHBOARD_PATH = '/'

const ManagementRoutes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={ManagementHome} />
      <Route exact path={`${match.path}themes`} component={ManagementThemes} />
      <Route path={`${match.path}manage-store/:id?`} component={ManageStore} />
      <Route path={`${match.path}${ManageModule.url}`} component={ManageModule} />
      <Route path={`${match.path}${Modules.url}`} component={Modules} />
      <Route path={`${match.path}manage-theme/:id?`} component={ManageTheme} />
      <Route path={`${match.path}${CHANGE_PASSWORD_PATH}`} component={ChangePassword} />

      <Route component={NotFound} />
    </Switch>
  )
}

export default ManagementRoutes
