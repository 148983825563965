import React, { useEffect, useState } from 'react'
import { Row, Col, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { getDealOfTheDayOffers, PagedOffers } from 'core/application/dashboard/offers/offers'

import { MANAGE_DEAL_OF_THE_DAY_PATH } from './ManageDealOfTheDay'

import '../Discounts.less'
import deleteOffer from 'core/application/dashboard/offers/deleteOffer'
import { DISCOUNTS_PATH } from '../Routes'

import useQueryRequestV2 from '../../../common/hooks/useQueryRequestV2'
import { formatDate } from '../../../common/dateFormatters'
import TableEditButton from '../../../common/components/tables/TableEditButton'
import TableDeleteButton from '../../../common/components/tables/TableDeleteButton'

export const DEAL_OF_THE_DAY_PATH = 'deal-of-the-day'

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const DealOfTheDay: React.FC = () => {
  const { t: translate } = useTranslation()
  const [filter, setFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)

  const history = useHistory()

  const { data: offers, sendQuery } = useQueryRequestV2<PagedOffers>()

  useEffect(() => {
    ;(async () => {
      await sendQuery(() => getDealOfTheDayOffers(filter))
    })()
  }, [filter])

  const columns: any = [
    {
      title: translate('dashboard.offers.date'),
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => (
        <span>
          <div>
            <span>{formatDate(record.startDate)}</span>
          </div>
        </span>
      ),
    },
    {
      title: translate('dashboard.the_product'),
      dataIndex: 'name',
      key: 'name',
      sorter: false,
      render: (text: string, record: any) => (
        <div>
          <span className={'mr-16'}>
            <img src={record.product.mainPhotoUrl} alt={record.product.name} width={48} />
          </span>

          <span>{record.product.name}</span>
        </div>
      ),
    },
    {
      title: translate('general.sku'),
      dataIndex: 'stockKeepingUnit',
      key: 'stockKeepingUnit',
      sorter: false,
      render: (text: string, record: any) => record.product?.stockKeepingUnit,
    },
    {
      title: translate('dashboard.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      responsive: ['xl'],
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => formatDate(record.createdAt),
    },
    {
      title: translate('dashboard.offers.discount'),
      dataIndex: 'discount',
      key: 'discount',
      sorter: false,
      align: 'right',
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      sorter: false,
      render: (text: string, record: any) => (
        <span>
          <div className="flex-center">
            <TableEditButton
              className="mr-16"
              editUrl={`/${DISCOUNTS_PATH}/${MANAGE_DEAL_OF_THE_DAY_PATH}/${record.id}`}
            />
            <TableDeleteButton onDelete={() => deleteOffer(record.id)} onDeleted={() => setFilter({ ...filter })} />
          </div>
        </span>
      ),
    },
  ]

  const columnToSort: any = columns.find((c: any) => c.key === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  return (
    <>
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.offers.deal_of_the_day')}</h4>
            </Col>
            <Col xs={6} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${DISCOUNTS_PATH}/${MANAGE_DEAL_OF_THE_DAY_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          dataSource={offers?.items}
          onChange={(pagination: any, filters: any, sorter: any) => {
            setFilter({
              ...filter,
              pageNumber: pagination.current,
              pageSize: pagination.pageSize,
              orderColumn: sorter.field || 'createdAt',
              orderDescending: sorter.order !== 'ascend',
            })
          }}
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            total: offers?.totalItemCount,
            pageSize: offers?.pageSize,
            current: offers?.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default DealOfTheDay
