import React from 'react'
import { Layout as AntLayout } from 'antd'

interface Props {
  children?: React.ReactNode
  className?: string
}

const Layout: React.FC<any> = (props) => {
  return (
    <>
      <AntLayout {...props}>{props.children}</AntLayout>
    </>
  )
}

export default Layout
