import React from 'react'
import { Table, Dropdown, Menu } from 'antd'

import { StoreShippingRate } from 'core/application/dashboard/storeSettings/storeShippingSettings'
import { ShippingRateType } from 'core/domain/store/shippingRateType'
import { User } from 'core/application/account/loginUser'

import { formatMoney } from 'common/moneyFormatter'
import Icon from 'common/components/general/Icon'

interface ShippingRatesTableProps {
  user: User | null
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  shippingRates: StoreShippingRate[]
  loadingShippingRates: boolean
  translate: (key: string) => string
}

const displayShippingRateCondition = (
  shippingRate: StoreShippingRate,
  user: User | null,
  translate: (key: string) => string,
) => {
  if (shippingRate.rateType === ShippingRateType.Simple) return '--'

  if (shippingRate.rateType === ShippingRateType.OrderPrice) {
    var currency = user !== null ? user.currency : ''
    if (shippingRate.maximum == null)
      return `${formatMoney(shippingRate.minimum, currency)} ${translate(
        'dashboard.store_settings.shipping_rates.and_up',
      )}`
    return `${formatMoney(shippingRate.minimum, currency)} - ${formatMoney(shippingRate.maximum, currency)}`
  }
  if (shippingRate.rateType === ShippingRateType.WeightBased) {
    var weightUnit = user !== null ? user.weightUnit : ''
    if (shippingRate.maximum == null)
      return `${shippingRate.minimum} ${weightUnit} ${translate('dashboard.store_settings.shipping_rates.and_up')}`
    return `${shippingRate.minimum} ${weightUnit} - ${shippingRate.maximum} ${weightUnit}`
  }
}

const displayShippingRatePrice = (price: number, user: User | null, translate: (key: string) => string) => {
  var currency = user !== null ? user.currency : ''
  return price === 0 ? translate('dashboard.store_settings.shipping_rates.free') : formatMoney(price, currency)
}

const menu = (translate: (key: string) => string, onEdit: () => void, onDelete: () => void) => (
  <Menu>
    <Menu.Item onClick={onEdit}>
      <div>
        <Icon type="far fa-pencil" className="mr-8" />
        <span>{translate('general.edit')}</span>
      </div>
    </Menu.Item>
    <Menu.Item onClick={onDelete}>
      <div>
        <Icon type="far fa-trash-alt" className="mr-8" />
        <span>{translate('general.delete')}</span>
      </div>
    </Menu.Item>
  </Menu>
)

const ShippingRatesTable: React.FC<ShippingRatesTableProps> = ({
  user: user,
  onEdit: onEdit,
  onDelete: onDelete,
  shippingRates: shippingRates,
  loadingShippingRates: loadingShippingRates,
  translate,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.store_settings.shipping_rates.name'),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.shippingMethod.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.store_settings.shipping_rates.condition'),
        dataIndex: 'rateType',
        key: 'rateType',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{displayShippingRateCondition(record, user, translate)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.store_settings.shipping_rates.price'),
        dataIndex: 'rateType',
        key: 'rateType',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{displayShippingRatePrice(record.price, user, translate)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        dataIndex: '',
        key: '',
        render: (text: string, record: any) => (
          <span>
            <div className="actions">
              <Dropdown
                overlay={menu(
                  translate,
                  () => onEdit(record.id),
                  () => onDelete(record.id),
                )}
                placement="bottomCenter"
              >
                <div>
                  <Icon type="far fa-ellipsis-h" />
                </div>
              </Dropdown>
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <Table
      dataSource={shippingRates}
      loading={loadingShippingRates}
      columns={getColumns()}
      pagination={false}
      scroll={{ x: true }}
    />
  )
}

export default ShippingRatesTable
