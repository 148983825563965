import React, { useState, useEffect } from 'react'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import moment, { Moment } from 'moment'

import CustomCard from '../../common/components/dataDisplay/CustomCard'
import Card from '../../common/components/dataDisplay/Card'

import StatisticCard from './statistics/StatisticCard'
import OrdersChart from './statistics/OrdersChart'
import OrdersTable from '../orders/OrdersTable'
import { queryRequest, commandRequest } from '../../common/RequestUtils'
import notification from '../../common/components/feedback/Notification'
import OrdersFilter from '../orders/OrdersFilter'
import DatePicker from '../../common/components/dataEntry/components/DatePicker'
import { formatMoney } from '../../common/moneyFormatter'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getOrders, { PagedOrders } from 'core/application/dashboard/home/orders/orders'
import getAvailableStatuses from 'core/application/dashboard/home/orders/availableOrderStatuses'
import updateStatus, { OrderStatusToUpdate } from 'core/application/dashboard/home/orders/updateOrderStatus'
import getOrderStatistics, { MonthInfo } from 'core/application/dashboard/home/statistics/orderStatistics'
import getStoreStatistics, { StoreStatistics } from 'core/application/dashboard/home/statistics/storeStatistics'
import { NamedItem } from 'core/application/commons/namedItem'
import './Home.less'
import useLoginUser from '../../common/hooks/useLoginUser'
import OrdersStatisticsCard from './statistics/OrdersStatisticsCard'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import {
  getStoreAdminPanelSettings,
  StoreAdminPanelSettingsProps,
} from 'core/application/dashboard/storeSettings/storeAdminPanelSettings'

export const HOME_PATH = '/'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter: OrderedPageFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [selectedYear, setSelectedYear] = useState<Moment>(moment())
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false)
  const [orders, setOrders] = useState<PagedOrders>(pagedColumnDefaultValues)
  const [availableOrderStatuses, setAvailableOrderStatuses] = useState<NamedItem[]>([])
  const [orderStatistics, setOrderStatistics] = useState<MonthInfo[]>([])
  const [loadingOrderStatistics, setLoadingOrderStatistics] = useState<boolean>(false)
  const [user] = useLoginUser()

  const [storeStatistics, setStoreStatistics] = useState<StoreStatistics | null>(null)
  const [loadingStoreStatistics, setLoadingStoreStatistics] = useState<boolean>(false)
  const [dashboardStatistics] = useQueryRequest<StoreAdminPanelSettingsProps | null>(null, () =>
    getStoreAdminPanelSettings(),
  )

  useEffect(() => {
    updateOrdersTable(searchFilter)
  }, [searchFilter])

  useEffect(() => {
    getOrderStatuses()
    getOrderStats(null)
    getStoreStats()
  }, [])

  const updateOrdersTable = async (searchFilter: OrderedPageFilter) => {
    setLoadingOrders(true)
    const result = await queryRequest(() => getOrders(searchFilter))
    setOrders(result)
    setLoadingOrders(false)
  }

  const getOrderStatuses = async () => {
    const result = await queryRequest(() => getAvailableStatuses())
    result.unshift({ id: -1, name: 'Të gjitha statuset' })
    setAvailableOrderStatuses(result)
  }

  const getOrderStats = async (year: number | null) => {
    setLoadingOrderStatistics(true)
    const result = await queryRequest(() => getOrderStatistics(year))
    setOrderStatistics(result)
    setLoadingOrderStatistics(false)
  }

  const getStoreStats = async () => {
    setLoadingStoreStatistics(true)
    const result = await queryRequest(() => getStoreStatistics())
    setStoreStatistics(result)
    setLoadingStoreStatistics(false)
  }

  const updateOrderStatus = async (order: OrderStatusToUpdate) => {
    const result = await commandRequest(() => updateStatus(order))
    if (!result.errors) {
      notification.open({
        type: 'success',
        message: translate('general.saved').replace('[]', translate('dashboard.the_order_status')),
      })
      await updateOrdersTable(searchFilter)
      await getOrderStats(null)
      await getStoreStats()
    } else {
      notification.open({
        type: 'error',
        message: result.errors[0],
      })
    }
  }

  const onDateChange = (date: Moment | null, dateString: string) => {
    getOrderStats(+dateString)
    setSelectedYear(moment(dateString))
  }

  return (
    user && (
      <div>
        {dashboardStatistics && dashboardStatistics.showStatistics && (
          <Row>
            <Col xs={24} md={24} lg={12}>
              <Row className="store_statistics">
                <Col xs={24} md={12} lg={12} className="pr_2rem  pb_2rem statistic_card">
                  <StatisticCard
                    title={translate('dashboard.sales')}
                    amount={formatMoney(storeStatistics?.sales ?? 0, user.currency)}
                    loading={loadingStoreStatistics}
                  />
                </Col>
                <Col xs={24} md={12} lg={12} className="pr_2rem pb_2rem statistic_card">
                  <OrdersStatisticsCard
                    statistics={storeStatistics?.orders}
                    loading={loadingStoreStatistics}
                    translate={translate}
                  />
                </Col>
                <Col xs={24} md={12} lg={12} className="pr_2rem statistic_card mb_mobile">
                  <StatisticCard
                    title={translate('dashboard.clients')}
                    amount={storeStatistics?.clients ?? 0}
                    loading={loadingStoreStatistics}
                  />
                </Col>
                <Col xs={24} md={12} lg={12} className="pr_2rem statistic_card">
                  <StatisticCard
                    title={translate('dashboard.products.title')}
                    amount={storeStatistics?.products ?? 0}
                    loading={loadingStoreStatistics}
                  />
                </Col>
              </Row>
            </Col>

            <Col xs={24} md={24} lg={12} className="order_history">
              <CustomCard className="custom_card__padding">
                <Skeleton active loading={loadingOrderStatistics} paragraph={{ rows: 5 }}>
                  <div className="flex flex__space_between">
                    <div>
                      <h4>{translate('dashboard.orders_history')}</h4>
                    </div>
                    <div className="flex flex__end pt-8">
                      <DatePicker
                        onChange={onDateChange}
                        placeholder={translate('dashboard.select_year')}
                        picker="year"
                        value={selectedYear}
                        allowClear={false}
                      />
                    </div>
                  </div>
                  <div className="mt-16">
                    <OrdersChart items={orderStatistics} />
                  </div>
                </Skeleton>
              </CustomCard>
            </Col>
          </Row>
        )}

        <Row>
          <Col xs={24} className="orders_table mt_2rem">
            <Card bordered={true} title={translate('dashboard.orders.title')}>
              <OrdersFilter
                translate={translate}
                updateOrdersTable={updateOrdersTable}
                availableStatuses={availableOrderStatuses}
                setSearchFilter={setSearchFilter}
                user={user}
              />

              <OrdersTable
                setOrderFilter={setSearchFilter}
                filter={searchFilter}
                orders={orders}
                loadingOrders={loadingOrders}
                history={history}
                translate={translate}
                orderStatuses={availableOrderStatuses}
                updateOrderStatus={updateOrderStatus}
              />
            </Card>
          </Col>
        </Row>
      </div>
    )
  )
}

export default Home
