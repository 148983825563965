import React from 'react'
import { Table, Modal } from 'antd'

import { formatDate } from 'common/dateFormatters'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import TableEditButton from 'common/components/tables/TableEditButton'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { EMAILS_PATH_EDIT } from './ManageEmail'
import { PagedEmails } from 'core/application/emailSubscriptions/emails'
import { EMAIL_SUBSCRIPTIONS_PATH } from '../Routes'

import Button from '../../../common/components/general/Button'

const { confirm } = Modal

interface EmailsTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  history: any
  emails: PagedEmails
  loadingEmails: boolean
  translate: (key: string) => string
  onDeleteEmail: (id: string) => void
  updateEmailsTable: () => void
  onSendEmailToSubscriptions: (emailId: string) => void
}

const EmailsTable: React.FC<EmailsTableProps> = ({
  setOrderFilter,
  filter,
  history,
  emails,
  loadingEmails,
  translate,
  onDeleteEmail,
  updateEmailsTable,
  onSendEmailToSubscriptions,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.email_subscriptions.emails.name'),
        dataIndex: 'name',
        key: 'title',
        width: '40%',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <>
            <Button
              className={'mr-16'}
              icon="fal fa-mail-bulk"
              iconPadding={false}
              onClick={() => {
                onSendEmailToSubscriptions(record.id)
              }}
            ></Button>
            <TableEditButton
              className="mr-16"
              editUrl={`/${EMAIL_SUBSCRIPTIONS_PATH}/${EMAILS_PATH_EDIT}/${record.id}`}
            />
            <TableDeleteButton
              disableDeleted={record.children?.length > 0}
              onDelete={() => onDeleteEmail(record.id)}
              onDeleted={() => updateEmailsTable()}
            />
          </>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={emails.items}
      onChange={onTableChange}
      loading={loadingEmails}
      columns={getColumns()}
      pagination={{
        total: emails.totalItemCount,
        pageSize: emails.pageSize,
        current: emails.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default EmailsTable
