import React, { useState } from 'react'
import { Table, Menu, Dropdown, message, Modal, notification, Rate, Row, Col } from 'antd'

import { formatDateTime } from '../../common/dateFormatters'
import { formatMoney } from '../../common/moneyFormatter'
import Icon from 'common/components/general/Icon'
import { ADMIN_DASHBOARD_PATH } from '../routes/StoreRoutes'
import { PRODUCTS_PATH } from './Products'
import { MANAGE_PRODUCTS_PATH } from './ManageProduct'
import QuickEditForm from './QuickEditForm'

import { PagedProducts, Product, ProductFilter } from 'core/application/dashboard/products/products'
import { QuickEditProductRequest, saveQuickEditProduct } from 'core/application/dashboard/products/quickEditProduct'
import { toggleFavourite } from 'core/application/dashboard/products/toggleFavourite'
import { commandRequest } from 'common/RequestUtils'
import { duplicateProduct } from 'core/application/dashboard/products/duplicateProduct'
import { addRelatedProduct } from 'core/application/dashboard/products/addRelatedProduct'
import Tag from 'common/components/dataDisplay/Tag'
import TagInput from 'common/components/dataEntry/components/Tag'
import { getOrderItemTitleMap, orderItemTagColorMap } from 'dashboard/orders/OrderStatusActions'
import { LanguageDto } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import { MANAGE_PRODUCT_TRANSLATION_PATH } from './ManageProductTranslation'

import '../Dashboard.less'
import { useCommandRequestV2 } from 'common/hooks'

interface ProductsTableProps {
  setOrderFilter: (value: ProductFilter) => void
  filter: ProductFilter
  products: PagedProducts
  loadingOrders: boolean
  history: any
  translate: (key: string) => string
  onDelete: (id: string) => void
  updateProductsTable: () => Promise<void>
  languages: LanguageDto[]
}

const ProductsTable: React.FC<ProductsTableProps> = ({
  setOrderFilter,
  products,
  loadingOrders,
  history,
  translate,
  filter,
  onDelete,
  updateProductsTable,
  languages,
}) => {
  const { confirm } = Modal

  const { sendCommand: submitQuickEdit, isLoading: isQuickEditLoading } = useCommandRequestV2(saveQuickEditProduct)

  const [quickEditProduct, setQuickEditProduct] = useState<Product>()
  const [showQuickEditProductForm, setShowQuickEditProductForm] = useState<boolean>(false)

  const onQuickEditProductClick = async (product: Product) => {
    await displayQuickEditProductForm(product)
  }

  const displayQuickEditProductForm = async (product: Product) => {
    setQuickEditProduct(product)
    setShowQuickEditProductForm(true)
  }

  const onSubmitQuickEditProduct = async (values: QuickEditProductRequest) => {
    const result = await submitQuickEdit(values)
    if (!result.completed) return notification.open({ message: result.errors[0], type: 'error' })

    notification.open({
      message: translate('general.saved').replace('[]', translate('dashboard.the_product')),
      type: 'success',
    })
    setShowQuickEditProductForm(false)
    setQuickEditProduct(undefined)
    await updateProductsTable()
  }

  const onDuplicate = async (id: string) => {
    const result = await duplicateProduct(id)

    history.push(`${ADMIN_DASHBOARD_PATH}${PRODUCTS_PATH}/${MANAGE_PRODUCTS_PATH}/${result}`)
  }

  const onAddRelatedProduct = async (id: string) => {
    const result = await addRelatedProduct(id)

    history.push(`${ADMIN_DASHBOARD_PATH}${PRODUCTS_PATH}/${MANAGE_PRODUCTS_PATH}/${result}`)
  }

  const menu = (
    translate: (key: string) => string,
    parentProductId: string | null,
    onQuickEdit: () => void,
    onEdit: () => void,
    onDelete: () => void,
    onDuplicate: () => void,
    onAddRelatedProduct: () => void,
  ) => (
    <Menu>
      <Menu.Item onClick={onQuickEdit}>
        <div>
          <Icon type="far fa-edit" className="mr-8" />
          <span>{translate('general.quick_edit')}</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={onEdit}>
        <div>
          <Icon type="far fa-pencil" className="mr-8" />
          <span>{translate('general.edit')}</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={onDuplicate}>
        <div>
          <Icon type="far fa-copy" className="mr-8" />
          <span>{translate('general.duplicate')}</span>
        </div>
      </Menu.Item>
      {!parentProductId && (
        <Menu.Item onClick={onAddRelatedProduct}>
          <div>
            <Icon type="far fa-link" className="mr-8" />
            <span>{translate('general.add_related_product')}</span>
          </div>
        </Menu.Item>
      )}
      <Menu.Item onClick={onDelete}>
        <div>
          <Icon type="far fa-trash-alt" className="mr-8" />
          <span>{translate('general.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  )

  const onConfirmDelete = (onDelete: () => void, onDeleted: () => void) => {
    return confirm({
      title: translate('general.delete_alert_title'),
      content: translate('general.delete_alert_content'),
      okText: translate('general.ok_text'),
      cancelText: translate('general.cancel_text'),
      onOk: async () => {
        const result = await commandRequest(() => onDelete())
        if (!result.errors) {
          onDeleted()
        } else {
          message.error(result.errors[0])
        }
      },
    })
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <Row align="middle" gutter={10}>
            <Col span={6}>
              <img src={record.mainPhotoUrl} alt={record.name} style={{ maxWidth: '100%' }} />
            </Col>
            <Col span={16}>{record.name}</Col>
          </Row>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => formatDateTime(record.createdAt),
      },
      {
        title: translate('dashboard.products.price'),
        dataIndex: 'price',
        key: 'price',
        responsive: ['md'],
        sorter: true,
        align: 'right',
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => formatMoney(record.price, record.currency),
      },
      {
        title: translate('dashboard.products.stock'),
        dataIndex: 'stock',
        key: 'stock',
        align: 'center',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => record.stock,
      },
      {
        title: translate('general.sku'),
        dataIndex: 'stockKeepingUnit',
        key: 'stockKeepingUnit',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        align: 'center',
        render: (text: string, record: any) => {
          return (
            <>
              <div>
                <Tag color={orderItemTagColorMap.get(record.status)}>
                  {getOrderItemTitleMap(translate).get(record.status)}
                </Tag>
              </div>
            </>
          )
        },
      },
      {
        title: translate('dashboard.products.favourite'),
        dataIndex: 'favourite',
        key: 'favourite',
        align: 'center',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>
                <Rate
                  count={1}
                  value={record.favourite ? 1 : 0}
                  onChange={async () => {
                    await toggleFavourite(record.id)
                    await updateProductsTable()
                  }}
                  tooltips={
                    record.favourite
                      ? [translate('dashboard.products.unmark_favourite')]
                      : [translate('dashboard.products.mark_favourite')]
                  }
                />
              </span>
            </div>
          </span>
        ),
      },
      languages.length > 0
        ? {
            title: translate('dashboard.translations'),
            dataIndex: 'translations',
            key: 'translations',
            align: 'center',
            responsive: ['md'],
            render: (text: string, record: any) => {
              return (
                <span>
                  <div>
                    <Row className="translation-row">
                      {languages.map((val: LanguageDto, index: number) => (
                        <TagInput
                          className="translation-tag"
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/${MANAGE_PRODUCT_TRANSLATION_PATH}/${record.id}/${val.id}`,
                            )
                          }}
                        >
                          {val.name}
                        </TagInput>
                      ))}
                    </Row>
                  </div>
                </span>
              )
            },
          }
        : {},
      {
        title: translate('dashboard.action'),
        width: '10%',
        dataIndex: '',
        key: 'actions',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="actions">
              <Dropdown
                overlay={menu(
                  translate,
                  record.parentProductId,
                  () => onQuickEditProductClick(record),
                  () => history.push(`${ADMIN_DASHBOARD_PATH}${PRODUCTS_PATH}/${MANAGE_PRODUCTS_PATH}/${record.id}`),
                  () => onConfirmDelete(() => onDelete(record.id), updateProductsTable),
                  () => onDuplicate(record.id),
                  () => onAddRelatedProduct(record.id),
                )}
                placement="bottomRight"
              >
                <div className="dropdown_actions">
                  <Icon type="far fa-ellipsis-h" />
                </div>
              </Dropdown>
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    } as ProductFilter)
  }

  return (
    <>
      <Table
        dataSource={products.items}
        onChange={onTableChange}
        loading={loadingOrders}
        columns={getColumns()}
        pagination={{
          total: products.totalItemCount,
          pageSize: products.pageSize,
          current: products.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />

      {quickEditProduct && (
        <QuickEditForm
          initialValues={quickEditProduct}
          visible={showQuickEditProductForm}
          onCancel={() => {
            setQuickEditProduct(undefined)
            setShowQuickEditProductForm(false)
          }}
          onSubmit={onSubmitQuickEditProduct}
          submitting={isQuickEditLoading}
        />
      )}
    </>
  )
}

export default ProductsTable
