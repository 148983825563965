import { commandRequest, queryRequest } from 'common/RequestUtils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Col, Row, Skeleton } from 'antd'
import { Formik, Form as FormikForm } from 'formik'

import { Notification } from 'common/genericNotification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Card from 'common/components/dataDisplay/Card'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import getBrandForSave, { Brand, saveBrand } from 'core/application/dashboard/brands/manageBrand'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'

export const MANAGE_BRAND_PATH = 'manage-brand'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .min(0, translate('general.minimun_required')),
  })

const ManageBrand: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [item, setItem] = useState<Brand | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getBrandForSave(id))
    setItem(result)
    setLoadingData(false)
  }

  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: Brand) => {
    setLoading(true)
    const result = await commandRequest(() =>
      saveBrand({ ...values, id: values.id === '00000000-0000-0000-0000-000000000000' ? undefined : values.id }),
    )
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.brands.name')))
    setLoading(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card bordered={true} title={id ? translate('dashboard.brands.edit') : translate('dashboard.brands.add')}>
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.name')}
                        name="name"
                        placeholder={translate('general.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'description'}
                        placeholder={translate('dashboard.description')}
                        value={item?.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={translate('dashboard.photo')}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={translate('dashboard.thumbnail')}
                        initialValues={[item.photoThumbnailId]}
                        name="photoThumbnailId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoThumbnailId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <FormikSeoFields />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageBrand
