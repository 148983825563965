import { notification } from 'antd'

export const Notification = (result: any, successMessage: string) => {
  if (!result.errors) {
    notification.open({ message: successMessage, type: 'success' })
    window.history.back()
  } else {
    notification.open({ message: result.errors[0], type: 'error' })
  }
}
