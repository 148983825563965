import { OrderedPageFilter } from '../../commons'
import { adminHttpClient } from '../../commons/adminHttpClient'
import { DynamicEntityForSave } from './types'

const URL = `/admindashboard/dynamicentities`

export async function getDynamicEntetiesForSaveQuery(id?: string | undefined): Promise<DynamicEntityForSave> {
  if (id) {
    return await adminHttpClient.get(`${URL}/forsave/${id}`)
  }
  return await adminHttpClient.get(`${URL}/forsave`)
}

export const getDynamicEntetiesQuery = async (filter: OrderedPageFilter) => await adminHttpClient.get(URL, filter)

export const saveDynamicEntityCommand = async (request: DynamicEntityForSave) => adminHttpClient.post(URL, request)

export const deleteDynamicEntity = async (id: string) => {
  return await adminHttpClient.delete(`${URL}/${id}`, { id: id })
}
