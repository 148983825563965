import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import NotFound from '../../errorPages/NotFound'
import ChangePassword, { CHANGE_PASSWORD_PATH } from '../../account/profile/ChangePassword'
import { SERVICES_PATH } from '../services/Services'
import Services from '../services'
import Projects from '../projects'
import Testimonials from '../testimonials'
import { PROJECTS_PATH } from '../projects/Projects'
import { TESTIMONIALS_PATH } from '../testimonials/Testimonials'
import BlogCategory from '../blog/categories'
import { BLOG_CATEGORY_PATH } from '../blog/categories/BlogCategory'
import Banners from '../banners'
import { BANNERS_PATH } from '../banners/Banners'
import { ARTICLES_PATH } from '../blog/Articles'
import Articles from '../blog'
import TeamMembers from '../teamMembers'
import { TEAM_MEMBERS_PATH } from '../teamMembers/TeamMembers'
import SiteHome from '../siteHome'
import { SETTINGS_PATH } from '../storeSettings/Routes'
import StoreSettings from '../storeSettings'
import { EMAIL_SUBSCRIPTIONS_PATH } from '../emailSubscriptions/Routes'
import EmailSubscriptions from '../emailSubscriptions'
import Files from '../files'
import { FILES_PATH } from '../files/Files'
import { GALLERY_PATH } from '../galleries/Gallery'
import Gallery from '../galleries'
import { GALLERY_ITEM_PATH } from '../galleries/galleryItems/GalleryItem'
import GalleryItem from '../galleries/galleryItems'
import { ROOM_TYPE_PATH } from '../roomTypes/RoomType'
import RoomType from '../roomTypes'
import ContactRequests from '../contacts'
import { CONTACT_REQUESTS_PATH } from '../contacts/ContactRequests'
import { JOB_CATEGORIES_PATH } from '../careers/categories/JobCategories'
import JobCategories from '../careers/categories'
import { JOB_POSITIONS_PATH } from '../careers/jobs/JobPositions'
import JobPositions from '../careers/jobs'
import Menu, { RESTAURANT_MENU_PATH } from 'dashboard/restaurants/Menu'
import MenuItems, { RESTAURANT_MENU_ITEMS_PATH } from 'dashboard/restaurants/MenuItems'
import Appointments from '../appointments'
import ROUTE_PATHS from '../../common/routePaths'
import FutureAppointments from '../appointments/futuredAppointments/FutureAppointments'
import { FUTURE_APPOINTMENTS_PATH } from '../appointments/futuredAppointments/FutureAppointments'
import Companies from '../companies'
import ManageCompanies from 'dashboard/companies/ManageCompanies'
import PageSection from '../pageSections/PageSection'
import ManagePageSection from '../pageSections/ManagePageSection'
export const ADMIN_DASHBOARD_PATH = '/'

const StoreRoutes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={SiteHome} />
      <Route path={`${match.path}${ARTICLES_PATH}`} component={Articles} />
      <Route path={`${match.path}${BLOG_CATEGORY_PATH}`} component={BlogCategory} />
      <Route path={`${match.path}${BANNERS_PATH}`} component={Banners} />
      <Route path={`${match.path}${SERVICES_PATH}`} component={Services} />
      <Route path={`${match.path}${PROJECTS_PATH}`} component={Projects} />
      <Route path={`${match.path}${TESTIMONIALS_PATH}`} component={Testimonials} />
      <Route path={`${match.path}${TEAM_MEMBERS_PATH}`} component={TeamMembers} />
      <Route path={`${match.path}${FILES_PATH}`} component={Files} />
      <Route path={`${match.path}${SETTINGS_PATH}`} component={StoreSettings} />
      <Route path={`${match.path}${EMAIL_SUBSCRIPTIONS_PATH}`} component={EmailSubscriptions} />
      <Route path={`${match.path}${GALLERY_PATH}`} component={Gallery} />
      <Route path={`${match.path}${GALLERY_ITEM_PATH}`} component={GalleryItem} />
      <Route path={`${match.path}${ROOM_TYPE_PATH}`} component={RoomType} />
      <Route path={`${match.path}${CONTACT_REQUESTS_PATH}`} component={ContactRequests} />
      <Route path={`${match.path}${JOB_POSITIONS_PATH}`} component={JobPositions} />
      <Route path={`${match.path}${JOB_CATEGORIES_PATH}`} component={JobCategories} />
      <Route path={`${match.path}${RESTAURANT_MENU_PATH}`} component={Menu} />
      <Route path={`${match.path}${RESTAURANT_MENU_ITEMS_PATH}`} component={MenuItems} />
      <Route path={`${ROUTE_PATHS.APPOINTMENTS}`} component={Appointments} />
      <Route path={`${match.path}${FUTURE_APPOINTMENTS_PATH}`} component={FutureAppointments} />
      <Route path={`${match.path}${CHANGE_PASSWORD_PATH}`} component={ChangePassword} />
      <Route exact path={ROUTE_PATHS.COMPANIES} component={Companies} />
      <Route exact path={`${ROUTE_PATHS.COMPANIES_MANAGE}/:id?`} component={ManageCompanies} />

      <Route exact path={`${ROUTE_PATHS.PAGE_SECTIONS_MANAGE}/:id?`} component={ManagePageSection} />
      <Route exact path={ROUTE_PATHS.PAGE_SECTIONS} component={PageSection} />

      {/*<Route exact path={`${ROUTE_PATHS.PAGE_SECTIONS}`} component={ManagePageSection} />*/}

      <Route component={NotFound} />
    </Switch>
  )
}

export default StoreRoutes
