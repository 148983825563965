export enum Module {
  Home = 0,
  Orders = 1,
  Banners = 2,
  Blog = 3,
  Clients = 4,
  Contacts = 5,
  EmailSubscriptions = 6,
  Galleries = 7,
  Offers = 8,
  Products = 9,
  RoomTypes = 10,
  Settings = 11,
  Services = 12,
  Projects = 13,
  Testimonials = 14,
  TeamMembers = 15,
  Files = 16,
  GeneralSettings = 17,
  ShippingSettings = 18,
  SiteSettings = 19,
  TemplateSettings = 20,
  SocialSettings = 21,
  Pages = 22,
  Menus = 23,
  PaymentMethods = 24,
  SupportedLanguages = 25,
  SupportedCurrencies = 26,
  ManagementHome = 27,
  ManagementTheme = 28,
  Jobs = 29,
  RestaurantMenu = 30,
  Appointment = 31,
  Reports = 32,
  AdminPanelSettings = 33,
  PopUp = 34,
  Companies = 35,
}

export const Modules = new Map<Module, string>([
  [Module.Home, 'Home'],
  [Module.Orders, 'Orders'],
  [Module.Banners, 'Banners'],
  [Module.Blog, 'Blog'],
  [Module.Clients, 'Clients'],
  [Module.Contacts, 'Contacts'],
  [Module.EmailSubscriptions, 'Email Subscriptions'],
  [Module.Galleries, 'Galleries'],
  [Module.Offers, 'Offers'],
  [Module.Products, 'Products'],
  [Module.RoomTypes, 'Room Types'],
  [Module.Settings, 'Settings'],
  [Module.Services, 'Services'],
  [Module.Projects, 'Projects'],
  [Module.Testimonials, 'Testimonials'],
  [Module.TeamMembers, 'TeamMembers'],
  [Module.Files, 'Files'],
  [Module.GeneralSettings, 'General Settings'],
  [Module.ShippingSettings, 'Shipping Settings'],
  [Module.SiteSettings, 'Site Settings'],
  [Module.TemplateSettings, 'Template Settings'],
  [Module.SocialSettings, 'Social Settings'],
  [Module.Pages, 'Pages'],
  [Module.Menus, 'Menus'],
  [Module.PaymentMethods, 'Payment Methods'],
  [Module.SupportedLanguages, 'Supported Languages'],
  [Module.SupportedCurrencies, 'Supported Currencies'],
  [Module.ManagementHome, 'ManagementHome'],
  [Module.ManagementTheme, 'ManagementTheme'],
  [Module.Jobs, 'Jobs'],
  [Module.RestaurantMenu, 'Restaurant menues'],
  [Module.Appointment, 'Appointments'],
  [Module.Reports, 'Reports'],
  [Module.AdminPanelSettings, 'Admin Panel Settings'],
  [Module.PopUp, 'Pop up Settings'],
])
