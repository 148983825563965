import OrderedPageFilter from '../../commons/orderedPageFilter'
import { adminHttpClient } from '../../commons/adminHttpClient'

export type PageSectionResponse = {
  id: string
  name: string
  status: number
  createdAt: Date
}

export type PagedColumns = {
  items: PageSectionResponse[]
  pageCount: 1
  pageNumber: 1
  pageSize: 10
  totalItemCount: 0
}

export type DefaultPopUp = {
  id: string
  title: string
  content: string
}
export const POPUP_TEMPLATES = {
  defaultPopUp: 'default',
}
export const getPageSections = (filter: OrderedPageFilter): Promise<PagedColumns> => {
  return adminHttpClient.get(`/AdminDashboard/PageSections`, filter)
}

export const getPageSectionForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/AdminDashboard/PageSections/PageSectionForSave/${id}`)
  }
  return adminHttpClient.get(`/AdminDashboard/PageSections/PageSectionForSave`)
}

export const savePageSection = (values: any): Promise<any> => {
  return adminHttpClient.post(`/AdminDashboard/PageSections`, values)
}

export const deletePageSection = (id: any): Promise<any> => {
  return adminHttpClient.delete(`/AdminDashboard/PageSections`, {
    data: { id: id },
  })
}
export const getDefaultPageSection = (): Promise<DefaultPopUp[]> => {
  return adminHttpClient.get(`/AdminDashboard/PageSections/DefaultPageSection`)
}
