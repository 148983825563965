import { Col, Row, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import { queryRequest } from 'common/RequestUtils'
import OrderedPageFilter, { defaultFilter } from 'core/application/commons/orderedPageFilter'
import PagedList from 'core/application/commons/pagedList'
import { MANAGE_TEAM_MEMBERS_PATH } from './ManageTeamMember'
import getTeamMember from 'core/application/dashboard/teamMembers/teamMembers'
import { formatDate } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import deleteTeamMember from 'core/application/dashboard/teamMembers/deleteTeamMember'
import { TeamMemberStatus, TeamMemberStatusEnum } from 'core/application/dashboard/teamMembers/manageTeamMember'
import Tag from 'common/components/dataDisplay/Tag'

export const TEAM_MEMBERS_PATH = 'team-members'
export const teamMemberTagColor = new Map<TeamMemberStatusEnum, string>([
  [TeamMemberStatusEnum.Draft, '#fbbc06'],
  [TeamMemberStatusEnum.Active, '#23af47'],
  [TeamMemberStatusEnum.Archived, '#e94437'],
])
const TeamMember: React.FC = () => {
  const { t } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [item, setitem] = useState<PagedList<any>>({} as PagedList<any>)
  const [loading, setloading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    updateTable()
  }, [searchFilter])

  const updateTable = async () => {
    setloading(true)
    setitem(await queryRequest(() => getTeamMember(searchFilter)))
    setloading(false)
  }
  const getColumns = (): any[] => {
    const columns = [
      {
        title: t('dashboard.sites.team_members.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('priority'),
        dataIndex: 'priority',
        key: 'priority',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: t('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <Tag color={teamMemberTagColor.get(record.status)}>
            {TeamMemberStatus(t).find((c) => c.id === record.status).name}
          </Tag>
        ),
      },
      {
        title: t('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => formatDate(record.createdAt),
      },
      {
        title: t('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${TEAM_MEMBERS_PATH}/${MANAGE_TEAM_MEMBERS_PATH}/${record.id}`}
              />

              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteTeamMember(record.id)}
                onDeleted={() => updateTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)

    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={16}>
              <h4>{t('dashboard.sites.team_members.title')}</h4>
            </Col>
            <Col xs={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => history.push(`${history.location.pathname}/${MANAGE_TEAM_MEMBERS_PATH}`)}
                >
                  {t('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          dataSource={item.items}
          onChange={onTableChange}
          loading={loading}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: item.totalItemCount,
            pageSize: item.pageSize,
            current: item.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default TeamMember
