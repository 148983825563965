import React, { useState, useCallback } from 'react'
import Card from 'common/components/dataDisplay/Card'
import {
  defaultFilter,
  EntityStatus,
  getMenues,
  PagedFilter,
  PagedList,
  saveMenu,
  SaveMenuPayload,
  deleteMenu,
} from 'core'
import useQueryRequest from 'common/hooks/useQueryRequest'
import { Button, Col, Modal, notification, Popconfirm, Row, Space, Table, Tag } from 'antd'
import { formatDateTime } from 'common/dateFormatters'
import CreateMenuForm from './CreateMenuForm'
import useCommandRequest from 'common/hooks/useCommandRequest'
import { PlusOutlined, DeleteOutlined, EditOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

const { Column } = Table

const entityStatus = new Map([
  [EntityStatus.Active, { title: 'Active', color: 'green' }],
  [EntityStatus.Archived, { title: 'Archived', color: 'red' }],
  [EntityStatus.Draft, { title: 'Draft', color: 'orange' }],
])

export const RESTAURANT_MENU_PATH = 'restaurants/menues'

export default function Menu() {
  const history = useHistory()
  const { t } = useTranslation()
  const [isOpen, setModalState] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)
  const [filter, setFilter] = useState<PagedFilter>(defaultFilter)
  const getMenuesList = useCallback(() => getMenues(filter), [filter])
  const [mutation, isSubmitting] = useCommandRequest()
  const [menues, isLoading, query] = useQueryRequest<PagedList<any> | null>(null, getMenuesList, {
    variables: filter,
  })

  const handleSubmit = async (request: SaveMenuPayload, { resetForm }: any) => {
    const result = await mutation(() => saveMenu(request))
    if (result.completed) {
      notification.success({ message: 'Menu saved successfully!' })
      setModalState(false)
      resetForm()
      await query(() => getMenues(filter))
      setReload(!reload)
    }
  }
  const handleEditMenu = (id: string) => {
    history.push({ search: `?id=${id}` })
    setModalState(true)
  }
  const handleCloseModal = () => {
    history.replace({ search: '' })
    setModalState(false)
  }
  const handleDelete = async (id: string) => {
    const result = await mutation(() => deleteMenu(id))
    if (result.completed) {
      await query(() => getMenuesList())
    }
  }
  return (
    <Card
      bordered={true}
      title={
        <Row>
          <Col xs={24} md={16}>
            <h4>{t('dashboard.menues.title')}</h4>
          </Col>
          <Col xs={24} md={8}>
            <div className={'text-right'}>
              <Button icon={<PlusOutlined />} type="primary" onClick={() => setModalState(true)}>
                {t('dashboard.menues.create')}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Modal title={t('dashboard.menues.create')} visible={isOpen} onCancel={handleCloseModal} footer={null}>
        <CreateMenuForm submitHandler={handleSubmit} isSubmitting={isSubmitting} reload={reload} />
      </Modal>

      <Table
        rowKey={(record) => record.id}
        loading={isLoading}
        dataSource={menues?.items}
        scroll={{ x: true }}
        onChange={(pagination: any, filter, sorter: any) => {
          setFilter({
            searchValue: '',
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || 'createdAt',
            orderDescending: sorter.order !== 'ascend',
          })
        }}
        pagination={{
          total: menues?.totalItemCount,
          pageSize: menues?.pageSize,
          current: menues?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      >
        <Column title={t('general.name')} dataIndex="name" key="name" sorter sortDirections={['ascend', 'descend']} />
        <Column
          title={t('dashboard.status')}
          dataIndex="status"
          sorter
          sortDirections={['ascend', 'descend']}
          key="status"
          align={'center'}
          render={(value) => <Tag color={entityStatus.get(value)?.color}>{entityStatus.get(value)?.title}</Tag>}
        />
        <Column
          title={t('dashboard.created_at')}
          className={'createdtitle'}
          dataIndex="createdAt"
          key="createdAt"
          sorter
          align={'center'}
          defaultSortOrder="descend"
          sortDirections={['ascend', 'descend']}
          render={(value) => formatDateTime(value)}
        />
        <Column
          title="Action"
          dataIndex={'action'}
          key={'action'}
          align={'center'}
          render={(val, record: any) => (
            <Space>
              <Button icon={<EditOutlined />} onClick={() => handleEditMenu(record.id)} />
              <Button
                icon={<UnorderedListOutlined />}
                onClick={() =>
                  history.push(`/restaurant/menu-items/${record.id}?menu-name=${encodeURIComponent(record.name)}`)
                }
                type="primary"
              />
              <Popconfirm
                disabled={record.children !== null}
                title={t('general.delete_alert_title')}
                onConfirm={() => handleDelete(record.id)}
              >
                <Button disabled={record.children !== null} type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}
