import { Moment } from 'moment'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface PagedClients {
  items: Client[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}

export interface Client {
  id: string
  firstName: string
  lastName: number
  email: string
  phoneNumber: string
  address: string
  city: string
  country: string
  createdAt: Moment
  hasAccount: boolean
  hasOrders: boolean
}

export interface ClientFilter {
  pageNumber: number
  pageSize: number
  orderColumn: string
  orderDescending: boolean
}

const getClients = (filter: ClientFilter): Promise<any> => adminHttpClient.get('/admindashboard/clients', filter)

export default getClients
