import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import ShippingZones from './ShippingZones'
import NotFound from '../../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}/`} component={ShippingZones} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
