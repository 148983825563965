import React, { useState } from 'react'
import { notification, PageHeader, Space, Table } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import useQueryRequest from 'common/hooks/useQueryRequest'
import useCommandRequest from 'common/hooks/useCommandRequest'
import useUrlQueryParam from 'common/hooks/useUrlQueryParam'
import {
  defaultFilter,
  defaultPagedColumns,
  deleteStoreModule,
  getStoreModules,
  Modules as ModulesMap,
  saveDefaultModules,
} from 'core'
import TableEditButton from 'common/components/tables/TableEditButton'

const { Column } = Table

export default function Modules() {
  const history = useHistory()
  const store = useUrlQueryParam('store')
  const { storeId } = useParams<any>()
  const [mutation, isSubmiting] = useCommandRequest()
  const [filter, setFilter] = useState<any>({ ...defaultFilter, storeId: storeId, orderColumn: 'module' })
  const [modules, isLoading, query] = useQueryRequest(defaultPagedColumns, () => getStoreModules(filter), {
    variables: filter,
  })

  const saveDefaultModulesHandler = async () => {
    const result = await mutation(() => saveDefaultModules(storeId))
    if (result.completed) {
      notification.success({ message: 'Modules saved successfully' })
      query(() => getStoreModules(filter))
    }
  }

  return (
    <Card
      title={<PageHeader className="p-0" title={`Modules for ${store}`} onBack={() => history.goBack()} />}
      extra={
        <Space>
          <Button onClick={saveDefaultModulesHandler} loading={isSubmiting} disabled={modules.items.length}>
            Add default modules
          </Button>
          <Button onClick={() => history.push(`/manage-module/${storeId}`)} type="primary">
            Add module
          </Button>
        </Space>
      }
    >
      <Table
        rowKey={(key) => key.module}
        loading={isLoading}
        dataSource={modules?.items}
        onChange={(pagination: any, filter, sorter: any) => {
          setFilter({
            storeId: storeId,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || 'module',
            orderDescending: sorter.order !== 'ascend',
          })
        }}
        pagination={{
          total: modules?.totalItemCount,
          pageSize: modules?.pageSize,
          current: modules?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      >
        <Column title="Module" dataIndex="module" key="module" render={(value) => ModulesMap.get(value)} />
        <Column
          title="Priority"
          dataIndex="priority"
          key="priority"
          sorter
          defaultSortOrder={'ascend'}
          sortDirections={['ascend', 'descend']}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(value, record: any) => (
            <Space>
              <TableEditButton editUrl={`/manage-module/${storeId}/${record.module}`} />
              <TableDeleteButton
                onDelete={async () => await deleteStoreModule(storeId, record.module)}
                onDeleted={async () => await query(() => getStoreModules(filter))}
              />
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}

Modules.url = 'modules/:storeId'
