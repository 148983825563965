import { adminHttpClient } from '../../commons/adminHttpClient'

export const getGalleryForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/AdminDashboard/Gallery/ForSave/${id}`)
  }
  return adminHttpClient.get(`/AdminDashboard/Gallery/ForSave`)
}

const saveGallery = (data: any): Promise<any> => {
  return adminHttpClient.post(`/AdminDashboard/Gallery`, data)
}
export default saveGallery
