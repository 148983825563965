import React from 'react'
import { Moment } from 'moment'

import './DataEntry.less'

import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'

const { RangePicker: AntRangePicker } = DatePicker

export type RangeProps = RangePickerProps<Moment>

const RangePicker: React.FC<RangeProps> = (props) => {
  return <AntRangePicker className={`input_height round_border mx-4 ${props.className}`} {...props} />
}

export default RangePicker
