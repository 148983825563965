import { Col, notification, Row, Table } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Button as AntButton } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import { queryRequest } from 'common/RequestUtils'
import OrderedPageFilter, { defaultFilter } from 'core/application/commons/orderedPageFilter'
import { PagedList } from 'core'
import { MANAGE_FILE_PATH } from './ManageFile'
import getFile from 'core/application/dashboard/files/files'
import { formatDate } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import deleteFile from 'core/application/dashboard/files/deleteFile'
import { CopyOutlined, FileImageOutlined, FileZipOutlined } from '@ant-design/icons'
import { FileType, FileTypeEnum } from 'core/application/dashboard/files/manageFile'
import Tag from 'common/components/dataDisplay/Tag'
import { FileOutlined, VideoCameraOutlined } from '@ant-design/icons'

export const FILES_PATH = 'files'
export const fileTagColor = new Map<FileTypeEnum, string>([
  [FileTypeEnum.Image, '#fbbc06'],
  [FileTypeEnum.Video, '#23af47'],
  [FileTypeEnum.Audio, '#25378b'],
  [FileTypeEnum.Pdf, '#e94437'],
  [FileTypeEnum.Word, '#02abfe'],
  [FileTypeEnum.File, '#02abfe'],
  [FileTypeEnum.Zip, '#02abfe'],
  [FileTypeEnum.Rar, '#02abfe'],
])
const notImgExtension = ['pdf', 'mp4', 'mov']

const Files: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [item, setitem] = useState<PagedList<any>>({} as PagedList<any>)
  const [loading, setloading] = useState(false)
  const history = useHistory()

  const updateTable = useCallback(async () => {
    setloading(true)
    setitem(await queryRequest(() => getFile(searchFilter)))
    setloading(false)
  }, [searchFilter])

  useEffect(() => {
    updateTable()
  }, [updateTable])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.sites.files.title'),
        dataIndex: 'title',
        key: 'title',
        width: '50%',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <Row>
              {record.fileUrl ? (
                <Col md={4} xs={0} className="mr-8">
                  {record.type !== FileTypeEnum.Image ? (
                    FileIcon(record.type)
                  ) : (
                    <img src={record.fileUrl} alt={record.name} style={{ maxWidth: '100%' }} />
                  )}
                </Col>
              ) : (
                <FileImageOutlined style={{ fontSize: '40px' }} />
              )}
              <Col md={6} className="d-flex align-center">
                {record.title}
              </Col>
            </Row>
          </span>
        ),
      },
      {
        title: translate('dashboard.sites.files.type'),
        dataIndex: 'type',
        key: 'type',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <Tag color={fileTagColor.get(record.type)}>
                {FileType(translate).find((c) => c.id === record.type).name}
              </Tag>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: '',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <AntButton
                className="mr-16 ml-16"
                style={{ borderRadius: '5px' }}
                size="small"
                icon={<CopyOutlined />}
                onClick={() => {
                  notification.success({ message: 'Copied to clipboard!' })
                  navigator.clipboard.writeText(record.fileUrl)
                }}
              />
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${FILES_PATH}/${MANAGE_FILE_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteFile(record.id)}
                onDeleted={() => updateTable()}
              />
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }
  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={16}>
              <h4>{translate('dashboard.sites.files.title')}</h4>
            </Col>
            <Col xs={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => history.push(`${history.location.pathname}/${MANAGE_FILE_PATH}`)}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          dataSource={item.items}
          onChange={onTableChange}
          loading={loading}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: item.totalItemCount,
            pageSize: item.pageSize,
            current: item.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}
const FileIcon = (fileType: FileTypeEnum) => {
  switch (fileType) {
    case FileTypeEnum.Pdf:
      return <FileOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    case FileTypeEnum.Video:
      return <VideoCameraOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    case FileTypeEnum.Audio:
      return <VideoCameraOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    case FileTypeEnum.Zip:
      return <FileZipOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    case FileTypeEnum.Rar:
      return <FileZipOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    case FileTypeEnum.File:
      return <FileOutlined style={{ fontSize: '20px', padding: '0 1rem' }} />
    default:
      return null
  }
}
export default Files
