import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface MonthInfo {
  sales: number
  month: string
}
const getOrderStatistics = (year: number | null): Promise<MonthInfo[]> => {
  return adminHttpClient.get('/admindashboard/reports/ordershistory', {
    year: year,
  })
}

export default getOrderStatistics
