import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Articles from './Articles'
import ManageArticle, { MANAGE_ARTICLE_PATH } from './ManageArticle'
import NotFound from '../../errorPages/NotFound'
import ManageArticleTranslation, { MANAGE_ARTICLE_TRANSLATION_PATH } from './ManageArticleTranslation'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}/${MANAGE_ARTICLE_TRANSLATION_PATH}/:articleId/:languageId`}
        component={ManageArticleTranslation}
      />
      <Route path={`${match.path}/${MANAGE_ARTICLE_PATH}/:id?`} component={ManageArticle} />
      <Route path={`${match.path}`} component={Articles} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
