import { OrderedPageFilter } from '../../commons'
import { adminHttpClient } from '../../commons/adminHttpClient'
import { DynamicCategoryForSave } from './types'

const URL = `/admindashboard/dynamiccategories`

export const getDynamicCategory = async (filter: OrderedPageFilter) => await adminHttpClient.get(URL, filter)

export const getDynamicCategoryForSave = async (id: string | undefined) => {
  if (id) {
    return await adminHttpClient.get(`${URL}/ForSave/${id}`)
  }
  return await adminHttpClient.get(`${URL}/ForSave`)
}

export const saveDynamicCategory = async (request: DynamicCategoryForSave) => await adminHttpClient.post(URL, request)

export const deleteDynamicCategory = async (id: string) => {
  return await adminHttpClient.delete(`${URL}/${id}`, { id: id })
}
