import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from 'application/commons/namedItem'

export type StoreGeneralSettings = {
  id: string
  name: string
  subDomain: string
  customDomain: string | null
  email: string | null
  phoneNumber: string | null
  address: string | null
  city: string | null
  countryId: number
  description: string | null
  languageId: number
  currencyId: number
  startHour: string | null
  endHour: string | null
  availableLanguages: NamedItem[]
  availableCurrencies: NamedItem[]
  availableCountries: Country[]
  postalCode: string | null
  aptSuite: string | null
}

export type Country = {
  id: number
  name: string
  isoCode: string
  photoId: string
}

export const getStoreGeneralSettings = (): Promise<StoreGeneralSettings> => {
  return adminHttpClient.get('/admindashboard/storegeneralsettings')
}
export const updateStoreGeneralSettings = (data: StoreGeneralSettings) => {
  return adminHttpClient.put('/admindashboard/storegeneralsettings', data)
}
