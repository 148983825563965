import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import ClientsTable from './ClientsTable'
import { queryRequest } from '../../common/RequestUtils'
import Card from '../../common/components/dataDisplay/Card'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getClients, { PagedClients } from 'core/application/dashboard/clients/clients'

export const CLIENTS_PATH = 'clients'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'firstName',
  orderDescending: false,
}

const Clients: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingClients, setLoadingClients] = useState<boolean>(false)
  const [clients, setClients] = useState<PagedClients>(pagedColumnDefaultValues)
  const history = useHistory()
  useEffect(() => {
    updateClientsTable()
  }, [searchFilter])

  const updateClientsTable = async () => {
    setLoadingClients(true)
    const result = await queryRequest(() => getClients(searchFilter))
    setClients(result)
    setLoadingClients(false)
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={24}>
              <h4>{translate('dashboard.clients_table.title')}</h4>
            </Col>
          </Row>
        }
      >
        <ClientsTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          clients={clients}
          loadingClients={loadingClients}
          history={history}
          translate={translate}
          updateClientsTable={updateClientsTable}
        />
      </Card>
    </>
  )
}

export default Clients
