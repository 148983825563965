import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, notification, Row, Skeleton } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Formik, Form as FormikForm } from 'formik'
import {
  getSupportedLanguagesForSave,
  saveSupportedLanguages,
} from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import * as Yup from 'yup'

export const STORE_SUPPORTED_LANGUAGES_PATH = 'supported-languages'

export const formValidator = (translate: any) =>
  Yup.object({
    supportedLanguages: Yup.string().trim().nullable().required(translate('general.required')),
  })
const SupportedLanguages: React.FC = () => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<any>({})

  useEffect(() => {
    updateForm()
  }, [])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getSupportedLanguagesForSave()))
    setLoading(false)
  }

  const onSubmit = async (values: any) => {
    const request = {
      supportedLanguages: values.supportedLanguages,
    }
    const result = await commandRequest(() => saveSupportedLanguages(request))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace(
          '[]',
          translate('dashboard.store_settings.supported_languages.name'),
        ),
        type: 'success',
      })
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item || {}}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card bordered={false} title={translate('dashboard.store_settings.supported_languages.name')}>
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="supportedLanguages"
                        placeholder={translate('dashboard.store_settings.supported_languages.name')}
                        label={translate('dashboard.store_settings.supported_languages.name')}
                        showArrow={true}
                        defaultValue={item.supportedLanguages ? item.supportedLanguages : []}
                        mode="multiple"
                      >
                        {item.availableLanguages &&
                          item.availableLanguages.map((c: any) => (
                            <option key={c.id} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default SupportedLanguages
