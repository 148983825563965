import React, { useState, useEffect } from 'react'
import getOrders, { PagedOrders } from 'core/application/dashboard/home/orders/orders'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { NamedItem } from 'core/application/commons/namedItem'
import { commandRequest, queryRequest } from '../../common/RequestUtils'
import updateStatus, { OrderStatusToUpdate } from 'core/application/dashboard/home/orders/updateOrderStatus'
import notification from '../../common/components/feedback/Notification'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import Card from '../../common/components/dataDisplay/Card'
import OrdersFilter from './OrdersFilter'
import OrdersTable from './OrdersTable'
import { useSelector } from 'react-redux'
import getAvailableStatuses from 'core/application/dashboard/home/orders/availableOrderStatuses'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter: OrderedPageFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const Component: React.FC = ({}) => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const [orders, setOrders] = useState<PagedOrders>(pagedColumnDefaultValues)
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingOrders, setLoadingOrders] = useState<boolean>(false)
  const [availableOrderStatuses, setAvailableOrderStatuses] = useState<NamedItem[]>([])
  const user = useSelector((s: any) => s.userReducer)

  const updateOrdersTable = async (searchFilter: OrderedPageFilter) => {
    setLoadingOrders(true)
    const result = await queryRequest(() => getOrders(searchFilter))
    setOrders(result)
    setLoadingOrders(false)
  }

  const updateAvailableOrderStatuses = async () => {
    const result = await queryRequest(() => getAvailableStatuses())
    result.unshift({ id: -1, name: 'Të gjitha statuset' })
    setAvailableOrderStatuses(result)
  }

  useEffect(() => {
    ;(async () => {
      await updateOrdersTable(searchFilter)
    })()
  }, [searchFilter])

  useEffect(() => {
    ;(async () => {
      await updateAvailableOrderStatuses()
    })()
  }, [])

  const updateOrderStatus = async (order: OrderStatusToUpdate) => {
    const result = await commandRequest(() => updateStatus(order))
    if (!result.errors) {
      notification.open({
        type: 'success',
        message: translate('general.saved').replace('[]', translate('dashboard.the_order_status')),
      })

      await updateOrdersTable(searchFilter)
    } else {
      notification.open({ type: 'error', message: result.errors[0] })
    }
  }

  return (
    <Card bordered={true} title={translate('dashboard.orders.title')}>
      <OrdersFilter
        translate={translate}
        updateOrdersTable={updateOrdersTable}
        availableStatuses={availableOrderStatuses}
        setSearchFilter={setSearchFilter}
        user={user}
      />

      <OrdersTable
        setOrderFilter={setSearchFilter}
        filter={searchFilter}
        orders={orders}
        loadingOrders={loadingOrders}
        history={history}
        translate={translate}
        orderStatuses={availableOrderStatuses}
        updateOrderStatus={updateOrderStatus}
      />
    </Card>
  )
}

export default Component
