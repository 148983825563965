import React from 'react'

import { AnalyticsMetric, AnalyticsReport } from 'core/application/dashboard/home/analytics/userCount'
import { Pie } from '@ant-design/charts'

interface DeviceChartProps {
  report?: AnalyticsReport
}

const DeviceChart: React.FC<DeviceChartProps> = ({ report }) => {
  const data: Record<string, any>[] = report?.metrics
    ? report?.metrics.map((x: AnalyticsMetric) => {
        return { type: x.key, value: Number(x.value) }
      })
    : []
  const config = {
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 1,
    innerRadius: 0.6,
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
    statistic: {
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
  }
  return <Pie {...config} />
}

export default DeviceChart
