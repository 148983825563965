import { useEffect, useState } from 'react'

import notification from 'common/components/feedback/Notification'

export type SendQueryRequestOptions<T = unknown> = {
  showErrors?: boolean
  initialState?: T
  queryFn?: () => Promise<T>
  enable?: boolean
}

export default <T = unknown>(
  options: SendQueryRequestOptions<T> = {
    showErrors: true,
  },
): {
  data: T | null
  isLoading: boolean
  hasError: boolean
  sendQuery: (overrideFunc?: () => Promise<T>) => Promise<T | null>
  reload: () => Promise<T | null>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState<T | null>(options.initialState ?? null)
  const [hasError, setHasError] = useState<boolean>(false)

  const { enable = true } = options

  const sendQueryRequest = async (overrideFunc?: () => Promise<T>): Promise<T | null> => {
    const funcToExecute = overrideFunc ?? options.queryFn
    if (!funcToExecute) throw new Error('No function specified to execute')

    try {
      setIsLoading(true)
      setHasError(false)
      const result = await funcToExecute()
      setData(result)
      setIsLoading(false)
      return result
    } catch (err: any) {
      setIsLoading(false)
      setHasError(true)
      if (err && err.status === 401) {
        ;(window.location.href as any) = `/login`
        return null
      }

      if (options.showErrors) {
        notification.error('An error occurred while getting the data.')
      }

      return null
    }
  }

  const reload = (): Promise<T | null> => {
    if (!options.queryFn) throw new Error('No query function specified to execute.')

    return sendQueryRequest(options.queryFn)
  }

  useEffect(() => {
    if (options.queryFn && enable) {
      sendQueryRequest(options.queryFn).then(() => {})
    }
  }, [enable])

  return {
    data,
    isLoading,
    hasError,
    sendQuery: sendQueryRequest,
    reload,
  }
}
