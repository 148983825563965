import { NamedItemInt } from 'application/commons/namedItem'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface TestimonialsForSaveDto {
  id?: string
  author: string
  description: string
  priority: number
  photoId: string
  status: string
  rate: number
}
export enum TestimonialsStatusEnum {
  Draft = 0,
  Active = 1,
}
export const TestimonialsStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
  ]
}
export const getTestimonialsForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/Testimonials/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/Testimonials/ForSave`)
}

const saveTestimonials = (values: any): Promise<any> => {
  return adminHttpClient.post(`/SiteDashboard/Testimonials`, values)
}

export default saveTestimonials
