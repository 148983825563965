import React, { Fragment, useMemo } from 'react'
import Input from '../../common/components/dataEntry/formik/FormikInputField'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import { FieldArray, Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import { Button, Card, Col, notification, PageHeader, Row } from 'antd'
import TextArea from 'common/components/dataEntry/formik/FormikTextAreaField'
import HtmlEditor from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import useCommandRequest from 'common/hooks/useCommandRequest'
import { serializeFieldsDictionary } from './utlis'
import * as Yup from 'yup'
import { DeleteOutlined } from '@ant-design/icons'
import {
  DynamicEntityForSave,
  dynamicEntityStatusMap,
  dynamicFieldTypeMap,
  getDynamicEntetiesForSaveQuery,
  saveDynamicEntityCommand,
} from 'core'

const validator = Yup.object({
  name: Yup.string().trim().required('Field is required!').nullable(),
  friendlyUrl: Yup.string().trim().required('Field is required!').nullable(),
  description: Yup.string().trim().required('Field is required!').nullable(),
  priority: Yup.number().required('Field is required!').nullable(),
  status: Yup.number().required('Field is required!').nullable(),
  fields: Yup.array(
    Yup.object({
      type: Yup.number().required('Field is required!').nullable(),
      value: Yup.string().required('Field is required!').nullable(),
    }),
  ),
})
export default function ManageDynamicEntity() {
  const { id }: any = useParams()
  const { goBack } = useHistory()
  const [mutation] = useCommandRequest()
  const [entity, isLoading] = useQueryRequest<DynamicEntityForSave | undefined>(undefined, () =>
    getDynamicEntetiesForSaveQuery(id),
  )

  const title = id ? 'Update dynamic entity' : 'Create dynamic entity'
  const hasFields = entity?.fields && Object.keys(entity.fields || {}).length

  const items = useMemo(
    () => ({
      ...entity,
      fields: hasFields ? Object.values(entity?.fields as any) : [{ type: 0, value: '' }],
    }),
    [entity, hasFields],
  )

  const handleSubmit = async (request: DynamicEntityForSave) => {
    const payload = { ...request, fields: serializeFieldsDictionary(request.fields) }
    const result = await mutation(() => saveDynamicEntityCommand(payload))
    if (result.completed) {
      notification.success({ message: 'Dynamic entity created sucessfully!' })
      goBack()
    }
  }
  return (
    <Card
      loading={isLoading}
      bordered={false}
      title={<PageHeader className="p-0" onBack={() => goBack()} title={title} />}
    >
      <Formik initialValues={items as any} validationSchema={validator} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <Input
              name="name"
              onBlur={(e: any) => setFieldValue('friendlyUrl', generateFriendlyUrl(e.target?.value))}
              label="Name"
              placeholder="Name"
            />
            <Input name="friendlyUrl" label="FriendlyUrl" placeholder="FrienldyUrl" />
            <HtmlEditor name="description" value={items.description} label="Description" />
            <TextArea name="shortDescription" rows={10} label="Short description" placeholder="Short description" />
            <InputNumber name="priority" label="Priority" placeholder="Priority" />
            <Row>
              <Col span={8}>
                <Uploader
                  name="photoId"
                  initialValues={[entity?.photoId || '']}
                  label="Photo Id"
                  fieldName="photoId"
                  resizeToHeight={0}
                  resizeToWidth={0}
                  mode="single"
                  maxUploads={1}
                  optimizedResize
                />
              </Col>
              <Col span={8}>
                <Uploader
                  name="photoIds"
                  initialValues={entity?.photoIds || []}
                  label="Photo Ids"
                  fieldName="photoIds"
                  resizeToHeight={0}
                  resizeToWidth={0}
                  mode="multiple"
                  maxUploads={5}
                  optimizedResize
                />
              </Col>
              <Col span={8}>
                <Uploader
                  name="thumbnailId"
                  initialValues={[entity?.thmbnailId || '']}
                  label="Thumbnail Ids"
                  fieldName="thumbnailId"
                  resizeToHeight={0}
                  resizeToWidth={0}
                  mode="single"
                  maxUploads={1}
                  optimizedResize
                />
              </Col>
              <Col span={8}>
                <Uploader
                  name="bannerPhotoId"
                  initialValues={[entity?.bannerPhotoId || '']}
                  label="Banner Photo Id "
                  fieldName="bannerPhotoId"
                  resizeToHeight={0}
                  resizeToWidth={0}
                  mode="single"
                  maxUploads={1}
                  optimizedResize
                />
              </Col>
              <Col span={8}>
                <Uploader
                  name="iconId"
                  initialValues={[entity?.iconId || '']}
                  label="Icon Id "
                  fieldName="iconId"
                  resizeToHeight={0}
                  resizeToWidth={0}
                  mode="single"
                  maxUploads={1}
                  optimizedResize
                />
              </Col>
            </Row>
            <Select name="status" label="Status" placeholder="Status">
              {dynamicEntityStatusMap.map((c) => (
                <option value={c.value}>{c.label}</option>
              ))}
            </Select>
            <Card type="inner" title={<label htmlFor="fields">Fields</label>}>
              <FieldArray name="fields">
                {({ remove, insert }) => (
                  <Fragment>
                    {values?.fields.map((_: any, index: number) => (
                      <Row gutter={10} align="middle">
                        <Col span={10}>
                          <Select name={`fields[${index}].type`} label="Type" placeholder="Type">
                            {dynamicFieldTypeMap.map((c) => (
                              <option value={c.value}>{c.label}</option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={12}>
                          <Input name={`fields[${index}].value`} label="Value" placeholder="Value" />
                        </Col>
                        <Col span={2}>
                          <Button danger className="w-100" onClick={() => remove(index)}>
                            <DeleteOutlined />
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Button onClick={() => insert(values.fields.length, { type: 0, value: '' })}>Insert</Button>
                  </Fragment>
                )}
              </FieldArray>
            </Card>
            <Select name="parentId" label="Parent" placeholder="Parent">
              {values.availableParents?.map((c) => (
                <option value={c.id}>{c.name}</option>
              ))}
            </Select>
            <Select name="categoryIds" label="Category" placeholder="Category" mode="multiple">
              {values.availableCategories?.map((c) => (
                <option value={c.value}>{c.title}</option>
              ))}
            </Select>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
