import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Files from './Files'
import ManageFile, { MANAGE_FILE_PATH } from './ManageFile'
import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_FILE_PATH}/:id?`} component={ManageFile} />
      <Route path={`${match.path}`} component={Files} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
