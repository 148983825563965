import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface ArticleDto {}

export enum ArticleStatus {
  Draft,
  Published,
}
export interface PagedArticleDto extends PagedList<ArticleDto> {}

const getArticles = (filter: OrderedPageFilter): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/Articles`, filter)
}

export default getArticles
