import { useState } from 'react'

import notification from 'common/components/feedback/Notification'

export type CommandResponse<T> = {
  errors: string[]
  data: T | null
  hasErrors: boolean
  completed: boolean
}

const ok = <T>(data: any): CommandResponse<T> => {
  return {
    errors: [],
    hasErrors: false,
    data,
    completed: true,
  }
}

const badRequest = <T>(errors: []): CommandResponse<T> => {
  return {
    errors,
    hasErrors: true,
    data: null,
    completed: false,
  }
}

export type SendCommandRequestOptions = {
  showErrors: boolean
}

export default <T = unknown, TVariables = unknown>(
  func: (variables: TVariables) => Promise<T>,
  opts: SendCommandRequestOptions = { showErrors: true },
): {
  isLoading: boolean
  sendCommand: (variables: TVariables) => Promise<CommandResponse<T>>
  hasError: boolean
  errorMessages: string[]
} => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState<boolean>(false)
  const [errorMessages, setErrorMessages] = useState<string[]>([])

  const sendCommandRequest = async (variables: TVariables): Promise<CommandResponse<T>> => {
    setHasError(false)
    setErrorMessages([])
    try {
      setIsLoading(true)
      const result = await func(variables)
      setIsLoading(false)
      return ok(result)
    } catch (err: any) {
      setIsLoading(false)
      setHasError(true)

      if (err && err.status === 401) {
        ;(window.location as any) = '/login'
        return badRequest<any>([])
      }

      if (err && err.status === 400) {
        let errors = []
        try {
          errors = err.data.map((x: any) => x.message)
        } catch (e) {}

        if (opts.showErrors) {
          notification.error(errors.join(','))
        }
        setErrorMessages(errors)
        return badRequest<any>(errors as any)
      }

      if (opts.showErrors) {
        setErrorMessages(['An error occurred while processing your request'])

        await notification.error('An error occurred while processing your request')
      }

      return badRequest([])
    }
  }

  return {
    isLoading,
    sendCommand: sendCommandRequest,
    hasError,
    errorMessages,
  }
}
