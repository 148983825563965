import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface TestimonialsDto {
  id?: string
  author: string
  status: string
  createdAt: string
}

const getTestimonials = (filter: OrderedPageFilter): Promise<PagedList<TestimonialsDto>> => {
  return adminHttpClient.get(`/SiteDashboard/Testimonials`, filter)
}

export default getTestimonials
