import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Testimonials from './Testimonials'
import ManageTestimonials, { MANAGE_TESTIMONIALS_PATH } from './ManageTestimonials'
import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_TESTIMONIALS_PATH}/:id?`} component={ManageTestimonials} />
      <Route path={`${match.path}`} component={Testimonials} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
