import React from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'

import DateRangePicker from '../../common/components/dataEntry/components/DateRange'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import Select from '../../common/components/dataEntry/formik/FormikSelectField'
import Button from '../../common/components/general/Button'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { NamedItem } from 'core/application/commons/namedItem'
import { OrderStatus } from 'core/domain/order/orderStatus'

import './Orders.less'
import { Col, Row } from 'antd'
import FormikDefaultInputField from '../../common/components/dataEntry/formik/FormikInputField'
import { exportCSVFile } from 'common/downloadFiles'
import { getOrdersForExport } from 'core/application/dashboard/home/orders/downloadOrders'
import { User } from 'core/application/account/loginUser'
import { formatDate } from 'common/dateFormatters'
import { formatMoney } from 'common/moneyFormatter'

interface Props {
  translate: (key: string) => string
  updateOrdersTable: (filter: OrderedPageFilter) => void
  setSearchFilter: (filter: OrderedPageFilter) => void
  availableStatuses: NamedItem[]
  user: User
}

const formValidator = (translate: any) =>
  Yup.object({
    minPrice: Yup.number()
      .nullable()
      .when('maxPrice', {
        is: true,
        then: Yup.number().max(Yup.ref('maxPrice'), translate('dashboard.orders.min_price_less_than_max')),
      })
      .typeError('')
      .notRequired(),

    maxPrice: Yup.number()
      .nullable()
      .min(Yup.ref('minPrice'), translate('dashboard.orders.max_price_greater_than_min'))
      .typeError('')
      .notRequired(),
  })

let searchFilter: OrderedPageFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: false,
}

interface OverFilterProps {
  minPrice: number | null
  maxPrice: number | null
  orderStatus: number | null
  orderNumber: string | null
}

const orderFilterValues: OverFilterProps = {
  minPrice: null,
  maxPrice: null,
  orderStatus: null,
  orderNumber: null,
}

const OrdersFilter: React.FC<Props> = ({ translate, updateOrdersTable, setSearchFilter, availableStatuses, user }) => {
  const orderStatusTitleMap = new Map<number, string>([
    [-1, translate('dashboard.orders.all_statuses')],
    [OrderStatus.New, translate('dashboard.orders.new')],
    [OrderStatus.Processing, translate('dashboard.orders.processing')],
    [OrderStatus.Delivering, translate('dashboard.orders.delivering')],
    [OrderStatus.Completed, translate('dashboard.orders.completed')],
    [OrderStatus.Aborted, translate('dashboard.orders.aborted')],
  ])

  const onSubmit = (values: OverFilterProps) => {
    searchFilter.priceRangeStart = values.minPrice
    searchFilter.priceRangeEnd = values.maxPrice
    searchFilter.status = values.orderStatus !== -1 ? values.orderStatus : null
    searchFilter.orderNumber = values.orderNumber
    updateOrdersTable(searchFilter)
    setSearchFilter(searchFilter)
  }

  const onCsvExport = async (filters: any) => {
    searchFilter.priceRangeStart = filters.minPrice
    searchFilter.priceRangeEnd = filters.maxPrice
    searchFilter.status = filters.orderStatus !== -1 ? filters.orderStatus : null
    searchFilter.orderNumber = filters.orderNumber
    searchFilter.orderDescending = true
    const result = await getOrdersForExport(searchFilter)
    const orders = result.map((order: any) => {
      let temp: any = {}
      temp.orderNumber = order.orderNumber
      temp.clientName = order.clientName
      temp.city = order.city
      temp.createdAt = order.createdAt
      temp.total = `"${formatMoney(order.totalPrice, order.currency)}"`
      temp.status = order.status
      return temp
    })
    let date =
      searchFilter.createdDateStart && searchFilter.createdDateEnd
        ? `${formatDate(new Date(searchFilter.createdDateStart))}__${formatDate(new Date(searchFilter.createdDateEnd))}`
        : `${formatDate(new Date())}`

    const fileName = `${user.storeName}_${translate('dashboard.orders.title')}_${date}`
    exportCSVFile(
      {
        orderNumber: `${translate('dashboard.orders.order_number')}`,
        clientName: `${translate('dashboard.client')}`,
        city: `${translate('dashboard.city')}`,
        createdAt: `${translate('dashboard.created_at')}`,
        totalPrice: `${translate('dashboard.amount')}`,
        status: `${translate('dashboard.status')}`,
      },
      orders,
      fileName,
    )
  }

  function onDateChange(dates: any, dateStrings: any) {
    searchFilter.createdDateStart = dateStrings[0]
    searchFilter.createdDateEnd = dateStrings[1]
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={orderFilterValues}
      validationSchema={formValidator(translate)}
      onSubmit={() => {}}
    >
      {(formik) => (
        <Row gutter={16}>
          <Col md={3} xs={24}>
            <FormikDefaultInputField placeholder={translate('dashboard.orders.order_number')} name="orderNumber" />
          </Col>
          <Col md={6} xs={24}>
            <DateRangePicker
              allowClear={false}
              onChange={onDateChange}
              placeholder={[translate('general.start_date'), translate('general.end_date')]}
              className="full_width mb-16"
              style={{ height: '40px', borderRadius: '4px' }}
            />
          </Col>
          <Col md={3} xs={24}>
            <InputNumber placeholder={translate('dashboard.orders.min_price')} name="minPrice" min={0} />
          </Col>
          <Col md={3} xs={24}>
            <InputNumber placeholder={translate('dashboard.orders.max_price')} name="maxPrice" min={0} />
          </Col>
          <Col md={4} xs={24}>
            <Select
              defaultValue={[-1]}
              name="orderStatus"
              placeholder={translate('dashboard.orders.order_status')}
              className="orders_filter__status_select full_width"
            >
              {availableStatuses.map((status) => (
                <option key={status.id} value={status.id}>
                  {orderStatusTitleMap.get(+status.id)}
                </option>
              ))}
            </Select>
          </Col>
          <Col md={2} xs={24}>
            <Button
              onClick={() => onSubmit(formik.values)}
              disabled={!formValidator(translate).isValidSync(formik.values)}
              type="default"
              size="large"
              className="filter_button mb-16"
            >
              {translate('general.filter')}
            </Button>
          </Col>
          <Col md={3} xs={24}>
            <Button
              type="default"
              size="large"
              className="filter_button mb-16"
              onClick={() => onCsvExport(formik.values)}
            >
              {translate('general.csv_export')}
            </Button>
          </Col>
        </Row>
      )}
    </Formik>
  )
}

export default OrdersFilter
