import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import TreeSelect from 'common/components/dataEntry/formik/FormikTreeSelectField'
import TagInputField from 'common/components/dataEntry/formik/FormikTagInputField'

import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import getJobCategoryForSave, {
  JobCategoryForSave,
  JobCategoryStatus,
  JobCategoryStatusEnum,
  JobCategoryToSave,
  saveJobCategory,
} from 'core/application/dashboard/careers/categories/manageJobCategory'

export const MANAGE_JOB_CATEGORY_PATH = 'manage-category'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .min(0, translate('general.minimun_required')),
    status: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .oneOf(
        [JobCategoryStatusEnum.Active, JobCategoryStatusEnum.Archived, JobCategoryStatusEnum.Draft],
        translate('general.required'),
      ),
  })

const ManageJobCategory: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const [item, setItem] = useState<JobCategoryForSave | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const updateForm = async () => {
    setLoading(true)
    const result = await queryRequest(() => getJobCategoryForSave(id))
    result.availableParents.unshift({
      id: null,
      name: translate('general.no_parent'),
    })
    setItem(result)
    setLoading(false)
  }

  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: JobCategoryForSave) => {
    let categoryToSave: JobCategoryToSave = {
      id: values.id === '00000000-0000-0000-0000-000000000000' ? null : values.id,
      friendlyUrl: values.friendlyUrl,
      photoId: values.photoId,
      iconPhotoId: values.iconPhotoId,
      name: values.name,
      parentId: values.parentId,
      priority: values.priority,
      status: values.status,
      emailsToNotify: values.emailsToNotify,
    }

    const result = await commandRequest(() => saveJobCategory(categoryToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.the_category')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.categories.edit') : translate('dashboard.categories.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.categories.name')}
                        name="name"
                        placeholder={translate('dashboard.categories.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <TreeSelect
                        label={translate('dashboard.parent')}
                        name="parentId"
                        placeholder={translate('dashboard.parent')}
                        allowClear
                        showArrow
                        showSearch
                        defaultValue={[item.parentId]}
                        treeData={item.availableParents}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                      ></TreeSelect>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}(${translate('general.optional')})`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.icon')}(${translate('general.optional')})`}
                        initialValues={[item.iconPhotoId]}
                        name="iconPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="iconPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <TagInputField
                        label={translate('dashboard.jobs.category.emails_to_notify')}
                        name="emailsToNotify"
                        initialTags={item.emailsToNotify}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [item.status] : []}
                      >
                        {JobCategoryStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageJobCategory
