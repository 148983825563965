import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from '../../commons/namedItem'
import { DiscountType, OfferType } from '../../../domain/product/offer'

export interface Offer {
  id: string
  name: string
  discount: string
  type: DiscountType
  availableTypes: NamedItem[]
  startDate: string
  endDate: string
  productIds: string[]
  availableProducts: NamedItem[]
}

const manageOffer = (id: string | undefined): Promise<Offer> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/offers/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/offers/forsave`)
}

export interface OfferToSave {
  id: string | null
  name: string
  discount: string
  type: DiscountType
  startDate: string
  endDate: string
  productIds: string[]
  offerType: OfferType
}

export const saveOffer = (product: OfferToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/offers', product)
}

export default manageOffer
