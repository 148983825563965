import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { commandRequest, queryRequest } from 'common/RequestUtils'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'

import {
  PagedEmails,
  getEmails,
  deleteEmail,
  sendEmailToSubscriptions,
  SendEmailToSubscriptionsRequest,
} from 'core/application/emailSubscriptions/emails'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import EmailsTable from './EmailsTable'
import { EMAILS_PATH_EDIT } from './ManageEmail'
import { EMAIL_SUBSCRIPTIONS_PATH } from '../Routes'
import notification from '../../../common/components/feedback/Notification'
import SendEmailToSubscriptionsForm from './send/SendEmailToSubscriptionsForm'
import {
  EmailSubscription,
  getEmailSubscriptionsForSend,
} from 'core/application/emailSubscriptions/emailSubscriptionsApi'

export const EMAILS_PATH = 'emails'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const Emails: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingEmails, setLoadingEmails] = useState<boolean>(false)
  const [emails, setEmails] = useState<PagedEmails>(pagedColumnDefaultValues)
  const [emailId, setEmailId] = useState<string>()
  const [showSendEmailToSubscriptions, setShowSendEmailToSubscriptions] = useState<boolean>(false)
  const [subcriptions, setSubscriptions] = useState<EmailSubscription[]>([])
  const [submittingSendEmailsToSubscriptions, setSubmittingSendEmailsToSubscriptions] = useState<boolean>(false)

  const history = useHistory()
  useEffect(() => {
    updateEmailsTable()
  }, [searchFilter])

  const updateEmailsTable = async () => {
    setLoadingEmails(true)
    const result = await queryRequest(() => getEmails(searchFilter))
    setEmails(result)
    setLoadingEmails(false)
  }

  const displaySendEmailToSubscriptionsForm = async (emailId: string) => {
    const subcriptions = await queryRequest(() => getEmailSubscriptionsForSend())
    setSubscriptions(subcriptions)
    setShowSendEmailToSubscriptions(true)
    setEmailId(emailId)
  }

  const sendEmailToSubscriptionsHandler = async (request: SendEmailToSubscriptionsRequest) => {
    setSubmittingSendEmailsToSubscriptions(true)

    const result = await commandRequest(() =>
      sendEmailToSubscriptions({
        ...request,
        emailId: emailId ?? '',
      }),
    )

    if (!result.errors) {
      notification.open({
        message: translate('dashboard.email_subscriptions.email_sent_to_subscribers'),
        type: 'success',
      })
      setSubscriptions([])
      setShowSendEmailToSubscriptions(false)
      setEmailId(undefined)
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }

    setSubmittingSendEmailsToSubscriptions(false)
  }

  return (
    <>
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.email_subscriptions.emails.edit_emails')}</h4>
            </Col>
            <Col xs={6} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${EMAIL_SUBSCRIPTIONS_PATH}/${EMAILS_PATH_EDIT}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <EmailsTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          history={history}
          emails={emails}
          loadingEmails={loadingEmails}
          translate={translate}
          onDeleteEmail={deleteEmail}
          updateEmailsTable={updateEmailsTable}
          onSendEmailToSubscriptions={async (emailId: string) => {
            await displaySendEmailToSubscriptionsForm(emailId)
          }}
        />

        {emailId && (
          <SendEmailToSubscriptionsForm
            emailId={emailId}
            subscriptions={subcriptions}
            visible={showSendEmailToSubscriptions}
            onCancel={() => {
              setSubscriptions([])
              setShowSendEmailToSubscriptions(false)
              setEmailId(undefined)
            }}
            onSubmit={sendEmailToSubscriptionsHandler}
            submitting={submittingSendEmailsToSubscriptions}
          />
        )}
      </Card>
    </>
  )
}

export default Emails
