import { NamedItem, NamedItemInt } from 'application/commons/namedItem'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface TeamMemberForSaveDto {
  id?: string
  name: string
  description: string
  shortDescription: string
  jobPosition: string
  photoId: string
  thumbnailPhotoId: string
  status: string
  resumeLink: string
  availableServices: NamedItem[]
  services: string[]
  bannerPhotoId: string
}
export enum TeamMemberStatusEnum {
  Draft = 0,
  Active = 1,
  Archived = 2,
}
export const TeamMemberStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
    { id: 2, name: translate('general.archived') },
  ]
}
export const getTeamMemberForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/TeamMembers/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/TeamMembers/ForSave`)
}

const saveTeamMember = (values: any): Promise<any> => {
  return adminHttpClient.post(`/SiteDashboard/TeamMembers`, values)
}

export default saveTeamMember
