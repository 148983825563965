import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Products from './Products'
import ProductsForm, { MANAGE_PRODUCTS_PATH } from './ManageProduct'
import NotFound from '../../errorPages/NotFound'
import ManageProductTranslation, { MANAGE_PRODUCT_TRANSLATION_PATH } from './ManageProductTranslation'
import ProductAuction from './productAuction'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}/`} component={Products} />
      <Route path={`${match.path}/auction/:productId`} component={ProductAuction} />
      <Route
        path={`${match.path}/${MANAGE_PRODUCT_TRANSLATION_PATH}/:productId/:languageId`}
        component={ManageProductTranslation}
      />
      <Route path={`${match.path}/${MANAGE_PRODUCTS_PATH}/:id?`} component={ProductsForm} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
