import { adminHttpClient } from '../../../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from '../../../commons/orderedPageFilter'
import PagedList from '../../../commons/pagedList'

export interface PagedOfferOrders extends PagedList<OfferOrdersDto> {}

export interface OfferProductDto {
  mainPhotoUrl: string
  name: string
}
export interface OfferOrdersDto {
  id: string
  name: string
  discount: string
  createdAt: Moment
  startDate: Moment
  endDate: Moment
  orders: number
  totalPrice: number
  currency: string
  product?: OfferProductDto
}

const getOfferOrders = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/reports/offerOrders', filter)
}

export default getOfferOrders
