import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import NotFound from '../../errorPages/NotFound'
import OrderIndex from './OrdersIndex'
import AbandonCheckouts from './abandonCheckouts'
import AbandonCheckoutDetails, { ABANDON_CHECKOUT_DETAILS_PATH } from './abandonCheckouts/abandonCheckoutDetails'

export const ORDERS_PATH = 'orders'
export const ABANDON_CHECKOUTS_PATH = 'abandon-checkouts'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}/`} component={OrderIndex} />
      <Route exact path={`${match.path}/${ABANDON_CHECKOUT_DETAILS_PATH}/:id`} component={AbandonCheckoutDetails} />
      <Route exact path={`${match.path}/${ABANDON_CHECKOUTS_PATH}`} component={AbandonCheckouts} />

      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
