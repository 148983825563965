import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import BlogCategory from './BlogCategory'
import ManageBlogCategory, { MANAGE_BLOG_CATEGORY_PATH } from './ManageBlogCategory'
import NotFound from '../../../errorPages/NotFound'
import ManageCategoryTranslation, { MANAGE_BLOG_CATEGORY_TRANSLATION_PATH } from './ManageCategoryTranslation'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}/${MANAGE_BLOG_CATEGORY_TRANSLATION_PATH}/:categoryId/:languageId`}
        component={ManageCategoryTranslation}
      />
      <Route path={`${match.path}/${MANAGE_BLOG_CATEGORY_PATH}/:id?`} component={ManageBlogCategory} />
      <Route path={`${match.path}`} component={BlogCategory} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
