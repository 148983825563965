import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface AnalyticsReportRequest {
  startDate?: string | null
  endDate?: string | null
}

export interface AnalyticsMetric {
  key: string
  value: string
}

export interface AnalyticsReport {
  total: string
  max: number
  metrics: AnalyticsMetric[]
}

const getUserCount = (filter: AnalyticsReportRequest): Promise<AnalyticsReport> => {
  return adminHttpClient.get('/admindashboard/analytics/userCount', filter)
}

export default getUserCount
