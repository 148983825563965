import { NamedItemInt } from 'application/commons/namedItem'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface FileForSaveDto {
  id?: string
  title: string
  filename: string
  size: string
  type: string
}
export enum FileTypeEnum {
  Image = 0,
  Video = 1,
  Audio = 2,
  Pdf = 3,
  Word = 4,
  Zip = 5,
  Rar = 6,
  File = 7,
}
export const FileType = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.sites.files.image') },
    { id: 1, name: translate('dashboard.sites.files.video') },
    { id: 2, name: translate('dashboard.sites.files.audio') },
    { id: 3, name: 'Pdf' },
    { id: 4, name: 'Word' },
    { id: 5, name: 'Zip' },
    { id: 6, name: 'Rar' },
    { id: 7, name: 'File' },
  ]
}
export const getFileForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/Files/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/Files/ForSave`)
}

const saveFile = (values: any): Promise<any> => {
  return adminHttpClient.post(`/SiteDashboard/Files`, values)
}

export default saveFile
