import React, { useState, useEffect } from 'react'
import { Row, Col, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { queryRequest } from '../../common/RequestUtils'
import Button from '../../common/components/general/Button'
import Card from '../../common/components/dataDisplay/Card'
import Select from '../../common/components/dataEntry/components/Select'
import BannersItemsTable from './bannerItems/BannerItemsTable'
import useUrlQueryParam from '../../common/hooks/useUrlQueryParam'
import { MANAGE_BANNER_ITEM_PATH } from './bannerItems/ManageBannerItem'

import getBanners, { Banner } from 'core/application/dashboard/banners/banners'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getBannerItems, { PagedBannerItems } from 'core/application/dashboard/banners/bannerItems'
import deleteBannerItem from 'core/application/dashboard/banners/deleteBannerItem'
import { NamedItem } from 'core/application/commons/namedItem'

import './Banners.less'
import useSharedState from '../../common/hooks/useSharedState'

export const BANNERS_PATH = 'banners'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'priority',
  orderDescending: false,
}

const Banners: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingItems, setLoadingItems] = useState<boolean>(false)
  const [bannerItems, setBannerItems] = useState<PagedBannerItems>(pagedColumnDefaultValues)
  const [loading, setLoading] = useState<boolean>(false)
  const [banners, setBanners] = useState<Banner[]>([])
  const [selectedBanner, setSelectedBanner] = useSharedState<NamedItem | null>('selected_banner', null, true)
  const history = useHistory()
  const bannerId = useUrlQueryParam('parentId')
  const bannerName = useUrlQueryParam('parentName')

  useEffect(() => {
    getBannersList()
  }, [])

  useEffect(() => {
    if (selectedBanner || bannerId) updateBannerItemsTable()
  }, [searchFilter])

  useEffect(() => {
    if (selectedBanner) updateBannerItemsTable()
  }, [selectedBanner])

  useEffect(() => {
    if (bannerId && bannerName) setSelectedBanner({ id: bannerId, name: bannerName })
  }, [bannerId])

  const getBannersList = async () => {
    setLoading(true)
    const result = await queryRequest(() => getBanners())
    setBanners(result)
    if (!selectedBanner) {
      setFistBannerAsDefaultSelected(result)
    }
    setLoading(false)
  }

  const setFistBannerAsDefaultSelected = (banners: Banner[]) => {
    if (banners.length > 0) {
      setSelectedBanner({ id: banners[0].id, name: banners[0].name })
    }
  }

  const updateBannerItemsTable = async () => {
    setLoadingItems(true)
    const bannerId = getBannerId()

    if (!bannerId) {
      notification.open({
        type: 'error',
        message: translate('dashboard.wrong_id'),
      })
      return
    }

    const result = await queryRequest(() => getBannerItems(searchFilter, bannerId))
    setBannerItems(result)
    setLoadingItems(false)
  }

  const getBannerId = () => {
    if (selectedBanner && selectedBanner.id) {
      return selectedBanner.id
    } else if (bannerId) return bannerId
    else return null
  }

  const onBannerSelect = (value: any, option: any) => {
    setSelectedBanner({
      id: value,
      name: getBannerNameById(value),
    })
  }

  const getBannerNameById = (id: string) => {
    const banner = banners.find((b) => b.id === id)
    return banner ? banner.name : ''
  }

  const getBannerDefaultValue = (): string | undefined => {
    if (bannerName) {
      return bannerName
    } else {
      if (banners.length > 0) {
        return banners[0].name
      }
    }
    return undefined
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          banners.length > 0 && (
            <>
              <Row>
                <Col md={16} xs={24}>
                  <h4>{translate('dashboard.banners.title')}</h4>
                </Col>
                <Col md={8} xs={24}>
                  <div className="flex ">
                    <Col md={8} xs={24}>
                      <div>
                        <Select
                          placeholder={translate('dashboard.banners.select_banner')}
                          onChange={onBannerSelect}
                          value={selectedBanner?.id}
                        >
                          {banners.map((banner) => (
                            <option key={banner.id} value={banner.id}>
                              {banner.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </Col>
                    <Col md={16} xs={24}>
                      {selectedBanner && (
                        <div className={'text-right'}>
                          <Button
                            icon="far fa-plus"
                            type="primary"
                            onClick={() => {
                              history.push(
                                `${history.location.pathname}/${MANAGE_BANNER_ITEM_PATH}?parentId=${selectedBanner?.id}&parentName=${selectedBanner?.name}`,
                              )
                            }}
                          >
                            {translate('dashboard.banners.add_banner_item')}
                          </Button>
                        </div>
                      )}
                    </Col>
                  </div>
                </Col>
              </Row>
            </>
          )
        }
      >
        {selectedBanner && (
          <Row>
            <Col span={24}>
              <BannersItemsTable
                setOrderFilter={setSearchFilter}
                selectedBanner={selectedBanner}
                filter={searchFilter}
                bannerItems={bannerItems}
                loading={loadingItems}
                history={history}
                translate={translate}
                onDelete={deleteBannerItem}
                updateBannerItemsTable={updateBannerItemsTable}
              />
            </Col>
          </Row>
        )}
      </Card>
    </>
  )
}

export default Banners
