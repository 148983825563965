import React from 'react'
import { FilePdfOutlined } from '@ant-design/icons'
import FileDisplayProps from './FileDisplayProps'
import { Popconfirm } from 'antd'
import { isCss } from './fileUtils'
import { useTranslation } from 'react-i18next'
import Icon from 'common/components/general/Icon'

interface DisplayPdfProps extends FileDisplayProps {
  deleteAttachment: () => void
}

export const DisplayPdf: React.FC<DisplayPdfProps> = ({ fileUrl, deleteAttachment }) => {
  const { t } = useTranslation()

  return (
    <div className={'mb-16'}>
      <a href={fileUrl} target="_blank">
        <FilePdfOutlined style={{ fontSize: '64px' }} />
      </a>
      <Popconfirm
        title={isCss(fileUrl) ? t('general.delete_css_alert') : t('general.delete_image_alert')}
        onConfirm={() => {
          deleteAttachment()
        }}
        okText={t('general.ok_text')}
        cancelText={t('general.cancel_text')}
      >
        <div className="text-center">
          <Icon type="far fa-trash-alt" className="delete_image_icon primary-color cursor_pointer" />
        </div>
      </Popconfirm>
    </div>
  )
}

export default DisplayPdf
