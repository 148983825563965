import useQueryRequest from '../../common/hooks/useQueryRequest'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import useSharedState from '../../common/hooks/useSharedState'
import { defaultFilter } from 'core/application/commons/orderedPageFilter'
import { formatDateTime } from '../../common/dateFormatters'
import { Button, Col, Row, Table, Tag as AntdTag } from 'antd'
import TableDeleteButton from '../../common/components/tables/TableDeleteButton'
import { MANAGE_PAGE_SECTION_PATH } from './ManagePageSection'
import { deletePageSection, getPageSections, PagedColumns } from 'core/application/dashboard/storeSettings/pageSection'
import { PageStatusMap } from 'core/application/dashboard/storeSettings/storePageSettings'
import { useTranslation } from 'react-i18next'
import TableEditButton from '../../common/components/tables/TableEditButton'
import { SETTINGS_PATH } from '../storeSettings/Routes'
import Card from '../../common/components/dataDisplay/Card'
import ROUTE_PATHS from '../../common/routePaths'

export const STORE_PAGE_SECTION_SETTINGS_PATH = 'page-sections'

const PageSection: React.FC = () => {
  const history = useHistory()
  const { t: translate } = useTranslation()

  const [pageSections, isLoading, sendQueryRequest] = useQueryRequest<PagedColumns>({
    items: [],
    pageCount: 1,
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 0,
  })
  const [filter, setFilter] = useSharedState<any>('PageSectionsFilter', defaultFilter, true)

  useEffect(() => {
    ;(async () => await reloadTable())()
  }, [filter])

  const reloadTable = async () => {
    await sendQueryRequest(() => getPageSections(filter))
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      searchValue: filter.searchValue,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text: string, record: any) => (
          <span>
            <div>
              <b>{record.name}</b>
            </div>
          </span>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_: string, record: any) => (
          <AntdTag color={PageStatusMap.get(record.status)?.color}>{PageStatusMap.get(record.status)?.name}</AntdTag>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton className="mr-16" editUrl={`${ROUTE_PATHS.PAGE_SECTIONS_MANAGE}/${record.id}`} />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deletePageSection(record.id)}
                onDeleted={() => reloadTable()}
              />
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  return (
    <>
      <Card
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.store_settings.pop_up_settings')}</h4>
            </Col>
            <Col xs={10} md={8}>
              <div className={'text-right'}>
                <Button
                  // icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(ROUTE_PATHS.PAGE_SECTIONS_MANAGE)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          rowKey={(record) => record.id}
          dataSource={pageSections!.items}
          onChange={onTableChange}
          loading={isLoading}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: pageSections!.totalItemCount,
            pageSize: pageSections!.pageSize,
            current: pageSections!.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default PageSection
