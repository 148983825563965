import { adminHttpClient } from '../../commons/adminHttpClient'

export const deleteArticleTranslation = (articleId: string, languageId: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/articlestranslations', {
    data: { articleId: articleId, languageId: languageId },
  })
}

export interface ArticleTranslation {
  articleId: string
  languageId: string
  title: string | null
  defaultTitle: string
  content: string
  photoCaption: string | null
  defaultPhotoCaption: string
}

export const getArticleTranslation = (articleId: string, languageId: string): Promise<ArticleTranslation> => {
  return adminHttpClient.get(`/admindashboard/articlestranslations/forsave/${articleId}/${languageId}`)
}

export interface ArticleTranslationToSave {
  articleId: string
  languageId: string
  title: string
  content: string
  photoCaption: string
}

export const saveArticleTranslation = (translation: ArticleTranslationToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/articlestranslations', translation)
}
