import { adminHttpClient } from '../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from '../commons/pagedList'
import AppointmentStatus from '../../domain/appointments/AppointmentStatus'
export interface Appointment {
  id: string
  date: Date
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  note: string
  birthDate: Date
  teamMemberId: string[]
  serviceId: string[]
  status: AppointmentStatus
  completed: AppointmentCompleted
  price: number
}

export enum AppointmentCompleted {
  true,
  false,
}

export interface AppointmentsPagedFilter {
  pageNumber: number
  pageSize: number
  orderColumn: string
  orderDescending: boolean
  date: Date
}
export interface AppointmentsPaged<T> extends PagedList<T> {
  totalPrices: any[]
}

const getAppointments = async (payload: OrderedPageFilter): Promise<AppointmentsPaged<Appointment>> => {
  return adminHttpClient.get(`/appointments`, payload)
}
export default getAppointments
