import React from 'react'
import { useHistory } from 'react-router-dom'

import Button from '../../components/general/Button'
import Icon from '../general/Icon'

interface TableEditButtonProps {
  editUrl: string
  className?: string
}

const TableEditButton: React.FC<TableEditButtonProps> = ({ editUrl, className }) => {
  const history = useHistory()

  return (
    <>
      <Button
        size="small"
        iconPadding={false}
        className={className}
        icon={'far fa-edit'}
        onClick={() => {
          history.push(editUrl)
        }}
      ></Button>
    </>
  )
}

export default TableEditButton
