import React, { useState, useEffect } from 'react'
import { Menu, Layout as AntLayout, Row, Col, Card } from 'antd'

import { useHistory, useLocation } from 'react-router-dom'
import { navigationPathMapping, NavigationMapping, EMAIL_SUBSCRIPTIONS_PATH } from './Routes'
import { useTranslation } from 'react-i18next'

import { SUBSCRIPTIONS_PATH } from './subscriptions'

import './EmailSubscriptions.less'
import { EMAILS_PATH } from './emails'

interface EmailSubscriptionsWrapper {}

const EmailSubscriptionsWrapper: React.FC<EmailSubscriptionsWrapper> = ({ children }) => {
  const history = useHistory()
  let location = useLocation()
  const { t: translate } = useTranslation()

  const [activeKey, setActiveKey] = useState<any>()

  useEffect(() => {
    let pathname = location.pathname
    const split = pathname.split('/')[2]

    const res = navigationPathMapping.get(split)
    setActiveKey(res ? [res.toString()] : [NavigationMapping.Subscriptions.toString()])
  }, [location])

  const goTo = (path: string) => {
    history.push(`/${EMAIL_SUBSCRIPTIONS_PATH}/${path}`)
  }

  return (
    <Card title={translate('general.email_subscriptions')}>
      <Row>
        <Col xs={24} md={6}>
          <Menu selectedKeys={activeKey} mode="inline" style={{ borderRight: '0px' }}>
            <Menu.Item key="1" onClick={() => goTo(SUBSCRIPTIONS_PATH)}>
              {translate('dashboard.email_subscriptions.subscriptions')}
            </Menu.Item>
            <Menu.Item key="2" onClick={() => goTo(EMAILS_PATH)}>
              {translate('dashboard.email_subscriptions.emails.emails')}
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={24} md={18}>
          <div style={{ borderLeft: '1px solid #e8e8e8', paddingLeft: '1rem' }}>{children}</div>
        </Col>
      </Row>
    </Card>
  )
}

export default EmailSubscriptionsWrapper
