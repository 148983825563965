import React from 'react'
import { Table } from 'antd'

import { ProductMatrix, Product } from 'core/application/dashboard/products/manageProduct'
import Checkbox from '../../common/components/dataEntry/formik/FormikCheckboxField'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import { useFormikContext } from 'formik'

interface ProductMatrixTableProps {
  productMatrix: ProductMatrix[]
  translate: (key: string) => string
  name: string
  currency: string
}

const ProductMatrixTable: React.FC<ProductMatrixTableProps> = ({ translate, productMatrix, name, currency }) => {
  const { values, setFieldValue } = useFormikContext()

  const updateProductStock = () => {
    setFieldValue(
      'stock',
      (values as Product).productMatrix.reduce((a, b) => a + b.stock, 0),
    )
  }

  const columns = [
    {
      title: translate('dashboard.products.product_matrix_name'),
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      sorter: false,
    },
    {
      title: `${translate('dashboard.products.price')} (${currency})`,
      dataIndex: 'price',
      key: 'price',
      width: '25%',
      sorter: false,
      render: (text: string, record: any, i: number) => (
        <InputNumber min={0} name={`${name}[${i}].price`} placeholder={translate('dashboard.products.price')} />
      ),
    },
    {
      title: translate('dashboard.products.stock'),
      dataIndex: 'stock',
      key: 'stock',
      width: '25%',
      sorter: false,
      render: (text: string, record: any, i: number) => (
        <InputNumber
          min={0}
          name={`${name}[${i}].stock`}
          placeholder={translate('dashboard.products.stock')}
          onPressEnter={() => updateProductStock()}
          onBlur={() => updateProductStock()}
        />
      ),
    },
  ]

  return <Table dataSource={productMatrix} rowKey={(record) => record.id} columns={columns} pagination={false} />
}

export default ProductMatrixTable
