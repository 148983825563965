import React, { useState, useEffect } from 'react'
import { Menu, Layout as AntLayout, Row, Col, Card } from 'antd'

import { useHistory, useLocation } from 'react-router-dom'
import { navigationPathMapping, NavigationMapping, DISCOUNTS_PATH } from './Routes'
import { useTranslation } from 'react-i18next'

import './Discounts.less'
import { OFFERS_PATH } from './offers'
import { DISCOUNT_CODES_PATH } from './discountCodes'
import { DEAL_OF_THE_DAY_PATH } from './dealOfTheDay'

interface DiscountsWrapperProps {}

const DiscountsWrapper: React.FC<DiscountsWrapperProps> = ({ children }) => {
  const history = useHistory()
  let location = useLocation()
  const { t: translate } = useTranslation()

  const [activeKey, setActiveKey] = useState<any>()

  useEffect(() => {
    let pathname = location.pathname
    const split = pathname.split('/')[2]

    const res = navigationPathMapping.get(split)
    setActiveKey(res ? [res.toString()] : [NavigationMapping.Offers.toString()])
  }, [location])

  const goTo = (path: string) => {
    history.push(`/${DISCOUNTS_PATH}/${path}`)
  }

  return (
    <Card title={translate('dashboard.discounts')}>
      <Row>
        <Col xs={24} md={6}>
          <Menu selectedKeys={activeKey} mode="inline" style={{ borderRight: '0px' }}>
            <Menu.Item key="2" onClick={() => goTo(OFFERS_PATH)}>
              {translate('dashboard.offers.title')}
            </Menu.Item>
            <Menu.Item key="3" onClick={() => goTo(DISCOUNT_CODES_PATH)}>
              {translate('dashboard.discount_codes.title')}
            </Menu.Item>
            <Menu.Item key="4" onClick={() => goTo(DEAL_OF_THE_DAY_PATH)}>
              {translate('dashboard.offers.deal_of_the_day')}
            </Menu.Item>
          </Menu>
        </Col>
        <Col xs={24} md={18}>
          <div style={{ borderLeft: '1px solid #e8e8e8', paddingLeft: '1rem' }}>{children}</div>
        </Col>
      </Row>
    </Card>
  )
}

export default DiscountsWrapper
