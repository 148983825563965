import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface TopProductsRequest {
  startDate?: string | null
  endDate?: string | null
}

export interface ProductSales {
  unitsSold: number
  productId: string
  productName: string
}
const getTopProductsSold = (filter: TopProductsRequest): Promise<ProductSales[]> => {
  return adminHttpClient.get('/admindashboard/reports/topProductsSold', filter)
}

export default getTopProductsSold
