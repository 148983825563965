import { httpClient } from 'core/application/commons/httpClient'
import resizeImage from 'core/application/commons/imageResizer'
import files from 'core/application/files/files'

import { isSvg } from './fileUtils'

export const uploadImage = async (
  file: any,
  { optimizedResize = false, resizeToWidth = 1080, resizeToHeight = 720 },
) => {
  try {
    const requiresResize = !isSvg(file.name)
    let blob = file

    if (requiresResize) {
      blob = await resizeImage(file, {
        maxWidth: resizeToWidth,
        maxHeight: resizeToHeight,
        optimizedResize,
      })
    }
    return uploadFileToS3(file.name, file.type, blob)
  } catch (e) {
    return Promise.reject('Ka ndodhur një gabim gjatë ngarkimit të skedarit.')
  }
}

export const uploadCss = async (file: any, optimizedResize = false, resizeToWidth = 1080, resizeToHeight = 720) => {
  try {
    let blob = file

    return uploadFileToS3(file.name, file.type, blob)
  } catch (e) {
    return Promise.reject('Ka ndodhur një gabim gjatë ngarkimit të skedarit.')
  }
}

export const uploadFile = async (file: any) => {
  try {
    let blob = file
    return uploadFileToS3(file.name, file.type, blob)
  } catch (e) {
    return Promise.reject('Ka ndodhur një gabim gjatë ngarkimit të skedarit.')
  }
}

const uploadFileToS3 = async (filename: string, fileType: any, blob: any) => {
  try {
    const preSignedUrl = await files.getUploadSignedUrl(filename, fileType)
    await httpClient.put(preSignedUrl.signedUrl, blob, {
      headers: {
        'Content-Type': fileType,
        'x-amz-acl': 'public-read',
      },
    })
    const fileUrl = await files.getFileUrl(preSignedUrl.objectKey)

    return Promise.resolve({
      fileUrl,
      fileId: preSignedUrl.objectKey,
    })
  } catch (e) {
    return Promise.reject('Ka ndodhur një gabim gjatë ngarkimit të skedarit.')
  }
}
