import { adminHttpClient } from '../../commons/adminHttpClient'

export interface SaveArticleInputDto {
  id: string
  title: string
  content: string
  friendlyUrl: string
  photoId: string
  photoCaption: string
  categoryIds: string[]
  leading: true
  articleStatus: ArticleStatus
  seoTitle: string
  seoDescription: string
  seoImage: string
  shortDescription: string
  link: string
  source: string
}

export enum ArticleStatus {
  Draft = 0,
  Published = 1,
}
export const getArticlesForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/AdminDashboard/Articles/ForSave/${id}`)
  }
  return adminHttpClient.get(`/AdminDashboard/Articles/ForSave`)
}

const saveArticle = (data: SaveArticleInputDto): Promise<any> => {
  return adminHttpClient.post(`/AdminDashboard/Articles`, data)
}

export default saveArticle
