import { adminHttpClient } from '../../../commons/adminHttpClient'
import { NamedItemInt } from '../../../commons/namedItem'
import { NamedItemChildren } from '../../../commons/namedItemChildren'

export interface JobCategoryForSave {
  id: string | null
  name: string
  friendlyUrl: string
  photoId: string
  iconPhotoId: string
  priority: number
  parentId: string
  status: JobCategoryStatusEnum
  availableParents: NamedItemChildren[]
  emailsToNotify: string[]
}

export enum JobCategoryStatusEnum {
  Draft = 0,
  Active = 1,
  Archived = 2,
}
export const JobCategoryStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
    { id: 2, name: translate('general.archived') },
  ]
}

const getJobCategoryForSave = (id: string | undefined): Promise<JobCategoryForSave> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/jobCategories/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/jobCategories/forsave`)
}

export interface JobCategoryToSave {
  id?: string | null
  name: string
  friendlyUrl: string
  photoId: string
  iconPhotoId: string | null
  priority: number
  status: number
  parentId: string | null
  emailsToNotify: string[]
}

export const saveJobCategory = (category: JobCategoryToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/jobCategories', category)
}

export default getJobCategoryForSave
