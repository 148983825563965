import React from 'react'
import { Col, Row, Skeleton, Tag } from 'antd'

import CustomCard from '../../../common/components/dataDisplay/CustomCard'
import { OrdersStatistics } from 'core/application/dashboard/home/statistics/storeStatistics'
import { getOrderTitleMap, orderTagColorMap } from 'dashboard/orders/OrderStatusActions'
import { OrderStatus } from 'core/domain/order/orderStatus'

interface OrdersStatisticsCardProps {
  statistics?: OrdersStatistics
  className?: string
  loading?: boolean
  translate: (key: string) => string
}

const OrdersStatisticsCard: React.FC<OrdersStatisticsCardProps> = ({ statistics, className, loading, translate }) => {
  return (
    <CustomCard className={`custom_card__padding  ${className}`}>
      <Skeleton active loading={loading} paragraph={{ rows: 2 }}>
        <h4>{translate('dashboard.orders.title')}</h4>
        <div className="flex flex__space_between_baseline flex__wrap break_word font-14">
          <Row className="mb-2">
            <Col>
              <div>
                <Tag color={orderTagColorMap.get(OrderStatus.Completed)}>
                  {statistics?.completed ?? 0} {translate('dashboard.orders.completed')}
                </Tag>
              </div>
            </Col>
          </Row>
          <Row className="full-width mb-2">
            <Col>
              <div>
                <Tag color={orderTagColorMap.get(OrderStatus.New)} className="mb-2">
                  {statistics?.new ?? 0} {translate('dashboard.orders.new_orders')}
                </Tag>

                <Tag color={orderTagColorMap.get(OrderStatus.Processing)} className="mb-2">
                  {statistics?.processing ?? 0} {translate('dashboard.orders.processing')}
                </Tag>

                <Tag color={orderTagColorMap.get(OrderStatus.Delivering)} className="mb-2">
                  {statistics?.delivering ?? 0} {translate('dashboard.orders.delivering')}
                </Tag>

                <Tag color={orderTagColorMap.get(OrderStatus.Aborted)} className="mb-2">
                  {statistics?.aborted ?? 0} {translate('dashboard.orders.aborted')}
                </Tag>
              </div>
            </Col>
          </Row>
        </div>
      </Skeleton>
    </CustomCard>
  )
}

export default OrdersStatisticsCard
