import React from 'react'

import FileDisplayProps from './FileDisplayProps'
import Icon from 'common/components/general/Icon'

interface DisplayPdfProps extends FileDisplayProps {}

export const DisplayCss: React.FC<DisplayPdfProps> = ({ fileUrl }) => (
  <div className={'mb-16'}>
    <a href={fileUrl} target="_blank">
      <Icon type="far fa-file-code" style={{ fontSize: '64px' }} className="mt-16" />
    </a>
  </div>
)

export default DisplayCss
