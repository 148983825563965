import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'

import saveTeamMember, {
  getTeamMemberForSave,
  TeamMemberForSaveDto,
  TeamMemberStatus,
} from 'core/application/dashboard/teamMembers/manageTeamMember'

import { Row, Col, Skeleton } from 'antd'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Card from 'common/components/dataDisplay/Card'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import Textarea from 'common/components/dataEntry/formik/FormikTextAreaField'

import { formValidator } from './TeamMemberValidator'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import useCommandRequest from '../../common/hooks/useCommandRequest'
import notification from '../../common/components/feedback/Notification'

import { useHistory } from 'react-router'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import generateFriendlyUrl from '../../common/generateFriendlyUrl'
import { FormikSeoFields } from '../../common/components/dataEntry/formik/FormikSeoFields'
export const MANAGE_TEAM_MEMBERS_PATH = 'manage-team-members'

const ManageTeamMember: React.FC = () => {
  const { t } = useTranslation()
  const { id } = useParams<any>()
  const history = useHistory()
  const [item] = useQueryRequest<TeamMemberForSaveDto | null>(null, () => getTeamMemberForSave(id))
  const [sendCommandRequest, isCommandLoading] = useCommandRequest()

  const onSubmit = async (values: any) => {
    const result = await sendCommandRequest(() => saveTeamMember(values))

    if (result.completed) {
      notification.success(t('general.saved').replace('[]', t('dashboard.sites.team_members.the_team_member')))
      history.goBack()
    }
  }

  return (
    <Skeleton active loading={!item} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik enableReinitialize={true} initialValues={item} validationSchema={formValidator(t)} onSubmit={() => {}}>
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? t('dashboard.sites.team_members.edit') : t('dashboard.sites.team_members.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={t('dashboard.sites.team_members.name')}
                        name="name"
                        onBlur={(e: any) => {
                          if (!id) {
                            formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                          }
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={t('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={t('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={t('dashboard.priority')}
                        name="priority"
                        placeholder={t('dashboard.priority')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={t('dashboard.sites.team_members.job_position')}
                        name="jobPosition"
                        showOptionalLabel={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input label={t('email')} name="email" showOptionalLabel={true} />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input label={t('linkedin')} name="linkedin" showOptionalLabel={true} />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={t('dashboard.sites.team_members.description')}
                        name={'description'}
                        value={item.description}
                        showOptionalLabel={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Textarea
                        label={t('dashboard.sites.team_members.short_description')}
                        name={'shortDescription'}
                        rows={6}
                        showOptionalLabel={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Uploader
                        label={t('dashboard.the_banner')}
                        initialValues={[item.bannerPhotoId]}
                        name="bannerPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="bannerPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                        showOptionalLabel={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Uploader
                        label={t('dashboard.photo')}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                        showOptionalLabel={true}
                      />
                    </Col>
                    <Col xs={12}>
                      <Uploader
                        label={t('dashboard.thumbnail')}
                        initialValues={[item.thumbnailPhotoId]}
                        name="thumbnailPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="thumbnailPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                        showOptionalLabel={true}
                      />
                    </Col>
                    <Col xs={24}>
                      <Input label={t('resume')} name="resumeLink" showOptionalLabel={true} />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${t('dashboard.status')}`}
                        defaultValue={[item.status]}
                      >
                        {TeamMemberStatus(t).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Select
                        name="services"
                        showSearch={true}
                        mode={'multiple'}
                        showOptionalLabel={true}
                        label={`${t('services')}`}
                        defaultValue={item.services}
                      >
                        {item.availableServices.map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <FormikSeoFields />
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(t).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={isCommandLoading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageTeamMember
