import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Col, Row, Table } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import TableEditButton from 'common/components/tables/TableEditButton'
import { SETTINGS_PATH } from '../Routes'
import { MANAGE_PAYMENT_METHOD_PATH } from './ManagePaymentMethod'
import { queryRequest } from 'common/RequestUtils'
import { getPaymentMethods } from 'core/application/dashboard/storeSettings/storePaymentMethodSettings'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import Tag from 'common/components/dataDisplay/Tag'

type Props = {}
const Display = (props: any) => {
  const { t: translate } = useTranslation()

  return props.active === true ? <Tag color="green">Aktiv</Tag> : <Tag color="red">Jo Aktiv</Tag>
}
export const STORE_PAYMENT_METHOD_PATH = 'payment-method'
const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'PaymentMethodId',
  orderDescending: true,
}
const PaymentMethod = (props: Props) => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [item, setItem] = useState<any>({})
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)

  const updatePagesTable = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getPaymentMethods(searchFilter)))
    setLoading(false)
  }

  useEffect(() => {
    updatePagesTable()
  }, [searchFilter])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.store_settings.payment_method.type'),
        dataIndex: 'name',
        key: 'name',
        width: '50%',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.store_settings.payment_method.active'),
        dataIndex: 'active',
        key: 'active',
        width: '30%',
        render: (text: string, record: any) => (
          <span>
            <div>
              <Display active={record.active} />
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        render: (text: string, record: any) => (
          <span>
            <div>
              <TableEditButton
                className="mr-16"
                editUrl={`/${SETTINGS_PATH}/${MANAGE_PAYMENT_METHOD_PATH}/${record.paymentMethodId}`}
              />
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <>
      <Card
        bordered={false}
        title={
          <Row>
            <Col xs={20}>
              <h4>{translate('dashboard.store_settings.payment_method.name')}</h4>
            </Col>
            <Col xs={4}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${SETTINGS_PATH}/${MANAGE_PAYMENT_METHOD_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table dataSource={item && item.items} loading={loading} columns={getColumns()} />
      </Card>
    </>
  )
}

export default PaymentMethod
