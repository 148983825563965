import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Row, Col, Skeleton, Tabs } from 'antd'
import saveProject, {
  getProjectForSave,
  ProjectForSaveDto,
  ProjectStatus,
} from 'core/application/dashboard/projects/manageProjects'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Notification } from 'common/genericNotification'
import Input from '../../common/components/dataEntry/formik/FormikInputField'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from '../../common/components/dataEntry/formik/upload/FormikUploaderField'
import Card from '../../common/components/dataDisplay/Card'
import { Formik, Form as FormikForm } from 'formik'
import FormSaveCancelButtons from '../../common/components/forms/SaveCancelFormButtons'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import Textarea from 'common/components/dataEntry/formik/FormikTextAreaField'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import { formValidator } from './ProjectValidator'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'

export const MANAGE_PROJECTS_PATH = 'manage-projects'
function isValidHttpUrl(string: string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
const ManageProjects: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [item, setItem] = useState<ProjectForSaveDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getProjectForSave(id)))
    setLoading(false)
  }
  const onSubmit = async (values: any) => {
    const result = await commandRequest(() => saveProject(values))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.projects.name')))
  }

  const isTabDisabled = (fileId: string | null, fieldType: 'video' | 'url') => {
    if (id) return false
    if (fileId === null) return false
    if (fileId === '') return false
    if (isValidHttpUrl(fileId) && fieldType === 'video') return false
    return true
  }
  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.projects.edit') : translate('dashboard.projects.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.name')}
                        name="name"
                        placeholder={translate('general.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="serviceId"
                        showSearch={true}
                        label={`${translate('dashboard.projects.available_services')}`}
                        placeholder={translate('dashboard.projects.available_services')}
                        defaultValue={id ? [item.serviceId] : []}
                      >
                        {item.availableServices.map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'description'}
                        placeholder={translate('dashboard.description')}
                        value={item?.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Textarea
                        label={translate('dashboard.short_description')}
                        name="shortDescription"
                        placeholder={translate('dashboard.short_description')}
                        rows={6}
                      />
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col xs={24} md={12}>
                      <Uploader
                        label={`${translate('dashboard.photo')}`}
                        initialValues={item.photoIds}
                        name="photoIds"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoIds"
                        optimizedResize={true}
                        mode="multiple"
                        maxUploads={5}
                      />
                    </Col>
                    <Col xs={24} md={12}>
                      <Uploader
                        label={`${translate('dashboard.thumbnail')}`}
                        initialValues={item.thumbnailId ? [item.thumbnailId] : []}
                        name="thumbnailId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="thumbnailId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                    <Col xs={24} md={24}>
                      <Uploader
                        label={`${translate('dashboard.banner_photo')}`}
                        initialValues={[item.bannerPhotoId]}
                        name="bannerPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="bannerPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                    <Col span={24}>
                      <Tabs defaultActiveKey={id ? (isValidHttpUrl(formik.values.fileId) ? '2' : '1') : '1'}>
                        <Tabs.TabPane
                          tab="Video Upload"
                          disabled={isTabDisabled(formik.values.fileId, 'video')}
                          key="1"
                        >
                          <Uploader
                            label={`${translate('dashboard.sites.files.video')}`}
                            initialValues={[isValidHttpUrl(formik.values.fileId) ? '' : item.fileId]}
                            name="fileId"
                            resizeToWidth={1080}
                            resizeToHeight={720}
                            fieldName="fileId"
                            optimizedResize={true}
                            mode="single"
                            maxUploads={1}
                          />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Video Url" disabled={isTabDisabled(formik.values.fileId, 'url')} key="2">
                          <Input
                            label={translate('dashboard.url')}
                            name="fileId"
                            placeholder={translate('dashboard.url')}
                            allowClear
                          />
                        </Tabs.TabPane>
                      </Tabs>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [item.status] : []}
                      >
                        {ProjectStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <FormikSeoFields />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageProjects
