import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import { Col, Row, Table } from 'antd'
import {
  EmailSubscriptionsPaged,
  getEmailSubscriptions,
} from 'core/application/emailSubscriptions/emailSubscriptionsApi'
import { formatDate, formatDateWithUtc } from 'common/dateFormatters'

export const SUBSCRIPTIONS_PATH = 'subscriptions'

const EmailSubscriptions: React.FC = () => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [pagedItems, setPagedItems] = useState<EmailSubscriptionsPaged>({} as EmailSubscriptionsPaged)
  const [filter, setFilter] = useState<OrderedPageFilter>({
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: true,
  } as OrderedPageFilter)

  useEffect(() => {
    ;(async () => {
      await updateTable()
    })()
  }, [filter])

  const updateTable = async () => {
    setLoading(true)

    const result = await queryRequest(() => getEmailSubscriptions(filter))
    setPagedItems(result)

    setLoading(false)
  }

  const columns: any[] = [
    {
      title: translate('dashboard.orders.order_details.email'),
      dataIndex: 'email',
      key: 'email',
      width: '30%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('general.name'),
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['md'],
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => formatDate(record.createdAt),
    },
  ]

  const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <>
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('general.email_subscriptions')}</h4>
            </Col>
            <Col xs={6} md={8}></Col>
          </Row>
        }
      >
        <Table
          rowKey={(record) => record.id}
          dataSource={pagedItems.items}
          onChange={onTableChange}
          loading={loading}
          columns={columns}
          scroll={{ x: true }}
          pagination={{
            total: pagedItems.totalItemCount,
            pageSize: pagedItems.pageSize,
            current: pagedItems.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default EmailSubscriptions
