export enum OrderStatus {
  New = 0,
  Processing = 1,
  Delivering = 2,
  Completed = 3,
  Aborted = 4,
}

export enum OrderItemStatus {
  Active = 0,
  Archived = 1,
  Draft = 2,
}
