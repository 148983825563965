import React from 'react'
import { Table } from 'antd'

import { Order, OrderItem, SelectedAttributeValue } from 'core/application/dashboard/home/orders/orderDetails'
import { formatMoney } from 'common/moneyFormatter'
import Tag from 'common/components/dataDisplay/Tag'
import Dropdown from 'common/components/navigation/Dropdown'
import OrderStatusActions, {
  getOrderItemTitleMap,
  getOrderTitleMap,
  orderItemTagColorMap,
  orderTagColorMap,
} from '../OrderStatusActions'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { ORDER_DETAILS_PATH } from './index'
import { useHistory } from 'react-router-dom'
import { MANAGE_PRODUCTS_PATH } from 'dashboard/products/ManageProduct'
import { PRODUCTS_PATH } from 'dashboard/products/Products'
import { AbandonCheckoutDetails } from 'core/application/dashboard/orders/abandonCheckoutDetails'

interface ProductsTableProps {
  order: Order | AbandonCheckoutDetails
  products: OrderItem[]
  currency: string
  translate: (key: string) => string
}

const OrderProductsTable: React.FC<ProductsTableProps> = ({ order, products, currency, translate }) => {
  const history = useHistory()
  const goToProductDetails = (id: string) => {
    history.push(`/${PRODUCTS_PATH}/${MANAGE_PRODUCTS_PATH}/${id}`)
  }
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.orders.order_details.name'),
        dataIndex: 'productName',
        key: 'productName',
        sorter: false,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <>
            <div className="d-flex">
              <img src={record.productMainPhotoUrl} width={100} />
              <span className="ml-8">
                {record.productName}
                <br />
                {record.stockKeepingUnit ? ` (SKU: ${record.stockKeepingUnit})` : ''}
              </span>
            </div>
          </>
        ),
      },
      {
        title: translate('dashboard.orders.order_details.selected_attributes'),
        dataIndex: 'selectedAttributes',
        key: 'selectedAttributes',
        sorter: false,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => {
          return (
            <span>
              {record.selectedAttributes.map((attr: SelectedAttributeValue) => (
                <div>
                  <span className="text-bold">{attr.attributeName}:</span> {attr.attributeValueName}
                </div>
              ))}
            </span>
          )
        },
      },
      {
        title: translate('dashboard.orders.order_details.product_price'),
        dataIndex: 'finalPrice',
        key: 'finalPrice',
        align: 'right',
        sorter: false,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatMoney(record.finalPrice, currency)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.orders.order_details.total_amount'),
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'center',
        sorter: false,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.quantity}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.orders.order_details.total_price'),
        dataIndex: 'subTotal',
        key: 'subTotal',
        align: 'right',
        sorter: false,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatMoney(record.subTotal, currency)}</span>
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <Table
      onRow={(record: any, index: any) => {
        return {
          onClick: () => goToProductDetails(record.productId),
        }
      }}
      rowClassName="pointer"
      dataSource={products}
      columns={getColumns()}
      pagination={false}
      scroll={{ x: 'max-content' }}
    />
  )
}

export default OrderProductsTable
