import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItemInt } from 'application/commons/namedItem'
import PagedList from 'application/commons/pagedList'
import { Moment } from 'moment'
import OrderedPageFilter from 'application/commons/orderedPageFilter'

export interface PagedStorePages {
  items: StorePageInfo[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}
export enum PageStatus {
  Active,
  Archived,
  Draft,
}
export const PageStatusMap = new Map([
  [PageStatus.Active, { name: 'Active', value: PageStatus.Active, color: 'green' }],
  [PageStatus.Archived, { name: 'Archived', value: PageStatus.Archived, color: 'red' }],
  [PageStatus.Draft, { name: 'Draft', value: PageStatus.Draft, color: 'orange' }],
])
export interface StorePageInfo {
  id: string
  title: string
  createdAt: Moment
}

export type StorePageForSave = {
  id?: string | null | undefined
  title: string
  template: string
  content: string
  codeRevisions?: any[] | undefined
  status: number
  friendlyUrl: string
  availableStatuses: NamedItemInt[]
  seoTitle?: string
  seoDescription?: string
  seoImage?: string
}

export type StorePageToSave = Omit<StorePageForSave, 'availableStatuses'>

export const getStorePages = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/storepagesettings', filter)
}

export const getStorePageForSave = (id: string | null): Promise<StorePageForSave | undefined> => {
  return id
    ? adminHttpClient.get(`/admindashboard/storepagesettings/forsave/${id}`)
    : adminHttpClient.get(`/admindashboard/storepagesettings/forsave`)
}

export const deleteStorePage = (id: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/storepagesettings', {
    data: { id: id },
  })
}

export const saveStorePage = (data: StorePageToSave) => {
  return adminHttpClient.post('/admindashboard/storepagesettings', data)
}
