import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import BrandsTable from './BrandsTable'
import { queryRequest } from '../../common/RequestUtils'
import Button from '../../common/components/general/Button'
import Card from '../../common/components/dataDisplay/Card'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getBrands, { PagedBrands } from 'core/application/dashboard/brands/brands'
import deleteBrand from 'core/application/dashboard/brands/deleteBrand'
import { MANAGE_BRAND_PATH } from './ManageBrand'

export const BRANDS_PATH = 'brands'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const Categories: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingBrands, setLoadingBrands] = useState<boolean>(false)
  const [brands, setBrands] = useState<PagedBrands>(pagedColumnDefaultValues)
  const history = useHistory()
  useEffect(() => {
    updateBrandsTable()
  }, [searchFilter])

  const updateBrandsTable = async () => {
    setLoadingBrands(true)
    const result = await queryRequest(() => getBrands(searchFilter))
    setBrands(result)
    setLoadingBrands(false)
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('general.brands')}</h4>
            </Col>
            <Col xs={10} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`${history.location.pathname}/${MANAGE_BRAND_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <BrandsTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          brands={brands}
          loadingBrands={loadingBrands}
          history={history}
          translate={translate}
          onDeleteBrand={deleteBrand}
          updateBrandsTable={updateBrandsTable}
        />
      </Card>
    </>
  )
}

export default Categories
