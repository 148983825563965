import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'

import {
  getCategoryTranslation,
  CategoryTranslation,
  saveCategoryTranslation,
  CategoryTranslationToSave,
  deleteCategoryTranslation,
} from 'core/application/dashboard/blog/categories/translations'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'

export const MANAGE_BLOG_CATEGORY_TRANSLATION_PATH = 'manage-blog-category-translation'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().nullable().trim().required(translate('general.required')),
  })

const ManageCategoryTranslation: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { categoryId, languageId } = useParams<any>()
  const [item, setItem] = useState<CategoryTranslation | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getCategoryTranslation(categoryId, languageId))
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: CategoryTranslation) => {
    let categoryTranslationToSave: CategoryTranslationToSave = {
      categoryId: values.categoryId,
      languageId: values.languageId,
      name: values.name ?? '',
    }

    const result = await commandRequest(() => saveCategoryTranslation(categoryTranslationToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.translation')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={
                  <Row>
                    <Col xs={14} md={16}>
                      <h4>{`${translate('dashboard.translation')} ${languageId.toUpperCase()}`}</h4>
                    </Col>
                    <Col xs={10} md={8}>
                      <div className={'text-right'}>
                        <TableDeleteButton
                          onDelete={() => deleteCategoryTranslation(item.categoryId, item.languageId)}
                          onDeleted={() => history.goBack()}
                        />
                      </div>
                    </Col>
                  </Row>
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={`${translate('dashboard.categories.name')} (${translate(
                          'dashboard.original_translation',
                        )} : ${item.defaultName})`}
                        name="name"
                        placeholder={translate('dashboard.categories.name')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageCategoryTranslation
