import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { queryRequest } from 'common/RequestUtils'
import { Col, Row } from 'antd'

import { PagedContactRequests, getContactRequests } from 'core/application/dashboard/contacts/contactRequests'

import Card from 'common/components/dataDisplay/Card'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import ContactRequestsTable from './ContactRequestsTable'

export const CONTACT_REQUESTS_PATH = 'contact-requests'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const ContactRequests: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [contactRequests, setContactRequests] = useState<PagedContactRequests>(pagedColumnDefaultValues)
  const history = useHistory()
  useEffect(() => {
    updateContactRequestsTable()
  }, [searchFilter])

  const updateContactRequestsTable = async () => {
    setLoading(true)
    const result = await queryRequest(() => getContactRequests(searchFilter))
    setContactRequests(result)
    setLoading(false)
  }

  return (
    <>
      <Card
        bordered={false}
        title={
          <Row>
            <Col xs={24} md={24}>
              <h4>{translate('dashboard.contact_requests.title')}</h4>
            </Col>
          </Row>
        }
      >
        <ContactRequestsTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          contactRequests={contactRequests}
          loading={loading}
          history={history}
          translate={translate}
          updateContactRequestsTable={updateContactRequestsTable}
        />
      </Card>
    </>
  )
}

export default ContactRequests
