import React, { ReactNode } from 'react'

import './DataDisplay.less'

interface CustomCardProps {
  title?: string | ReactNode
  children: ReactNode
  className?: string
}

const CustomCard: React.FC<CustomCardProps> = ({ title, children, className }) => {
  return (
    <div className={`${className} custom_card`}>
      {title && (
        <div className="p16-24">
          <h5>{title}</h5>
        </div>
      )}
      <div className="custom_card__content">{children}</div>
    </div>
  )
}

export default CustomCard
