import { adminHttpClient } from '../../commons/adminHttpClient'
import moment, { Moment } from 'moment'
import OrderedPageFilter from '../../commons/orderedPageFilter'
import PagedList from '../../commons/pagedList'

export interface PagedBannerItems extends PagedList<BannerItem> {}

export interface BannerItem {
  id: string
  name: string
  priority: number
  photoUrl: string
}

const getBannerItems = (filter: OrderedPageFilter, bannerId: string): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'name',
    orderDescending: false,
  }

  return adminHttpClient.get(`/admindashboard/bannerItems/${bannerId}`, filter)
}

export default getBannerItems
