import React, { Fragment } from 'react'
import AceEditor from 'react-ace'
import { Field } from 'formik'
import Error from './FormItemError'
import Label from '../components/Label'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/ext-language_tools'

type FormikCodeEditorProps = {
  value?: string
  name: string
  label?: string
}

const FormikCodeEditor: React.FC<FormikCodeEditorProps> = ({ name, label }) => {
  return (
    <Field name={name}>
      {({ field, meta: { touched, error } }: any) => (
        <Fragment>
          <Label htmlFor={name} className="no-select hand-on-hover">
            {label}
          </Label>
          <AceEditor
            {...field}
            onChange={(value) => field.onChange(name)(value)}
            mode="html"
            theme="github"
            id={name}
            name="codeEditor"
            width={'100%'}
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showPrintMargin: false,
            }}
          />
          {touched && error && <Error>{error}</Error>}
        </Fragment>
      )}
    </Field>
  )
}

export default FormikCodeEditor
