import React, { ReactNode } from 'react'
import { Card as AntCard } from 'antd'
import classnames from 'classnames'

import './DataDisplay.less'

interface CardProps {
  size?: 'default' | 'small'
  title?: string | ReactNode
  children: ReactNode
  extra?: string | ReactNode
  className?: string
  noPadding?: boolean
  bordered?: boolean
  bodyStyle?: any
}

const Card = (props: CardProps) => {
  const classNames = classnames({ no_padding: props.noPadding })

  return (
    <AntCard {...props} className={`${classNames} ${props.className}`} bodyStyle={props.bodyStyle}>
      {props.children}
    </AntCard>
  )
}

export default Card
