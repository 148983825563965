import React from 'react'
import { Table } from 'antd'

import { formatDateTime } from '../../common/dateFormatters'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedClients } from 'core/application/dashboard/clients/clients'
import Icon from 'common/components/general/Icon'

interface ClientsTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  clients: PagedClients
  loadingClients: boolean
  history: any
  translate: (key: string) => string
  updateClientsTable: () => void
}

const ClientsTable: React.FC<ClientsTableProps> = ({
  setOrderFilter,
  filter,
  clients,
  loadingClients,
  history,
  translate,
  updateClientsTable,
}) => {
  const resolveIcon = (property: boolean) => {
    return property ? (
      <Icon type="far fa-check-circle" className="color-green-5 mr-8" />
    ) : (
      <Icon type="far fa-ban" className="color-yellow-7 mr-8" />
    )
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.clients_table.firstName'),
        dataIndex: 'firstName',
        key: 'firstName',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.firstName}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.lastName'),
        dataIndex: 'lastName',
        key: 'lastName',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.lastName}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.email}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.phoneNumber'),
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.phoneNumber}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.city'),
        dataIndex: 'city',
        key: 'city',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.city}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.country'),
        dataIndex: 'country',
        key: 'country',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.country}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.has_account'),
        dataIndex: 'hasAccount',
        key: 'hasAccount',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{resolveIcon(record.hasAccount)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.has_orders'),
        dataIndex: 'hasOrders',
        key: 'hasOrders',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>{resolveIcon(record.hasOrders)}</div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={clients.items}
      onChange={onTableChange}
      loading={loadingClients}
      columns={getColumns()}
      scroll={{ x: true }}
      pagination={{
        total: clients.totalItemCount,
        pageSize: clients.pageSize,
        current: clients.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default ClientsTable
