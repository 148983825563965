export enum CompanyStatus {
  Draft,
  InReview,
  Published,
  Archived,
}

export enum NumberOfStoresOptions {
  Undefined,
  Zero,
  One,
  TwoToFive,
  SixToTen,
  ElevenToTwenty,
  TwentyOneToFifty,
  MoreThanFifty,
}

export enum NumberOfEmployeesOptions {
  Undefined,
  OneToTen,
  ElevenToTwenty,
  TwentyOneToFifty,
  FiftyOneToOneHundred,
  OneHundredAndOneToTwoHundred,
  TwoHundredAndOneToThreeHundred,
  MoreThanThreeHundred,
}
