import React from 'react'
import { Skeleton } from 'antd'

import CustomCard from '../../../common/components/dataDisplay/CustomCard'

interface StatisticCardProps {
  title: string
  amount: string | number
  percent?: number
  className?: string
  loading?: boolean
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, amount, percent, className, loading }) => {
  return (
    <CustomCard className={`custom_card__padding  ${className}`}>
      <Skeleton active loading={loading} paragraph={{ rows: 2 }}>
        <div className="pb-8">
          <h4>{title}</h4>
        </div>
        <div className="flex flex__space_between_baseline  flex__wrap break_word">
          <h2>{amount}</h2>
          {percent && <h4 className="color-success"> +{percent}%</h4>}
        </div>
      </Skeleton>
    </CustomCard>
  )
}

export default StatisticCard
