import React from 'react'
import TimePicker, { TimePickerProps } from '../components/TimePicker'
import Error from './FormItemError'
import { useField, useFormikContext } from 'formik'
import Label from '../components/Label'
import FieldProps from './FieldProps'

type FormikTimePickerProps = TimePickerProps & FieldProps

const FormikTimePickerField: React.FC<FormikTimePickerProps> = (props) => {
  const [field, meta] = useField(props.name)
  const context = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>

  const onTimeChange = (time: any, timeString: string) => {
    context.setFieldValue(props.name, time)
  }

  const onBlur = (): void => {
    context.setFieldTouched(props.name)
  }

  return (
    <>
      <div>
        <div>
          {props.label && (
            <Label htmlFor={props.id || props.name} className=" formik-field__input-label no-select hand-on-hover">
              {props.label}
            </Label>
          )}
        </div>
        <div>
          <TimePicker {...props} onChange={onTimeChange} className={` ${props.className} p-8`} onBlur={onBlur} />
        </div>
        <div className="mb-16 mt-8">{error}&nbsp;</div>
      </div>
    </>
  )
}

export default FormikTimePickerField
