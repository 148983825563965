import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface TeamMemberDto {
  id?: string
  name: string
  jobPosition: string
  status: string
  createdAt: string
}

const getTeamMembers = (filter: OrderedPageFilter): Promise<PagedList<TeamMemberDto>> => {
  return adminHttpClient.get(`/SiteDashboard/TeamMembers`, filter)
}

export default getTeamMembers
