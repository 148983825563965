import * as Yup from 'yup'

export const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    friendlyUrl: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    description: Yup.string().nullable().trim().notRequired(),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.number_required'))
      .min(0, translate('general.minimun_required')),
    photoIds: Yup.array().required(translate('general.required')).nullable().typeError(translate('general.required')),
  })
