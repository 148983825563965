import { adminHttpClient } from '../../commons/adminHttpClient'
import { ShippingRateType } from 'domain/store/shippingRateType'
import { NamedItemInt } from '../../commons/namedItem'
import { NamedItemChildren } from '../../commons/namedItemChildren'

export type StoreShippingZone = {
  id: string
  name: string
  isDomestic: boolean
  isRestOfTheWorld: boolean
  countries: string[]
  shippingRates: StoreShippingRate[]
}

export type StoreShippingZoneForSave = {
  id?: string | null
  name?: string | null
  isDomestic: boolean
  isRestOfTheWorld: boolean
  canBeRestOfTheWorld: boolean
  continents: string[]
  availableContinents: NamedItemChildren[]
}

export type ShippingZoneContinent = {
  id: number
  countries: ShippingZoneCountry[]
}

export type ShippingZoneCountry = {
  id: number
  regions: number[]
}

export type ShippingZoneContinentForSave = {
  id: number
  name: string
  countries: ShippingZoneCountryForSave[]
}

export type ShippingZoneCountryForSave = {
  id: number
  name: string
  isoCode: string
  regions: ShippingZoneRegionForSave[]
}

export type ShippingZoneRegionForSave = {
  id: number
  name: string
  isSelectable: boolean
}

export type StoreShippingZoneRequest = {
  id?: string | null
  name: string
  isDomestic: boolean
  isRestOfTheWorld: boolean
  countries: ShippingZoneCountry[]
}

export type StoreShippingRate = {
  id: string
  shippingMethod: NamedItemInt
  price: number
  minimum: number
  maximum: number | null
  rateType: ShippingRateType
}

export type StoreShippingRateForSave = {
  id?: string | null
  shippingZoneId: string
  shippingMethodId?: number | null
  price?: number | null
  minimum?: number | null
  maximum?: number | null
  rateType: ShippingRateType
  availableShippingMethods: NamedItemInt[]
  availableShippingRateTypes: NamedItemInt[]
}

export type StoreShippingRateRequest = {
  id?: string | null
  shippingZoneId: string
  shippingMethodId: number
  price: number
  minimum: number
  maximum: number | null
  rateType: ShippingRateType
}

export const getStoreShippingZones = (): Promise<StoreShippingZone[]> => {
  return adminHttpClient.get('/admindashboard/storeshippingsettings')
}

export const getStoreShippingZoneForSave = (id?: string | null): Promise<StoreShippingZoneForSave> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/storeshippingsettings/shippingzoneforsave/${id}`)
  }
  return adminHttpClient.get('/admindashboard/storeshippingsettings/shippingzoneforsave')
}

export const saveShippingZone = (shippingZone: StoreShippingZoneRequest): Promise<any> => {
  return adminHttpClient.put('/admindashboard/storeshippingsettings/shippingzone', shippingZone)
}

export const deleteShippingZone = (id: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/storeshippingsettings/shippingzone', {
    data: { id: id },
  })
}

export const getStoreShippingRateForSave = (id?: string | null): Promise<StoreShippingRateForSave> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/storeshippingsettings/shippingrateforsave/${id}`)
  }
  return adminHttpClient.get('/admindashboard/storeshippingsettings/shippingrateforsave')
}

export const saveShippingRate = (shippingRate: StoreShippingRateRequest): Promise<any> => {
  return adminHttpClient.put('/admindashboard/storeshippingsettings/shippingrate', shippingRate)
}

export const deleteShippingRate = (id: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/storeshippingsettings/shippingrate', {
    data: { id: id },
  })
}
