import React, { useState, useEffect } from 'react'
import { Menu, Row, Col, Card } from 'antd'

import { useHistory, useLocation } from 'react-router-dom'
import { SETTINGS_PATH } from './Routes'
import { useTranslation } from 'react-i18next'
import { STORE_SHIPPING_SETTINGS_PATH } from './storeShippingSettings'
import { STORE_GENERAL_SETTINGS_PATH } from './storeGeneralSettings'
import { STORE_SOCIAL_SETTINGS_PATH } from './storeSocialSettings'
import { STORE_TEMPLATE_SETTINGS_PATH } from './storeTemplateSettings'
import { STORE_SITE_SETTINGS_PATH } from './storeSiteSettings'
import { STORE_PAGE_SETTINGS_PATH } from './storePageSettings'
import { STORE_MENU_SETTINGS_PATH } from './storeMenuSettings'

import './StoreSettings.less'
import { STORE_PAYMENT_METHOD_PATH } from './storePaymentMethodSettings'
import { STORE_SUPPORTED_LANGUAGES_PATH } from './storeSupportedLanguages'

import { STORE_SUPPORTED_CURRENCIES_PATH } from './storeSupportedCurrencies'
import { MenuItem } from 'dashboard/Navigation'
import useLoginUser from '../../common/hooks/useLoginUser'
import { User } from 'core/application/account/loginUser'
import { Module } from 'core/domain/store/module'
import { STORE_ADMIN_PANEL_SETTINGS_PATH } from './storeAdminPanelSettings'
import { STORE_PAGE_SECTION_SETTINGS_PATH } from '../pageSections/PageSection'

interface StoreSettingsWrapper {
  children: any
}

const StoreSettingsWrapper: React.FC<StoreSettingsWrapper> = ({ children }) => {
  const history = useHistory()
  let location = useLocation()
  const { t: translate } = useTranslation()

  const [activeKeys, setActiveKeys] = useState<any>()
  const [user] = useLoginUser()
  const navigationMenu = getSettingsMenu(user)

  useEffect(() => {
    let pathname = location.pathname
    const split = pathname.split('/')[2]

    const res = navigationMenu.find((x) => x.path === split)
    setActiveKeys(res ? [`${res.key}`] : [])
  }, [location])

  const goTo = (path: string) => {
    history.push(`/${SETTINGS_PATH}/${path}`)
  }

  return (
    <Card title={translate('general.config')}>
      <Row>
        <Col xs={24} md={6}>
          <Menu selectedKeys={activeKeys} mode="inline" style={{ borderRight: '0px' }}>
            {navigationMenu.map((item: MenuItem) => (
              <Menu.Item
                style={{ margin: '0' }}
                key={item.key}
                icon={item.icon}
                onClick={() => {
                  goTo(item.path)
                }}
              >
                {translate(item.name)}
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col xs={24} md={18}>
          <div style={{ borderLeft: '1px solid #e8e8e8' }}>{children}</div>
        </Col>
      </Row>
    </Card>
  )
}

export default StoreSettingsWrapper

const getSettingsMenu = (user: User): MenuItem[] => {
  const menuItems: MenuItem[] = []

  const moduleMenuItemMap = getModuleMenuItemMap()
  const settingsModule = user.modules.find((m) => m.module == Module.Settings)

  if (settingsModule) {
    settingsModule.children.forEach((x) => {
      const menuItem = moduleMenuItemMap.get(x)
      if (menuItem) {
        menuItems.push(menuItem)
      }
    })
  }

  return menuItems
}

const getModuleMenuItemMap = (): Map<Module, MenuItem> => {
  const moduleMenuMap = new Map<Module, MenuItem>()

  moduleMenuMap.set(Module.ShippingSettings, {
    name: 'dashboard.store_settings.shipping_settings',
    key: Module.ShippingSettings,
    path: `${STORE_SHIPPING_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.GeneralSettings, {
    name: 'dashboard.store_settings.general_settings',
    key: Module.GeneralSettings,
    path: `${STORE_GENERAL_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.SocialSettings, {
    name: 'dashboard.store_settings.social_settings',
    key: Module.SocialSettings,
    path: `${STORE_SOCIAL_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.TemplateSettings, {
    name: 'dashboard.store_settings.template_settings',
    key: Module.TemplateSettings,
    path: `${STORE_TEMPLATE_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.SiteSettings, {
    name: 'dashboard.store_settings.site_settings',
    key: Module.SiteSettings,
    path: `${STORE_SITE_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.Pages, {
    name: 'dashboard.store_settings.page_settings',
    key: Module.Pages,
    path: `${STORE_PAGE_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.Menus, {
    name: 'dashboard.store_settings.menu_settings',
    key: Module.Menus,
    path: `${STORE_MENU_SETTINGS_PATH}`,
  })

  moduleMenuMap.set(Module.PaymentMethods, {
    name: 'dashboard.store_settings.payment_method.name',
    key: Module.PaymentMethods,
    path: `${STORE_PAYMENT_METHOD_PATH}`,
  })

  moduleMenuMap.set(Module.SupportedLanguages, {
    name: 'dashboard.store_settings.supported_languages.name',
    key: Module.SupportedLanguages,
    path: `${STORE_SUPPORTED_LANGUAGES_PATH}`,
  })

  moduleMenuMap.set(Module.SupportedCurrencies, {
    name: 'dashboard.store_settings.supported_currencies.name',
    key: Module.SupportedCurrencies,
    path: `${STORE_SUPPORTED_CURRENCIES_PATH}`,
  })

  moduleMenuMap.set(Module.AdminPanelSettings, {
    name: 'dashboard.store_settings.admin_panel_settings',
    key: Module.AdminPanelSettings,
    path: `${STORE_ADMIN_PANEL_SETTINGS_PATH}`,
  })

  return moduleMenuMap
}
