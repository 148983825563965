import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Col, Row } from 'antd'

import { useTranslation } from 'react-i18next'

import Modal from 'common/components/feedback/Modal'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import TreeSelect from 'common/components/dataEntry/formik/FormikTreeSelectField'

import {
  StoreShippingZoneForSave,
  StoreShippingZoneRequest,
  ShippingZoneCountry,
} from 'core/application/dashboard/storeSettings/storeShippingSettings'
import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'

const ShippingZoneForm: React.FC<ShippingZoneFormProps> = ({
  initialValues,
  visible,
  onCancel,
  onSubmit,
  submitting,
}) => {
  let { t: translate } = useTranslation()
  const [formIsValid, setFormIsValid] = useState<boolean>(false)
  const [restOfTheWorld, setRestOfTheWorld] = useState<boolean>(initialValues.isRestOfTheWorld)
  const [storeShippingZoneRequest, setStoreShippingZoneRequest] = useState<StoreShippingZoneRequest>(
    {} as StoreShippingZoneRequest,
  )

  const formFooter = (
    <FormSaveCancelButtons
      disabledSave={!formIsValid}
      onButtonClick={() => onSubmit(storeShippingZoneRequest)}
      loading={submitting}
    />
  )

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title={
        initialValues.id
          ? translate('dashboard.store_settings.shipping_zones.edit_title')
          : translate('dashboard.store_settings.shipping_zones.add_title')
      }
      footer={formFooter}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={formValidator(translate, restOfTheWorld)}
        initialValues={initialValues}
        onSubmit={(values) => {}}
        validate={(values: StoreShippingZoneForSave) => {
          setFormIsValid(formValidator(translate, restOfTheWorld).isValidSync(values))

          var countries: ShippingZoneCountry[] = []
          if (!restOfTheWorld) {
            values.continents.forEach((country: string) => {
              var ids = country.split('-')
              var findIndex = countries.findIndex((c) => c.id === +ids[1])
              if (findIndex !== -1 && ids[2]) {
                countries[findIndex].regions.push(+ids[2])
              } else {
                countries.push({
                  id: +ids[1],
                  regions: ids[2] ? [+ids[2]] : [],
                })
              }
            })
          }

          setStoreShippingZoneRequest({
            id: values.id,
            name: restOfTheWorld ? translate('dashboard.store_settings.shipping_zones.rest_of_the_world') : values.name,
            isDomestic: values.isDomestic,
            isRestOfTheWorld: restOfTheWorld,
            countries: countries,
          } as StoreShippingZoneRequest)

          return {}
        }}
      >
        {(formik) => (
          <FormikForm>
            <Row>
              <Col xs={24}>
                <Input
                  label={translate('dashboard.store_settings.shipping_zones.name')}
                  name="name"
                  placeholder={translate('dashboard.store_settings.shipping_zones.name')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Checkbox
                  label={translate('dashboard.store_settings.shipping_zones.rest_of_the_world')}
                  name="isRestOfTheWorld"
                  defaultChecked={restOfTheWorld}
                  onChange={() => setRestOfTheWorld(!restOfTheWorld)}
                  disabled={!initialValues.canBeRestOfTheWorld}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <TreeSelect
                  label={translate('dashboard.store_settings.shipping_zones.countries_and_regions')}
                  name="continents"
                  placeholder={translate('dashboard.store_settings.shipping_zones.countries_and_regions')}
                  maxTagCount={3}
                  allowClear
                  multiple
                  showArrow
                  showSearch
                  treeCheckable
                  defaultValue={initialValues.continents}
                  treeData={initialValues.availableContinents}
                  treeDefaultExpandAll
                  treeNodeFilterProp="title"
                  disabled={restOfTheWorld}
                ></TreeSelect>
              </Col>
            </Row>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

interface ShippingZoneFormProps {
  initialValues: StoreShippingZoneForSave
  visible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  onSubmit: (values: StoreShippingZoneRequest) => void | Promise<any>
  submitting: boolean
}

const formValidator = (translate: any, restOfTheWorld: boolean) =>
  Yup.object({
    name: Yup.string().nullable().trim().required(translate('general.required')),
    isRestOfTheWorld: Yup.boolean().required(translate('general.required')),
    continents: Yup.array()
      .nullable()
      .notRequired()
      .when('isRestOfTheWorld', {
        is: (isRestOfTheWorld) => !restOfTheWorld,
        then: Yup.array().required(translate('general.required')).min(1),
      }),
  })
export default ShippingZoneForm
