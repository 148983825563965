import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from '../../commons/namedItem'
import { DiscountType } from '../../../domain/product/offer'

export interface DiscountCode {
  id: string
  code: string
  discount: string
  type: DiscountType
  availableTypes: NamedItem[]
  startDate: string
  endDate: string
  applyToProductsInOffer: boolean
}

const getDiscountCodeForSave = (id: string | undefined): Promise<DiscountCode> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/discountcodes/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/discountcodes/forsave`)
}

export interface DiscountCodeToSave {
  id: string | null
  code: string
  discount: string
  type: DiscountType
  startDate: string
  endDate: string
  applyToProductsInOffer: boolean
}

export const saveDiscountCode = (discountCode: DiscountCodeToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/discountcodes', discountCode)
}

export default getDiscountCodeForSave
