import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Banners from './Banners'
import ManageBannerItem, { MANAGE_BANNER_ITEM_PATH } from './bannerItems/ManageBannerItem'

import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_BANNER_ITEM_PATH}/:id?`} component={ManageBannerItem} />
      <Route path={`${match.path}`} component={Banners} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
