import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import OffersTable from './OffersTable'
import { queryRequest } from 'common/RequestUtils'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getOffers, { PagedOffers } from 'core/application/dashboard/offers/offers'

import { MANAGE_OFFER_PATH } from './ManageOffer'

import '../Discounts.less'
import deleteOffer from 'core/application/dashboard/offers/deleteOffer'
import { DISCOUNTS_PATH } from '../Routes'

export const OFFERS_PATH = 'offers'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const Offers: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [offers, setOffers] = useState<PagedOffers>(pagedColumnDefaultValues)
  const history = useHistory()
  useEffect(() => {
    updateOffersTable()
  }, [searchFilter])

  const updateOffersTable = async () => {
    setLoading(true)
    const result = await queryRequest(() => getOffers(searchFilter))
    setOffers(result)
    setLoading(false)
  }

  return (
    <>
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.offers.title')}</h4>
            </Col>
            <Col xs={6} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${DISCOUNTS_PATH}/${MANAGE_OFFER_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <OffersTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          offers={offers}
          loading={loading}
          history={history}
          translate={translate}
          onDelete={deleteOffer}
          updateOffersTable={updateOffersTable}
        />
      </Card>
    </>
  )
}

export default Offers
