import { adminHttpClient } from '../../../commons/adminHttpClient'
import OrderedPageFilter from '../../../commons/orderedPageFilter'
import PagedList from '../../../commons/pagedList'
import { Moment } from 'moment'

export interface PagedJobPositions extends PagedList<JobPosition> {}

export interface JobPosition {
  id: string
  name: string
  price: number
  createdAt: Moment
  priority: number
}

const getJobPositions = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/jobPositions', filter)
}

export default getJobPositions
