import React from 'react'
import { Table, Col } from 'antd'
import { Row } from 'antd'

import Input from '../../common/components/dataEntry/formik/FormikInputField'
import { NamedItem } from 'core/application/commons/namedItem'
import { NamedItemWithDefault } from 'core/application/dashboard/products/translations'

interface ProductAttributesTranslationsTableProps {
  productAttributes: NamedItem[]
  translate: (key: string) => string
  name: string
}

const ProductAttributesTranslationsTable: React.FC<ProductAttributesTranslationsTableProps> = ({
  translate,
  productAttributes,
  name,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.products.attribute_name'),
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => (
          <span>
            <Input
              label={`${translate('dashboard.original_translation')}: ${record.defaultName}`}
              name={`${name}[${i}].name`}
              placeholder={translate('dashboard.products.attribute')}
            />
          </span>
        ),
      },
      {
        title: translate('dashboard.products.attribute_values'),
        dataIndex: 'values',
        key: 'values',
        width: '70%',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => {
          return (
            <>
              {record.values.map((val: NamedItemWithDefault, index: number) => (
                <Row>
                  <Col xs={12}>
                    <Input
                      label={`${translate('dashboard.original_translation')}: ${val.defaultName}`}
                      name={`${name}[${i}].values[${index}].name`}
                      placeholder={translate('dashboard.products.attribute_value')}
                    />
                  </Col>
                </Row>
              ))}
            </>
          )
        },
      },
    ]

    return columns
  }

  return <Table dataSource={productAttributes} columns={getColumns()} pagination={false} />
}

export default ProductAttributesTranslationsTable
