import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import TeamMembers from './TeamMembers'
import ManageTeamMember, { MANAGE_TEAM_MEMBERS_PATH } from './ManageTeamMember'
import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_TEAM_MEMBERS_PATH}/:id?`} component={ManageTeamMember} />
      <Route path={`${match.path}`} component={TeamMembers} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
