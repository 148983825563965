import React from 'react'
import FileDisplayProps from './FileDisplayProps'

interface DisplayAudioProps extends FileDisplayProps {}

export const DisplayAudio: React.FC<DisplayAudioProps> = ({ fileUrl }) => (
  <div className={'mb-16'}>
    <audio controls src={fileUrl}>
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  </div>
)

export default DisplayAudio
