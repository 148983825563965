import React from 'react'

import { Switch, Route, useRouteMatch } from 'react-router-dom'

import NotFound from 'errorPages/500Page'
import DiscountCodes, { DISCOUNT_CODES_PATH } from './discountCodes'
import Offers, { OFFERS_PATH } from './offers'
import ManageDiscountCode, { MANAGE_DISCOUNT_CODE_PATH } from './discountCodes/ManageDiscountCode'
import ManageOffer, { MANAGE_OFFER_PATH } from './offers/ManageOffer'
import DiscountsWrapper from './DiscountsWrapper'
import DealOfTheDay, { DEAL_OF_THE_DAY_PATH } from './dealOfTheDay'
import ManageDealOfTheDay, { MANAGE_DEAL_OF_THE_DAY_PATH } from './dealOfTheDay/ManageDealOfTheDay'
import Reports, { REPORTS_PATH } from 'dashboard/reports/Reports'
import { Card } from 'antd'
import { useTranslation } from 'react-i18next'
import OfferOrders from 'dashboard/reports/OfferOrders'
import DealOfTheDayOrders from 'dashboard/reports/DealOfTheDayOrders'
import DiscountCodeOrders from 'dashboard/reports/DiscountCodeOrders'

export const DISCOUNTS_PATH = 'discounts'

export const NavigationMapping = {
  Reports: 1,
  Offers: 2,
  ManageOffer: 2,
  DiscountCodes: 3,
  ManageDiscountCode: 3,
  DealOfTheDay: 4,
  ManageDealOfTheDay: 4,
}

export const navigationPathMapping = new Map([
  [REPORTS_PATH, NavigationMapping.Reports],
  [OFFERS_PATH, NavigationMapping.Offers],
  [MANAGE_OFFER_PATH, NavigationMapping.ManageOffer],
  [DISCOUNT_CODES_PATH, NavigationMapping.DiscountCodes],
  [MANAGE_DISCOUNT_CODE_PATH, NavigationMapping.ManageDiscountCode],
  [DEAL_OF_THE_DAY_PATH, NavigationMapping.DealOfTheDay],
  [MANAGE_DEAL_OF_THE_DAY_PATH, NavigationMapping.ManageDealOfTheDay],
])

const Routes: React.FC = () => {
  const { t: translate } = useTranslation()
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${REPORTS_PATH}`}>
        <Card bordered={true} title={translate('dashboard.offers.title')}>
          <OfferOrders />
        </Card>
        <Card bordered={true} title={translate('dashboard.discount_codes.title')}>
          <DiscountCodeOrders />
        </Card>
        <Card bordered={true} title={translate('dashboard.offers.deal_of_the_day')}>
          <DealOfTheDayOrders />
        </Card>
      </Route>
      <Route path={`${match.path}/${MANAGE_OFFER_PATH}/:id?`}>
        <DiscountsWrapper>
          <ManageOffer />
        </DiscountsWrapper>
      </Route>
      <Route path={`${match.path}/${OFFERS_PATH}`}>
        <DiscountsWrapper>
          <Offers />
        </DiscountsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_DISCOUNT_CODE_PATH}/:id?`}>
        <DiscountsWrapper>
          <ManageDiscountCode />
        </DiscountsWrapper>
      </Route>
      <Route path={`${match.path}/${DISCOUNT_CODES_PATH}`}>
        <DiscountsWrapper>
          <DiscountCodes />
        </DiscountsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_DEAL_OF_THE_DAY_PATH}/:id?`}>
        <DiscountsWrapper>
          <ManageDealOfTheDay />
        </DiscountsWrapper>
      </Route>
      <Route path={`${match.path}/${DEAL_OF_THE_DAY_PATH}`}>
        <DiscountsWrapper>
          <DealOfTheDay />
        </DiscountsWrapper>
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
