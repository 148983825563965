import React from 'react'
import { Table } from 'antd'

import { formatDateTime } from '../../../common/dateFormatters'
import TableDeleteButton from '../../../common/components/tables/TableDeleteButton'
import TableEditButton from '../../../common/components/tables/TableEditButton'
import { ADMIN_DASHBOARD_PATH } from '../../routes/StoreRoutes'
import { MANAGE_BANNER_ITEM_PATH } from './ManageBannerItem'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedBannerItems } from 'core/application/dashboard/banners/bannerItems'
import { BANNERS_PATH } from '../Banners'
import { NamedItem } from 'core/application/commons/namedItem'

interface BannerItemsTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  selectedBanner: NamedItem
  bannerItems: PagedBannerItems
  loading: boolean
  history: any
  translate: (key: string) => string
  onDelete: (id: string) => void
  updateBannerItemsTable: () => void
}

const BannersItemsTable: React.FC<BannerItemsTableProps> = ({
  setOrderFilter,
  selectedBanner,
  bannerItems,
  loading,
  history,
  translate,
  filter,
  onDelete,
  updateBannerItemsTable,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.photo'),
        dataIndex: 'photoUrl',
        key: 'photoUrl',
        sorter: false,
        render: (text: string, record: any) => <img src={record.photoUrl} alt={record.name} width={200} />,
      },
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.priority'),
        dataIndex: 'priority',
        key: 'priority',
        align: 'center',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.priority}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        dataIndex: '',
        align: 'center',
        key: 'actions',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${BANNERS_PATH}/${MANAGE_BANNER_ITEM_PATH}/${record.id}?parentId=${selectedBanner?.id}&parentName=${selectedBanner?.name}`}
              />
              <TableDeleteButton onDelete={() => onDelete(record.id)} onDeleted={() => updateBannerItemsTable()} />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'priority',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={bannerItems.items}
      onChange={onTableChange}
      loading={loading}
      columns={getColumns()}
      scroll={{ x: true }}
      pagination={{
        total: bannerItems.totalItemCount,
        pageSize: bannerItems.pageSize,
        current: bannerItems.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default BannersItemsTable
