import { NumberOfStoresOptions, NumberOfEmployeesOptions, CompanyStatus } from 'domain/companies'
import { adminHttpClient } from '../commons/adminHttpClient'
import SocialSettings from '../commons/socialSettings'

export type UpsertCompanyRequest = {
  name: string
  identificationCode: string
  phoneNumber: string
  friendlyUrl: string
  status: CompanyStatus
  numberOfStores?: NumberOfStoresOptions
  numberOfEmployees?: NumberOfEmployeesOptions
  id?: string
  email?: string
  description?: string
  advantages?: string
  website?: string
  time?: string
  coordinates?: string
  servicesSummary?: string
  clientBenefits?: string
  vision?: string
  logo?: string
  address?: string
  postalCode?: string
  city?: string
  countryId?: number
  regionId?: number
  seoTitle?: string
  seoDescription?: string
  seoImage?: string
  socialSettings: SocialSettings
  serviceIds: string[]
  industryIds: string[]
  secondaryPhoneNumber: string
  isVerified: boolean
  yearFounded: number
  whyUs: string
  googleMapsEmbed: string
  bannerImage: string
}

const upsertCompany = (req: UpsertCompanyRequest): Promise<string> => adminHttpClient.post('/Companies/Add', req)

export default upsertCompany
