import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface ProjectDto {
  id?: string
  name: string
  status: any
  createdAt: string
}
const getProjects = (filters: OrderedPageFilter): Promise<PagedList<ProjectDto>> => {
  return adminHttpClient.get(`/SiteDashboard/Projects`, filters)
}

export default getProjects
