import { adminHttpClient } from '../../commons/adminHttpClient'

export const getSupportedLanguages = (): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/StoreSupportedLanguages`)
}

export const getSupportedLanguagesForSave = (): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/StoreSupportedLanguages/ForSave`)
}

export const saveSupportedLanguages = (data: any): Promise<any> => {
  return adminHttpClient.put(`/AdminDashboard/StoreSupportedLanguages`, data)
}

export interface LanguageDto {
  id: string
  name: string
  photoUrl: string | null
}
