import React from 'react'
import { Table, Dropdown, Tag } from 'antd'

import { parseZoneFormat } from '../../common/dateFormatters'
import { formatMoney } from '../../common/moneyFormatter'
import Icon from '../../common/components/general/Icon'
import { ADMIN_DASHBOARD_PATH } from '../routes/StoreRoutes'
import { ORDER_DETAILS_PATH } from './OrderDetails'
import { getOrderTitleMap, orderTagColorMap } from './OrderStatusActions'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedOrders } from 'core/application/dashboard/home/orders/orders'
import { NamedItem } from 'core/application/commons/namedItem'
import { OrderStatusToUpdate } from 'core/application/dashboard/home/orders/updateOrderStatus'
import OrderStatusActions from './OrderStatusActions'

interface CustomersTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  orders: PagedOrders
  filter: OrderedPageFilter
  loadingOrders: boolean
  history: any
  orderStatuses: NamedItem[]
  translate: (key: string) => string
  updateOrderStatus: (order: OrderStatusToUpdate) => void
}

const OrdersTable: React.FC<CustomersTableProps> = ({
  setOrderFilter,
  filter,
  orders,
  loadingOrders,
  history,
  translate,
  orderStatuses,
  updateOrderStatus,
}) => {
  const goToOrderDetails = (id: string) => {
    history.push(`${ADMIN_DASHBOARD_PATH}${ORDER_DETAILS_PATH}/${id}`)
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.orders.order_number'),
        dataIndex: 'orderNumber',
        key: 'orderNumber',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToOrderDetails(record.id)}>
              <span>{record.orderNumber}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.client'),
        dataIndex: 'clientName',
        key: 'clientName',
        sorter: false,
        responsive: ['md'],
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToOrderDetails(record.id)}>
              <span>{record.clientName}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.city'),
        dataIndex: 'city',
        key: 'city',
        sorter: false,
        responsive: ['md'],
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToOrderDetails(record.id)}>
              <span>{record.city}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('payment'),
        dataIndex: 'paymentStatus',
        align: 'center',
        key: 'paymentStatus',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        responsive: ['md'],
        render: (paymentStatus: any, record: any) => {
          return paymentStatus.id === 0 ? (
            <Tag color={'green'}>{paymentStatus.name}</Tag>
          ) : (
            <Tag color={'red'}>{paymentStatus.name}</Tag>
          )
        },
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        align: 'center',
        key: 'createdAt',
        sorter: true,
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToOrderDetails(record.id)}>
              <span>{parseZoneFormat(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.amount'),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        sorter: true,
        align: 'right',
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToOrderDetails(record.id)}>
              <span>{formatMoney(record.totalPrice, record.currency)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: false,
        align: 'center',
        render: (text: string, record: any) => {
          return (
            <>
              <div>
                <Tag color={orderTagColorMap.get(record.status.id)}>
                  {getOrderTitleMap(translate).get(record.status.id)}
                </Tag>

                <Dropdown
                  overlay={
                    <OrderStatusActions
                      id={record.id}
                      updateOrderStatus={updateOrderStatus}
                      hasOnlyDigitalProducts={record.hasOnlyDigitalProducts}
                    />
                  }
                >
                  <a className="ant-dropdown-link more_actions_icon">
                    <Icon type="far fa-ellipsis-h" />
                  </a>
                </Dropdown>
              </div>
            </>
          )
        },
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={orders.items}
      onChange={onTableChange}
      loading={loadingOrders}
      columns={getColumns()}
      pagination={{
        total: orders.totalItemCount,
        pageSize: orders.pageSize,
        current: orders.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default OrdersTable
