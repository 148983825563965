import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'

import {
  getPageTranslation,
  PageTranslation,
  savePageTranslation,
  PageTranslationToSave,
  deletePageTranslation,
} from 'core/application/dashboard/storeSettings/pagesTranslations'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import FormikCodeEditor from '../../../common/components/dataEntry/formik/FormikCodeEditor'

export const MANAGE_PAGE_TRANSLATION_PATH = 'manage-page-translation'

const formValidator = (translate: any) =>
  Yup.object({
    title: Yup.string().nullable().trim().required(translate('general.required')),
    content: Yup.string().nullable().trim().required(translate('general.required')),
  })

const ManagePageTranslation: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { pageId, languageId } = useParams<any>()
  const [item, setItem] = useState<PageTranslation | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getPageTranslation(pageId, languageId))
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: PageTranslation) => {
    let pageTranslationToSave: PageTranslationToSave = {
      pageId: values.pageId,
      languageId: values.languageId,
      title: values.title ?? '',
      content: values.content ?? '',
    }

    const result = await commandRequest(() => savePageTranslation(pageTranslationToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.translation')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={
                  <Row>
                    <Col xs={14} md={16}>
                      <h4>{`${translate('dashboard.translation')} ${languageId.toUpperCase()}`}</h4>
                    </Col>
                    <Col xs={10} md={8}>
                      <div className={'text-right'}>
                        <TableDeleteButton
                          onDelete={() => deletePageTranslation(item.pageId, item.languageId)}
                          onDeleted={() => history.goBack()}
                        />
                      </div>
                    </Col>
                  </Row>
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={`${translate('dashboard.store_settings.edit_page_form.title')} (${translate(
                          'dashboard.original_translation',
                        )}: ${item.defaultTitle})`}
                        name="title"
                        placeholder={translate('dashboard.store_settings.edit_page_form.title')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikCodeEditor
                        name={'content'}
                        label={translate('dashboard.store_settings.edit_page_form.content')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManagePageTranslation
