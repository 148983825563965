import React, { useState, useEffect } from 'react'
import { queryRequest } from 'common/RequestUtils'
import { useTranslation } from 'react-i18next'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'
import getNetProfit, { NetProfitResponse, NetProfitRequest } from 'core/application/dashboard/home/statistics/netProfit'
import { formatMoney } from 'common/moneyFormatter'
import StatisticCard from '../home/statistics/StatisticCard'

const NetProfit: React.FC = ({}) => {
  const { t: translate } = useTranslation()
  const [netProfit, setNetProfit] = useState<NetProfitResponse>()
  const [searchFilter, setSearchFilter] = useState<NetProfitRequest>({})
  const [loading, setLoading] = useState<boolean>(false)

  const updateNetProfit = async (searchFilter: NetProfitRequest) => {
    setLoading(true)
    const result = await queryRequest(() => getNetProfit(searchFilter))
    setNetProfit(result)
    setLoading(false)
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setSearchFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  useEffect(() => {
    ;(async () => {
      await updateNetProfit(searchFilter)
    })()
  }, [searchFilter])

  return (
    <>
      <DateRangePicker
        onChange={onDateChange}
        placeholder={[translate('general.start_date'), translate('general.end_date')]}
        className="mb-16"
      />

      {netProfit && (
        <>
          <StatisticCard
            className="mb-8"
            title={translate('dashboard.products.total_orders_price')}
            amount={formatMoney(netProfit.totalPrice, netProfit.currency)}
            loading={loading}
          />
          <StatisticCard
            title={translate('dashboard.products.profit')}
            amount={formatMoney(netProfit.profit, netProfit.currency)}
            loading={loading}
          />
        </>
      )}
    </>
  )
}

export default NetProfit
