import { useCallback, useEffect, useState } from 'react'
import notification from 'common/components/feedback/Notification'

type SendQueryRequestOptions = {
  showErrors?: boolean
  variables?: any
}
interface Data<T> {
  data: T
  isLoading: boolean
}
export default <T = undefined>(
  initialState: T,
  func?: () => Promise<T>,
  opts?: SendQueryRequestOptions,
): [T | undefined, boolean, (func: () => Promise<T>, opts?: SendQueryRequestOptions) => Promise<T | null>] => {
  const [state, setSate] = useState<Data<T>>({ data: initialState, isLoading: false })
  const { data, isLoading } = state

  const sendQueryRequest = useCallback(
    async (overrideFunc?: () => Promise<T>, overrideOpts?: SendQueryRequestOptions): Promise<T | null> => {
      const funcToExecute = overrideFunc ?? func

      if (!funcToExecute) throw new Error('No function specified to execute')

      try {
        setSate({ ...state, isLoading: true })
        const result = await funcToExecute()
        setSate({ data: result, isLoading: false })
        return result
      } catch (err: Error | any) {
        setSate({ ...state, isLoading: false })

        const options = overrideOpts ?? opts
        const displayErrors = !options || options.showErrors

        if (err && err.response && err.response.status === 401) {
          ;(window.location as any) = '/login'
          return null
        }

        if (displayErrors) {
          notification.error('An error occurred while getting the data.')
        }
        return null
      }
    },
    [opts?.variables],
  )

  useEffect(() => {
    if (func) {
      sendQueryRequest(func, opts).then(() => {})
    }
  }, [sendQueryRequest])

  return [data, isLoading, sendQueryRequest]
}
