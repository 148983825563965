import { OrderItem } from '../home/orders/orderDetails'
import { NamedItem } from 'application/commons/namedItem'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface AbandonCheckoutDetails {
  id: string
  paymentMethod: NamedItem
  currency: NamedItem
  totalAmount: number
  totalPrice: number
  notes: string
  firstName: string
  deliveryCost: number
  lastName: string
  email: string
  phoneNumber: string
  address: string
  city: string
  country: string
  orderNumber: string
  items: OrderItem[]
  aptSuite: string
  postalCode: string
  subTotalPrice: number
  createdAt: string
  discountCode: string
  discountValue: number
  hasOnlyDigitalProducts: boolean
}

const getAbandonCheckoutDetails = (id: string): Promise<AbandonCheckoutDetails> => {
  return adminHttpClient.get(`/admindashboard/abandonCheckouts/${id}`)
}

export default getAbandonCheckoutDetails
