import { NamedItem, NamedItemInt } from 'application/commons/namedItem'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface ServiceForSaveDto {
  id?: string
  name: string
  friendlyUrl: string
  description: string
  shortDescription: string
  priority: number
  photoId: string
  additionalPhotoIds: string[]
  thumbnailPhotoId: string
  bannerPhotoId: string
  iconId: string
  status: string
  seoTitle: string
  seoDescription: string
  seoImage: string
  availableParents: NamedItem[]
  parentId?: NamedItem[]
}

export enum ServiceStatusEnum {
  Draft = 0,
  Active = 1,
}

export const ServiceStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: ServiceStatusEnum.Draft, name: translate('dashboard.draft') },
    { id: ServiceStatusEnum.Active, name: translate('general.active') },
  ]
}

export const getServicesForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/Services/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/Services/ForSave`)
}

const saveServices = (values: any): Promise<any> => {
  return adminHttpClient.post(`/SiteDashboard/Services`, values)
}

export default saveServices
