import Button from 'common/components/general/Button'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import getAppointments, {
  Appointment,
  AppointmentsPaged,
  AppointmentsPagedFilter,
} from 'core/application/appointments/appointments'
import { formatDateTimeEu } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import { Card, Col, Descriptions, Row, Space, Table, Tag } from 'antd'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import deleteAppointments from 'core/application/appointments/deleteAppointments'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import ROUTE_PATHS from '../../common/routePaths'

import moment from 'moment'
import FormikDatePickerField from '../../common/components/dataEntry/formik/FormikDatePickerField'
import * as Yup from 'yup'
import Icon from '../../common/components/general/Icon'
import AppointmentStatus from 'core/domain/appointments/AppointmentStatus'
import { formatMoney } from '../../common/moneyFormatter'

const { Column } = Table

interface Props {
  translate: (key: string) => string
  updateAppointmentTable: (filter: AppointmentsPagedFilter) => void
  setSearchFilter: (filter: AppointmentsPagedFilter) => void
}

export const getAppointmentStatus = (translate: (key: string) => string): Map<AppointmentStatus, string> => {
  return new Map<AppointmentStatus, string>([
    [AppointmentStatus.Confirmed, 'Confirmed'],
    [AppointmentStatus.New, 'New'],
  ])
}

export const appointmentStatusTagColor = new Map<AppointmentStatus, string>([
  [AppointmentStatus.Confirmed, 'green'],
  [AppointmentStatus.New, 'red'],
])

export const Appointments: React.FC<Props> = ({ updateAppointmentTable, setSearchFilter }) => {
  const { t: translate } = useTranslation()
  const history = useHistory()

  const [filter, setFilter] = useState<AppointmentsPagedFilter>({
    pageNumber: 1,
    pageSize: 30,
    orderColumn: 'date',
    orderDescending: false,
    date: moment().toDate(),
  })

  const [pagedAppointments, isLoading, sendQueryRequest] = useQueryRequest<AppointmentsPaged<Appointment> | null>(null)

  useEffect(() => {
    ;(async () => {
      await sendQueryRequest(() => getAppointments(filter))
    })()
  }, [filter])

  const onSubmit = (values: any) => {
    setFilter({
      ...filter,
      date: values.date || moment().toDate(),
    })
  }

  return (
    <Formik initialValues={{}} onSubmit={() => {}} validationSchema={formValidator(translate)}>
      {(formik) => (
        <Card
          bordered={true}
          title={
            <Row>
              <Col xs={16}>
                <h4>{'List of appointments'}</h4>
              </Col>
              <Col xs={8}>
                <div className={'text-right'}>
                  <Button
                    icon="far fa-plus"
                    type="primary"
                    onClick={() => history.push(`${ROUTE_PATHS.APPOINTMENTS}/manage-appointment`)}
                  >
                    {translate('dashboard.add')}
                  </Button>
                </div>
              </Col>
            </Row>
          }
        >
          <Row gutter={16} className="mt-16">
            <Col md={4} xs={24}>
              <FormikDatePickerField defaultValue={moment()} name={'date'} allowClear={false} />
            </Col>
            <Col md={4} xs={24}>
              <div className="mb-8">
                <Button
                  type="default"
                  onClick={() => onSubmit(formik.values)}
                  className="height_40 filter_button"
                  size="large"
                  disabled={!formValidator(translate).isValidSync(formik.values)}
                >
                  {'Filter'}
                </Button>
              </div>
            </Col>
          </Row>
          <Table
            rowKey={(record) => record.id}
            loading={isLoading}
            dataSource={pagedAppointments?.items}
            scroll={{ x: true }}
            pagination={false}
            onChange={(pagination: any, filters: any, sorter: any) => {
              setFilter({
                ...filter,
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                orderColumn: sorter.field || 'date',
                orderDescending: sorter.order !== 'ascend',
              })
            }}
          >
            <Column
              title="Date"
              dataIndex="date"
              key="date"
              sorter={true}
              sortDirections={['ascend', 'descend']}
              defaultSortOrder="ascend"
              render={(date: any) => formatDateTimeEu(date)}
            />

            <Column
              title="First Name"
              dataIndex="firstName"
              key="firstName"
              sorter={true}
              sortDirections={['ascend', 'descend']}
            />

            <Column
              title="Last Name"
              dataIndex="lastName"
              key="lastName"
              sorter={true}
              sortDirections={['ascend', 'descend']}
            />

            <Column title="Phone Number" dataIndex="phoneNumber" key="phoneNumber" />

            <Column
              title="Price"
              dataIndex="price"
              key="price"
              sorter={true}
              sortDirections={['ascend', 'descend']}
              render={(text: string, record: any) => formatMoney(record.currency, record.price)}
            />

            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(status: AppointmentStatus, record: any) => (
                <Tag color={appointmentStatusTagColor.get(status)}>{getAppointmentStatus(translate).get(status)}</Tag>
              )}
            />

            <Column
              title="Completed"
              dataIndex="completed"
              key="completed"
              sorter={true}
              sortDirections={['ascend', 'descend']}
              align={'center'}
              render={(value) => (
                <Space>
                  {value ? (
                    <Icon type="far fa-check-circle" className="color-green-5 mr-8" />
                  ) : (
                    <Icon type="far fa-ban" className="color-yellow-7 mr-8" />
                  )}
                </Space>
              )}
            />
            <Column
              title="Action"
              dataIndex="action"
              key="action"
              render={(value, record: Appointment) => (
                <Space>
                  <TableEditButton editUrl={`${ROUTE_PATHS.APPOINTMENTS}/manage-appointment/${record.id}`} />
                  <TableDeleteButton
                    onDelete={async () => await deleteAppointments(record.id)}
                    onDeleted={async () => await sendQueryRequest(() => getAppointments(filter))}
                  />
                </Space>
              )}
            />
          </Table>

          {pagedAppointments && (
            <Descriptions title="Total Amount">
              {pagedAppointments.totalPrices.map((c) => (
                <Descriptions.Item label={c.currency} className={'d-block'}>
                  <b>{c.total}</b>
                </Descriptions.Item>
              ))}
            </Descriptions>
          )}
        </Card>
      )}
    </Formik>
  )
}

const formValidator = (translate: any) => Yup.object({})

export default Appointments
