import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import Input from 'common/components/dataEntry/formik/FormikInputField'

import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'

import {
  getStoreSocialSettings,
  StoreSocialSettings,
  updateStoreSocialSettings,
} from 'core/application/dashboard/storeSettings/storeSocialSettings'
import FormSaveCancelButtons from '../../../common/components/forms/SaveCancelFormButtons'

export const STORE_SOCIAL_SETTINGS_PATH = 'store-social-settings'

const formValidator = (translate: any) =>
  Yup.object({
    facebook: Yup.string().nullable().trim().notRequired(),
    youtube: Yup.string().nullable().trim().notRequired(),
    instagram: Yup.string().nullable().trim().notRequired(),
    twitter: Yup.string().nullable().trim().notRequired(),
    pinterest: Yup.string().nullable().trim().notRequired(),
    linkedin: Yup.string().nullable().trim().notRequired(),
  })

const StoreSocialSettingsForm: React.FC = () => {
  const { t: translate } = useTranslation()
  const [item, setItem] = useState<StoreSocialSettings | null>(null)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getStoreSocialSettings())
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: StoreSocialSettings) => {
    setLoadingSave(true)

    const result = await commandRequest(() => updateStoreSocialSettings(values))

    if (!result.errors) {
      notification.open({
        message: translate('general.saved_plural').replace('[]', translate('dashboard.store_settings.settings')),
        type: 'success',
      })
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }

    setLoadingSave(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card bordered={false} title={translate('dashboard.store_settings.edit_social_settings')}>
                <FormikForm>
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={translate('dashboard.store_settings.facebook')}
                        name="facebook"
                        placeholder={translate('dashboard.store_settings.facebook')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.store_settings.youtube')}
                        name="youtube"
                        placeholder={translate('dashboard.store_settings.youtube')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.store_settings.instagram')}
                        name="instagram"
                        placeholder={translate('dashboard.store_settings.instagram')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.store_settings.pinterest')}
                        name="pinterest"
                        placeholder={translate('dashboard.store_settings.pinterest')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.store_settings.twitter')}
                        name="twitter"
                        placeholder={translate('dashboard.store_settings.twitter')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Input label={translate('linkedin')} name="linkedin" />
                    </Col>
                  </Row>

                  <FormSaveCancelButtons
                    disabledSave={!formValidator(translate).isValidSync(formik.values)}
                    onButtonClick={() => onSubmit(formik.values)}
                    loading={loadingSave}
                    showCancelButton={false}
                  ></FormSaveCancelButtons>
                </FormikForm>
              </Card>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default StoreSocialSettingsForm
