import React, { ReactNode } from 'react'
import { Modal } from 'antd'
import { ButtonType } from 'antd/lib/button/button'
const { confirm } = Modal

export interface BaseModalProps {
  visible?: boolean
  confirmLoading?: boolean
  title?: React.ReactNode | string
  closable?: boolean
  onOk?: (e: React.MouseEvent<HTMLElement>) => void
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void
  afterClose?: () => void
  width?: string | number
  footer?: ReactNode
  okText?: ReactNode
  okType?: ButtonType
  cancelText?: ReactNode
  forceRender?: boolean
  destroyOnClose?: boolean
  style?: React.CSSProperties
  className?: string
  keyboard?: boolean
  closeIcon?: ReactNode
}

export interface ModalProps extends BaseModalProps {
  children?: ReactNode
}

export interface ConfirmProps extends BaseModalProps {
  content?: React.ReactNode
}

class Feedback extends React.Component<ModalProps> {
  static info = (props: ConfirmProps) => {
    return Modal.info({ ...props })
  }
  static success = (props: ConfirmProps) => {
    return Modal.success({ ...props })
  }
  static warning = (props: ConfirmProps) => {
    return Modal.warning({ ...props })
  }
  static error = (props: ConfirmProps) => {
    return Modal.error({ ...props })
  }
  static confirm = (props: ConfirmProps) => {
    return confirm({ ...props })
  }

  render() {
    return <Modal {...this.props}>{this.props.children}</Modal>
  }
}

export default Feedback
