import React from 'react'
import { useField } from 'formik'
import Error from './FormItemError'
import Checkbox from '../components/Checkbox'
import Label from '../components/Label'
import FieldProps from './FieldProps'

interface CheckboxProps extends FieldProps {
  defaultChecked?: boolean
  checked?: boolean
  disabled?: boolean
  indeterminate?: boolean
  className?: string
  onChange?: (e: any) => void
}

const FormikCheckboxField: React.FC<CheckboxProps> = ({ label, ...props }) => {
  const [field, meta] = useField({ ...props, type: '' })
  const showError = meta.touched && meta.error
  const id = props.id || props.name
  return (
    <>
      <Checkbox {...field} {...props} id={id} />
      {label && (
        <Label htmlFor={id} className="no-select pl-8">
          {label}
        </Label>
      )}
      {showError && <Error>{meta.error}</Error>}
    </>
  )
}

export default FormikCheckboxField
