import React from 'react'
import { Table, Tooltip } from 'antd'
import { Row } from 'antd'

import EditableTag from '../../common/components/dataEntry/components/EditableTag'
import ClearableInput from '../../common/components/dataEntry/components/ClearableInput'
import { ProductAttribute, ProductAttributeValue, Product } from 'core/application/dashboard/products/manageProduct'
import Input from '../../common/components/dataEntry/formik/FormikInputField'
import { useFormikContext } from 'formik'
import Button from 'common/components/general/Button'

interface ProductAttributesTableProps {
  productAttributes: ProductAttribute[]
  canEditAttributes: boolean
  translate: (key: string) => string
  onDeleteProductAttribute: (id: string, values: Product) => void
  onAddProductAttributeValue: (name: string, index: number, values: Product) => void
  onDeleteProductAttributeValue: (id: string, index: number, values: Product) => void
  updateProductMatrixTable: (product: Product) => void
  name: string
}

const ProductAttributesTable: React.FC<ProductAttributesTableProps> = ({
  translate,
  productAttributes,
  canEditAttributes,
  onDeleteProductAttribute,
  onAddProductAttributeValue,
  onDeleteProductAttributeValue,
  updateProductMatrixTable,
  name,
}) => {
  const { values, setFieldValue } = useFormikContext()

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.products.attribute_name'),
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => (
          <span>
            {canEditAttributes ? (
              <Input name={`${name}[${i}].name`} placeholder={translate('dashboard.products.attribute')} />
            ) : (
              record.name
            )}
          </span>
        ),
      },
      {
        title: translate('dashboard.products.attribute_values'),
        dataIndex: 'values',
        key: 'values',
        width: '50%',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => {
          return (
            <span>
              <div>
                <Row>
                  {record.values.map((val: ProductAttributeValue, index: number) => (
                    <EditableTag
                      editable={canEditAttributes}
                      key={val.id}
                      defaultValue={val.name}
                      name={`${name}[${i}].values[${index}].name`}
                      className="product-attribute"
                      placeholder={translate('dashboard.products.attribute_value')}
                      onClose={() => {
                        onDeleteProductAttributeValue(val.id, i, values as Product)
                      }}
                      onPressedEnter={updateProductMatrixTable}
                      closable={canEditAttributes}
                    ></EditableTag>
                  ))}
                  {canEditAttributes && (
                    <ClearableInput
                      placeHolder={translate('dashboard.products.add_attribute')}
                      className="product-attribute tag-input dashed input_height round_border"
                      onValueUpdate={(e) => {
                        onAddProductAttributeValue(e.currentTarget.value, i, values as Product)
                      }}
                    />
                  )}
                </Row>
              </div>
            </span>
          )
        },
      },
      {
        title: translate('dashboard.action'),
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              {canEditAttributes ? (
                <Button
                  className={'error-button'}
                  icon="far fa-trash-alt"
                  iconPadding={false}
                  onClick={() => {
                    onDeleteProductAttribute(record.id, values as Product)
                  }}
                ></Button>
              ) : (
                <Tooltip title={translate('dashboard.products.edit_attribute_tooltip')}>
                  <Button icon="far fa-trash-alt" iconPadding={false} disabled={true}></Button>
                </Tooltip>
              )}
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return <Table dataSource={productAttributes} columns={getColumns()} pagination={false} />
}

export default ProductAttributesTable
