export interface OrderedPageFilter {
  pageNumber: number
  pageSize: number
  orderColumn: string
  orderDescending: boolean
  searchValue?: string | null
  priceRangeStart?: number | null
  priceRangeEnd?: number | null
  createdDateStart?: string | null
  createdDateEnd?: string | null
  status?: number | null
  createdAt?: string | null
  orderNumber?: string | null
}

export const defaultFilter = {
  searchValue: '',
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}
export const defaultFilterThemes = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'name',
  orderDescending: true,
}
export const defaultFilterAppointments = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'date',
  orderDescending: true,
}
export const defaultPagedColumns = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

export default OrderedPageFilter
