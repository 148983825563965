import React from 'react'

import { InputNumber as AntInputNumber } from 'antd'
import { InputNumberProps as AntInputNumberProps } from 'antd/lib/input-number'

import './DataEntry.less'

export type InputNumberProps = AntInputNumberProps

const InputNumber: React.FC<InputNumberProps> = (props) => {
  return <AntInputNumber {...props} className={`input_height round_border ${props.className}`} />
}

export default InputNumber
