export const ROUTE_PATHS = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  DASHBOARD: '/',
  INITIAL_STORE_SETUP: '/setup',
  FILES: '/files',
  CONTACT_REQUESTS: '/contact-requests',
  SERVICES: '/services',
  TESTIMONIALS: '/testimonials',
  TEAM_MEMBERS: '/team-members',
  PROJECTS: '/projects',
  APPOINTMENTS: '/appointments',
  COMPANIES: '/companies',
  COMPANIES_MANAGE: '/companies/manage',
  PAGE_SECTIONS: '/page-sections',
  PAGE_SECTIONS_MANAGE: '/page-sections/manage',
} as const

export default ROUTE_PATHS
