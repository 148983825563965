import { adminHttpClient } from '../../commons/adminHttpClient'

export interface BannerItem {
  id: string | null
  name: string
  photoId: string
  url: string
  priority: number
  mobilePhotoId: string
  shortDescription: string
  bannerId: string
}

const manageBannerItem = (id: string | undefined): Promise<BannerItem> => {
  return adminHttpClient.get(`/admindashboard/bannerItems/forsave/${id}`)
}

export const saveBannerItem = (bannerItem: BannerItem): Promise<any> => {
  return adminHttpClient.post('/admindashboard/bannerItems', bannerItem)
}

export default manageBannerItem
