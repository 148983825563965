import React, { useState, useEffect } from 'react'
import { Row, Col, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'
import { MANAGE_JOB_POSITION_PATH } from './ManageJobPosition'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import deleteJobPosition from 'core/application/dashboard/careers/jobs/deleteJobPosition'
import getJobPositions, { PagedJobPositions } from 'core/application/dashboard/careers/jobs/jobPositions'

import useQueryRequest from 'common/hooks/useQueryRequest'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/SiteRoutes'
import { JobPositionStatus, JobPositionStatusEnum } from 'core/application/dashboard/careers/jobs/manageJobPosition'
import Display from 'common/components/dataDisplay/Tag'
import { formatDateTime } from 'common/dateFormatters'

export const JOB_POSITIONS_PATH = 'job-positions'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const JobCategories: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [jobPositions, isCategoriesLoading, query] = useQueryRequest<PagedJobPositions>(
    pagedColumnDefaultValues,
    () => getJobPositions(searchFilter),
    {
      variables: searchFilter,
    },
  )
  const history = useHistory()

  const jobPositionsTagColor = new Map<JobPositionStatusEnum, string>([
    [JobPositionStatusEnum.Draft, '#e94437'],
    [JobPositionStatusEnum.Active, '#23af47'],
    [JobPositionStatusEnum.Archived, '#fbbc06'],
  ])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <Display color={jobPositionsTagColor.get(record.status)}>
                {JobPositionStatus(translate).find((c) => c.id === record.status)?.name}
              </Display>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.priority'),
        dataIndex: 'priority',
        key: 'priority',
        align: 'center',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.priority}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${JOB_POSITIONS_PATH}/${MANAGE_JOB_POSITION_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteJobPosition(record.id)}
                onDeleted={async () => await query(() => getJobPositions(searchFilter))}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.jobs.positions')}</h4>
            </Col>
            <Col xs={10} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`${history.location.pathname}/${MANAGE_JOB_POSITION_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          rowKey={(record) => record.id}
          dataSource={jobPositions?.items}
          loading={isCategoriesLoading}
          columns={getColumns()}
          onChange={onTableChange}
          pagination={{
            total: jobPositions?.totalItemCount,
            pageSize: jobPositions?.pageSize,
            current: jobPositions?.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default JobCategories
