import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from 'application/commons/namedItem'
import { Moment } from 'moment'

export type MenuItemForSave = {
  id: string | null
  title: string
  target: string
  url: string
  priority: number
  parentId: string | null
  menuId: string
  availableParents: NamedItem[]
  availableUrls: NamedItem[]
}

export type MenuItemToSave = {
  id: string | null
  title: string
  url: string
  photoId: string
  target: string
  priority: number
  parentId: string | null
  menuId: string
}

export type MenuItemInfo = {
  id: string
  title: string
  target: string
  url: string
  priority: number
  createdAt: Moment
  children: MenuItemInfo[]
}

export type MenuInfo = {
  id: string
  name: string
  code: string
}

export const getMenus = (): Promise<any> => {
  return adminHttpClient.get(`/admindashboard/storemenusettings`)
}

export const getMenuItems = (id: string): Promise<any> => {
  return adminHttpClient.get(`/admindashboard/storemenusettings/${id}`)
}

export const getMenuItemForSave = (
  menuId: string | undefined,
  menuItemId: string | undefined,
): Promise<MenuItemForSave> => {
  if (menuItemId) {
    return adminHttpClient.get(`/admindashboard/storemenusettings/forsave/${menuId}/${menuItemId}`)
  }
  return adminHttpClient.get(`/admindashboard/storemenusettings/forsave/${menuId}`)
}

export const deleteMenuItem = (id: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/storemenusettings', {
    data: { id: id },
  })
}

export const saveMenuItem = (data: MenuItemToSave) => {
  return adminHttpClient.post('/admindashboard/storemenusettings', data)
}
