export const numberRegx = '[0-9]'
export const lowercaseRegx = '[a-z]'
export const uppercaseRegx = '[A-Z]'
export const specialCharRegx = '[!@#$%^&*]'
export const generalPasswordRegx: RegExp = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])')
export const numberOnlyRegx = new RegExp('^[0-9]+$')
export const numbersAndSpacesOnlyRegx = new RegExp('[0-9]+$')
export const numbersAndSlashOnlyRegx = new RegExp('[0-9/]$')
export const underscoreRegx = new RegExp('^[0-9s+_]*$')
export const publicUrlRegx = new RegExp('^[a-zA-Z0-9-_]*$')
export const urlRegx = new RegExp(
  '^(http://www.|https://www.|http://|https://)?[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
)
export const phoneNumberRegx = new RegExp('^[0-9+]*$')
