import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'
import { connect } from 'react-redux'

import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'
import DatePicker from 'common/components/dataEntry/formik/FormikDatePickerField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'

import getOffer, { Offer, OfferToSave, saveOffer } from 'core/application/dashboard/offers/manageOffer'
import { DiscountType, OfferType } from 'core/domain/product/offer'
import { NamedItem, NamedItemInt } from 'core/application/commons/namedItem'
import { User } from 'core/application/account/loginUser'
import useLoginUser from '../../../common/hooks/useLoginUser'

export const MANAGE_DEAL_OF_THE_DAY_PATH = 'manage-deal-of-the-day'

const formValidator = (translate: any) =>
  Yup.object({
    productIds: Yup.string().nullable().required(translate('general.required')),
    type: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .oneOf([DiscountType.FixedRate, DiscountType.Percentage], translate('general.required')),
    discount: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .min(1, translate('general.minimun_required')),
    startDate: Yup.date().required(translate('general.required')).nullable().typeError(translate('general.required')),
  })

const getOfferTypes = (translate: (key: string) => string): NamedItemInt[] => {
  var types: NamedItemInt[] = []
  types.push({
    id: DiscountType.Percentage,
    name: translate('dashboard.offers.percentage'),
  })
  types.push({
    id: DiscountType.FixedRate,
    name: translate('dashboard.offers.fixedRate'),
  })
  return types
}

const getDiscountSymbol = (type: DiscountType, user: User | null) => {
  if (type === DiscountType.Percentage) return '(%)'
  else if (type === DiscountType.FixedRate && user !== null) return `(${user.currency})`
  else return ''
}

interface ManageDealOfTheDayProps {}

const ManageDealOfTheDay: React.FC<ManageDealOfTheDayProps> = ({}) => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [item, setItem] = useState<Offer | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [user] = useLoginUser()

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getOffer(id))
    if (id) {
      setItem(result)
    } else {
      setItem({
        ...result,
        type: null,
        endDate: null,
        startDate: null,
        productIds: [],
      })
    }
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: Offer) => {
    setLoading(true)
    let offer: OfferToSave = {
      id: id ? id : null,
      discount: values.discount,
      name: translate('dashboard.offers.deal_of_the_day'),
      startDate: values.startDate,
      endDate: values.startDate,
      productIds: typeof values.productIds === 'string' ? [values.productIds] : values.productIds,
      type: values.type,
      offerType: OfferType.DealOfTheDay,
    }
    const result = await commandRequest(() => saveOffer(offer))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.offers.deal_of_the_day')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={id ? translate('dashboard.offers.edit') : translate('dashboard.offers.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="type"
                        showSearch={true}
                        label={`${translate('dashboard.offers.type')} ${getDiscountSymbol(formik.values.type, user)}`}
                        placeholder={translate('dashboard.offers.type')}
                        defaultValue={id ? [item.type] : []}
                      >
                        {getOfferTypes(translate).map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.offers.discount')}
                        name="discount"
                        placeholder={translate('dashboard.offers.discount')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <DatePicker
                        label={translate('dashboard.offers.date')}
                        name="startDate"
                        allowClear={false}
                        value={formik.values.startDate ? moment(formik.values.startDate) : null}
                        placeholder={translate('dashboard.offers.date')}
                        disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day'))}
                        className="full_width"
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Select
                        label={translate('dashboard.product')}
                        name="productIds"
                        showSearch={true}
                        placeholder={translate('dashboard.product')}
                        defaultValue={formik.values.productIds}
                      >
                        {item.availableProducts.map((item: NamedItem) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageDealOfTheDay
