import { adminHttpClient } from '../../commons/adminHttpClient'

export interface GalleryDto {
  id: string | undefined
  name: string
  friendlyUrl: string
}

const getGalleries = (): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/Gallery`)
}
export default getGalleries
