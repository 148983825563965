import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ManageAppointments from './ManageAppointments'
import NotFound from '../../errorPages/NotFound'
import Appointments from './Appointments'
import ROUTE_PATHS from '../../common/routePaths'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${ROUTE_PATHS.APPOINTMENTS}/manage-appointment/:id?`} component={ManageAppointments} />
      <Route path={`${ROUTE_PATHS.APPOINTMENTS}`} component={Appointments} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
