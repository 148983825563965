import { adminHttpClient } from '../commons/adminHttpClient'
import { OrderedPageFilter } from '../commons'

export const getMenuItems = (menuId: string): Promise<any> => {
  return adminHttpClient.get(`/Restaurants/MenusItems/${menuId}`)
}

export const saveMenuItem = (request: any): Promise<any> => {
  return adminHttpClient.post(`/Restaurants/MenusItems`, request)
}
