import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from '../../commons/namedItem'
import { NamedItemChildren } from '../../commons/namedItemChildren'

export interface Product {
  id: string
  name: string
  friendlyUrl: string
  description: string
  mainPhotoId: string
  photoIds: string[]
  price: number
  stock: number
  discountPrice?: number | null
  costPerItem?: number | null
  weight: number
  measurementUnitId: number
  status: number
  type: string
  vendor: string
  tags: string[]
  seoTitle: string
  seoImage: string
  seoKeywords: string
  seoDescription: string
  seoUrl: string
  applyTax: boolean
  stockKeepingUnit: string
  barcode: string
  isPhysicalProduct: boolean
  isDigitalProduct: boolean
  trackQuantity: boolean
  continueSellingWhenOutOfStock: boolean
  canEditAttributes: boolean
  askForPrice: boolean
  makeAnOffer: boolean
  categoryIds: string[]
  hasStaticAttributes: boolean
  availableCategories: NamedItemChildren[]
  availableMeasurementUnits: NamedItem[]
  availableProductStatuses: NamedItem[]
  attributes: ProductAttribute[]
  availableAttributes: ProductAttribute[]
  productMatrix: ProductMatrix[]
  auctionRequest: AuctionRequest
  isForAuction: boolean
  canEditAuction: boolean
  parentProductId: string | null
  relatedProductText: string | null
  relatedProductIcon: string | null
  availableParentProducts: NamedItem[]
  availableBrands: NamedItem[]
  brandIds: string[]
  hasRelatedProducts: boolean
  hasPredefinedAttributes: boolean
}

type ProductPredefinedAttributeResponse = {
  Name: string
  RestrictToCategoryIds: string[]
  Values: ProductPredefineAttributeValue[]
}

type ProductPredefineAttributeValue = {
  Id: string
  Name: string
  Translations: {
    Name: string
    LanguageId: string
  }[]
}

const manageProduct = (id: string): Promise<Product> => {
  return id
    ? adminHttpClient.get(`/admindashboard/products/forsave/${id}`)
    : adminHttpClient.get(`/admindashboard/products/forsave`)
}

export interface ProductToSave {
  id?: string | null
  name: string
  friendlyUrl: string
  description: string
  mainPhotoId: string
  photoIds: string[] | null
  price: number
  stock: number
  discountPrice?: number | null
  costPerItem?: number | null
  weight: number
  measurementUnitId?: number | null
  status: number
  type: string
  vendor: string
  tags: string[] | null
  seoTitle: string
  seoImage: string
  seoKeywords: string
  seoDescription: string
  seoUrl: string
  applyTax: boolean
  stockKeepingUnit: string
  barcode: string
  isPhysicalProduct: boolean
  isDigitalProduct: boolean
  trackQuantity: boolean
  continueSellingWhenOutOfStock: boolean
  askForPrice: boolean
  makeAnOffer: boolean
  categoryIds: string[]
  attributes: ProductAttribute[]
  productMatrix: ProductMatrix[]
  auctionRequest: AuctionRequest
  isForAuction: boolean
  hasStaticAttributes: boolean
  parentProductId: string | null
  relatedProductText: string | null
  relatedProductIcon: string | null
  brandIds: string[]
}

export interface ProductAttribute {
  id: string
  name: string
  priority: number
  values: ProductAttributeValue[]
}

export interface ProductAttributeValue {
  id: string
  name: string
  description: string
  priority: number
}

export interface ProductMatrix {
  id: string
  name: string
  description: string
  photoId: string
  price: number
  stock: number
  inactive: boolean
  attributeValues: string[]
}

export type AuctionRequest = {
  startPrice: number
  startDate: string
  endDate: string
}

export const saveProduct = (product: ProductToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/products', product)
}

export default manageProduct
