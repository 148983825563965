import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from 'application/commons/namedItem'

export type StoreSiteSettings = {
  seoTitle: string
  seoKeywords: string
  seoDescription: string
  seoSocialSharingImage: string
  defaultTimezone: string
  facebookPixel: string
  googleAnalyticsCode: string
  googleTagManagerCode: string
  googleAnalyticsClientId: string
  googleAnalyticsViewId: string
  enableRecaptcha: boolean
  showGdprPolicy: boolean
  industryId?: number | null
  measurementUnitId?: number | null
  recaptchaSiteKey: string
  recaptchaSecretKey: string
}

export type StoreSiteSettingsForSave = {
  seoTitle: string
  seoKeywords: string
  seoDescription: string
  seoSocialSharingImage: string
  defaultTimezone: string
  facebookPixel: string
  googleAnalyticsCode: string
  googleTagManagerCode: string
  googleAnalyticsClientId: string
  googleAnalyticsViewId: string
  enableRecaptcha: boolean
  showGdprPolicy: boolean
  industryId?: number | null
  coordinates: string | null
  measurementUnitId?: number | null
  availableIndustries: NamedItem[]
  availableMeasurements: NamedItem[]
  googleTagManagerScriptCode: string
  googleAnalyticsScriptCode: string
  recaptchaSiteKey: string
  recaptchaSecretKey: string
}

export const getStoreSiteSettings = (): Promise<StoreSiteSettingsForSave> => {
  return adminHttpClient.get('/admindashboard/storesitesettings')
}
export const updateStoreSiteSettings = (data: StoreSiteSettings) => {
  return adminHttpClient.put('/admindashboard/storesitesettings', data)
}
