import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import DiscountCodesTable from './DiscountCodesTable'
import { queryRequest } from 'common/RequestUtils'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getDiscountCodes, { PagedDiscountCodes } from 'core/application/dashboard/discountCodes/discountCodes'

import '../Discounts.less'
import deleteDiscountCode from 'core/application/dashboard/discountCodes/deleteDiscountCode'
import { MANAGE_DISCOUNT_CODE_PATH } from './ManageDiscountCode'
import { DISCOUNTS_PATH } from '../Routes'

export const DISCOUNT_CODES_PATH = 'discount-codes'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const DiscountCodes: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [discountCodes, setDiscountCodes] = useState<PagedDiscountCodes>(pagedColumnDefaultValues)
  const history = useHistory()
  useEffect(() => {
    updateDiscountCodesTable()
  }, [searchFilter])

  const updateDiscountCodesTable = async () => {
    setLoading(true)
    const result = await queryRequest(() => getDiscountCodes(searchFilter))
    setDiscountCodes(result)
    setLoading(false)
  }

  return (
    <>
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.discount_codes.title')}</h4>
            </Col>
            <Col xs={6} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${DISCOUNTS_PATH}/${MANAGE_DISCOUNT_CODE_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <DiscountCodesTable
          setOrderFilter={setSearchFilter}
          filter={searchFilter}
          discountCodes={discountCodes}
          loading={loading}
          history={history}
          translate={translate}
          onDelete={deleteDiscountCode}
          updateDiscountCodesTable={updateDiscountCodesTable}
        />
      </Card>
    </>
  )
}

export default DiscountCodes
