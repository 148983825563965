import React, { useState } from 'react'
import { Button, Card, PageHeader, Skeleton, Space, Table } from 'antd'
import Column from 'antd/lib/table/Column'
import useQueryRequest from 'common/hooks/useQueryRequest'
import { defaultFilter, deleteDynamicCategory, getDynamicCategory } from 'core'
import { Link, useRouteMatch } from 'react-router-dom'
import { formatDateTime } from 'common/dateFormatters'
import { PlusOutlined } from '@ant-design/icons'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'

export default function Category() {
  const { path } = useRouteMatch()
  const [filter, setFilter] = useState<any>(defaultFilter)
  const [results, isLoading, query] = useQueryRequest(undefined, () => getDynamicCategory(filter))

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Skeleton loading={isLoading}>
      <Card
        bordered={false}
        title={
          <PageHeader
            className="p-0"
            title="Dynamic Category"
            extra={
              <Link to={`${path}/manage`}>
                <Button icon={<PlusOutlined />} type="primary">
                  Add
                </Button>
              </Link>
            }
          />
        }
      >
        {results && (
          <Table
            rowKey={(key) => key.id}
            dataSource={results.items}
            onChange={onTableChange}
            pagination={{
              total: results.totalItemCount,
              pageSize: results.pageSize,
              current: results.pageNumber,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '30', '40'],
            }}
          >
            <Column title="Name" dataIndex="name" />
            <Column title="Created At" dataIndex="createdAt" render={(value) => formatDateTime(value)} />
            <Column
              title="Action"
              dataIndex="action"
              render={(_, record: any) => (
                <Space>
                  <TableEditButton editUrl={`${path}/manage/${record?.id}`} />
                  <TableDeleteButton
                    disableDeleted={record.hasChildren}
                    onDeleted={() => query(() => getDynamicCategory(filter))}
                    onDelete={() => deleteDynamicCategory(record.id)}
                  />
                </Space>
              )}
            />
          </Table>
        )}
      </Card>
    </Skeleton>
  )
}
