import React from 'react'
import { Result, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Error500Page: React.FC = () => {
  let history = useHistory()
  const { t: translate } = useTranslation()

  return (
    <Result
      status="500"
      title="500"
      subTitle={translate('general.error500')}
      extra={
        <Button type="primary" onClick={() => history.push('/')}>
          {translate('general.back_to_home')}
        </Button>
      }
    />
  )
}

export default Error500Page
