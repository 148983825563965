import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Brands from './Brands'
import ManageBrand, { MANAGE_BRAND_PATH } from './ManageBrand'
import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_BRAND_PATH}/:id?`} component={ManageBrand} />
      <Route path={`${match.path}`} component={Brands} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
