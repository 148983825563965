import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface ServiceWithChildrenResponse {
  id?: string
  name: string
  friendlyUrl: string
  description: string
  shortDescription: string
  priority: number
  photo: string
  status: string
  seoTitle: string
  seoDescription: string
  children: ServiceWithChildrenResponse[]
}

const getServices = (filter: OrderedPageFilter): Promise<PagedList<ServiceWithChildrenResponse>> => {
  return adminHttpClient.get(`/SiteDashboard/Services`, filter)
}

export default getServices
