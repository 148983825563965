import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'

import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'

import { SETTINGS_PATH } from '../Routes'
import { STORE_MENU_SETTINGS_PATH } from '.'
import {
  MenuItemForSave,
  MenuItemToSave,
  saveMenuItem,
  getMenuItemForSave,
} from 'core/application/dashboard/storeSettings/storeMenuSettings'

export const STORE_MENU_SETTINGS_PATH_EDIT = 'store-menu-settings-edit'

const formValidator = (translate: any) =>
  Yup.object({
    title: Yup.string().trim().nullable().required(translate('general.required')),
    url: Yup.string().nullable().trim().required(translate('general.required')),
    target: Yup.string().trim().nullable().required(translate('general.required')),
    priority: Yup.number().required(translate('general.required')),
    parentId: Yup.string().trim().nullable().notRequired(),
  })

const ManageStoreMenu: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { menuId }: any = useParams()
  const { menuItemId }: any = useParams()
  const [item, setItem] = useState<MenuItemForSave | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getMenuItemForSave(menuId, menuItemId))
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const getSelectUrl = (id: string): any => {
    if (!id) return null
    const result: any = item?.availableUrls.filter((c: any) => c.id === id)
    return result.length ? result[0].url : null
  }
  const onSubmit = async (values: MenuItemForSave) => {
    let selectedUrl = getSelectUrl(values.url)
    let menuItem: any = {
      id: values.id === '00000000-0000-0000-0000-000000000000' ? null : values.id,
      url: values.url,
      photoId: selectedUrl,
      target: values.target,
      title: values.title,
      priority: values.priority,
      parentId: values.parentId,
      menuId: menuId,
    }

    const result = await commandRequest(() => saveMenuItem(menuItem))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.store_settings.the_menu')),
        type: 'success',
      })
      history.push(`/${SETTINGS_PATH}/${STORE_MENU_SETTINGS_PATH}`)
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={
                  menuItemId
                    ? translate('dashboard.store_settings.edit_menu')
                    : translate('dashboard.store_settings.add_menu')
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.store_settings.edit_menu_form.title')}
                        name="title"
                        placeholder={translate('dashboard.store_settings.edit_menu_form.title')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={10}>
                      <Input
                        label={translate('dashboard.store_settings.edit_menu_form.url')}
                        name="url"
                        placeholder={translate('dashboard.store_settings.edit_menu_form.url')}
                      />
                    </Col>
                    <Col span={1}></Col>
                    <Col span={6}>
                      <Select
                        label={translate('dashboard.store_settings.edit_menu_form.available_urls')}
                        name="url"
                        showArrow={true}
                        placeholder={translate('dashboard.store_settings.edit_menu_form.choose_url')}
                        defaultValue={item.url ? [item.url] : []}
                      >
                        {item.availableUrls.map((url) => (
                          <option key={url.id} value={url.id}>
                            {url.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={6}>
                      <Select
                        defaultValue={item.target ? [item.target] : []}
                        showArrow={true}
                        label={translate('dashboard.store_settings.edit_menu_form.target')}
                        placeholder={translate('dashboard.store_settings.edit_menu_form.choose_target')}
                        name="target"
                      >
                        <option key="_self" value="_self">
                          {translate('dashboard.store_settings.edit_menu_form.self')}
                        </option>
                        <option key="_blank" value="_blank">
                          {translate('dashboard.store_settings.edit_menu_form.blank')}
                        </option>
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.store_settings.edit_menu_form.priority')}
                        name="priority"
                        placeholder={translate('dashboard.store_settings.edit_menu_form.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        defaultValue={item.parentId ? [item.parentId] : []}
                        label={`${translate('dashboard.store_settings.edit_menu_form.parent')} (${translate(
                          'general.optional',
                        )})`}
                        name="parentId"
                        showArrow={true}
                        placeholder={translate('dashboard.store_settings.edit_menu_form.choose_parent')}
                        allowClear={true}
                      >
                        {item.availableParents.map((parent) => (
                          <option key={parent.id} value={parent.id}>
                            {parent.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageStoreMenu
