export const isPdf = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'pdf'
}

export const isWord = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'docx'
}

export const isZip = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'zip'
}

export const isRar = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'rar'
}

export const isAudio = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'mp3'
}

export const isVideo = (fileUrl: string) => {
  const videoExtensions = ['mp4', 'webm', 'ogg']
  const extension = fileUrl && fileUrl.split('.').pop()
  return videoExtensions.indexOf(extension ? extension.toLowerCase() : '') !== -1
}

export const isImage = (fileUrl: string) => {
  const imageExtensions = ['jpg', 'png', 'jpeg', 'svg']
  const extension = fileUrl && fileUrl.split('.').pop()
  return imageExtensions.indexOf(extension ? extension.toLowerCase() : '') !== -1
}

export const isSvg = (fileUrl: string) => {
  const extension = fileUrl && fileUrl.split('.').pop()
  return (extension ? extension.toLowerCase() : '') === 'ssvg'
}

export const isCss = (fileUrl: string) => {
  const cssxtensions = ['css', 'scss', 'less']
  const extension = fileUrl && fileUrl.split('.').pop()
  return cssxtensions.indexOf(extension ? extension.toLowerCase() : '') !== -1
}
