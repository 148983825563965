import React, { ComponentProps, memo, useCallback, useMemo, useRef } from 'react'
import { Typography } from 'antd'
import { useField } from 'formik'
import DynamicInputsList from '../components/dynamicInputs'
import { InputDescriptor } from '../components/dynamicInputs/DynamicInput'
import AceEditor from 'react-ace'
import Label from '../components/Label'

const { Text } = Typography

const parser = new DOMParser()

type Props = {
  name: string
  label: string
  originalContent: string
}
const FormikDynamicHtmlEditor = memo(({ name, label, originalContent }: Props) => {
  const [field] = useField(name)

  const parsedContentRef = useRef<Document | null>(null)

  const inputDescriptors = useMemo(() => {
    parsedContentRef.current = parser.parseFromString(field.value, 'text/html')
    const elementsToEdit = Array.from(parsedContentRef.current.querySelectorAll('[data-di-name][data-di-type]'))

    if (elementsToEdit.length === 0) return []

    return elementsToEdit
      .map(
        (element) =>
          ({
            name: element.getAttribute('data-di-name')!,
            type: element.getAttribute('data-di-type'),
            element,
            onChange: (value, update = (element) => void (element.innerHTML = value)) => {
              update(element)
              field.onChange({ target: { name, type: 'text', value: parsedContentRef.current?.body.innerHTML } })
            },
          } as InputDescriptor),
      )
      .filter((curr, i, arr) => curr.type && !arr.slice(i + 1).find(({ name }) => name === curr.name))
  }, [field.value, parsedContentRef.current])

  return (
    <>
      <DynamicInputsList inputDescriptors={inputDescriptors} />
      <div className={'mt-24'}>
        <Label htmlFor={name} className="no-select hand-on-hover">
          {label}
        </Label>
        <AceEditor
          {...field}
          onChange={(value) => field.onChange(name)(value)}
          mode="html"
          theme="github"
          name="codeEditor"
          width={'100%'}
          editorProps={{ $blockScrolling: true }}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
            showPrintMargin: false,
          }}
        />

        {field.value !== originalContent && (
          <Text
            style={{ cursor: 'pointer', color: '#096dd9' }}
            underline
            onClick={() => field.onChange({ target: { name, type: 'text', value: originalContent } })}
          >
            Return current content.
          </Text>
        )}
      </div>
    </>
  )
})

export default FormikDynamicHtmlEditor
