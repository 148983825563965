import React from 'react'
import { Tabs as AntTabs } from 'antd'
import { TabPaneProps as AntTabPaneProps } from 'antd/lib/tabs/index'

const { TabPane: AntTabPane } = AntTabs

interface TabPaneProps extends AntTabPaneProps {}

const TabPane: React.FC<TabPaneProps> = (props) => {
  return <AntTabPane {...props}>{props.children}</AntTabPane>
}

export default TabPane
