import React, { ReactNode, MouseEventHandler } from 'react'
import { Alert } from 'antd'

import './AlertStyles.less'
import { AlertProps } from 'antd/lib/alert'

const classNameMappingForEachType = new Map<string, string>([
  ['error', 'alert_error'],
  ['success', 'alert_success'],
  ['info', 'alert_info'],
  ['warning', 'alert_warning'],
])

type Props = AlertProps & {
  type: string
}

const Feedback = (props: Props) => {
  const className = `${props.className} ${classNameMappingForEachType.get(props.type)}`
  return <Alert {...props} className={className}></Alert>
}

export default Feedback
