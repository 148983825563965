import React from 'react'
import { Editor as TextEditor } from '@tinymce/tinymce-react'
import Error from './FormItemError'
import { useField } from 'formik'
import FieldProps from './FieldProps'
import Label from '../components/Label'
import { uploadImage } from './upload/uploadImage'
import { useTranslation } from 'react-i18next'

export interface HtmlEditorProps extends FieldProps {
  hint?: string
  initialValue?: string
  value?: string
  height?: number
  disabled?: boolean
  showOptionalLabel?: boolean
}

const FormikHtmlEditorField: React.FC<HtmlEditorProps> = (props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props)
  const { name } = props
  const showError = meta.touched && meta.error
  const type = 'text'

  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>

  const id = props.id || props.name

  const handleEditorBlur = () => {
    field.onBlur({ target: { name } })
  }

  return (
    <div className={'form-group'}>
      <Label htmlFor={id} className="formik-field__input-label no-select hand-on-hover">
        {props.label}
        {props.showOptionalLabel ? `(${t('optional')})` : ''}
        {props.hint}
      </Label>

      <TextEditor
        tinymceScriptSrc="/tinymce/tinymce.min.js"
        apiKey="ksspwqci2t2s16g0enoe1lcybmup5mzbym550putf9lxyh96"
        initialValue={props.value}
        onChange={(e: any) => {
          field.onChange({ target: { type, name, value: e.target.getContent() } })
        }}
        onBlur={handleEditorBlur}
        disabled={props.disabled}
        init={{
          height: 500,
          width: '100%',
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap imagetools print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | code | image',
          quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
          contextmenu: 'paste | link image imagetools | cell row column deletetable',
          images_upload_handler: handleImageUpload,
        }}
      />

      {error}
    </div>
  )
}

const handleImageUpload = (blobInfo: any, success: any, failure: any) => {
  const file = blobInfo.blob()

  uploadImage(file, { optimizedResize: true })
    .then((result) => {
      success(result.fileUrl)
    })
    .catch(() => {
      failure('An error occured while uploading the file.')
    })
}

export default FormikHtmlEditorField
