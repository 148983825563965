import React from 'react'

import { Switch, Route, useRouteMatch } from 'react-router-dom'
import StoreSettingsWrapper from './StoreSettingsWrapper'
import StoreShippingSettings, { STORE_SHIPPING_SETTINGS_PATH } from './storeShippingSettings'
import StoreGeneralSettings, { STORE_GENERAL_SETTINGS_PATH } from './storeGeneralSettings'
import StoreSocialSettings, { STORE_SOCIAL_SETTINGS_PATH } from './storeSocialSettings'
import StoreTemplateSettings, { STORE_TEMPLATE_SETTINGS_PATH } from './storeTemplateSettings'
import StoreSiteSettings, { STORE_SITE_SETTINGS_PATH } from './storeSiteSettings'
import StorePageSettings, { STORE_PAGE_SETTINGS_PATH } from './storePageSettings'
import ManageStorePage, { STORE_PAGE_SETTINGS_PATH_EDIT } from './storePageSettings/ManageStorePage'
import StoreMenuSettings, { STORE_MENU_SETTINGS_PATH } from './storeMenuSettings'
import ManageStoreMenu, { STORE_MENU_SETTINGS_PATH_EDIT } from './storeMenuSettings/ManageStoreMenu'
import NotFound from 'errorPages/500Page'
import PaymentMethod, { STORE_PAYMENT_METHOD_PATH } from './storePaymentMethodSettings'
import ManagePaymentMethod, { MANAGE_PAYMENT_METHOD_PATH } from './storePaymentMethodSettings/ManagePaymentMethod'
import SupportedLanguages, { STORE_SUPPORTED_LANGUAGES_PATH } from './storeSupportedLanguages'
import ManageMenuItemTranslation, {
  MANAGE_MENU_ITEM_TRANSLATION_PATH,
} from './storeMenuSettings/ManageMenuItemTranslation'
import ManagePageTranslation, { MANAGE_PAGE_TRANSLATION_PATH } from './storePageSettings/ManagePageTranslation'
import SupportedCurrencies, { STORE_SUPPORTED_CURRENCIES_PATH } from './storeSupportedCurrencies'
import { ErrorBoundary } from 'react-error-boundary'
import DynamicEntityRoutes from '../dynamicEntities'
import StoreAdminPanelSettingsForm, { STORE_ADMIN_PANEL_SETTINGS_PATH } from './storeAdminPanelSettings'
import PageSection, { STORE_PAGE_SECTION_SETTINGS_PATH } from '../pageSections/PageSection'
import ManagePageSection, { MANAGE_PAGE_SECTION_PATH } from '../pageSections/ManagePageSection'

export const SETTINGS_PATH = 'store-settings'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${STORE_SHIPPING_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreShippingSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_GENERAL_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreGeneralSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_SOCIAL_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreSocialSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_TEMPLATE_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreTemplateSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_SITE_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreSiteSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_PAGE_TRANSLATION_PATH}/:pageId/:languageId`}>
        <StoreSettingsWrapper>
          <ManagePageTranslation />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_PAGE_SETTINGS_PATH_EDIT}/:id?`}>
        <StoreSettingsWrapper>
          <ManageStorePage />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_PAGE_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <ErrorBoundary fallback={<>An error accoured.</>}>
            <StorePageSettings />
          </ErrorBoundary>
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_MENU_ITEM_TRANSLATION_PATH}/:menuItemId/:languageId`}>
        <StoreSettingsWrapper>
          <ManageMenuItemTranslation />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_MENU_SETTINGS_PATH_EDIT}/:menuId/:menuItemId?`}>
        <StoreSettingsWrapper>
          <ManageStoreMenu />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_MENU_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreMenuSettings />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_PAYMENT_METHOD_PATH}`}>
        <StoreSettingsWrapper>
          <PaymentMethod />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_PAYMENT_METHOD_PATH}/:id?`}>
        <StoreSettingsWrapper>
          <ManagePaymentMethod />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_SUPPORTED_LANGUAGES_PATH}`}>
        <StoreSettingsWrapper>
          <SupportedLanguages />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_SUPPORTED_CURRENCIES_PATH}`}>
        <StoreSettingsWrapper>
          <SupportedCurrencies />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_ADMIN_PANEL_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <StoreAdminPanelSettingsForm />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${STORE_PAGE_SECTION_SETTINGS_PATH}`}>
        <StoreSettingsWrapper>
          <PageSection />
        </StoreSettingsWrapper>
      </Route>
      <Route path={`${match.path}/${MANAGE_PAGE_SECTION_PATH}/:id?`}>
        <StoreSettingsWrapper>
          <ManagePageSection />
        </StoreSettingsWrapper>
      </Route>
      <Route path={match.path}>
        <StoreSettingsWrapper>
          <DynamicEntityRoutes />
        </StoreSettingsWrapper>
      </Route>
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
