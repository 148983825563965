import React from 'react'
import { useField, useFormikContext } from 'formik'
import classnames from 'classnames'

import Error from './FormItemError'
import Input, { InputProps } from '../components/Input'
import FieldProps from './FieldProps'
import Label from '../components/Label'

import './FormikStyles.less'
import { useTranslation } from 'react-i18next'

export type FormikInputProps = InputProps & FieldProps & { hint?: string; showOptionalLabel?: boolean }

const FormikDefaultInputField: React.FC<FormikInputProps> = (props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props.name)
  const { setFieldValue } = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error> </Error>
  const id = props.id || props.name

  const onChange = (e: any) => {
    setFieldValue(props.name, e.target.value)
  }

  const hint = props.hint ? <span className="formik-field__span"> {props.hint}</span> : null

  const inputErrorClassname = classnames({ border_error: showError })

  return (
    <>
      <div className={'form-group'}>
        <div>
          <Label htmlFor={id} className="formik-field__input-label no-select hand-on-hover">
            {props.label}
            {props.showOptionalLabel ? `(${t('optional')})` : ''}
            {hint}
          </Label>
          <Input
            {...field}
            {...props}
            placeholder={props.placeholder || props.label}
            className={`${inputErrorClassname} ${props.className}`}
            id={id}
            onChange={onChange}
          />
        </div>
        {showError && <div className="mt-4">{error}&nbsp;</div>}
      </div>
    </>
  )
}

export default FormikDefaultInputField
