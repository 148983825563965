import React, { useState, useEffect } from 'react'
import { Row, Col, Table, Tag as AntdTag } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { queryRequest } from 'common/RequestUtils'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'

import {
  PagedStorePages,
  getStorePages,
  deleteStorePage,
  PageStatusMap,
} from 'core/application/dashboard/storeSettings/storePageSettings'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'

import { STORE_PAGE_SETTINGS_PATH_EDIT } from './ManageStorePage'
import { SETTINGS_PATH } from '../Routes'
import { getSupportedLanguages, LanguageDto } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import { formatDateTime } from '../../../common/dateFormatters'
import Tag from '../../../common/components/dataEntry/components/Tag'
import { MANAGE_PAGE_TRANSLATION_PATH } from './ManagePageTranslation'
import TableEditButton from '../../../common/components/tables/TableEditButton'
import TableDeleteButton from '../../../common/components/tables/TableDeleteButton'
import { PageStatus } from 'core'

export const STORE_PAGE_SETTINGS_PATH = 'store-page-settings'

const StorePageSettings: React.FC = () => {
  const { t: translate } = useTranslation()
  const [filter, setFilter] = useState<OrderedPageFilter>({
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: true,
  })
  const [loadingStorePages, setLoadingStorePages] = useState<boolean>(false)
  const [storePages, setStorePages] = useState<PagedStorePages>({
    items: [],
    pageCount: 1,
    pageNumber: 1,
    pageSize: 10,
    totalItemCount: 0,
  })
  const history = useHistory()
  const [languages, setLanguages] = useState<LanguageDto[]>([])

  useEffect(() => {
    ;(async () => {
      await refreshLanguages()
      await refreshTable()
    })()
  }, [filter])

  const refreshTable = async () => {
    setLoadingStorePages(true)
    const result = await queryRequest(() => getStorePages(filter))
    setStorePages(result)
    setLoadingStorePages(false)
  }

  const refreshLanguages = async () => {
    const result = await queryRequest(() => getSupportedLanguages())
    setLanguages(result)
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.store_settings.edit_page_form.title'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.title}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'stauts',
        render: (_: string, record: any) => (
          <AntdTag color={PageStatusMap.get(record.status)?.color}>{PageStatusMap.get(record.status)?.name}</AntdTag>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      languages.length > 0
        ? {
            title: translate('dashboard.translations'),
            dataIndex: 'translations',
            key: 'translations',
            align: 'center',
            responsive: ['md'],
            render: (text: string, record: any) => {
              return (
                <span>
                  <div>
                    <Row className="translation-row">
                      {languages.map((val: LanguageDto, index: number) => (
                        <Tag
                          className="translation-tag"
                          onClick={() => {
                            history.push(`/${SETTINGS_PATH}/${MANAGE_PAGE_TRANSLATION_PATH}/${record.id}/${val.id}`)
                          }}
                        >
                          {val.name}
                        </Tag>
                      ))}
                    </Row>
                  </div>
                </span>
              )
            },
          }
        : {},
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`/${SETTINGS_PATH}/${STORE_PAGE_SETTINGS_PATH_EDIT}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteStorePage(record.id)}
                onDeleted={() => refreshTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  return (
    <>
      <Card
        bordered={false}
        title={
          <Row>
            <Col xs={14} md={16}>
              <h4>{translate('dashboard.store_settings.edit_page_settings')}</h4>
            </Col>
            <Col xs={10} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${SETTINGS_PATH}/${STORE_PAGE_SETTINGS_PATH_EDIT}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          rowKey={(record) => record.id}
          dataSource={storePages.items}
          onChange={onTableChange}
          loading={loadingStorePages}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: storePages.totalItemCount,
            pageSize: storePages.pageSize,
            current: storePages.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default StorePageSettings
