import React, { useState } from 'react'
import ModalPopup from 'common/components/feedback/Modal'
import { Col, Menu, Modal, Row, Tag } from 'antd'
import { OrderStatusToUpdate } from 'core/application/dashboard/home/orders/updateOrderStatus'
import { OrderItemStatus, OrderStatus } from 'core/domain/order/orderStatus'
import { Formik, Form as FormikForm } from 'formik'

import TextArea from 'common/components/dataEntry/formik/FormikTextAreaField'
import * as Yup from 'yup'
import Button from '../../common/components/general/Button'
import { useTranslation } from 'react-i18next'

const { confirm } = Modal

export const orderTagColorMap = new Map<OrderStatus, string>([
  [OrderStatus.Aborted, '#e94437'],
  [OrderStatus.Completed, '#23af47'],
  [OrderStatus.Delivering, '#02abfe'],
  [OrderStatus.Processing, '#fbbc06'],
  [OrderStatus.New, '#25378b'],
])

export const getOrderTitleMap = (translate: (key: string) => string): Map<OrderStatus, string> => {
  return new Map<OrderStatus, string>([
    [OrderStatus.Aborted, translate('dashboard.orders.abort')],
    [OrderStatus.Completed, translate('dashboard.orders.completed')],
    [OrderStatus.Delivering, translate('dashboard.orders.delivering')],
    [OrderStatus.Processing, translate('dashboard.orders.processing')],
    [OrderStatus.New, translate('dashboard.orders.new')],
  ])
}
export const orderItemTagColorMap = new Map<OrderItemStatus, string>([
  [OrderItemStatus.Active, '#23af47'],
  [OrderItemStatus.Archived, '#fbbc06'],
  [OrderItemStatus.Draft, '#e94437'],
])
export const getOrderItemTitleMap = (translate: (key: string) => string): Map<OrderItemStatus, string> => {
  return new Map<OrderItemStatus, string>([
    [OrderItemStatus.Active, translate('dashboard.products.statuses.active')],
    [OrderItemStatus.Archived, translate('dashboard.products.statuses.archived')],
    [OrderItemStatus.Draft, translate('dashboard.products.statuses.draft')],
  ])
}

type OrderStatusActionsProps = {
  id: string
  updateOrderStatus: (order: OrderStatusToUpdate) => void
  hasOnlyDigitalProducts: boolean
}

const OrderStatusActions = ({ id, updateOrderStatus, hasOnlyDigitalProducts }: OrderStatusActionsProps) => {
  const { t: translate } = useTranslation()

  const [showShippingPopup, setShowShippingPopup] = useState(false)
  const [formSubmitting, setFormSubmitting] = useState(false)
  const [shippingInitialValues, setShippingInitialValues] = useState<OrderStatusToUpdate>({
    id: id,
    status: OrderStatus.Delivering,
    shippingInstructions: '',
  })

  return (
    <>
      <Menu>
        <Menu.Item onClick={() => updateOrderStatus({ id: id, status: OrderStatus.Processing })}>
          <Tag color={orderTagColorMap.get(OrderStatus.Processing)}>
            {getOrderTitleMap(translate).get(OrderStatus.Processing)}
          </Tag>
        </Menu.Item>
        {!hasOnlyDigitalProducts && (
          <Menu.Item onClick={() => setShowShippingPopup(true)}>
            <Tag color={orderTagColorMap.get(OrderStatus.Delivering)}>
              {getOrderTitleMap(translate).get(OrderStatus.Delivering)}
            </Tag>
          </Menu.Item>
        )}
        <Menu.Item onClick={() => updateOrderStatus({ id: id, status: OrderStatus.Completed })}>
          <Tag color={orderTagColorMap.get(OrderStatus.Completed)}>
            {getOrderTitleMap(translate).get(OrderStatus.Completed)}
          </Tag>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            confirm({
              title: translate('dashboard.orders.abort_title'),
              okText: translate('general.ok_text'),
              cancelText: translate('general.no_text'),
              onOk: async () => updateOrderStatus({ id: id, status: OrderStatus.Aborted }),
            })
          }}
        >
          <Tag color={orderTagColorMap.get(OrderStatus.Aborted)}>
            {getOrderTitleMap(translate).get(OrderStatus.Aborted)}
          </Tag>
        </Menu.Item>
      </Menu>
      {showShippingPopup && (
        <ModalPopup
          onCancel={() => {
            setShowShippingPopup(false)
          }}
          visible={showShippingPopup}
          title={''}
          footer={
            <>
              <Button
                type="primary"
                htmlType="button"
                disabled={formSubmitting}
                className="mr-16"
                onClick={() => {
                  updateOrderStatus({
                    id: shippingInitialValues.id,
                    status: shippingInitialValues.status,
                    shippingInstructions: shippingInitialValues.shippingInstructions,
                  })
                  setFormSubmitting(true)
                  setShowShippingPopup(false)
                }}
                loading={formSubmitting}
              >
                {translate('general.submit')}
              </Button>

              <Button onClick={() => setShowShippingPopup(false)}>{translate('dashboard.cancel')}</Button>
            </>
          }
        >
          <Formik
            enableReinitialize={true}
            validationSchema={Yup.object({})}
            initialValues={shippingInitialValues}
            onSubmit={(values) => {}}
            validate={(values: any) => {
              setShippingInitialValues({ ...values })
            }}
          >
            <FormikForm>
              <Row>
                <Col xs={24}>
                  <TextArea
                    label={translate('general.shippingInstructions')}
                    name="shippingInstructions"
                    placeholder={translate('general.shippingInstructions')}
                    rows={6}
                  />
                </Col>
              </Row>
            </FormikForm>
          </Formik>
        </ModalPopup>
      )}
    </>
  )
}

export default OrderStatusActions
