import { adminHttpClient } from '../../commons/adminHttpClient'

export const getSupportedCurrencies = (): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/StoreSupportedCurrencies`)
}

export const getSupportedCurrenciesForSave = (): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/StoreSupportedCurrencies/ForSave`)
}

export const saveSupportedCurrencies = (data: any): Promise<any> => {
  return adminHttpClient.put(`/AdminDashboard/StoreSupportedCurrencies`, data)
}

export interface CurrencyDto {
  id: string
  name: string
}

export interface SupportedCurrencyDto {
  currencyId: string
  rate: number | null
  enabled: boolean
}
