import { adminHttpClient } from '../../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from '../../commons/orderedPageFilter'
import PagedList from '../../commons/pagedList'

export interface PagedBrands extends PagedList<Brand> {}

export interface Brand {
  id: string
  name: string
  price: number
  createdAt: Moment
  priority: number
}

const getBrands = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/brands', filter)
}

export default getBrands
