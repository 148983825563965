import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'

import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'

import {
  getStoreTemplateSettings,
  StoreTemplateSettings,
  updateStoreTemplateSettings,
} from 'core/application/dashboard/storeSettings/storeTemplateSettings'

import '../StoreSettings.less'
import FormSaveCancelButtons from '../../../common/components/forms/SaveCancelFormButtons'
import Input from '../../../common/components/dataEntry/formik/FormikInputField'
import TextArea from '../../../common/components/dataEntry/formik/FormikTextAreaField'

export const STORE_TEMPLATE_SETTINGS_PATH = 'store-template-settings'

const formValidator = (translate: any) =>
  Yup.object({
    primaryColor: Yup.string().required(),
  })

const StoreTemplateSettingsForm: React.FC = () => {
  const { t: translate } = useTranslation()
  const [item, setItem] = useState<StoreTemplateSettings | null>(null)
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getStoreTemplateSettings())
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: StoreTemplateSettings) => {
    setLoadingSave(true)

    const result = await commandRequest(() => updateStoreTemplateSettings(values))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved_plural').replace('[]', translate('dashboard.store_settings.settings')),
        type: 'success',
      })
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setLoadingSave(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card bordered={false} title={translate('dashboard.store_settings.edit_template_settings')}>
                <FormikForm>
                  <Row className="mb-16">
                    <Col xs={24}>
                      <Uploader
                        label={`${translate('dashboard.store_settings.upload_logo')}(140x40)`}
                        initialValues={item && item.logo ? [item.logo] : []}
                        name="logo"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="logo"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-16">
                    <Col xs={24}>
                      <Uploader
                        label={`${translate('general.favicon')}(32x32)`}
                        initialValues={item && item.favicon ? [item.favicon] : []}
                        name="favicon"
                        resizeToWidth={270}
                        resizeToHeight={180}
                        fieldName="favicon"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-16">
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.store_settings.primary_color')}
                        name="primaryColor"
                        placeholder={translate('dashboard.store_settings.primary_color')}
                      />
                    </Col>
                  </Row>

                  <Row className="mb-16">
                    <Col xs={24}>
                      <TextArea
                        label={translate('general.css')}
                        name="css"
                        placeholder={translate('general.css')}
                        rows={6}
                      />
                    </Col>
                  </Row>

                  <FormSaveCancelButtons
                    disabledSave={!formValidator(translate).isValidSync(formik.values)}
                    onButtonClick={() => onSubmit(formik.values)}
                    loading={loadingSave}
                    showCancelButton={false}
                  ></FormSaveCancelButtons>
                </FormikForm>
              </Card>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default StoreTemplateSettingsForm
