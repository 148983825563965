import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'

import {
  getProductTranslation,
  ProductTranslation,
  saveProductTranslation,
  ProductTranslationToSave,
  deleteProductTranslation,
} from 'core/application/dashboard/products/translations'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'
import ProductAttributesTranslationsTable from './ProductAttributesTranslationsTable'

export const MANAGE_PRODUCT_TRANSLATION_PATH = 'manage-product-translation'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().nullable().trim().required(translate('general.required')),
  })

const ManageProductTranslation: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { productId, languageId } = useParams<any>()
  const [item, setItem] = useState<ProductTranslation | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getProductTranslation(productId, languageId))
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: ProductTranslation) => {
    let productTranslationToSave: ProductTranslationToSave = {
      productId: values.productId,
      languageId: values.languageId,
      name: values.name ?? '',
      type: values.type,
      vendor: values.vendor,
      description: values.description,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
      seoKeywords: values.seoKeywords,
      seoUrl: values.seoUrl,
      tags: values.tags,
      attributes: values.attributes,
      relatedProductText: values.relatedProductText,
    }

    const result = await commandRequest(() => saveProductTranslation(productTranslationToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.translation')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="pb-16">
              <FormikForm>
                <Card
                  bordered={true}
                  title={
                    <Row>
                      <Col xs={14} md={16}>
                        <h4>{`${translate('dashboard.translation')} ${languageId.toUpperCase()}`}</h4>
                      </Col>
                      <Col xs={10} md={8}>
                        <div className={'text-right'}>
                          <TableDeleteButton
                            onDelete={() => deleteProductTranslation(item.productId, item.languageId)}
                            onDeleted={() => history.goBack()}
                          />
                        </div>
                      </Col>
                    </Row>
                  }
                >
                  <Row>
                    <Col span={24}>
                      <Input
                        label={`${translate('dashboard.products.name')} (${translate(
                          'dashboard.original_translation',
                        )}: ${item.defaultName})`}
                        name="name"
                        placeholder={translate('dashboard.products.name')}
                        onBlur={(e: any) => {
                          if (item.seoTitle) return
                          formik.setFieldValue('seoTitle', e.currentTarget.value)
                        }}
                      />
                    </Col>
                  </Row>
                  {(item.parentProductId || item.hasRelatedProducts) && item.defaultRelatedProductText && (
                    <Row>
                      <Col span={24}>
                        <Input
                          label={`${translate('dashboard.products.related_product_text')} (${translate(
                            'dashboard.original_translation',
                          )}: ${item.defaultRelatedProductText})`}
                          name="relatedProductText"
                          placeholder={translate('dashboard.products.related_product_text')}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'description'}
                        placeholder={translate('dashboard.description')}
                        value={item.description}
                      />
                    </Col>
                  </Row>
                </Card>
                {item.attributes && item.attributes.length > 0 && (
                  <Card bordered={true} title={`${translate('dashboard.products.attributes')}`}>
                    <Row className="mb-16">
                      <Col xs={24}>
                        <ProductAttributesTranslationsTable
                          name="attributes"
                          translate={translate}
                          productAttributes={item.attributes}
                        />
                      </Col>
                    </Row>
                  </Card>
                )}
                <Card bordered={true} title={`${translate('dashboard.products.seo')}`}>
                  <Row>
                    <Col xs={24}>
                      <Input
                        label={`${translate('dashboard.products.seo_title')} (${translate(
                          'dashboard.original_translation',
                        )}: ${item.defaultSeoTitle})`}
                        name="seoTitle"
                        placeholder={translate('dashboard.products.seo_title')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <FormikTextAreaField
                        label={translate('dashboard.products.seo_description')}
                        name="seoDescription"
                        placeholder={translate('dashboard.products.seo_description')}
                        rows={6}
                      />
                    </Col>
                  </Row>
                </Card>
                <Card bordered={true} title={`${translate('dashboard.products.organization')}`}>
                  <Row>
                    <Col xs={24}>
                      <Input
                        label={`${translate('dashboard.products.vendor')} (${translate(
                          'dashboard.original_translation',
                        )}: ${item.defaultVendor ? item.defaultVendor : 'N/A'})`}
                        name="vendor"
                        placeholder={translate('dashboard.products.vendor')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <Input
                        label={`${translate('dashboard.products.type')} (${translate(
                          'dashboard.original_translation',
                        )}: ${item.defaultType ? item.defaultType : 'N/A'})`}
                        name="type"
                        placeholder={translate('dashboard.products.type')}
                      />
                    </Col>
                  </Row>
                </Card>
              </FormikForm>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageProductTranslation
