import React, { useState, useEffect, useCallback, FC } from 'react'
import { Card, Table } from 'antd'
import { useTranslation } from 'react-i18next'

import { queryRequest } from 'common/RequestUtils'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getDiscountCodeOrders, {
  PagedDiscountCodeOrders,
} from 'core/application/dashboard/home/statistics/discountCodeOrders'

import { formatMoney } from 'common/moneyFormatter'

export const DISCOUNT_CODE_PATH = 'discount-code'
const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'code',
  orderDescending: true,
}

const DiscountCodeOrders: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [discountCodes, setDiscountCodes] = useState<PagedDiscountCodeOrders>(pagedColumnDefaultValues)

  const updateDiscountCodesTable = useCallback(async () => {
    setLoading(true)
    const result = await queryRequest(() => getDiscountCodeOrders(searchFilter))
    setDiscountCodes(result)
    setLoading(false)
  }, [searchFilter])

  useEffect(() => {
    updateDiscountCodesTable()
  }, [updateDiscountCodesTable])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.discount_codes.code'),
        dataIndex: 'code',
        width: '40%',
        key: 'code',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.code}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.discount_codes.discount'),
        dataIndex: 'discount',
        key: 'discount',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.discount}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.orders.title'),
        dataIndex: 'orders',
        key: 'orders',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.orders}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.price'),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatMoney(record.totalPrice, record.currency)}</span>
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'code',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setSearchFilter({ ...searchFilter, createdDateStart: dateStrings[0], createdDateEnd: dateStrings[1] })
  }

  return (
    <>
      <DateRangePicker
        onChange={onDateChange}
        placeholder={[translate('general.start_date'), translate('general.end_date')]}
        className="mb-16"
      />
      <Table
        dataSource={discountCodes.items}
        onChange={onTableChange}
        loading={loading}
        columns={getColumns()}
        scroll={{ x: true }}
        pagination={{
          total: discountCodes.totalItemCount,
          pageSize: discountCodes.pageSize,
          current: discountCodes.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </>
  )
}

export const DiscountCodeWrapper: FC = () => {
  const { t } = useTranslation()
  return (
    <Card bordered={true} title={t('dashboard.discount_codes.title')}>
      <DiscountCodeOrders />
    </Card>
  )
}
export default DiscountCodeOrders
