import { adminHttpClient } from '../commons/adminHttpClient'
import { NamedItem } from '../commons/namedItem'
import AppointmentStatus from '../../domain/appointments/AppointmentStatus'
import { AppointmentPaymentMethodType } from '../../domain/appointments/AppointmentPaymentMethodType'

export interface AppointmentForSave {
  id?: string
  date: Date
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  note: string
  birthDate?: Date
  teamMemberId?: string
  serviceId?: string
  status: string
  currencyId: number
  completed: boolean
  price: number
  paymentType: AppointmentPaymentMethodType
  availableTeamMembers: NamedItem[]
  availableServices: NamedItem[]
  availableStatuses: NamedItem[]
  availableCurrencies: NamedItem[]
  availablePaymentMethods: NamedItem[]
}

export const getAppointmentsForSave = (id: string | undefined): Promise<AppointmentForSave> => {
  if (id) {
    return adminHttpClient.get(`/Appointments/ForSave/${id}`)
  }
  return adminHttpClient.get(`/Appointments/ForSave`)
}

export interface SaveAppointmentCommand {
  id?: string
  date: Date
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  note: string
  birthDate?: Date
  teamMemberId?: string
  serviceId?: string
  currencyId: number
  price: number
  completed: boolean
  paymentType: AppointmentPaymentMethodType
  status: AppointmentStatus
}

const saveAppointments = async (request: SaveAppointmentCommand) => {
  return adminHttpClient.post(`/Appointments`, request)
}

export default saveAppointments
