import React from 'react'
import { Col, PageHeader, Row, Select as AntSelect, Skeleton, Card, notification } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import Button from 'common/components/general/Button'
import useQueryRequest from 'common/hooks/useQueryRequest'
import useCommandRequest from 'common/hooks/useCommandRequest'
import {
  getStoreById,
  saveStoreCommand,
  StoreForSave,
  StoreInitialStoreValues,
  StoreStatusMap,
  StoreTypeMap,
} from 'core'

const formValidator = (translate: any) =>
  Yup.object({
    type: Yup.number().required(translate('general.required')),
    status: Yup.number().required(translate('general.required')),
  })
const { Option } = AntSelect

export default function ManageStore() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [store, isLoading] = useQueryRequest<StoreForSave>(StoreInitialStoreValues, () => getStoreById(id))
  const [mutation, isSubmitting] = useCommandRequest()

  if (isLoading) return <Skeleton loading />

  const handleSubmit = async (request: StoreForSave) => {
    const result = await mutation(() => saveStoreCommand(request))
    if (result.completed) {
      notification.success({ message: `Store ${id ? 'edited' : 'saved'} successfully!` })
      history.goBack()
    }
  }
  return (
    <Card
      title={
        <PageHeader
          className="p-0"
          title={id ? `Edit ${store?.name}` : `Create ${store?.name}`}
          onBack={() => history.goBack()}
        />
      }
    >
      <Row gutter={20}>
        <Col xs={24} md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={store as StoreForSave}
            validationSchema={() => formValidator(t)}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <Input className="mb-0" label="Custom domain" name="customDomain" placeholder="Custom domain" />
                <Input className="mb-0" label="Sub domain" name="subDomain" placeholder="Sub domain" />
                <Select label="Type" name="type" defaultValue={store?.type as any} placeholder="Type" allowClear>
                  {StoreTypeMap.map(({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                <Select
                  label="Status"
                  name="status"
                  defaultValue={store?.status as any}
                  placeholder="Status"
                  allowClear
                >
                  {StoreStatusMap.map(({ value, label }) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
                <Select label="Language" name="languageId" defaultValue={store?.languageId} placeholder="Language">
                  {store?.availableLanguages.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
                <Select label="Currency" name="currencyId" defaultValue={store?.currencyId} placeholder="Currency">
                  {store?.availableCurrencies.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
                <Select label="Themes" name="themeId" defaultValue={store?.themeId as string} placeholder="Themes">
                  {store?.availableThemes.map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
                <Button
                  disabled={!formValidator(t).isValidSync(values)}
                  htmlType="submit"
                  type="primary"
                  loading={isSubmitting}
                >
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </Card>
  )
}
