import React from 'react'
import { Table, Row } from 'antd'

import { formatDateTime, parseZoneFormat } from 'common/dateFormatters'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import TableEditButton from 'common/components/tables/TableEditButton'

import { MenuItemInfo } from 'core/application/dashboard/storeSettings/storeMenuSettings'
import { STORE_MENU_SETTINGS_PATH_EDIT } from './ManageStoreMenu'
import { SETTINGS_PATH } from '../Routes'
import { LanguageDto } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import Tag from 'common/components/dataEntry/components/Tag'
import { MANAGE_MENU_ITEM_TRANSLATION_PATH } from './ManageMenuItemTranslation'

interface PagesTableProps {
  history: any
  menuId: string
  menuItems: MenuItemInfo[]
  loadingMenuItems: boolean
  translate: (key: string) => string
  onDeleteMenuItem: (id: string) => void
  updateMenuItemsTable: () => void
  languages: LanguageDto[]
}

const StoreMenusTable: React.FC<PagesTableProps> = ({
  history,
  menuId,
  menuItems,
  loadingMenuItems,
  translate,
  onDeleteMenuItem,
  updateMenuItemsTable,
  languages,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.store_settings.edit_page_form.title'),
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: translate('dashboard.store_settings.edit_menu_form.priority'),
        dataIndex: 'priority',
        key: 'priority',
        sorter: (a: any, b: any) => a.priority - b.priority,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      languages.length > 0
        ? {
            title: translate('dashboard.translations'),
            dataIndex: 'translations',
            key: 'translations',
            align: 'center',
            responsive: ['md'],
            render: (text: string, record: any) => {
              return (
                <span>
                  <div>
                    <Row className="translation-row">
                      {languages.map((val: LanguageDto, index: number) => (
                        <Tag
                          className="translation-tag"
                          onClick={() => {
                            history.push(
                              `/${SETTINGS_PATH}/${MANAGE_MENU_ITEM_TRANSLATION_PATH}/${record.id}/${val.id}`,
                            )
                          }}
                        >
                          {val.name}
                        </Tag>
                      ))}
                    </Row>
                  </div>
                </span>
              )
            },
          }
        : {},
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`/${SETTINGS_PATH}/${STORE_MENU_SETTINGS_PATH_EDIT}/${menuId}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => onDeleteMenuItem(record.id)}
                onDeleted={() => updateMenuItemsTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={menuItems}
      loading={loadingMenuItems}
      columns={getColumns()}
      scroll={{ x: 'max-content' }}
    />
  )
}

export default StoreMenusTable
