import { adminHttpClient } from '../../commons/adminHttpClient'

export interface Brand {
  id?: string
  name: string
  friendlyUrl: string
  photoId: string
  photoThumbnailId: string
  description: string
  priority: number
  seoTitle: string
  seoImage: string
  seoDescription: string
}

const getBrandForSave = (id: string | undefined): Promise<Brand> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/brands/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/brands/forsave`)
}

export const saveBrand = (category: Brand): Promise<any> => {
  return adminHttpClient.post('/admindashboard/brands', category)
}

export default getBrandForSave
