import React, { ReactNode } from 'react'
import { TreeSelect as AntTreeSelect } from 'antd'
import { TreeSelectProps as AntTreeSelectProps } from 'antd/lib/tree-select'

import './DataEntry.less'

interface LabeledValue {
  key: string
  label: React.ReactNode
}

declare type T = string | string[] | number | number[] | LabeledValue | LabeledValue[]

export type TreeSelectProps = AntTreeSelectProps<string | string[] | number[]>

const Input: React.FC<TreeSelectProps> = (props) => {
  return (
    <>
      <AntTreeSelect {...props} className={`round_border ${props.className}`}>
        {props.children}
      </AntTreeSelect>
    </>
  )
}

export default Input
