import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from 'application/commons/namedItem'
import { DeliveryFeeType } from 'domain/store/deliveryFee'

export type StoreSocialSettings = {
  facebook: string
  youtube: string
  instagram: string
  twitter: string
  pinterest: string
}

export const getStoreSocialSettings = (): Promise<StoreSocialSettings> => {
  return adminHttpClient.get('/admindashboard/storesocialsettings')
}
export const updateStoreSocialSettings = (data: StoreSocialSettings) => {
  return adminHttpClient.put('/admindashboard/storesocialsettings', data)
}
