import { adminHttpClient } from '../../../commons/adminHttpClient'
import moment, { Moment } from 'moment'
import OrderedPageFilter from '../../../commons/orderedPageFilter'
import PagedList from '../../../commons/pagedList'
import { NamedItem } from '../../../commons/namedItem'

export interface PagedOrders extends PagedList<Order> {}

export interface Order {
  id: string
  clientName: string
  city: string
  createdAt: string
  status: NamedItem
  totalPrice: number
  currency: string
  hasOnlyDigitalProducts: boolean
}

const getOrders = (filter: OrderedPageFilter): Promise<PagedOrders> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/orders', filter)
}

export default getOrders
