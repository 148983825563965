import React, { memo } from 'react'
import { Col, Row } from 'antd'
import DynamicInput, { InputDescriptor } from './DynamicInput'

type Props = {
  inputDescriptors: InputDescriptor[]
}

const DynamicInputsList = memo(({ inputDescriptors }: Props) => {
  if (inputDescriptors.length === 0) return null

  let imageInputCount = 0

  return (
    <Row gutter={[16, 16]} className="bg-gray-3 p-8">
      {inputDescriptors.map((inputDescriptor, i) => {
        let inputOrder: number | undefined = undefined
        if (inputDescriptor.type === 'image') {
          imageInputCount += 1
          inputOrder = inputDescriptors.length - imageInputCount
        }

        return (
          <Col key={inputDescriptor.name} order={inputOrder} span={24}>
            <DynamicInput {...inputDescriptor} />
          </Col>
        )
      })}
    </Row>
  )
})

export default DynamicInputsList
