import React, { ReactNode, useState } from 'react'
import { Layout as AntLayout, Row, Col, Drawer, Button } from 'antd'
import HeaderUserSection from '../common/components/layout/HeaderUserSection'
import './Dashboard.less'
import { AlignRightOutlined } from '@ant-design/icons'
import { DesktopNavigation, MobileNavigation } from './Navigation'

import useLoginUser from '../common/hooks/useLoginUser'

interface AdminDashboardProps {
  children?: ReactNode
}

const { Header } = AntLayout

const StoreDashboardWrapper: React.FC<AdminDashboardProps> = ({ children }) => {
  const [drawerNav, setDrawerNav] = useState(false)
  const [user] = useLoginUser()

  return (
    <>
      <AntLayout>
        <Drawer
          placement="left"
          closable={false}
          onClose={() => setDrawerNav(false)}
          visible={drawerNav}
          bodyStyle={{ padding: '0' }}
        >
          <MobileNavigation />
        </Drawer>

        <AntLayout id="body-content">
          <Header className="header" style={{ borderBottom: '1px solid #ddd' }}>
            <div className="container">
              <Row justify="space-between" align="middle">
                <Col xs={12}>
                  <Row>
                    <Col xs={0} md={24}>
                      <div className="">
                        <a className={'text-bold p-1'} target={'_blank'} href={user.siteUrl}>
                          {user.storeName}
                        </a>
                      </div>
                    </Col>
                    <Col lg={0}>
                      <Button
                        type="primary"
                        shape="circle"
                        className="collapse-button"
                        icon={<AlignRightOutlined />}
                        onClick={() => setDrawerNav(true)}
                      ></Button>
                    </Col>
                  </Row>
                </Col>

                <Col span={12}>
                  <Col md={2} xs={6} style={{ float: 'right' }}>
                    <div className={'text-right'}>
                      <HeaderUserSection user={user} />
                    </div>
                  </Col>
                </Col>
              </Row>
            </div>
          </Header>

          <Header
            className="hide-on-mobile header"
            style={{ padding: '0', boxShadow: 'rgb(192 192 199) 0 9px 20px -10px' }}
          >
            <div className="container">
              <DesktopNavigation />
            </div>
          </Header>

          <div className="dashboard_body p-16">
            <div className="container">{children}</div>
          </div>
        </AntLayout>
      </AntLayout>
    </>
  )
}

export default StoreDashboardWrapper
