import React from 'react'
import { Tag as AntTag } from 'antd'
import './RadioStyles.less'

export interface TagProps {
  closable?: boolean
  onClose?: (e: any) => void
  onClick?: (e: any) => void
  className?: string
  id?: string
  visible?: boolean
}

const Tag: React.FC<TagProps> = (props) => {
  return (
    <AntTag {...props} className={props.className}>
      {props.children}
    </AntTag>
  )
}

export default Tag
