import React from 'react'

import { DatePicker as AntDatepicker } from 'antd'
import { DatePickerProps as AntDatePickerProps } from 'antd/lib/date-picker/index'

import './DataEntryStyles.less'

export type DatePickerProps = AntDatePickerProps

const DatePicker: React.FC<DatePickerProps> = (props) => {
  return <AntDatepicker {...props} className={`input_height round_border ${props.className}`} />
}

export default DatePicker
