import React from 'react'

import { Col, notification, PageHeader, Row } from 'antd'
import { Formik } from 'formik'

import PasswordInput from '../common/components/dataEntry/formik/FormikPasswordField'
import useCommandRequest from 'common/hooks/useCommandRequest'
import useUrlQueryParam from 'common/hooks/useUrlQueryParam'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import SubmitButton from '../common/components/general/Button'
import { resetPassword } from 'core/application/account/resetPassword'
export const RESET_PASSWORD_PATH = 'reset-password'

const formValidator = (t: any) =>
  Yup.object({
    password: Yup.string().required(t('general.required')),
    confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('change_password.password_does_not_match')),
  })

const ResetPassword = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const code = useUrlQueryParam('code') as any
  const [sendCommandRequest, isSaving] = useCommandRequest<any>()

  const onSubmit = async (values: any) => {
    const { completed } = await sendCommandRequest(() => resetPassword(values.password, code))
    if (completed) {
      notification.success({ message: 'We have sent a confirmation to your email address.' })
      history.push('/login')
    }
  }

  return (
    <Row>
      <Col md={10} sm={24} lg={12}>
        <Row className="mt-4" justify="center">
          <Col md={12} sm={24}>
            <div>
              <img
                src="https://hermex.s3.eu-central-1.amazonaws.com/assets/hermex-logo-admin.svg"
                alt="Logo"
                width={150}
              />
            </div>
            <br />
            <PageHeader
              className="px-0"
              onBack={() => history.push('/login')}
              title={translate('general.reset_password')}
            />
            <Formik
              enableReinitialize
              validationSchema={() => formValidator(translate)}
              initialValues={{ password: '', confirmPassword: '' }}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Row className={'w-100'}>
                    <Col md={24}>
                      <PasswordInput
                        label={translate('change_password.new_password')}
                        name="password"
                        className="input-with-suffix__border-botton-only"
                      />
                    </Col>
                  </Row>
                  <Row className={'w-100'}>
                    <Col md={24}>
                      <PasswordInput
                        label={translate('change_password.confirm_new_password')}
                        name="confirmPassword"
                        className="input-with-suffix__border-botton-only"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <SubmitButton htmlType="submit" loading={isSaving}>
                      {translate('general.reset_password')}
                    </SubmitButton>
                  </Row>
                </form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>
      <Col lg={12} md={0}>
        <div className="background_primary"></div>
      </Col>
    </Row>
  )
}

export default ResetPassword
