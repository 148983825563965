import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Col, Row } from 'antd'

import { useTranslation } from 'react-i18next'

import Modal from 'common/components/feedback/Modal'
import Select from 'common/components/dataEntry/formik/FormikSelectField'

import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { SendEmailToSubscriptionsRequest } from 'core/application/emailSubscriptions/emails'
import { EmailSubscription } from 'core/application/emailSubscriptions/emailSubscriptionsApi'

const SendEmailToSubscriptionsForm: React.FC<SendEmailToSubscriptionsFormProps> = ({
  emailId,
  subscriptions,
  visible,
  onCancel,
  onSubmit,
  submitting,
}) => {
  let { t: translate } = useTranslation()
  const [formIsValid, setFormIsValid] = useState<boolean>(true)

  const [sendToSpecificSubscriptions, setSendToSpecificSubscriptions] = useState<boolean>(false)

  const [sendEmailToSubscriptionsRequest, setSendEmailToSubscriptionsRequest] =
    useState<SendEmailToSubscriptionsRequest>({} as SendEmailToSubscriptionsRequest)

  const formFooter = (
    <FormSaveCancelButtons
      disabledSave={!formIsValid}
      onButtonClick={() => onSubmit(sendEmailToSubscriptionsRequest)}
      loading={submitting}
      saveText={translate('dashboard.email_subscriptions.emails.send')}
    />
  )

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title={translate('dashboard.email_subscriptions.emails.send_email_to_subscriptions')}
      footer={formFooter}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={formValidator(translate, sendToSpecificSubscriptions)}
        initialValues={
          {
            emailId: emailId,
            sendToSpecificSubscriptions: false,
            specificSubscriptionIds: [],
          } as SendEmailToSubscriptionsRequest
        }
        onSubmit={(values) => {}}
        validate={(values: SendEmailToSubscriptionsRequest) => {
          setFormIsValid(formValidator(translate, sendToSpecificSubscriptions).isValidSync(values))

          setSendEmailToSubscriptionsRequest({
            emailId: emailId,
            sendToSpecificSubscriptions: sendToSpecificSubscriptions,
            specificSubscriptionIds: values.specificSubscriptionIds,
          } as SendEmailToSubscriptionsRequest)

          return {}
        }}
      >
        {(formik) => (
          <FormikForm>
            <Row>
              <Col xs={24}>
                <Checkbox
                  label={translate('dashboard.email_subscriptions.emails.send_email_to_specific_subscriptions')}
                  name="sendToSpecificSubscriptions"
                  defaultChecked={sendToSpecificSubscriptions}
                  onChange={() => setSendToSpecificSubscriptions(!sendToSpecificSubscriptions)}
                />
              </Col>
            </Row>
            {sendToSpecificSubscriptions && (
              <Row className="mt-16">
                <Col xs={24}>
                  <Select
                    label={translate('dashboard.email_subscriptions.subscriptions')}
                    name="specificSubscriptionIds"
                    placeholder={translate('dashboard.email_subscriptions.emails.choose_specific_subscriptions')}
                    mode="multiple"
                    showArrow={true}
                  >
                    {subscriptions.map((subscription) => (
                      <option key={subscription.id} value={subscription.id}>
                        {subscription.name}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
            )}
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

interface SendEmailToSubscriptionsFormProps {
  emailId: string
  subscriptions: EmailSubscription[]
  visible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  onSubmit: (values: SendEmailToSubscriptionsRequest) => void | Promise<any>
  submitting: boolean
}

const formValidator = (translate: any, isSendToSpecificSubscriptions: boolean) =>
  Yup.object({
    sendToSpecificSubscriptions: Yup.boolean().nullable().notRequired(),
    specificSubscriptionIds: Yup.array()
      .nullable()
      .notRequired()
      .when('sendToSpecificSubscriptions', {
        is: (sendToSpecificSubscriptions) => isSendToSpecificSubscriptions,
        then: Yup.array().required(translate('general.required')).min(1),
      }),
  })
export default SendEmailToSubscriptionsForm
