import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import { queryRequest } from 'common/RequestUtils'
import Button from 'common/components/general/Button'
import Card from 'common/components/dataDisplay/Card'
import Select from 'common/components/dataEntry/components/Select'

import {
  MenuInfo,
  MenuItemInfo,
  getMenus,
  getMenuItems,
  deleteMenuItem,
} from 'core/application/dashboard/storeSettings/storeMenuSettings'
import { STORE_MENU_SETTINGS_PATH_EDIT } from './ManageStoreMenu'
import { SETTINGS_PATH } from '../Routes'
import StoreMenusTable from './StoreMenusTable'
import { LanguageDto, getSupportedLanguages } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import useSharedState from 'common/hooks/useSharedState'

export const STORE_MENU_SETTINGS_PATH = 'store-menu-settings'

const StoreMenuSettings: React.FC = () => {
  const { t: translate } = useTranslation()

  const [menus, setMenus] = useState<MenuInfo[]>([])
  const [defaultMenuId, setDefaultMenuId] = useSharedState<string>('settings-menu', '', true)
  const [loading, setLoading] = useState<boolean>(false)
  const [menuItems, setMenuItems] = useState<MenuItemInfo[]>([])
  const [languages, setLanguages] = useState<LanguageDto[]>([])
  const history = useHistory()

  const updateMenusAndItems = async () => {
    setLoading(true)
    const availableMenus = await queryRequest(() => getMenus())
    setMenus(availableMenus)
    if (availableMenus.length) {
      if (defaultMenuId === '') setDefaultMenuId(availableMenus[0].id)
      const result = await queryRequest(() => getMenuItems(defaultMenuId))
      setMenuItems(result)
    }
    setLoading(false)
  }

  const getLanguages = async () => {
    const result = await queryRequest(() => getSupportedLanguages())
    setLanguages(result)
  }

  useEffect(() => {
    getLanguages()
    updateMenusAndItems()
  }, [])

  useEffect(() => {
    if (defaultMenuId) updateMenusAndItems()
  }, [defaultMenuId])

  const handleMenuChange = async (value: any, option: any) => {
    setDefaultMenuId(value)
  }

  return (
    <>
      <Card
        bordered={false}
        title={
          <Row>
            <Col md={12} xs={24}>
              <h4>{translate('dashboard.store_settings.edit_menu_settings')}</h4>
            </Col>
            <Col md={8} xs={24}>
              <Select
                className="mb-4"
                value={defaultMenuId}
                onChange={handleMenuChange}
                placeholder={translate('dashboard.store_settings.choose_menu')}
              >
                {menus.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.name}
                  </option>
                ))}
              </Select>
            </Col>
            <Col md={4} xs={24}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`/${SETTINGS_PATH}/${STORE_MENU_SETTINGS_PATH_EDIT}/${defaultMenuId}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <StoreMenusTable
          history={history}
          menuId={defaultMenuId}
          menuItems={menuItems}
          loadingMenuItems={loading}
          translate={translate}
          onDeleteMenuItem={deleteMenuItem}
          updateMenuItemsTable={updateMenusAndItems}
          languages={languages}
        />
      </Card>
    </>
  )
}

export default StoreMenuSettings
