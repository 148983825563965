import React, { useEffect, useState } from 'react'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Card from 'common/components/dataDisplay/Card'
import { Col, Row, Table } from 'antd'
import Button from '../../common/components/general/Button'
import PagedList from 'core/application/commons/pagedList'
import getServices, { ServiceWithChildrenResponse } from 'core/application/dashboard/services/services'

import { formatDate } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import deleteService from 'core/application/dashboard/services/deleteService'
import { ServiceStatus, ServiceStatusEnum } from 'core/application/dashboard/services/manageServices'
import Tag from 'common/components/dataDisplay/Tag'
import ROUTE_PATHS from '../../common/routePaths'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import moment from 'moment'

export const SERVICES_PATH = 'services'
export const serviceTagColor = new Map<ServiceStatusEnum, string>([
  [ServiceStatusEnum.Draft, '#fbbc06'],
  [ServiceStatusEnum.Active, '#23af47'],
])

const Services: React.FC = () => {
  const history = useHistory()
  const { t: translate } = useTranslation()

  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>({
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'priority',
    orderDescending: false,
  })
  const [pageItems, isPageItemsLoading, sendPagedItemQueryRequest] =
    useQueryRequest<PagedList<ServiceWithChildrenResponse> | null>(null)

  useEffect(() => {
    ;(async () => {
      await refreshTable()
    })()
  }, [searchFilter])

  const refreshTable = async () => {
    await sendPagedItemQueryRequest(() => getServices(searchFilter))
  }

  const columns: any[] = [
    {
      title: translate('general.name'),
      width: '40%',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.status'),
      align: 'center',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => (
        <Tag color={serviceTagColor.get(record.status)}>
          {ServiceStatus(translate).find((c) => c.id === record.status).name}
        </Tag>
      ),
    },
    {
      title: translate('dashboard.priority'),
      align: 'center',
      dataIndex: 'priority',
      key: 'priority',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.created_at'),
      align: 'center',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      responsive: ['md'],
      sortDirections: ['descend', 'ascend'],
      render: (text: string, record: any) => formatDate(record.createdAt),
    },
    {
      title: translate('dashboard.action'),
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      sorter: false,
      render: (text: string, record: any) => (
        <div className="flex-center">
          <TableEditButton className="mr-16" editUrl={`${ROUTE_PATHS.SERVICES}/manage-services/${record.id}`} />
          <TableDeleteButton
            disableDeleted={record.children?.length > 0}
            onDelete={() => deleteService(record.id)}
            onDeleted={() => refreshTable()}
          />
        </div>
      ),
    },
  ]

  const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
  columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Card
      bordered={true}
      title={
        <Row>
          <Col xs={16}>
            <h4>{translate('dashboard.services.name')}</h4>
          </Col>

          <Col xs={8}>
            <div className={'text-right'}>
              <Button
                icon="far fa-plus"
                type="primary"
                onClick={() => history.push(`${ROUTE_PATHS.SERVICES}/manage-services`)}
              >
                {translate('dashboard.add')}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Table
        rowKey={(record) => record.id as any}
        dataSource={pageItems?.items}
        onChange={onTableChange}
        loading={isPageItemsLoading}
        columns={columns}
        scroll={{ x: true }}
        pagination={{
          total: pageItems?.totalItemCount,
          pageSize: pageItems?.pageSize,
          current: pageItems?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </Card>
  )
}

export default Services
