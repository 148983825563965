import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import TreeSelect from 'common/components/dataEntry/formik/FormikTreeSelectField'

import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import getJobPositionForSave, {
  JobPositionForSave,
  JobPositionStatus,
  JobPositionStatusEnum,
  JobPositionToSave,
  JobTypeStatus,
  JobTypeStatusEnum,
  saveJobPosition,
} from 'core/application/dashboard/careers/jobs/manageJobPosition'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'

export const MANAGE_JOB_POSITION_PATH = 'manage-job-position'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    description: Yup.string().nullable().trim().required(translate('general.required')),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .min(0, translate('general.minimun_required')),
    status: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .oneOf(
        [JobPositionStatusEnum.Active, JobPositionStatusEnum.Archived, JobPositionStatusEnum.Draft],
        translate('general.required'),
      ),
    type: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .oneOf([JobTypeStatusEnum.FullTime, JobTypeStatusEnum.PartTime], translate('general.required')),
    categoryIds: Yup.array().required(translate('general.required')).min(1).nullable(),
  })

const ManageJobPosition: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const [item, setItem] = useState<JobPositionForSave | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const updateForm = async () => {
    setLoading(true)
    const result = await queryRequest(() => getJobPositionForSave(id))
    setItem(result)
    setLoading(false)
  }

  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: JobPositionForSave) => {
    let jobPositionToSave: JobPositionToSave = {
      id: values.id === '00000000-0000-0000-0000-000000000000' ? null : values.id,
      friendlyUrl: values.friendlyUrl,
      photoId: values.photoId,
      name: values.name,
      priority: values.priority,
      status: values.status,
      location: values.location,
      benefits: values.benefits,
      requirements: values.requirements,
      description: values.description,
      shortDescription: values.seoDescription,
      featured: values.featured,
      type: values.type,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
      seoImage: values.seoImage,
      categoryIds: values.categoryIds,
    }

    const result = await commandRequest(() => saveJobPosition(jobPositionToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.the_category')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.jobs.position.edit') : translate('dashboard.jobs.position.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.categories.name')}
                        name="name"
                        placeholder={translate('dashboard.categories.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                          formik.setFieldValue('seoTitle', e.currentTarget.value)
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="type"
                        showSearch={true}
                        label={`${translate('dashboard.jobs.position.type')}`}
                        placeholder={translate('dashboard.jobs.position.type')}
                        defaultValue={id ? [item.type] : []}
                      >
                        {JobTypeStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <TreeSelect
                        label={translate('dashboard.jobs.categories')}
                        name="categoryIds"
                        placeholder={translate('dashboard.jobs.categories')}
                        allowClear
                        showArrow
                        multiple
                        showSearch
                        defaultValue={item.categoryIds || []}
                        treeData={item.availableCategories || []}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                      ></TreeSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.jobs.position.location')}
                        name="location"
                        placeholder={translate('dashboard.jobs.position.location')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.jobs.position.description')}
                        name={'description'}
                        placeholder={translate('dashboard.jobs.position.description')}
                        value={item?.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <FormikTextAreaField
                        label={translate('dashboard.jobs.position.short_description')}
                        name="shortDescription"
                        placeholder={translate('dashboard.jobs.position.short_description')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.jobs.position.requirements')}
                        name={'requirements'}
                        placeholder={translate('dashboard.jobs.position.requirements')}
                        value={item?.requirements}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.jobs.position.benefits')}
                        name={'benefits'}
                        placeholder={translate('dashboard.jobs.position.benefits')}
                        value={item?.benefits}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}(${translate('general.optional')})`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={24}>
                      <FormikSeoFields />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [item.status] : []}
                      >
                        {JobPositionStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageJobPosition
