import React, { useState, useEffect } from 'react'
import { queryRequest } from 'common/RequestUtils'
import { useTranslation } from 'react-i18next'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'
import getAverageOrderPrice, {
  AverageOrderPriceResponse,
  AverageOrderPriceRequest,
} from 'core/application/dashboard/home/statistics/averageOrderPrice'
import { formatMoney } from 'common/moneyFormatter'
import StatisticCard from '../home/statistics/StatisticCard'

const AverageOrderPrice: React.FC = ({}) => {
  const { t: translate } = useTranslation()
  const [averageOrderPrice, setAverageOrderPrice] = useState<AverageOrderPriceResponse>()
  const [searchFilter, setSearchFilter] = useState<AverageOrderPriceRequest>({})
  const [loading, setLoading] = useState<boolean>(false)

  const updateAverageOrderPrice = async (searchFilter: AverageOrderPriceRequest) => {
    setLoading(true)
    const result = await queryRequest(() => getAverageOrderPrice(searchFilter))
    setAverageOrderPrice(result)
    setLoading(false)
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setSearchFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  useEffect(() => {
    ;(async () => {
      await updateAverageOrderPrice(searchFilter)
    })()
  }, [searchFilter])

  return (
    <>
      <DateRangePicker
        onChange={onDateChange}
        placeholder={[translate('general.start_date'), translate('general.end_date')]}
        className="mb-16"
      />

      {averageOrderPrice && (
        <StatisticCard
          title={''}
          amount={formatMoney(averageOrderPrice.price, averageOrderPrice.currency)}
          loading={loading}
        />
      )}
    </>
  )
}

export default AverageOrderPrice
