import React, { Fragment, useState } from 'react'
import { Col, Row, Select } from 'antd'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { v4 } from 'uuid'
import Input from '../../common/components/dataEntry/components/Input'
import {
  DefaultPopUp,
  getDefaultPageSection,
  POPUP_TEMPLATES,
} from 'core/application/dashboard/storeSettings/pageSection'
import useQueryRequest from '../../common/hooks/useQueryRequest'

const { Option } = Select

const CONTENT_KEYS = {
  headline: '**headline**',
  buttonLabel: '**button_label**',
  buttonLink: '**button_link**',
  popupCode: '**popup_code**',
  description: '**description**',
}

export function parseContent(str: string) {
  return str.replace(/\**/g, '')
}

export function SelectPopUpTemplate() {
  const { setFieldValue, values } = useFormikContext<any>()
  const [headline, setHeadline] = useState(values.headline || CONTENT_KEYS.headline)
  const [isDefaultTemplateSelected, setIsDefaultTemplateSelected] = useState(false)
  const [description, setDescription] = useState(values.description || CONTENT_KEYS.description)
  const [buttonLabel, setButtonLabel] = useState(values.buttonLabel || CONTENT_KEYS.buttonLabel)
  const [buttonLink, setButtonLink] = useState(values.buttonLink || CONTENT_KEYS.buttonLink)
  const [, , query] = useQueryRequest<any>(async () => await getDefaultPageSection())
  const [defaultPopUps, setDefaultPopUps] = useState<DefaultPopUp[]>()

  const [template, setTemplate] = useState({ key: '', value: '' })

  useEffect(() => {
    ;(async () => {
      const result = await query(() => getDefaultPageSection())
      setDefaultPopUps(result)
    })()
  }, [])

  useEffect(() => {
    setFieldValue('content', template.value)
  }, [template])

  const onSelectHandler = (value: string, options: any) => {
    if (options.key === POPUP_TEMPLATES.defaultPopUp) {
      const contentValue = value.replace(CONTENT_KEYS.popupCode, v4())
      setTemplate((prev) => ({ ...prev, value: contentValue }))
      setIsDefaultTemplateSelected(true)
    } else {
      setTemplate({
        key: options.key,
        value: value,
      })
      setIsDefaultTemplateSelected(false)
    }
  }

  const onBlurHeadline = (e: any) => {
    const value = `**${e.currentTarget.value}**`
    if (value.length === 0) return

    const contentValue = template.value.replace(headline, value)
    setTemplate((prev) => ({ ...prev, value: contentValue }))
    setHeadline(value)
  }

  const onBlurDescription = (e: any) => {
    const value = `**${e.currentTarget.value}**`
    if (value.length === 0) return

    const contentValue = template.value.replace(description, value)
    setTemplate((prev) => ({ ...prev, value: contentValue }))
    setDescription(value)
  }

  const onBlurButtonLabel = (e: any) => {
    const value = `**${e.currentTarget.value}**`
    if (value.length === 0) return

    const contentValue = template.value.replace(buttonLabel, value)
    setTemplate((prev) => ({ ...prev, value: contentValue }))
    setButtonLabel(value)
  }

  const onBlurButtonLink = (e: any) => {
    let value = `**${e.currentTarget.value}**`
    if (value.length === 0) return

    if (value.startsWith('**www.')) {
      value = 'https://' + value
    }

    const contentValue = template.value.replace(buttonLink, value)
    setTemplate((prev) => ({ ...prev, value: contentValue }))
    setButtonLink(value)
  }

  // const isDefaultTemplateSelected = template.key === POPUP_TEMPLATES.defaultPopUp && !!values.content;

  return (
    <Fragment>
      {defaultPopUps && defaultPopUps.length > 0 && (
        <Row>
          <Col md={16} xs={24}>
            <Select placeholder="Select template" onSelect={onSelectHandler} size="large">
              {defaultPopUps.map((c) => (
                <Option key={c.id} value={c.content}>
                  {c.title}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}

      {isDefaultTemplateSelected && (
        <div style={{ background: '#f1f5f9', padding: '1rem', borderRadius: '.5rem' }}>
          <Row>
            <Col md={16} xs={24}>
              <Input name="headline" title="Headline" placeholder="Headline" onBlur={onBlurHeadline} size="large" />
            </Col>
          </Row>
          <Row className={'pt-8'}>
            <Col md={16} xs={24}>
              <Input
                name="description"
                title="Description"
                placeholder="Description"
                onBlur={onBlurDescription}
                size="large"
              />
            </Col>
          </Row>
          <Row className={'pt-8'}>
            <Col md={16} xs={24}>
              <Input
                name="buttonLabel"
                title="Button text"
                placeholder="Button label"
                onBlur={onBlurButtonLabel}
                size="large"
              />
            </Col>
          </Row>
          <Row className={'pt-8'}>
            <Col md={16} xs={24}>
              {' '}
              <Input
                name="buttonLink"
                title="Button link"
                placeholder="Eg: www.google.com"
                onBlur={onBlurButtonLink}
                size="large"
              />
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  )
}
