import * as Yup from 'yup'

export const formValidator = (translate: any) =>
  Yup.object({
    author: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    rate: Yup.number()
      .nullable()
      .typeError(translate('general.number_required'))
      .required(translate('general.required'))
      .min(0, translate('general.minimun_required'))
      .max(5, translate('general.maximum_required')),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.number_required'))
      .min(0, translate('general.minimun_required')),
  })
