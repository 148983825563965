import React from 'react'
import { Checkbox as AntCheckbox } from 'antd'
import './RadioStyles.less'

interface CheckboxProps {
  disabled?: boolean
  onChange?: (e: any) => void
  className?: string
  id?: string
  defaultChecked?: boolean
  checked?: boolean
  value?: string
}

const Checkbox: React.FC<CheckboxProps> = (props) => {
  return (
    <AntCheckbox {...props} className={props.className}>
      {props.children}
    </AntCheckbox>
  )
}

export default Checkbox
