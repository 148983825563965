import React, { useState } from 'react'
import { useFormikContext } from 'formik'

import './RadioStyles.less'
import Tag, { TagProps } from './Tag'
import { FormikInputProps } from '../formik/FormikInputField'
import Input from '../formik/FormikInputField'

type EditableTagProps = TagProps &
  FormikInputProps & {
    onPressedEnter?: (values: any) => void
    editable?: boolean
  }

const EditableTag: React.FC<EditableTagProps> = (props) => {
  const { values, setFieldValue } = useFormikContext()
  const [editing, setEditing] = useState<boolean>(false)
  const [tagValue, setTagValue] = useState<any>(props.value ? props.value : props.defaultValue)

  const toggleEditing = () => {
    if (props.editable) setEditing(!editing)
  }

  const onPressEnter = (e: any) => {
    setFieldValue(props.name, e.target.value)
    setTagValue(e.currentTarget.value)
    toggleEditing()
    if (props.onPressedEnter) props.onPressedEnter(values)
  }

  return (
    <>
      <div className={`form-group ${props.className}`}>
        <div>
          {!editing && (
            <Tag onClick={toggleEditing} {...{ ...props, className: props.className + ' tag' }}>
              {tagValue}
            </Tag>
          )}
          {editing && (
            <Input onPressEnter={onPressEnter} {...{ ...props, className: props.className + ' tag-input' }} />
          )}
        </div>
      </div>
    </>
  )
}

export default EditableTag
