import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton, Modal, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'
import { v4 as uuidv4 } from 'uuid'

import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import Card from 'common/components/dataDisplay/Card'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import notification from 'common/components/feedback/Notification'

import getProduct, {
  Product,
  saveProduct,
  ProductAttribute,
  ProductMatrix,
  ProductAttributeValue,
} from 'core/application/dashboard/products/manageProduct'
import { ProductStatus } from 'core/domain/product/productStatus'
import { User } from 'core/application/account/loginUser'
import { NamedItem, NamedItemInt } from 'core/application/commons/namedItem'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import ProductAttributesTable from './ProductAttributesTable'
import ProductMatrixTable from './ProductMatrixTable'
import Button from 'common/components/general/Button'
import TreeSelect from 'common/components/dataEntry/formik/FormikTreeSelectField'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'
import { useCommandRequestV2, useLoginUserV2, useQueryRequestV2 } from 'common/hooks'
import { generateProductUnitsForPredefinedAttributes, GenerateProductUnitsResponse, GuidUtils } from 'core'
import DatePicker from 'common/components/dataEntry/formik/FormikDatePickerField'
import moment from 'moment'
import useFeatureFlag from 'common/hooks/useFeatureFlag'
import { Feature } from 'core/domain/featureFlags/feature'
import TimePicker from 'common/components/dataEntry/components/TimePicker'

export const MANAGE_PRODUCTS_PATH = 'manage-product'

export const getProductStatuses = (translate: (key: string) => string): NamedItemInt[] => {
  const statuses: NamedItemInt[] = []
  statuses.push({
    id: ProductStatus.Active,
    name: translate('dashboard.products.statuses.active'),
  })
  statuses.push({
    id: ProductStatus.Archived,
    name: translate('dashboard.products.statuses.archived'),
  })
  statuses.push({
    id: ProductStatus.Draft,
    name: translate('dashboard.products.statuses.draft'),
  })
  return statuses
}

const getWeightUnit = (user: User | null) => {
  return user ? user.weightUnit : ''
}

interface ManageProductsProps {}
const ManageProducts: React.FC<ManageProductsProps> = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const showAuction = useFeatureFlag(Feature.AddAuctionProduct)
  const showRelatedProducts = useFeatureFlag(Feature.RelatedProducts)

  const { confirm } = Modal
  const [item, setItem] = useState<Product | null>(null)

  const { sendCommand, isLoading: isCommandLoading } = useCommandRequestV2(saveProduct)

  const { data: productDetails } = useQueryRequestV2({
    initialState: undefined,
    queryFn: () => getProduct(id),
  })

  const { isLoading: isPredefinedAttributesLoading, sendQuery: sendGetPredefinedAttributes } = useQueryRequestV2<
    GenerateProductUnitsResponse[]
  >({
    initialState: [],
  })

  const { user } = useLoginUserV2()

  useEffect(() => {
    if (productDetails) {
      setItem(productDetails)
    }
  }, [productDetails])

  const cartesian = (...a: any[]) => a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())))

  const generateProductMatrixTable = (values: Product) => {
    const matrix: ProductMatrix[] = []
    if (values && !values.hasStaticAttributes) {
      var attributeValues: any[] = []
      attributeValues = values.attributes
        .filter((attr: ProductAttribute) => attr.values != null && attr.values.length > 0)
        .map((attr: ProductAttribute) => attr.values)
      if (attributeValues.length > 0) {
        var cartesianProduct = cartesian(...attributeValues)
        cartesianProduct.forEach((unit: ProductAttributeValue | ProductAttributeValue[]) => {
          const entry: ProductMatrix = {
            id: uuidv4(),
            name: Array.isArray(unit) ? unit.map((v) => v.name).join(' / ') : unit.name,
            price: values.price,
            stock: 1,
            description: '',
            photoId: '',
            inactive: false,
            attributeValues: Array.isArray(unit) ? unit.map((v) => v.id) : [unit.id],
          }
          matrix.push(entry)
        })

        setItem({
          ...values,
          productMatrix: [...matrix],
          stock: matrix.reduce((a, b) => a + b.stock, 0),
        })

        if (id) {
          notification.open({
            message: translate('dashboard.products.product_matrix_regenerated'),
            type: 'info',
          })
        }
      }
    } else {
      setItem({ ...values, productMatrix: [] })
    }
  }

  const handleProductPriceUpdate = (e: any, formik: any) => {
    const { values } = formik
    const price = +e.currentTarget.value
    formik.setFieldValue('price', price)
    if (values && values.productMatrix && values.productMatrix.length > 0) {
      values.productMatrix.forEach((p: any, i: number) => formik.setFieldValue(`productMatrix[${i}].price`, price))
    }
    setItem(values)
  }

  const onAddProductAttribute = (values: Product) => {
    if (values) {
      var latestPriorityIndex = values.attributes.length - 1
      const newAttribute: ProductAttribute = {
        id: uuidv4(),
        name: '',
        priority: values.attributes[latestPriorityIndex] ? values.attributes[latestPriorityIndex].priority + 1 : 0,
        values: [],
      }

      const updatedItem = {
        ...values,
        attributes: [...values.attributes, newAttribute],
      }
      setItem(updatedItem)
      generateProductMatrixTable(updatedItem)
    }
  }

  const onDeleteProductAttribute = (id: string, values: Product) => {
    if (values) {
      const result = values.attributes.filter((attr: ProductAttribute) => attr.id !== id)
      const updatedItem = {
        ...values,
        attributes: [...result],
        productMatrix: result && result.length > 0 ? values.productMatrix : [],
        stock: result && result.length > 0 ? values.stock : 0,
      }
      setItem(updatedItem)
      generateProductMatrixTable(updatedItem)
    }
  }

  const onDeleteAllProductAttributes = (values: Product) => {
    if (values) {
      const updatedItem = {
        ...values,
        stock: 0,
        attributes: [],
        productMatrix: [],
      }
      setItem(updatedItem)
      generateProductMatrixTable(updatedItem)
    }
  }

  const onAddProductAttributeValue = (name: string, index: number, values: Product) => {
    if (values && name) {
      var latestPriorityIndex = values.attributes[index].values.length - 1
      const newAttributeValue: ProductAttributeValue = {
        id: uuidv4(),
        name: name,
        description: '',
        priority: values.attributes[index].values[latestPriorityIndex]
          ? values.attributes[index].values[latestPriorityIndex].priority + 1
          : 0,
      }
      values.attributes[index].values.push(newAttributeValue)
      const updatedItem = { ...values, attributes: [...values.attributes] }
      setItem(updatedItem)
      generateProductMatrixTable(updatedItem)
    }
  }

  const onDeleteProductAttributeValue = (id: string, index: number, values: Product) => {
    if (values) {
      values.attributes[index].values = values.attributes[index].values.filter(
        (val: ProductAttributeValue) => val.id !== id,
      )
      const updatedItem = { ...values, attributes: [...values.attributes] }
      setItem(updatedItem)
      generateProductMatrixTable(updatedItem)
    }
  }

  const onSubmit = async (values: Product) => {
    const result = await sendCommand({
      ...values,
      id: values.id === '00000000-0000-0000-0000-000000000000' ? null : values.id,
      photoIds: values.photoIds !== null && values.photoIds.length > 0 ? values.photoIds : null,
    })

    if (result.completed) {
      notification.success(translate('general.saved').replace('[]', translate('dashboard.the_product')))
      history.goBack()
    }
  }

  const generateProductMatrixForPredefinedAttributes = async (
    productId: string,
    categoryIds: string[],
  ): Promise<GenerateProductUnitsResponse[]> => {
    if (!GuidUtils.isGuidNullOrEmpty(productId)) {
      return []
    }

    const result = await sendGetPredefinedAttributes(() => generateProductUnitsForPredefinedAttributes(categoryIds))
    return result ?? []
  }

  const onCategoriesSelectionChanged = async (values: any, categoryIds: string[]) => {
    const result = await generateProductMatrixForPredefinedAttributes(values.id, categoryIds)

    if (result.length > 0) {
      setItem({
        ...values,
        categoryIds,
        productMatrix: [...result],
      })
    } else {
      setItem({
        ...values,
        categoryIds,
      })
    }
  }

  return (
    <Skeleton active loading={!productDetails} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik<Product>
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="pb-16">
              <FormikForm>
                <Row gutter={16}>
                  <Col xs={24} md={16}>
                    <Card bordered={true} title={translate('dashboard.products.info')}>
                      <Row>
                        <Col xs={24}>
                          <Input
                            label={translate('dashboard.products.name')}
                            name="name"
                            placeholder={translate('dashboard.products.name')}
                            onBlur={(e: any) => {
                              if (id) return
                              const friendlyUrl = generateFriendlyUrl(e.currentTarget.value)
                              formik.setFieldValue('seoTitle', e.currentTarget.value)
                              formik.setFieldValue('friendlyUrl', friendlyUrl)
                              formik.setFieldValue('seoUrl', friendlyUrl)
                            }}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24}>
                          <Input
                            label={translate('dashboard.friendly_url')}
                            name="friendlyUrl"
                            placeholder={translate('dashboard.friendly_url')}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24}>
                          <TreeSelect
                            label={translate('dashboard.category')}
                            name="categoryIds"
                            placeholder={translate('dashboard.category')}
                            allowClear
                            multiple
                            showArrow
                            showSearch
                            treeCheckable
                            treeCheckStrictly
                            defaultValue={item.categoryIds}
                            treeData={item.availableCategories}
                            treeDefaultExpandAll
                            treeNodeFilterProp="title"
                            onChange={async (selectedIds: any) => {
                              await onCategoriesSelectionChanged(formik.values, selectedIds)
                            }}
                          ></TreeSelect>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24}>
                          <FormikHtmlEditorField
                            label={translate('dashboard.description')}
                            name={'description'}
                            placeholder={translate('dashboard.description')}
                            value={item?.description}
                          />
                        </Col>
                      </Row>
                    </Card>

                    <Card bordered={true} title={`${translate('dashboard.primary_photo')}`}>
                      <Row className="mb-16">
                        <Col xs={24}>
                          <Uploader
                            label=""
                            initialValues={[item.mainPhotoId]}
                            name="mainPhotoId"
                            resizeToWidth={1080}
                            resizeToHeight={720}
                            fieldName="mainPhotoId"
                            optimizedResize={true}
                            mode="single"
                            maxUploads={1}
                          />
                        </Col>
                      </Row>
                    </Card>

                    <Card
                      bordered={true}
                      title={`${translate('dashboard.other_photos')}(${translate('general.optional')})`}
                    >
                      <Row className="mb-16">
                        <Col xs={24}>
                          <Uploader
                            label=""
                            initialValues={item.photoIds}
                            name="photoIds"
                            resizeToWidth={1080}
                            resizeToHeight={720}
                            fieldName="photoIds"
                            optimizedResize={true}
                            mode="multiple"
                            maxUploads={6}
                          />
                        </Col>
                      </Row>
                    </Card>

                    <Card bordered={true} title={translate('dashboard.products.price')}>
                      <Row>
                        <Col xs={24}>
                          <InputNumber
                            label={`${translate('dashboard.products.price')} (${user != null ? user.currency : ''})`}
                            name="price"
                            placeholder={translate('dashboard.products.price')}
                            onPressEnter={(e: any) => {
                              handleProductPriceUpdate(e, formik)
                            }}
                            onBlur={(e: any) => {
                              handleProductPriceUpdate(e, formik)
                            }}
                          />
                        </Col>
                        {/* <Col xs={2}></Col>
                        <Col xs={11}>
                          <InputNumber
                            label={`${translate(
                              "dashboard.products.discount_price"
                            )} (${user != null ? user.currency : ""})`}
                            name="discountPrice"
                            placeholder={translate(
                              "dashboard.products.discount_price"
                            )}
                          />
                        </Col> */}
                      </Row>
                      <Row className="mb-8">
                        <Col xs={24}>
                          <Checkbox
                            label={translate('dashboard.products.ask_for_price')}
                            name="askForPrice"
                            defaultChecked={item.askForPrice}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-8">
                        <Col xs={24}>
                          <Checkbox
                            label={translate('dashboard.products.make_an_offer')}
                            name="makeAnOffer"
                            defaultChecked={item.makeAnOffer}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24}>
                          <InputNumber
                            label={`${translate('dashboard.products.cost_per_item')} (${
                              user != null ? user.currency : ''
                            })`}
                            name="costPerItem"
                            placeholder={translate('dashboard.products.cost_per_item')}
                            min={0}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col xs={24}>
                          <Checkbox
                            label={translate("dashboard.products.apply_tax")}
                            name="applyTax"
                            defaultChecked={item.applyTax}
                          />
                        </Col>
                      </Row> */}
                    </Card>

                    <Card bordered={true} title={`${translate('dashboard.products.inventory')}`}>
                      <Row>
                        <Col xs={24}>
                          <Input
                            label={translate('dashboard.products.stock_keeping_unit')}
                            name="stockKeepingUnit"
                            placeholder={translate('dashboard.products.stock_keeping_unit')}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24}>
                          <InputNumber
                            label={translate('dashboard.products.stock')}
                            name="stock"
                            placeholder={translate('dashboard.products.stock')}
                            min={0}
                            disabled={
                              item ? item.attributes && item.attributes.length > 0 && !item.hasStaticAttributes : false
                            }
                          />
                        </Col>
                      </Row>
                    </Card>

                    <Card bordered={true} title={`${translate('dashboard.products.shipping')}`}>
                      <Row>
                        <Col xs={24} className="mb-8">
                          <Checkbox
                            label={translate('dashboard.products.digital_product')}
                            name="isDigitalProduct"
                            defaultChecked={item.isDigitalProduct}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24}>
                          <Checkbox
                            label={translate('dashboard.products.physical_product')}
                            name="isPhysicalProduct"
                            defaultChecked={item.isPhysicalProduct}
                          />
                        </Col>
                      </Row>
                      <br />
                      {formik.values.isPhysicalProduct && (
                        <Row>
                          <Col xs={24}>
                            <InputNumber
                              label={`${translate('dashboard.products.weight')} (${getWeightUnit(user)})`}
                              name="weight"
                              placeholder={translate('dashboard.products.weight')}
                              min={0}
                            />
                          </Col>
                        </Row>
                      )}
                    </Card>

                    {!item?.hasPredefinedAttributes && (
                      <Card bordered={true} title={`${translate('dashboard.products.attributes')}`}>
                        {id ? (
                          item.hasStaticAttributes && <p>{translate('dashboard.products.has_static_attributes')}</p>
                        ) : (
                          <Row className="mb-8">
                            <Col xs={24}>
                              <Checkbox
                                label={translate('dashboard.products.has_static_attributes')}
                                name="hasStaticAttributes"
                                defaultChecked={item.hasStaticAttributes}
                              />
                            </Col>
                          </Row>
                        )}

                        <Row className="mb-16">
                          <Col xs={24}>
                            <ProductAttributesTable
                              name="attributes"
                              translate={translate}
                              productAttributes={item.attributes}
                              canEditAttributes={item.canEditAttributes}
                              onDeleteProductAttribute={onDeleteProductAttribute}
                              onAddProductAttributeValue={onAddProductAttributeValue}
                              onDeleteProductAttributeValue={onDeleteProductAttributeValue}
                              updateProductMatrixTable={generateProductMatrixTable}
                            />
                          </Col>
                        </Row>

                        <Row className="mb-16">
                          <Col xs={24}>
                            <div className={'text-left'}>
                              {formik.values.attributes.length == 3 && !item.hasStaticAttributes ? (
                                <Tooltip title={translate('dashboard.products.add_attribute_tooltip')}>
                                  <Button icon="far fa-plus" type="primary" disabled={true}>
                                    {translate('dashboard.add')}
                                  </Button>
                                </Tooltip>
                              ) : (
                                item.canEditAttributes && (
                                  <Button
                                    icon="far fa-plus"
                                    type="primary"
                                    onClick={() => onAddProductAttribute(formik.values)}
                                  >
                                    {translate('dashboard.add')}
                                  </Button>
                                )
                              )}{' '}
                              {item.attributes && item.attributes.length > 0 && item.canEditAttributes ? (
                                <Button
                                  className={'error-button'}
                                  icon="far fa-trash-alt"
                                  type="primary"
                                  onClick={() =>
                                    confirm({
                                      title: translate('dashboard.products.delete_all_alert'),
                                      content: translate('dashboard.products.delete_all_alert_content'),
                                      okText: translate('general.ok_text'),
                                      cancelText: translate('general.cancel_text'),
                                      onOk: () => {
                                        onDeleteAllProductAttributes(formik.values)
                                      },
                                    })
                                  }
                                >
                                  {translate('dashboard.products.delete_all')}
                                </Button>
                              ) : (
                                ''
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    )}

                    {item.productMatrix && item.productMatrix.length > 0 && !item.hasStaticAttributes && (
                      <Card bordered={true} title={`${translate('dashboard.products.product_matrix')}`}>
                        <Row className="mb-16">
                          <Col xs={24}>
                            <ProductMatrixTable
                              key={item.id}
                              translate={translate}
                              productMatrix={item.productMatrix}
                              name="productMatrix"
                              currency={user != null ? user.currency : ''}
                            />
                          </Col>
                        </Row>
                      </Card>
                    )}

                    <Card bordered={true} title={`${translate('dashboard.products.seo')}`}>
                      <FormikSeoFields />
                    </Card>
                  </Col>

                  <Col xs={24} md={8}>
                    <Card bordered={true} title={translate('dashboard.products.product_status')}>
                      <Row>
                        <Col xs={24}>
                          <Select
                            label={translate('dashboard.products.status')}
                            name="status"
                            showArrow={true}
                            placeholder={translate('dashboard.products.status')}
                            defaultValue={[item.status]}
                          >
                            {getProductStatuses(translate).map((status) => (
                              <option key={status.id} value={status.id}>
                                {status.name}
                              </option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    </Card>

                    {showAuction && (
                      <Card
                        noPadding={!formik.values.isForAuction}
                        bordered
                        title={
                          <Checkbox
                            disabled={!formik.values.canEditAuction}
                            name="isForAuction"
                            checked={formik.values.isForAuction}
                            label={translate('dashboard.products.product_auction')}
                            onChange={(e) => {
                              formik.setValues((v) =>
                                e.target.checked
                                  ? {
                                      ...v,
                                      auctionRequest: item.isForAuction
                                        ? item.auctionRequest
                                        : ({
                                            startPrice: 0,
                                            startDate: moment().startOf('day').add(1, 'day').format('YYYY-MM-DD'),
                                            endDate: moment().startOf('day').add(2, 'day').format('YYYY-MM-DD'),
                                          } as any),
                                    }
                                  : { ...v, auctionRequest: null },
                              )
                              formik.setFieldValue('isForAuction', e.target.checked)
                            }}
                          />
                        }
                      >
                        <Row>
                          <Col span={24}>
                            {formik.values.auctionRequest && (
                              <>
                                <InputNumber
                                  disabled={!formik.values.canEditAuction}
                                  name="auctionRequest.startPrice"
                                  label={translate('general.start_price') + (user != null ? `(${user.currency})` : '')}
                                  min={0}
                                />
                                <DatePicker
                                  showTime
                                  showNow={false}
                                  allowClear={false}
                                  name="auctionRequest.startDate"
                                  label={translate('general.start_date')}
                                  disabled={!formik.values.canEditAuction}
                                  value={moment(formik.values.auctionRequest.startDate)}
                                  disabledDate={(d) => !d || d.isBefore(moment())}
                                />
                                <DatePicker
                                  showTime
                                  showNow={false}
                                  allowClear={false}
                                  name="auctionRequest.endDate"
                                  label={translate('general.end_date')}
                                  disabled={!formik.values.canEditAuction}
                                  value={moment(formik.values.auctionRequest.endDate)}
                                  disabledDate={(d) =>
                                    !d || d.isBefore(moment(formik.values.auctionRequest.startDate ?? undefined).add(1))
                                  }
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </Card>
                    )}

                    <Card bordered={true} title={`${translate('dashboard.products.organization')}`}>
                      <Row>
                        <Col xs={24}>
                          <Input
                            label={translate('dashboard.products.type')}
                            name="type"
                            placeholder={translate('dashboard.products.type')}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24}>
                          <Select
                            label={translate('general.brands')}
                            name="brandIds"
                            placeholder={translate('general.brands')}
                            allowClear
                            mode="multiple"
                            showArrow
                            showSearch
                            defaultValue={item.brandIds}
                          >
                            {item.availableBrands.map((item: NamedItem) => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                    </Card>

                    {showRelatedProducts && (
                      <>
                        <Card bordered={true} title={`${translate('dashboard.products.related_product_attributes')}`}>
                          {!formik.values.hasRelatedProducts && (
                            <Row>
                              <Col xs={24}>
                                <Select
                                  label={translate('dashboard.products.parent_product')}
                                  name="parentProductId"
                                  showArrow
                                  showSearch
                                  placeholder={translate('dashboard.products.parent_product')}
                                  defaultValue={item.parentProductId ? [item.parentProductId] : []}
                                >
                                  {item.availableParentProducts.map((parent) => (
                                    <option key={parent.id} value={parent.id}>
                                      {parent.name}
                                    </option>
                                  ))}
                                </Select>
                              </Col>
                            </Row>
                          )}

                          <Row>
                            <Col xs={24}>
                              <Input
                                label={translate('dashboard.products.related_product_text')}
                                name="relatedProductText"
                                placeholder={translate('dashboard.products.related_product_text')}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col xs={24}>
                              <Input
                                label={translate('dashboard.products.related_product_icon')}
                                name="relatedProductIcon"
                                placeholder={translate('dashboard.products.related_product_icon')}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </>
                    )}
                  </Col>
                </Row>
              </FormikForm>

              <div className={'text-center'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values) || isCommandLoading}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={isCommandLoading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

const formValidator = (translate: any) =>
  Yup.object<Product>().shape({
    name: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    description: Yup.string().trim().nullable().notRequired(),
    categoryIds: Yup.array().required(translate('general.required')).min(1),
    mainPhotoId: Yup.string().required(translate('general.required')),
    photoIds: Yup.array().min(0).notRequired().nullable(),
    price: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .nullable()
      .min(0, translate('general.minimun_required')),
    discountPrice: Yup.number()
      .notRequired()
      .typeError(translate('general.required'))
      .nullable()
      .min(1, translate('general.minimun_required')),
    costPerItem: Yup.number()
      .notRequired()
      .typeError(translate('general.required'))
      .nullable()
      .min(1, translate('general.minimun_required')),
    weight: Yup.number()
      .nullable()
      .notRequired()
      .when('isPhysicalProduct', {
        is: (isPhysicalProduct) => isPhysicalProduct,
        then: Yup.number().typeError(translate('general.required')).min(0, translate('general.minimun_required')),
      }),
    seoTitle: Yup.string().trim().nullable().notRequired(),
    seoDescription: Yup.string().trim().nullable().notRequired(),
    seoKeywords: Yup.string().trim().nullable().notRequired(),
    seoUrl: Yup.string().trim().nullable().notRequired(),
    tags: Yup.array().notRequired().nullable(),
    status: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .oneOf([ProductStatus.Active, ProductStatus.Archived, ProductStatus.Draft], translate('general.required')),
    applyTax: Yup.boolean().notRequired().typeError(translate('general.required')).nullable(),
    stockKeepingUnit: Yup.string().trim().nullable().notRequired(),
    stock: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .nullable()
      .min(0, translate('general.minimun_required')),
    attributes: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(translate('general.required')).typeError(translate('general.required')).nullable(),
          name: Yup.string()
            .required(translate('general.required'))
            .typeError(translate('general.required'))
            .nullable(),
          priority: Yup.number()
            .required(translate('general.required'))
            .typeError(translate('general.required'))
            .nullable(),
          values: Yup.array()
            .of(
              Yup.object().shape({
                id: Yup.string()
                  .required(translate('general.required'))
                  .typeError(translate('general.required'))
                  .nullable(),
                name: Yup.string()
                  .required(translate('general.required'))
                  .typeError(translate('general.required'))
                  .nullable(),
                priority: Yup.number()
                  .required(translate('general.required'))
                  .typeError(translate('general.required'))
                  .min(0, 'general.minimun_required')
                  .nullable(),
                description: Yup.string(),
              }),
            )
            .required(translate('general.required')),
        }),
      )
      .nullable()
      .notRequired(),
    productMatrix: Yup.array()
      .nullable()
      .notRequired()
      .when(['attributes', 'hasStaticAttributes', 'hasPredefinedAttributes'], {
        is: ([attributes, hasStaticAttributes, hasPredefinedAttributes]) =>
          ((attributes != null && attributes.length > 0) || hasPredefinedAttributes) && !hasStaticAttributes,
        then: Yup.array()
          .of(
            Yup.object().shape({
              id: Yup.string()
                .required(translate('general.required'))
                .typeError(translate('general.required'))
                .nullable(),
              name: Yup.string()
                .required(translate('general.required'))
                .typeError(translate('general.required'))
                .nullable(),
              price: Yup.number()
                .required(translate('general.required'))
                .typeError(translate('general.required'))
                .min(0, 'general.minimun_required')
                .nullable(),
              stock: Yup.number()
                .required(translate('general.required'))
                .typeError(translate('general.required'))
                .min(0, 'general.minimun_required'),
            }),
          )
          .required(translate('general.required'))
          .typeError(translate('general.required'))
          .min(1, translate('general.minimun_required'))
          .nullable(),
      }),
    auctionRequest: Yup.object()
      .nullable()
      .notRequired()
      .when('isForAuction', {
        is: (isForAuction) => isForAuction,
        then: Yup.object({
          startPrice: Yup.number()
            .required(translate('general.required'))
            .typeError(translate('general.number_required')),
          startDate: Yup.string().nullable().required(translate('general.required')),
          endDate: Yup.string().nullable().required(translate('general.required')),
        }),
      })
      .default(undefined),
  })

export default ManageProducts
