import React, { useState, useRef } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Col, Row } from 'antd'

import { useTranslation } from 'react-i18next'

import Button from 'common/components/general/Button'
import Modal from 'common/components/feedback/Modal'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'

import {
  StoreShippingRateForSave,
  StoreShippingRateRequest,
} from 'core/application/dashboard/storeSettings/storeShippingSettings'
import { ShippingRateType } from 'core/domain/store/shippingRateType'

import { User } from 'core/application/account/loginUser'
import { NamedItemInt } from 'core/application/commons/namedItem'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'

export const getShippingRateTypes = (translate: (key: string) => string): NamedItemInt[] => {
  var types: NamedItemInt[] = []
  types.push({
    id: ShippingRateType.Simple,
    name: translate('dashboard.store_settings.shipping_rates.simple'),
  })
  types.push({
    id: ShippingRateType.OrderPrice,
    name: translate('dashboard.store_settings.shipping_rates.order_price'),
  })
  types.push({
    id: ShippingRateType.WeightBased,
    name: translate('dashboard.store_settings.shipping_rates.weight_based'),
  })
  return types
}

const ShippingRateForm: React.FC<ShippingRateFormProps> = ({
  user,
  initialValues,
  visible,
  onCancel,
  onSubmit,
  submitting,
}) => {
  let { t: translate } = useTranslation()
  const [formIsValid, setFormIsValid] = useState<boolean>(false)
  const [storeShippingRateRequest, setStoreShippingRateRequest] = useState<StoreShippingRateRequest>(
    {} as StoreShippingRateRequest,
  )

  const getCurrency = (user: User | null) => {
    return user ? user.currency : ''
  }

  const getWeightUnit = (user: User | null) => {
    return user ? user.weightUnit : ''
  }

  const formFooter = (
    <FormSaveCancelButtons
      disabledSave={!formIsValid}
      onButtonClick={() => onSubmit(storeShippingRateRequest)}
      loading={submitting}
    />
  )

  return (
    <Modal
      onCancel={onCancel}
      visible={visible}
      title={
        initialValues.id
          ? translate('dashboard.store_settings.shipping_rates.edit_title')
          : translate('dashboard.store_settings.shipping_rates.add_title')
      }
      footer={formFooter}
    >
      <Formik
        enableReinitialize={true}
        validationSchema={formValidator(translate)}
        initialValues={initialValues}
        onSubmit={(values) => {}}
        validate={(values: StoreShippingRateForSave) => {
          values.shippingMethodId =
            values.shippingMethodId != null ? values.shippingMethodId : values.availableShippingMethods[0].id
          setFormIsValid(formValidator(translate).isValidSync(values))

          setStoreShippingRateRequest({
            id: values.id,
            shippingZoneId: values.shippingZoneId,
            shippingMethodId: values.shippingMethodId,
            price: values.price ? values.price : 0,
            minimum: values.minimum ? values.minimum : 0,
            maximum: values.maximum ? values.maximum : null,
            rateType: values.rateType,
          } as StoreShippingRateRequest)

          return {}
        }}
      >
        {(formik) => (
          <FormikForm>
            <Row>
              <Col xs={24}>
                <Select
                  label={translate('dashboard.store_settings.shipping_rates.shipping_method')}
                  name="shippingMethodId"
                  placeholder={translate('dashboard.store_settings.shipping_rates.shipping_method')}
                  showArrow={true}
                  defaultValue={
                    initialValues.shippingMethodId
                      ? [initialValues.shippingMethodId]
                      : [initialValues.availableShippingMethods[0].id]
                  }
                >
                  {initialValues.availableShippingMethods.map((shippingMethod) => (
                    <option key={shippingMethod.id} value={shippingMethod.id}>
                      {shippingMethod.name}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <InputNumber
                  label={`${translate('dashboard.store_settings.shipping_rates.price')} (${getCurrency(user)})`}
                  name="price"
                  placeholder={translate('dashboard.store_settings.shipping_rates.price')}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Select
                  label={translate('dashboard.store_settings.shipping_rates.condition')}
                  name="rateType"
                  placeholder={translate('dashboard.store_settings.shipping_rates.condition')}
                  showArrow={true}
                  defaultValue={[initialValues.rateType]}
                >
                  {getShippingRateTypes(translate).map((rateType) => (
                    <option key={rateType.id} value={rateType.id}>
                      {rateType.name}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>
            {formik.values.rateType !== ShippingRateType.Simple && (
              <Row gutter={16}>
                <Col xs={12}>
                  <InputNumber
                    label={`${translate('dashboard.store_settings.shipping_rates.minimum')} (${
                      formik.values.rateType === ShippingRateType.WeightBased ? getWeightUnit(user) : getCurrency(user)
                    })`}
                    name="minimum"
                    placeholder={translate('dashboard.store_settings.shipping_rates.minimum')}
                  />
                </Col>

                <Col xs={12}>
                  <InputNumber
                    label={`${translate('dashboard.store_settings.shipping_rates.maximum')} (${
                      formik.values.rateType === ShippingRateType.WeightBased ? getWeightUnit(user) : getCurrency(user)
                    })`}
                    name="maximum"
                    placeholder={translate('dashboard.store_settings.shipping_rates.no_limit')}
                  />
                </Col>
              </Row>
            )}
          </FormikForm>
        )}
      </Formik>
    </Modal>
  )
}

interface ShippingRateFormProps {
  user: User | null
  initialValues: StoreShippingRateForSave
  visible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  onSubmit: (values: StoreShippingRateRequest) => void | Promise<any>
  submitting: boolean
}

const formValidator = (translate: any) =>
  Yup.object({
    shippingMethodId: Yup.number()
      .nullable()
      .typeError(translate('general.required'))
      .required(translate('general.required')),
    price: Yup.number().notRequired().nullable().min(0, translate('general.minimun_required')),
    minimum: Yup.number()
      .notRequired()
      .nullable()
      .when('rateType', {
        is: (rateType) => rateType != null && rateType !== ShippingRateType.Simple,
        then: Yup.number()
          .required(translate('general.required'))
          .typeError(translate('general.required'))
          .min(0, translate('general.minimun_required')),
      }),
    rateType: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .oneOf(
        [ShippingRateType.Simple, ShippingRateType.WeightBased, ShippingRateType.OrderPrice],
        translate('general.required'),
      ),
  })
export default ShippingRateForm
