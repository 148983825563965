import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import Item from '../../OrderDetails/OrderDetailsItem'
import Category from '../../OrderDetails/OrderCategory'
import { queryRequest } from 'common/RequestUtils'
import GoBack from 'common/components/navigation/GoBack'
import Card from 'common/components/dataDisplay/Card'
import { formatMoney } from 'common/moneyFormatter'

import '../../OrderDetails/OrderDetails.less'
import OrderProductsTable from '../../OrderDetails/OrderProductsTable'
import getAbandonCheckoutDetails, {
  AbandonCheckoutDetails,
} from 'core/application/dashboard/orders/abandonCheckoutDetails'
import { formatDateTime } from 'common/dateFormatters'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'

export const ABANDON_CHECKOUT_DETAILS_PATH = 'abandon-checkout-details'

const AbandonCheckoutDetailsComponent: React.FC = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const { t: translate } = useTranslation()
  const [abandonCheckoutDetails, setAbandonCheckoutDetails] = useState<AbandonCheckoutDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const getOrder = async () => {
    setLoading(true)
    const result = await queryRequest(() => getAbandonCheckoutDetails(id || ''))
    setAbandonCheckoutDetails(result)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      if (!id) {
        history.push(ADMIN_DASHBOARD_PATH)
        return
      }

      await getOrder()
    })()
  }, [id])

  return (
    <>
      {abandonCheckoutDetails && (
        <Skeleton active loading={loading} paragraph={{ rows: 6 }}>
          <Card
            bordered={true}
            title={
              <div className=" flex flex__wrap flex__space_between">
                <div>
                  <GoBack
                    title={`${translate('dashboard.orders.order_details.nav_title')} ${
                      abandonCheckoutDetails.orderNumber
                        ? `#${abandonCheckoutDetails.orderNumber}`
                        : abandonCheckoutDetails.id
                    }`}
                  />
                </div>
              </div>
            }
          >
            <div>
              <Row gutter={10} style={{ background: '#fafafa' }}>
                <Col md={12}>
                  <Row>
                    <Col xs={24} md={24} style={{ background: '#f5f5f5', padding: '1rem 0.5rem' }}>
                      <Category title={translate('dashboard.orders.order_details.order_details')} description={null} />
                    </Col>
                    <Col xs={24} md={24} style={{ padding: '1rem 0.5rem' }}>
                      <div>
                        <Row>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_number')}
                              text={abandonCheckoutDetails.orderNumber ?? ''}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('general.date')}
                              text={formatDateTime(new Date(abandonCheckoutDetails.createdAt as string))}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.payment_method')}
                              text={abandonCheckoutDetails.paymentMethod?.name}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.client_notes')}
                              text={abandonCheckoutDetails.notes ?? ''}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={12}>
                  <Row>
                    <Col xs={24} md={24} style={{ background: '#f5f5f5', padding: '1rem 0.5rem' }}>
                      <Category title={translate('dashboard.orders.order_details.client_details')} description={null} />
                    </Col>
                    <Col xs={24} md={24} style={{ padding: '1rem 0.5rem' }}>
                      <div>
                        <Row>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.email')}
                              text={abandonCheckoutDetails.email ?? ''}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.phone_number')}
                              text={abandonCheckoutDetails.phoneNumber ?? ''}
                            />
                          </Col>
                          <Col span={24}>
                            <Row className="mb-16">
                              <Col span={10} className="color-gray-7">
                                {translate(
                                  abandonCheckoutDetails.hasOnlyDigitalProducts
                                    ? 'dashboard.orders.order_details.billing_address'
                                    : 'dashboard.orders.order_details.address',
                                )}
                              </Col>
                              <Col span={14}>
                                <p className="mb-4">
                                  {abandonCheckoutDetails.firstName ?? ''} {abandonCheckoutDetails.lastName ?? ''}
                                </p>
                                <p className="mb-4">{abandonCheckoutDetails.address ?? ''}</p>
                                <p className="mb-4">{abandonCheckoutDetails.aptSuite ?? ''}</p>
                                <p className="mb-4">
                                  {abandonCheckoutDetails.city ?? ''}, {abandonCheckoutDetails.postalCode ?? ''}
                                </p>
                                <p className="mb-4">{abandonCheckoutDetails.country ?? ''}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs={24} md={24} className="mt-16">
                  <div>
                    <OrderProductsTable
                      order={abandonCheckoutDetails}
                      products={abandonCheckoutDetails.items}
                      translate={translate}
                      currency={abandonCheckoutDetails.currency.name}
                    />
                  </div>
                </Col>
                <Col xs={24} md={24}>
                  <div className="d-flex flex__end">
                    <Col span={8} className="p-8">
                      <div className="flex_space_between">
                        <b>{translate('dashboard.orders.order_details.sub_total')}</b>
                        <p>{`${formatMoney(
                          abandonCheckoutDetails.subTotalPrice,
                          abandonCheckoutDetails.currency.name,
                        )}`}</p>
                      </div>
                      {abandonCheckoutDetails.discountValue > 0 && (
                        <div className="flex_space_between">
                          <b>
                            {`${translate('dashboard.orders.order_details.cupon')}(${
                              abandonCheckoutDetails.discountCode
                            })`}
                          </b>
                          <p>{`${formatMoney(
                            -abandonCheckoutDetails.discountValue,
                            abandonCheckoutDetails.currency.name,
                          )}`}</p>
                        </div>
                      )}
                      {abandonCheckoutDetails.deliveryCost > 0 && (
                        <div className="flex_space_between">
                          <b>
                            {translate(
                              abandonCheckoutDetails.hasOnlyDigitalProducts
                                ? 'dashboard.orders.order_details.fees'
                                : 'dashboard.orders.order_details.shipping',
                            )}
                          </b>
                          <p>{`${formatMoney(
                            abandonCheckoutDetails.deliveryCost,
                            abandonCheckoutDetails.currency.name,
                          )}`}</p>
                        </div>
                      )}
                      <div className="flex_space_between">
                        <b>{translate('dashboard.orders.order_details.total_price')}</b>
                        <p>{`${formatMoney(
                          abandonCheckoutDetails.totalPrice,
                          abandonCheckoutDetails.currency.name,
                        )}`}</p>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Skeleton>
      )}
    </>
  )
}

export default AbandonCheckoutDetailsComponent
