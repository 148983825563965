import React from 'react'
import { FileWordOutlined } from '@ant-design/icons'
import FileDisplayProps from './FileDisplayProps'

interface DisplayWordProps extends FileDisplayProps {}

export const DisplayWord: React.FC<DisplayWordProps> = ({ fileUrl }) => (
  <div className={'mb-16'}>
    <a href={fileUrl} target="_blank">
      <FileWordOutlined style={{ fontSize: '64px' }} />
    </a>
  </div>
)

export default DisplayWord
