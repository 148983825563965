import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useQueryRequestV2 } from 'common/hooks'

import { CompanyRequest, CompanyResponse, deleteCompany, getCompanies, PagedList } from 'core'

import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import { formatDateTimeFromString } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { Table } from 'common/components'

const Companies = () => {
  const history = useHistory()
  const { t: translate } = useTranslation()

  const [filter, setFilter] = useState<CompanyRequest>({
    pageNumber: 1,
    pageSize: 30,
    orderColumn: 'createdAt',
    orderDescending: true,
  })

  const {
    data: pageItems,
    isLoading,
    sendQuery,
  } = useQueryRequestV2<PagedList<CompanyResponse>>({
    initialState: { items: [], pageCount: 1, pageNumber: 1, pageSize: 25, totalItemCount: 0 },
  })

  const refreshTable = async () => {
    await sendQuery(() => getCompanies(filter))
  }

  useEffect(() => {
    ;(async () => {
      await refreshTable()
    })()
  }, [filter])

  const columns: any[] = [
    {
      title: translate('general.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.companies.table.identification_code'),
      dataIndex: 'identificationCode',
      key: 'identificationCode',
      align: 'center',
      responsive: ['md'],
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.companies.table.phone_number'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      align: 'center',
      responsive: ['md'],
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: translate('dashboard.created_at'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      responsive: ['md'],
      sorter: true,
      sortDirections: ['descend', 'ascend'],
      render: (_: string, record: CompanyResponse) => formatDateTimeFromString(record.createdAt),
    },
    {
      title: translate('dashboard.action'),
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      sorter: false,
      render: (_: string, record: CompanyResponse) => (
        <div className="flex-center">
          <TableEditButton className="mr-16" editUrl={history.location.pathname + `/manage/${record.id}`} />
          <TableDeleteButton onDelete={() => deleteCompany(record.id)} onDeleted={() => refreshTable()} />
        </div>
      ),
    },
  ]

  const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
  columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Card
      bordered
      title={translate('dashboard.companies.title')}
      extra={
        <Button type="primary" icon="far fa-plus" onClick={() => history.push(history.location.pathname + '/manage')}>
          {translate('dashboard.companies.add_company')}
        </Button>
      }
    >
      <Table
        loading={isLoading}
        rowKey={(record) => record.id}
        dataSource={pageItems?.items ?? []}
        columns={columns}
        onChange={onTableChange}
        pagination={{
          total: pageItems?.totalItemCount,
          pageSize: pageItems?.pageSize,
          current: pageItems?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </Card>
  )
}

export default Companies
