import { Popconfirm } from 'antd'
import { DeleteTwoTone } from '@ant-design/icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FileDisplayProps from './FileDisplayProps'

interface DisplayVideoProps extends FileDisplayProps {
  deleteAttachment: () => void
}

const DisplayVideo: React.FC<DisplayVideoProps> = ({ fileUrl, deleteAttachment }) => {
  const { t } = useTranslation()

  return (
    <div className={'mb-16'}>
      <video controls width="320" height="240" src={fileUrl}>
        Your browser does not support the
        <code>video</code> element.
      </video>
      <br />
      <Popconfirm
        title={'Are you sure you want to delete'}
        onConfirm={() => deleteAttachment()}
        okText={t('general.ok_text')}
        cancelText={t('general.cancel_text')}
      >
        <div className="text-center pointer">
          <DeleteTwoTone />
        </div>
      </Popconfirm>
    </div>
  )
}

export default DisplayVideo
