import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Categories from './Categories'
import CategoryForm, { MANAGE_CATEGORY_PATH } from './ManageCategory'
import NotFound from '../../errorPages/NotFound'
import ManageCategoryTranslation, { MANAGE_CATEGORY_TRANSLATION_PATH } from './ManageCategoryTranslation'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route
        path={`${match.path}/${MANAGE_CATEGORY_TRANSLATION_PATH}/:categoryId/:languageId`}
        component={ManageCategoryTranslation}
      />
      <Route path={`${match.path}/${MANAGE_CATEGORY_PATH}/:id?`} component={CategoryForm} />
      <Route path={`${match.path}`} component={Categories} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
