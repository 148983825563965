import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItem } from '../../commons/namedItem'

export const deleteProductTranslation = (productId: string, languageId: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/productstranslations', {
    data: { productId: productId, languageId: languageId },
  })
}

export interface ProductTranslation {
  productId: string
  languageId: string
  name: string | null
  defaultName: string
  description?: string
  type: string | null
  defaultType: string
  vendor: string | null
  defaultVendor: string
  seoTitle: string | null
  defaultSeoTitle: string
  seoDescription: string | null
  seoKeywords: string
  seoUrl: string
  tags: NamedItem[]
  parentProductId: string | null
  hasRelatedProducts: boolean
  attributes: ProductAttributeWithValues[]
  defaultRelatedProductText: string | null
  relatedProductText: string | null
}

export interface ProductAttributeWithValues {
  id: string
  name: string
  defaultName: string
  values: NamedItemWithDefault[]
}

export interface NamedItemWithDefault {
  id: string
  name: string
  defaultName: string
}

export const getProductTranslation = (productId: string, languageId: string): Promise<ProductTranslation> => {
  return adminHttpClient.get(`/admindashboard/productstranslations/forsave/${productId}/${languageId}`)
}

export interface ProductTranslationToSave {
  productId: string
  languageId: string
  name: string
  description?: string
  type: string | null
  vendor: string | null
  seoTitle: string | null
  seoDescription: string | null
  seoKeywords: string
  seoUrl: string
  tags: NamedItem[]
  attributes: ProductAttributeWithValuesToSave[]
  relatedProductText: string | null
}

export interface ProductAttributeWithValuesToSave {
  id: string
  name: string
  values: NamedItem[]
}

export const saveProductTranslation = (translation: ProductTranslationToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/productstranslations', translation)
}
