import React from 'react'
import { useTranslation } from 'react-i18next'
import { message, Modal } from 'antd'
import { commandRequest } from '../../RequestUtils'
import Button from '../general/Button'

const { confirm } = Modal

interface TableDeleteButtonProps {
  onDelete: () => void
  onDeleted: () => void
  disableDeleted?: boolean
  deleteTitle?: string
  title?: string
  content?: string
  visible?: boolean
}

const TableDeleteButton: React.FC<TableDeleteButtonProps> = (props) => {
  const { t: translate } = useTranslation()
  return (
    <>
      <Button
        size="small"
        className={'error-button'}
        disabled={props.disableDeleted}
        icon="far fa-trash-alt"
        iconPadding={false}
        onClick={() => {
          confirm({
            title: props.title ?? translate('general.delete_alert_title'),
            content: props.content ?? translate('general.delete_alert_content'),
            okText: translate('general.ok_text'),
            cancelText: translate('general.cancel_text'),
            onOk: async () => {
              const result = await commandRequest(() => props.onDelete())
              if (!result.errors) {
                props.onDeleted()
              } else {
                message.error(result.errors[0])
              }
            },
          })
        }}
      >
        {props.deleteTitle}
      </Button>
    </>
  )
}

export default TableDeleteButton
