import React, { useEffect, useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'

import { useHistory, useParams } from 'react-router-dom'
import { getBlogCategoriesForSave } from 'core/application/dashboard/blog/categories/saveBlogCategory'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { Col, Row, Skeleton } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from '../../../common/components/dataEntry/formik/FormikInputNumberField'
import saveBlogCategory from 'core/application/dashboard/blog/categories/saveBlogCategory'
import { Notification } from 'common/genericNotification'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import TreeSelect from 'common/components/dataEntry/formik/FormikTreeSelectField'

export const MANAGE_ARTICLE_PATH = 'manage-article'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().required(translate('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
    priority: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .min(0, translate('general.minimun_required')),
  })
export const MANAGE_BLOG_CATEGORY_PATH = 'manage-blog-category'

const ManageBlogCategory: React.FC = () => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<any>({})
  const { id } = useParams<any>()
  const [_, setCategoriesOptions] = useState([])

  useEffect(() => {
    updateForm()
  }, [])

  const updateForm = async () => {
    setLoading(true)
    const article = await queryRequest(() => getBlogCategoriesForSave(id))
    setCategoriesOptions(article.availableCategories)
    setItem(article)
    setLoading(false)
  }

  const onSubmit = async (values: any) => {
    const request = {
      id: values.id,
      name: values.name,
      friendlyUrl: values.friendlyUrl,
      photoId: values.photoId,
      priority: values.priority,
      parentId: values.parentId,
      leading: true,
      color: values.color,
    }
    const result = await commandRequest(() => saveBlogCategory(request))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.the_category')))
  }
  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.categories.edit') : translate('dashboard.categories.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.categories.name')}
                        name="name"
                        placeholder={translate('dashboard.categories.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.blog.categories.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.blog.categories.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}(${translate('general.optional')})`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <TreeSelect
                        label={translate('dashboard.blog.categories.parent')}
                        name="parentId"
                        placeholder={translate('dashboard.blog.categories.parent')}
                        allowClear
                        showArrow
                        showSearch
                        defaultValue={[item.parentId]}
                        treeData={item.availableParents !== undefined && item.availableParents}
                        treeDefaultExpandAll
                        treeNodeFilterProp="title"
                      ></TreeSelect>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input name="color" label="Color" placeholder="Color" />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageBlogCategory
