import { adminHttpClient } from '../../commons/adminHttpClient'

export type StoreTemplateSettings = {
  logo: string
  primaryColor: string
  favicon: string
}

export const getStoreTemplateSettings = (): Promise<StoreTemplateSettings> => {
  return adminHttpClient.get('/admindashboard/storetemplatesettings')
}
export const updateStoreTemplateSettings = (data: StoreTemplateSettings) => {
  return adminHttpClient.put('/admindashboard/storetemplatesettings', data)
}
