import React, { useState, useEffect } from 'react'
import { queryRequest } from 'common/RequestUtils'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'
import { useSelector } from 'react-redux'
import getTopProductsSold, {
  ProductSales,
  TopProductsRequest,
} from 'core/application/dashboard/home/statistics/topProductsStatistics'
import { ADMIN_DASHBOARD_PATH } from '../routes/StoreRoutes'
import { PRODUCTS_PATH } from '../products/Products'
import { MANAGE_PRODUCTS_PATH } from '../products/ManageProduct'
import { Table } from 'antd'

const TopProductsList: React.FC = ({}) => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const [items, setItems] = useState<ProductSales[]>([])
  const [searchFilter, setSearchFilter] = useState<TopProductsRequest>({})
  const [loading, setLoading] = useState<boolean>(false)

  const updateTable = async (searchFilter: TopProductsRequest) => {
    setLoading(true)
    const result = await queryRequest(() => getTopProductsSold(searchFilter))
    setItems(result)
    setLoading(false)
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setSearchFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  useEffect(() => {
    ;(async () => {
      await updateTable(searchFilter)
    })()
  }, [searchFilter])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'productName',
        key: 'productName',
        sorter: (a: ProductSales, b: ProductSales) => a.productName.localeCompare(b.productName),
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: ProductSales) => (
          <span>
            <div
              className="cursor_pointer"
              onClick={() =>
                history.push(`${ADMIN_DASHBOARD_PATH}${PRODUCTS_PATH}/${MANAGE_PRODUCTS_PATH}/${record.productId}`)
              }
            >
              <span>{record.productName}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.products.unitsSold'),
        dataIndex: 'unitsSold',
        key: 'unitsSold',
        sorter: (a: ProductSales, b: ProductSales) => a.unitsSold - b.unitsSold,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: ProductSales) => (
          <span>
            <div>
              <span>{record.unitsSold}</span>
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <>
      <DateRangePicker
        onChange={onDateChange}
        placeholder={[translate('general.start_date'), translate('general.end_date')]}
        className="mb-16"
      />

      <Table
        rowKey={(record) => record.productId}
        dataSource={items}
        loading={loading}
        columns={getColumns()}
        pagination={false}
      />
    </>
  )
}

export default TopProductsList
