import React from 'react'

import { Table as AntTable, TablePaginationConfig } from 'antd'

import { ColumnType } from 'antd/lib/table'

export type SizeType = 'small' | 'middle' | 'large' | undefined

export type TablePagination = TablePaginationConfig

export type TableColumns = (ColumnType<any> & { isStatic?: boolean })[]

type TableProps = {
  dataSource: object[]
  columns: TableColumns
  className?: string
  scroll?: {
    x?: number | true | string
    y?: number | string
  }
  size?: SizeType
  pagination?: false | TablePaginationConfig
  onChange?: (pagination: TablePagination, filters: any, sorter: any) => void
  loading?: boolean
  rowKey?: string | ((record: any) => string)
  onRow?: any
  footer?: any
  rowSelection?: any
} & ({ canFilterColumns?: false; name?: string } | { canFilterColumns: true; name: string })

export default function Table(props: TableProps) {
  return <AntTable {...props} />
}
