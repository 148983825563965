import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Skeleton, notification, Select as AntSelect } from 'antd'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Card from 'common/components/dataDisplay/Card'
import { Formik, Form as FormikForm } from 'formik'
import { formValidator } from './ServiceValidator'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import saveServices, {
  getServicesForSave,
  ServiceForSaveDto,
  ServiceStatus,
} from 'core/application/dashboard/services/manageServices'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import Textarea from 'common/components/dataEntry/formik/FormikTextAreaField'
import useQueryRequest from '../../common/hooks/useQueryRequest'
import useCommandRequest from '../../common/hooks/useCommandRequest'

const { Option } = AntSelect

const ManageServices: React.FC = () => {
  const history = useHistory()
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()

  const [initialValues] = useQueryRequest<ServiceForSaveDto | null>(null, () => getServicesForSave(id))
  const [sendCommand, isCommandLoading] = useCommandRequest()

  const onSubmit = async (values: any) => {
    const request = {
      ...values,
      parentId: values.parentId != null && values.parentId != '' ? values.parentId : null,
    }

    const result = await sendCommand(() => saveServices(request))

    if (result.completed) {
      notification.success({ message: `Service ${id ? 'edited' : 'saved'} successfully!` })
      history.goBack()
    }
  }

  return (
    <Skeleton active loading={!initialValues}>
      {initialValues && (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.services.edit') : translate('dashboard.services.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.name')}
                        name="name"
                        placeholder={translate('general.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>

                  <Select
                    label="Parent"
                    name="parentId"
                    showOptionalLabel={true}
                    defaultValue={initialValues?.parentId}
                    showSearch
                    placeholder="Parent"
                  >
                    <Option key={'0'} value={''}>
                      No parent
                    </Option>
                    {initialValues?.availableParents.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>

                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'description'}
                        placeholder={translate('dashboard.description')}
                        value={initialValues?.description}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <Textarea
                        label={translate('dashboard.short_description')}
                        name="shortDescription"
                        placeholder={translate('dashboard.short_description')}
                        rows={6}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={translate('dashboard.photo')}
                        initialValues={[initialValues.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={translate('dashboard.icon')}
                        initialValues={[initialValues.iconId]}
                        name="iconId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="iconId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row gutter={10}>
                    <Col xs={24} md={8}>
                      <Uploader
                        label={`${translate('dashboard.additional_photos')}`}
                        initialValues={initialValues.additionalPhotoIds}
                        name="additionalPhotoIds"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="additionalPhotoIds"
                        optimizedResize={true}
                        mode="multiple"
                        maxUploads={5}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <Uploader
                        label={`${translate('dashboard.thumbnail')}`}
                        initialValues={initialValues.thumbnailPhotoId ? [initialValues.thumbnailPhotoId] : []}
                        name="thumbnailPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="thumbnailPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <Uploader
                        label={`${translate('dashboard.the_banner')}`}
                        initialValues={initialValues.bannerPhotoId ? [initialValues.bannerPhotoId] : []}
                        name="bannerPhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="bannerPhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [initialValues.status] : []}
                      >
                        {ServiceStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.seo_title')}
                        name="seoTitle"
                        placeholder={translate('dashboard.seo_title')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={translate('seoImage')}
                        initialValues={[initialValues.seoImage]}
                        name="seoImage"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="seoImage"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Textarea
                        label={translate('dashboard.seo_description')}
                        name="seoDescription"
                        placeholder={translate('dashboard.seo_description')}
                        rows={6}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={isCommandLoading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageServices
