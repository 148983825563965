import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Gallery from './GalleryItem'
import NotFound from '../../../errorPages/NotFound'
import ManageGalleryItem, { MANAGE_GALLERY_ITEM_PATH } from './ManageGalleryItem'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_GALLERY_ITEM_PATH}/:galleryId/:id?`} component={ManageGalleryItem} />
      <Route path={`${match.path}/:id`} component={Gallery} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
