import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import saveGalleryItem, {
  GalleryItemForSaveDto,
  getGalleryItemForSave,
} from 'core/application/dashboard/galleries/galleryItems/manageGalleryItem'
import { Formik, Form as FormikForm } from 'formik'
import { Row, Col, Skeleton } from 'antd'
import Input from '../../../common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from '../../../common/RequestUtils'
import Card from '../../../common/components/dataDisplay/Card'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { Notification } from 'common/genericNotification'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'

export const MANAGE_GALLERY_ITEM_PATH = 'manage-gallery-item'

const ManageGalleryItem = () => {
  const { t: translate } = useTranslation()
  const { id, galleryId } = useParams<any>()
  const [item, setItem] = useState<GalleryItemForSaveDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      setItem(await queryRequest(() => getGalleryItemForSave(id)))
      setLoading(false)
    })()
  }, [id])

  const onSubmit = async (data: any) => {
    const request = {
      ...data,
      id: id,
      galleryId: galleryId,
    }

    setIsSaving(true)
    const result = await commandRequest(() => saveGalleryItem(request))
    setIsSaving(false)

    Notification(result, translate('general.saved').replace('[]', translate('dashboard.galleries.name')))
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.galleries.edit') : translate('dashboard.galleries.add')}
              >
                <FormikForm>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.thumbnail')}`}
                        initialValues={[item.fileThumbnailId]}
                        name="fileThumbnailId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="fileThumbnailId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}`}
                        initialValues={[item.fileId]}
                        name="fileId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="fileId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={`${translate('general.title')}(${translate('general.optional')})`}
                        name="title"
                        placeholder={translate('general.title')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values) || isSaving}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={isSaving}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

const formValidator = (translate: any) =>
  Yup.object({
    fileId: Yup.string().nullable().trim().required(translate('general.required')),
    fileThumbnailId: Yup.string().nullable().trim().required(translate('general.required')),
  })

export default ManageGalleryItem
