import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'

import Input from 'common/components/dataEntry/formik/FormikInputField'

import { commandRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'

import {
  getStoreSiteSettings,
  StoreSiteSettings,
  StoreSiteSettingsForSave,
  updateStoreSiteSettings,
} from 'core/application/dashboard/storeSettings/storeSiteSettings'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import Uploader from 'common/components/dataEntry/formik/upload/FormikUploaderField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'
import useQueryRequest from '../../../common/hooks/useQueryRequest'

import getLoginUser, { User } from 'core/application/account/loginUser'
import useLoginUser from '../../../common/hooks/useLoginUser'

export const STORE_SITE_SETTINGS_PATH = 'store-site-settings'

const formValidator = (translate: any) =>
  Yup.object({
    seoTitle: Yup.string().nullable().trim().notRequired(),
    seoKeywords: Yup.string().nullable().trim().notRequired(),
    seoDescription: Yup.string()
      .nullable()
      .trim()
      .notRequired()
      .max(140, translate('general.max_length').replace('[]', 140)),
    seoSocialSharingImage: Yup.string().nullable().trim().notRequired(),
    defaultTimezone: Yup.string().nullable().trim().notRequired(),
    enableRecaptcha: Yup.boolean().nullable().notRequired(),
    showGdprPolicy: Yup.boolean().nullable().notRequired(),
    facebookPixel: Yup.string().nullable().trim().notRequired(),
    googleAnalyticsCode: Yup.string().nullable().trim().notRequired(),
    googleTagManagerCode: Yup.string().nullable().trim().notRequired(),
    googleAnalyticsClientId: Yup.string().nullable().trim().notRequired(),
    googleAnalyticsViewId: Yup.string().nullable().trim().notRequired(),
    industryId: Yup.number().nullable().notRequired(),
    coordinates: Yup.string().nullable().notRequired(),
    measurementUnitId: Yup.number().nullable().notRequired(),
  })

interface StoreSiteSettingsFormProps {}

const StoreSiteSettingsForm: React.FC<StoreSiteSettingsFormProps> = () => {
  const { t } = useTranslation()

  const [item] = useQueryRequest<StoreSiteSettingsForSave | null>(null, () => getStoreSiteSettings())
  const [loadingSave, setLoadingSave] = useState<boolean>(false)
  const [, , sendGetUserLoginRequest] = useQueryRequest<User | null>(null)
  const [, setUser] = useLoginUser()

  const onSubmit = async (values: StoreSiteSettingsForSave) => {
    setLoadingSave(true)

    const result = await commandRequest(() =>
      updateStoreSiteSettings({
        seoTitle: values.seoTitle,
        seoDescription: values.seoDescription,
        seoKeywords: values.seoKeywords,
        seoSocialSharingImage: values.seoSocialSharingImage,
        defaultTimezone: values.defaultTimezone,
        enableRecaptcha: values.enableRecaptcha,
        showGdprPolicy: values.showGdprPolicy,
        facebookPixel: values.facebookPixel,
        googleAnalyticsCode: values.googleAnalyticsCode,
        googleAnalyticsClientId: values.googleAnalyticsClientId,
        googleAnalyticsViewId: values.googleAnalyticsViewId,
        googleTagManagerCode: values.googleTagManagerCode,
        industryId: values.industryId,
        coordinates: values.coordinates,
        measurementUnitId: values.measurementUnitId,
        googleAnalyticsScriptCode: values.googleAnalyticsScriptCode,
        googleTagManagerScriptCode: values.googleTagManagerScriptCode,
        recaptchaSiteKey: values.recaptchaSiteKey,
        recaptchaSecretKey: values.recaptchaSecretKey,
      } as StoreSiteSettings),
    )

    if (!result.errors) {
      const user = await sendGetUserLoginRequest(() => getLoginUser())
      setUser(user)

      notification.open({
        message: t('general.saved_plural').replace('[]', t('dashboard.store_settings.site_settings')),
        type: 'success',
      })
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
    setLoadingSave(false)
  }

  return (
    <Skeleton active loading={!item} paragraph={{ rows: 6, className: 'color-gray-5' }} title={false}>
      {item && (
        <Formik enableReinitialize={true} initialValues={item} validationSchema={formValidator(t)} onSubmit={() => {}}>
          {(formik) => (
            <Card bordered={false} title={t('dashboard.store_settings.edit_site_settings')}>
              <FormikForm>
                <Card
                  className="small-card"
                  bordered={false}
                  title={t('dashboard.store_settings.site_settings_form.seo')}
                >
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.store_settings.site_settings_form.seo_title')}
                        name="seoTitle"
                        placeholder={t('dashboard.store_settings.site_settings_form.seo_title')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.store_settings.site_settings_form.seo_keywords')}
                        name="seoKeywords"
                        placeholder={t('dashboard.store_settings.site_settings_form.seo_keywords')}
                      />
                    </Col>
                    <Row>
                      <Col xs={24} md={24}>
                        <Uploader
                          label={`${t(
                            'dashboard.store_settings.site_settings_form.seo_social_sharing_image',
                          )}(1200x630)`}
                          initialValues={[item.seoSocialSharingImage]}
                          name="seoSocialSharingImage"
                          resizeToWidth={600}
                          resizeToHeight={315}
                          fieldName="seoSocialSharingImage"
                          optimizedResize={true}
                          mode="single"
                          maxUploads={1}
                        />
                      </Col>
                    </Row>
                    <Row className="full_width">
                      <Col xs={24} md={24}>
                        <FormikTextAreaField
                          maxLength={140}
                          label={t('dashboard.store_settings.site_settings_form.seo_description')}
                          name="seoDescription"
                          placeholder={t('dashboard.store_settings.site_settings_form.seo_description')}
                        />
                      </Col>
                    </Row>
                  </Row>
                </Card>
                <Card
                  className="small-card"
                  bordered={false}
                  title={t('dashboard.store_settings.site_settings_form.analytics')}
                >
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.store_settings.site_settings_form.facebook_pixel')}
                        name="facebookPixel"
                        placeholder={t('dashboard.store_settings.site_settings_form.facebook_pixel')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t('dashboard.store_settings.site_settings_form.google_analytics_view_id')}
                        name="googleAnalyticsViewId"
                        placeholder={t('dashboard.store_settings.site_settings_form.google_analytics_view_id')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t('dashboard.store_settings.site_settings_form.google_analytics_code')}
                        name="googleAnalyticsCode"
                        placeholder={t('dashboard.store_settings.site_settings_form.google_analytics_code')}
                      />
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t("dashboard.store_settings.site_settings_form.google_analytics_client_id")}
                        name="googleAnalyticsClientId"
                        placeholder={t("dashboard.store_settings.site_settings_form.google_analytics_client_id")}
                      />
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t('dashboard.store_settings.site_settings_form.google_analytics_script_code')}
                        name="googleAnalyticsScriptCode"
                        placeholder={t('dashboard.store_settings.site_settings_form.google_analytics_script_code')}
                        rows={5}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t('dashboard.store_settings.site_settings_form.google_tag_manager_code')}
                        name="googleTagManagerCode"
                        placeholder={t('dashboard.store_settings.site_settings_form.google_tag_manager_code')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} md={24}>
                      <FormikTextAreaField
                        label={t('dashboard.store_settings.site_settings_form.google_tag_manager_script_code')}
                        name="googleTagManagerScriptCode"
                        placeholder={t('dashboard.store_settings.site_settings_form.google_tag_manager_script_code')}
                        rows={5}
                      />
                    </Col>
                  </Row>
                </Card>
                {/* <Card
                    bordered={false}
                    title={translate(
                      "dashboard.store_settings.site_settings_form.industry"
                    )}
                  >
                    <Row>
                      <Col xs={24} md={24}>
                        <Select
                          label={translate(
                            "dashboard.store_settings.site_settings_form.industry"
                          )}
                          name="industryId"
                          showArrow={true}
                          placeholder={translate(
                            "dashboard.store_settings.site_settings_form.industry"
                          )}
                          defaultValue={
                            item.industryId
                              ? [item.industryId]
                              : [item.availableIndustries[0].id]
                          }
                        >
                          {item.availableIndustries.map(industry => (
                            <option key={industry.id} value={industry.id}>
                              {industry.name}
                            </option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Card> */}
                <Card
                  className="small-card"
                  bordered={false}
                  title={t('dashboard.store_settings.site_settings_form.mapCoordinates')}
                >
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.store_settings.site_settings_form.mapCoordinates')}
                        name="coordinates"
                        placeholder={t('dashboard.store_settings.site_settings_form.mapCoordinates')}
                      />
                    </Col>
                  </Row>
                </Card>

                <Card className="small-card" bordered={false} title={t('dashboard.recaptcha.title')}>
                  <Row>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.recaptcha.siteKey')}
                        name="recaptchaSiteKey"
                        placeholder={t('dashboard.recaptcha.siteKey')}
                      />
                    </Col>
                    <Col xs={24} md={24}>
                      <Input
                        label={t('dashboard.recaptcha.secretKey')}
                        name="recaptchaSecretKey"
                        placeholder={t('dashboard.recaptcha.secretKey')}
                      />
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="small-card"
                  bordered={false}
                  title={t('dashboard.store_settings.site_settings_form.measurement_unit')}
                >
                  <Row>
                    <Col xs={24} md={24}>
                      <Select
                        label={t('dashboard.store_settings.site_settings_form.measurement_unit')}
                        name="measurementUnitId"
                        showArrow={true}
                        placeholder={t('dashboard.store_settings.site_settings_form.measurement_unit')}
                        defaultValue={
                          item.measurementUnitId ? [item.measurementUnitId] : [item.availableMeasurements[0].id]
                        }
                      >
                        {item.availableMeasurements.map((unit) => (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Card>

                <FormSaveCancelButtons
                  disabledSave={!formValidator(t).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loadingSave}
                  showCancelButton={false}
                />
              </FormikForm>
            </Card>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default StoreSiteSettingsForm
