import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router'
import { Row, Col, Dropdown, Skeleton, Tag, Button } from 'antd'
import { useTranslation } from 'react-i18next'

import Item from './OrderDetailsItem'
import Category from './OrderCategory'
import { ADMIN_DASHBOARD_PATH } from '../../routes/StoreRoutes'
import { queryRequest, commandRequest } from '../../../common/RequestUtils'
import GoBack from '../../../common/components/navigation/GoBack'
import Card from '../../../common/components/dataDisplay/Card'
import OrderStatusActions, { orderTagColorMap } from '../OrderStatusActions'
import notification from '../../../common/components/feedback/Notification'
import Icon from '../../../common/components/general/Icon'
import { getOrderTitleMap } from '../OrderStatusActions'
import { formatMoney } from '../../../common/moneyFormatter'

import updateStatus, { OrderStatusToUpdate } from 'core/application/dashboard/home/orders/updateOrderStatus'
import { OrderStatus } from 'core/domain/order/orderStatus'

import './OrderDetails.less'
import OrderProductsTable from './OrderProductsTable'
import getOrderDetails, { OrderDetails } from 'core/application/dashboard/home/orders/orderDetails'
import { formatDate, formatDateTime } from 'common/dateFormatters'
import { PrinterOutlined } from '@ant-design/icons'
import { useReactToPrint } from 'react-to-print'

export const ORDER_DETAILS_PATH = 'order-details'

const OrderDetailsComponent: React.FC = () => {
  const { id } = useParams<any>()
  const history = useHistory()
  const { t: translate } = useTranslation()
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const componentRef = React.useRef<any>()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
  })

  const getOrder = async () => {
    setLoading(true)
    const result = await queryRequest(() => getOrderDetails(id || ''))
    setOrderDetails(result)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      if (!id) {
        history.push(ADMIN_DASHBOARD_PATH)
        return
      }

      await getOrder()
    })()
  }, [id])

  const getOrderStatus = (): string | null | undefined => {
    const status = orderDetails && orderDetails.availableStatuses.find((o) => +o.id === orderDetails.order.status)

    if (!status) {
      return null
    }

    const orderStatus = +status.id as OrderStatus
    const title: string | undefined = getOrderTitleMap(translate).get(orderStatus)

    return title
  }

  const updateOrderStatus = async (order: OrderStatusToUpdate) => {
    const result = await commandRequest(() => updateStatus(order))
    if (!result.errors) {
      notification.open({
        type: 'success',
        message: translate('general.saved').replace('[]', translate('dashboard.the_order_status')),
      })
      await getOrder()
    } else {
      notification.open({ type: 'error', message: result.errors[0] })
    }
  }

  return (
    <>
      {orderDetails && (
        <Skeleton active loading={loading} paragraph={{ rows: 6 }}>
          <Card
            bodyStyle={{ padding: '0' }}
            bordered={true}
            title={
              <div className=" flex flex__wrap flex__space_between">
                <div>
                  <GoBack
                    title={`${translate('dashboard.orders.order_details.nav_title')} #${
                      orderDetails.order.orderNumber
                    }`}
                  />
                </div>
                <div>
                  <div className="inline_block mr-8">
                    <Tag color={orderTagColorMap.get(orderDetails.order.status)}>
                      {getOrderTitleMap(translate).get(orderDetails.order.status)}
                    </Tag>
                  </div>
                  <div className="inline_block mr-8">
                    <Button onClick={handlePrint} icon={<PrinterOutlined />} />
                  </div>
                  <Dropdown
                    overlay={
                      <OrderStatusActions
                        id={orderDetails.order.id}
                        updateOrderStatus={updateOrderStatus}
                        hasOnlyDigitalProducts={orderDetails.order.hasOnlyDigitalProducts}
                      />
                    }
                  >
                    <a className="ant-dropdown-link more_actions_icon">
                      <Icon type="far fa-ellipsis-h" />
                    </a>
                  </Dropdown>
                </div>
              </div>
            }
          >
            <div ref={componentRef} className="pt-8 pl-16 pr-16">
              <Row gutter={10} style={{ background: '#fafafa' }}>
                <Col md={12}>
                  <Row>
                    <Col xs={24} md={24} style={{ background: '#f5f5f5', padding: '1rem 0.5rem' }}>
                      <Category title={translate('dashboard.orders.order_details.order_details')} description={null} />
                    </Col>
                    <Col xs={24} md={24} style={{ padding: '1rem 0.5rem' }}>
                      <div>
                        <Row>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_number')}
                              text={orderDetails.order.orderNumber}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('general.date')}
                              text={formatDateTime(new Date(orderDetails.order.createdAt as string))}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.payment_method')}
                              text={orderDetails.order.paymentMethod.name}
                            />
                          </Col>
                          <Col span={24}>
                            <div>
                              <Item
                                translate={translate}
                                title={translate('dashboard.orders.order_details.status')}
                                text={getOrderStatus()}
                              />
                            </div>
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.client_notes')}
                              text={orderDetails.order.notes}
                            />
                          </Col>
                          {/* <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate("dashboard.orders.order_details.ip")}
                              text={orderDetails.order.requestMetadata?.ip}
                            />
                          </Col> */}
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.device')}
                              text={orderDetails.order.requestMetadata?.device}
                            />
                          </Col>
                          {/* <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate("dashboard.orders.order_details.browser")}
                              text={orderDetails.order.requestMetadata?.browser}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate("dashboard.orders.order_details.os")}
                              text={orderDetails.order.requestMetadata?.os}
                            />
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col md={12}>
                  <Row>
                    <Col xs={24} md={24} style={{ background: '#f5f5f5', padding: '1rem 0.5rem' }}>
                      <Category title={translate('dashboard.orders.order_details.client_details')} description={null} />
                    </Col>
                    <Col xs={24} md={24} style={{ padding: '1rem 0.5rem' }}>
                      <div>
                        <Row>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.email')}
                              text={orderDetails.order.email}
                            />
                          </Col>
                          <Col span={24}>
                            <Item
                              translate={translate}
                              title={translate('dashboard.orders.order_details.phone_number')}
                              text={orderDetails.order.phoneNumber}
                            />
                          </Col>
                          <Col span={24}>
                            <Row className="mb-16">
                              <Col span={10} className="color-gray-7">
                                {translate(
                                  orderDetails.order.hasOnlyDigitalProducts
                                    ? 'dashboard.orders.order_details.billing_address'
                                    : 'dashboard.orders.order_details.address',
                                )}
                              </Col>
                              <Col span={14}>
                                <p className="mb-4">
                                  {orderDetails.order.firstName} {orderDetails.order.lastName}
                                </p>
                                <p className="mb-4">{orderDetails.order.address}</p>
                                <p className="mb-4">{orderDetails.order.aptSuite ?? ''}</p>
                                <p className="mb-4">
                                  {orderDetails.order.city}, {orderDetails.order.postalCode}
                                </p>
                                <p className="mb-4">{orderDetails.order.country}</p>
                              </Col>
                            </Row>
                          </Col>
                          {!orderDetails.order.hasOnlyDigitalProducts && (
                            <Col span={24}>
                              <Item
                                translate={translate}
                                title={translate('general.shippingInstructions')}
                                text={orderDetails.order.shippingInstructions}
                              />
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xs={24} md={24} className="mt-16">
                  <div>
                    <OrderProductsTable
                      order={orderDetails.order}
                      products={orderDetails.order.items}
                      translate={translate}
                      currency={orderDetails.order.currency.name}
                    />
                  </div>
                </Col>
                <Col xs={24} md={24}>
                  <div className="d-flex flex__end-lg">
                    <Col md={8} xs={24} className="p-8">
                      <div className="flex_space_between">
                        <b>{translate('dashboard.orders.order_details.sub_total')}</b>
                        <p>{`${formatMoney(orderDetails.order.subTotalPrice, orderDetails.order.currency.name)}`}</p>
                      </div>
                      {orderDetails.order.discountValue > 0 && (
                        <div className="flex_space_between">
                          <b>
                            {`${translate('dashboard.orders.order_details.cupon')}(${orderDetails.order.discountCode})`}
                          </b>
                          <p>{`${formatMoney(-orderDetails.order.discountValue, orderDetails.order.currency.name)}`}</p>
                        </div>
                      )}
                      {orderDetails.order.deliveryCost > 0 && (
                        <div className="flex_space_between">
                          <b>
                            {translate(
                              orderDetails.order.hasOnlyDigitalProducts
                                ? 'dashboard.orders.order_details.fees'
                                : 'dashboard.orders.order_details.shipping',
                            )}
                          </b>
                          <p>{`${formatMoney(orderDetails.order.deliveryCost, orderDetails.order.currency.name)}`}</p>
                        </div>
                      )}
                      {orderDetails.order.insuranceCost > 0 && (
                        <div className="flex_space_between">
                          <b>Insurance</b>
                          <p>{`${formatMoney(orderDetails.order.insuranceCost, orderDetails.order.currency.name)}`}</p>
                        </div>
                      )}
                      <div className="flex_space_between">
                        <b>{translate('dashboard.orders.order_details.total_price')}</b>
                        <p>{`${formatMoney(orderDetails.order.totalPrice, orderDetails.order.currency.name)}`}</p>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Skeleton>
      )}
    </>
  )
}

export default OrderDetailsComponent
