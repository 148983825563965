import React, { useState, useEffect } from 'react'
import { Skeleton, Row, Col, Menu, Dropdown, message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { queryRequest, commandRequest } from 'common/RequestUtils'
import {
  StoreShippingZone,
  StoreShippingZoneForSave,
  StoreShippingRateForSave,
  StoreShippingZoneRequest,
  saveShippingZone,
  getStoreShippingZones,
  deleteShippingZone,
  getStoreShippingZoneForSave,
  getStoreShippingRateForSave,
  deleteShippingRate,
  StoreShippingRateRequest,
  saveShippingRate,
} from 'core/application/dashboard/storeSettings/storeShippingSettings'
import { User } from 'core/application/account/loginUser'

import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import Icon from 'common/components/general/Icon'
import notification from 'common/components/feedback/Notification'

import ShippingRatesTable from './shippingRates/ShippingRatesTable'
import ShippingRateForm from './shippingRates/ShippingRateForm'
import ShippingZoneForm from './ShippingZoneForm'
import ShowMoreText from 'common/components/dataDisplay/ShowMoreText'
import useLoginUser from '../../../common/hooks/useLoginUser'

interface ShippingZoneProps {}

const ShippingZones: React.FC<ShippingZoneProps> = () => {
  const { t: translate } = useTranslation()
  const [loadingShippingZones, setLoadingShippingZones] = useState<boolean>(false)
  const [shippingZones, setShippingZones] = useState<StoreShippingZone[]>([])
  const [showShippingRateForm, setShowShippingRateForm] = useState<boolean>(false)
  const [shippingRate, setShippingRate] = useState<StoreShippingRateForSave>()
  const [submittingShippingRate, setSubmittingShippingRate] = useState<boolean>(false)
  const [shippingZone, setShippingZone] = useState<StoreShippingZoneForSave>()
  const [submittingShippingZone, setSubmittingShippingZone] = useState<boolean>(false)
  const [showShippingZoneForm, setShowShippingZoneForm] = useState<boolean>(false)
  const [user] = useLoginUser()
  const { confirm } = Modal

  const onAddShippingRateClick = async (zoneId: string) => {
    await displayShippingRateForm(zoneId, '')
  }

  const displayShippingRateForm = async (zoneId: string, id?: string) => {
    const item = await queryRequest(() => getStoreShippingRateForSave(id))
    item.shippingZoneId = zoneId
    setShippingRate(item)
    setShowShippingRateForm(true)
  }

  const onAddShippingZoneClick = async (zoneId: string) => {
    await displayShippingZoneForm(zoneId)
  }

  const displayShippingZoneForm = async (zoneId?: string) => {
    const item = await queryRequest(() => getStoreShippingZoneForSave(zoneId))
    setShippingZone(item)
    setShowShippingZoneForm(true)
  }

  useEffect(() => {
    getShippingZones()
  }, [])

  const getShippingZones = async () => {
    setLoadingShippingZones(true)
    const result = await queryRequest(() => getStoreShippingZones())
    setShippingZones(result)
    setLoadingShippingZones(false)
  }

  const onConfirmDelete = (onDelete: () => void, onDeleted: () => void) => {
    return confirm({
      title: translate('general.delete_alert_title'),
      content: translate('general.delete_alert_content'),
      okText: translate('general.ok_text'),
      cancelText: translate('general.cancel_text'),
      onOk: async () => {
        const result = await commandRequest(() => onDelete())
        if (!result.errors) {
          onDeleted()
        } else {
          message.error(result.errors[0])
        }
      },
    })
  }

  const onSubmitShippingRate = async (values: StoreShippingRateRequest) => {
    setSubmittingShippingRate(true)
    const result = await commandRequest(() => saveShippingRate(values))
    if (!result.errors) {
      setShowShippingRateForm(false)
      notification.open({
        message: translate('general.saved').replace(
          '[]',
          translate('dashboard.store_settings.shipping_rates.shipping_rate'),
        ),
        type: 'success',
      })
      setShippingRate(undefined)
      await getShippingZones()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setSubmittingShippingRate(false)
  }

  const onSubmitShippingZone = async (values: StoreShippingZoneRequest) => {
    setSubmittingShippingZone(true)
    const result = await commandRequest(() => saveShippingZone(values))
    if (!result.errors) {
      setShowShippingZoneForm(false)
      notification.open({
        message: translate('general.saved').replace(
          '[]',
          translate('dashboard.store_settings.shipping_zones.shipping_zone'),
        ),
        type: 'success',
      })
      setShippingZone(undefined)
      await getShippingZones()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setSubmittingShippingZone(false)
  }

  const menu = (translate: (key: string) => string, onEdit: () => void, onDelete: () => void) => (
    <Menu>
      <Menu.Item onClick={onEdit}>
        <div>
          <Icon type="far fa-pencil" className="mr-8" />
          <span>{translate('general.edit')}</span>
        </div>
      </Menu.Item>
      <Menu.Item onClick={onDelete}>
        <div>
          <Icon type="far fa-trash-alt" className="mr-8" />
          <span>{translate('general.delete')}</span>
        </div>
      </Menu.Item>
    </Menu>
  )

  return (
    <Skeleton
      active
      loading={loadingShippingZones}
      paragraph={{ rows: 6, className: 'p-64 color-gray-5' }}
      title={false}
    >
      <Card
        className="small-card"
        bordered={false}
        title={
          <Row>
            <Col xs={12} md={14} span={16}>
              <h4>{translate('dashboard.store_settings.shipping_zones.shipping_zones')}</h4>
            </Col>
            <Col xs={12} md={10}>
              <div className={'text-right'}>
                <Button icon="far fa-plus" type="primary" onClick={() => onAddShippingZoneClick('')}>
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        {shippingZones.map((zone: StoreShippingZone) => {
          return (
            <Card
              bordered={true}
              title={
                <Row>
                  <Col xs={22} md={22} span={16}>
                    <div>{zone.name}</div>
                    <ShowMoreText
                      className="countries_list"
                      text={zone.countries.join()}
                      length={50}
                      translate={translate}
                    />
                  </Col>
                  <Col xs={2} md={2}>
                    <div className="actions text-right">
                      <Dropdown
                        overlay={menu(
                          translate,
                          () => onAddShippingZoneClick(zone.id),
                          () => onConfirmDelete(() => deleteShippingZone(zone.id), getShippingZones),
                        )}
                        placement="bottomRight"
                      >
                        <div className="dropdown_actions">
                          <Icon type="far fa-ellipsis-h" />
                        </div>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
              }
            >
              {zone.shippingRates && zone.shippingRates.length > 0 && (
                <Row>
                  <Col xs={24} className="mt-16">
                    <ShippingRatesTable
                      user={user}
                      onEdit={async (id) => {
                        await displayShippingRateForm(zone.id, id)
                      }}
                      onDelete={async (id) => {
                        await onConfirmDelete(() => deleteShippingRate(id), getShippingZones)
                      }}
                      shippingRates={zone.shippingRates}
                      loadingShippingRates={loadingShippingZones}
                      translate={translate}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs={24} className="mt-16">
                  <div className={'text-left'}>
                    <Button icon="far fa-plus" type="primary" onClick={() => onAddShippingRateClick(zone.id)}>
                      {translate('dashboard.add')}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          )
        })}
        {shippingRate && (
          <ShippingRateForm
            user={user}
            initialValues={shippingRate}
            visible={showShippingRateForm}
            onCancel={() => {
              setShippingRate(undefined)
              setShowShippingRateForm(false)
            }}
            onSubmit={onSubmitShippingRate}
            submitting={submittingShippingRate}
          />
        )}

        {shippingZone && (
          <ShippingZoneForm
            initialValues={shippingZone}
            visible={showShippingZoneForm}
            onCancel={() => {
              setShippingZone(undefined)
              setShowShippingZoneForm(false)
            }}
            onSubmit={onSubmitShippingZone}
            submitting={submittingShippingZone}
          />
        )}
      </Card>
    </Skeleton>
  )
}

export default ShippingZones
