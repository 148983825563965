import React, { Fragment, useMemo } from 'react'
import { Button, Card, Col, notification, PageHeader, Row } from 'antd'
import InputNumber from '../../../common/components/dataEntry/formik/FormikInputNumberField'
import Input from '../../../common/components/dataEntry/formik/FormikInputField'
import Uploader from '../../../common/components/dataEntry/formik/upload/FormikUploaderField'
import useQueryRequest from '../../../common/hooks/useQueryRequest'
import { DynamicCategoryForSave, dynamicFieldTypeMap, getDynamicCategoryForSave, saveDynamicCategory } from 'core'
import { FieldArray, Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import Select from '../../../common/components/dataEntry/formik/FormikSelectField'
import useCommandRequest from 'common/hooks/useCommandRequest'
import { serializeFieldsDictionary } from '../utlis'
import generateFriendlyUrl from '../../../common/generateFriendlyUrl'
import * as Yup from 'yup'

const validator = Yup.object({
  name: Yup.string().trim().required('Field is required!').nullable(),
  friendlyUrl: Yup.string().trim().required('Field is required!').nullable(),
  fields: Yup.array(
    Yup.object({
      type: Yup.number().required('Field is required!').nullable(),
      value: Yup.string().required('Field is required!').nullable(),
    }),
  ),
})
export default function ManageDynamicCategory() {
  const { id }: any = useParams()
  const { goBack } = useHistory()
  const [mutation] = useCommandRequest()
  const [category, isLoading] = useQueryRequest<DynamicCategoryForSave | null>(null, () =>
    getDynamicCategoryForSave(id),
  )
  const title = id ? 'Update dynamic category' : 'Create dynamic category'
  const hasFields = category?.fields && Object.keys(category.fields || {}).length

  const items = useMemo(
    () => ({
      ...category,
      fields: hasFields ? Object.values(category?.fields) : [{ type: 0, value: '' }],
    }),
    [category, hasFields],
  )

  const submitHandler = async (request: DynamicCategoryForSave) => {
    const payload = { ...request, fields: serializeFieldsDictionary(request.fields) }
    const result = await mutation(() => saveDynamicCategory(payload))
    if (result.completed) {
      notification.success({ message: 'Category created successfully!' })
      goBack()
    }
  }
  return (
    <Card
      loading={isLoading}
      bordered={false}
      title={<PageHeader className="p-0" onBack={() => goBack()} title={title} />}
    >
      <Formik validationSchema={validator} initialValues={items as any} onSubmit={submitHandler}>
        {({ values, setFieldValue }) => (
          <Form>
            <Input
              name="name"
              onBlur={(e: any) => setFieldValue('friendlyUrl', generateFriendlyUrl(e.target?.value))}
              label="Name"
              key="name"
              placeholder="Name"
            />
            <Input name="friendlyUrl" label="Friendly Url" key="friendlyUrl" placeholder="Friendly Url" />
            <InputNumber name="priority" label="Priority" key="priority" placeholder="Priority" />
            <Uploader
              name="photoId"
              initialValues={[items?.photoId || '']}
              label="Photo Id"
              fieldName="photoId"
              resizeToHeight={0}
              resizeToWidth={0}
              mode="single"
              maxUploads={1}
              optimizedResize
            />
            <br />
            <Card type="inner" title={<label htmlFor="fields">Fields</label>}>
              <FieldArray name="fields">
                {({ remove, insert }) => (
                  <Fragment>
                    {values?.fields.map((_: any, index: number) => (
                      <Row gutter={10} align="middle">
                        <Col span={9}>
                          <Select name={`fields[${index}].type`} label="Type" placeholder="Type">
                            {dynamicFieldTypeMap.map((c) => (
                              <option value={c.value}>{c.label}</option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={12}>
                          <Input name={`fields[${index}].value`} label="Value" placeholder="Value" />
                        </Col>
                        <Col span={3}>
                          <Button className="w-100" onClick={() => remove(index)}>
                            Remove
                          </Button>
                        </Col>
                      </Row>
                    ))}
                    <Button
                      onClick={() =>
                        insert(values.fields.length, {
                          type: 0,
                          value: '',
                        })
                      }
                    >
                      Insert
                    </Button>
                  </Fragment>
                )}
              </FieldArray>
            </Card>
            <Select name="parentId" label="Parent" placeholder="Parent">
              {values.availableParents?.map((c) => (
                <option value={c.id}>{c.name}</option>
              ))}
            </Select>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Card>
  )
}
