import useSharedState, { SharedStateKeys } from './useSharedState'
import getLoginUser, { User } from 'core/application/account/loginUser'

const useLoginUserV2 = (): {
  user: User
  setUser: (newState: User) => void
  reloadUser: () => Promise<User>
} => {
  const [user, setUser] = useSharedState<User>(SharedStateKeys.LoginUser)

  return {
    user,
    setUser,
    reloadUser: getLoginUser,
  }
}

export default useLoginUserV2
