import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import getUserCount, {
  AnalyticsReport,
  AnalyticsReportRequest,
} from 'core/application/dashboard/home/analytics/userCount'
import getSessionCount from 'core/application/dashboard/home/analytics/sessionCount'
import getSessionDuration from 'core/application/dashboard/home/analytics/sessionDuration'
import getBounceRates from 'core/application/dashboard/home/analytics/bounceRate'
import getActiveUsers from 'core/application/dashboard/home/analytics/activeUsers'
import getSessionsByDevice from 'core/application/dashboard/home/analytics/sessionsByDevice'
import getPageViews from 'core/application/dashboard/home/analytics/pageViews'

import useLoginUser from 'common/hooks/useLoginUser'
import { Badge, Col, Row, Skeleton, Tabs } from 'antd'
import AudienceChart from './analytics/AudienceChart'

import './Home.less'
import TabPane from 'common/components/dataDisplay/TabPane'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'
import useQueryRequest from 'common/hooks/useQueryRequest'
import DeviceChart from './analytics/DeviceChart'
import PageViewsTable from './analytics/PageViewsTable'
import Card from 'common/components/dataDisplay/Card'
import moment from 'moment'

export const HOME_PATH = '/'

interface SiteHomeProps {}

const defaultReportValue: AnalyticsReport = {
  total: '',
  max: 0,
  metrics: [],
}

const SiteHome: React.FC<SiteHomeProps> = () => {
  const { t: translate } = useTranslation()
  const [filter, setFilter] = useState<AnalyticsReportRequest>({})
  const [deviceFilter, setDeviceFilter] = useState<AnalyticsReportRequest>({})
  const [pageViewsFilter, setPageViewsFilter] = useState<AnalyticsReportRequest>({})

  const [users, isUsersLoading] = useQueryRequest<AnalyticsReport>(defaultReportValue, () => getUserCount(filter), {
    variables: filter,
  })
  const [sessions, isSessionsLoading] = useQueryRequest<AnalyticsReport>(
    defaultReportValue,
    () => getSessionCount(filter),
    {
      variables: filter,
    },
  )
  const [sessionDuration, isSessionDurationLoading] = useQueryRequest<AnalyticsReport>(
    defaultReportValue,
    () => getSessionDuration(filter),
    {
      variables: filter,
    },
  )
  const [bounceRates, isBounceRatesLoading] = useQueryRequest<AnalyticsReport>(
    defaultReportValue,
    () => getBounceRates(filter),
    {
      variables: filter,
    },
  )
  const [activeUsers, isActiveUsersLoading] = useQueryRequest<number>(0, () => getActiveUsers())
  const [sessionsByDevice, isSessionsByDeviceLoading] = useQueryRequest<AnalyticsReport>(
    defaultReportValue,
    () => getSessionsByDevice(deviceFilter),
    {
      variables: deviceFilter,
    },
  )
  const [pageViews, isPageViewsLoading] = useQueryRequest<AnalyticsReport>(
    defaultReportValue,
    () => getPageViews(pageViewsFilter),
    {
      variables: pageViewsFilter,
    },
  )
  const [user] = useLoginUser()

  const getUsersTab = () => {
    return (
      <span>
        {translate('dashboard.analytics.users')}{' '}
        <Badge count={users?.total} style={{ backgroundColor: '#52c41a' }}></Badge>
      </span>
    )
  }

  const getSessionsTab = () => {
    return (
      <span>
        {translate('dashboard.analytics.sessions')}{' '}
        <Badge count={sessions?.total} style={{ backgroundColor: '#1890ff' }}></Badge>
      </span>
    )
  }

  const getBounceRateTab = () => {
    var total = bounceRates ? bounceRates.total : 0
    return (
      <span>
        {translate('dashboard.analytics.bounce_rate')}{' '}
        <Badge count={`${(+total).toFixed(2)} %`} style={{ backgroundColor: '#fa8c16' }}></Badge>
      </span>
    )
  }

  const getSessionDurationTab = () => {
    return (
      <span>
        {translate('dashboard.analytics.session_duration')}{' '}
        <Badge count={`${sessionDuration?.total} s`} style={{ backgroundColor: '#fadb14' }}></Badge>
      </span>
    )
  }

  function onDateChange(dates: any, dateStrings: any) {
    setFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  function onDeviceDateChange(dates: any, dateStrings: any) {
    setDeviceFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  function onPageViewsDateChange(dates: any, dateStrings: any) {
    setPageViewsFilter({ startDate: dateStrings[0], endDate: dateStrings[1] })
  }

  return user && user.googleAnalyticsViewId ? (
    <div>
      <Row gutter={16} className="mt-16">
        <Col xs={24} md={24} lg={16} className="analytics_report">
          <Card
            className="custom_card__padding analytics-card"
            title={translate('dashboard.analytics.audience_overview')}
          >
            <DateRangePicker
              defaultValue={[moment().days(-7), moment()]}
              onChange={onDateChange}
              placeholder={[translate('general.start_date'), translate('general.end_date')]}
              className="mb-16"
            />
            <Tabs defaultActiveKey="1">
              <TabPane tab={getUsersTab()} key="1">
                <Card className="custom_card__padding audienceReport">
                  <Skeleton active loading={isUsersLoading} paragraph={{ rows: 5 }}>
                    <AudienceChart report={users} />
                  </Skeleton>
                </Card>
              </TabPane>
              <TabPane tab={getSessionsTab()} key="2">
                <Card className="custom_card__padding audienceReport">
                  <Skeleton active loading={isSessionsLoading} paragraph={{ rows: 5 }}>
                    <AudienceChart report={sessions} />
                  </Skeleton>
                </Card>
              </TabPane>
              <TabPane tab={getBounceRateTab()} key="3">
                <Card className="custom_card__padding audienceReport">
                  <Skeleton active loading={isBounceRatesLoading} paragraph={{ rows: 5 }}>
                    <AudienceChart report={bounceRates} />
                  </Skeleton>
                </Card>
              </TabPane>
              <TabPane tab={getSessionDurationTab()} key="4">
                <Card className="custom_card__padding audienceReport">
                  <Skeleton active loading={isSessionDurationLoading} paragraph={{ rows: 5 }}>
                    <AudienceChart report={sessionDuration} />
                  </Skeleton>
                </Card>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
        <Col xs={24} md={24} lg={8} className="analytics_report flex_card">
          <Card className="custom_card__padding analytics-card" title={translate('dashboard.analytics.active_users')}>
            <Skeleton active loading={isActiveUsersLoading} paragraph={{ rows: 5 }}>
              <div className="active_users_count">{activeUsers}</div>
            </Skeleton>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} className="mt-16">
        <Col xs={24} md={24} lg={16} className="analytics_report flex_card">
          <Card className="custom_card__padding analytics-card" title={translate('dashboard.analytics.page_views')}>
            <DateRangePicker
              defaultValue={[moment().days(-7), moment()]}
              onChange={onPageViewsDateChange}
              placeholder={[translate('general.start_date'), translate('general.end_date')]}
              className="mb-16"
            />
            <Skeleton active loading={isPageViewsLoading} paragraph={{ rows: 5 }} className="mb-16">
              <PageViewsTable records={pageViews} translate={translate} />
            </Skeleton>
          </Card>
        </Col>
        <Col xs={24} md={24} lg={8} className="analytics_report">
          <Card
            className="custom_card__padding analytics-card"
            title={translate('dashboard.analytics.sessions_by_device')}
          >
            <DateRangePicker
              defaultValue={[moment().days(-7), moment()]}
              onChange={onDeviceDateChange}
              placeholder={[translate('general.start_date'), translate('general.end_date')]}
              className="mb-16"
            />
            <Skeleton active loading={isSessionsByDeviceLoading} paragraph={{ rows: 5 }}>
              <DeviceChart report={sessionsByDevice} />
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  )
}

export default SiteHome
