import React from 'react'
import { useField, useFormikContext } from 'formik'
import Error from './FormItemError'
import Input from '../components/Input'
import FieldProps from './FieldProps'
import Label from '../components/Label'
import TagInput, { TagInputProps } from '../components/TagInput'

import './FormikStyles.less'

type FormikTagInputProps = FieldProps & { initialTags: string[]; hint?: string }

const FormikDefaultInputField: React.FC<FormikTagInputProps> = (props) => {
  const [field, meta] = useField(props)
  const { setFieldValue } = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>
  const id = props.id || props.name

  const onTagUpdate = (tags: string[]) => {
    setFieldValue(props.name, tags)
  }

  const hint = props.hint ? <span className="formik-field__span"> {props.hint}</span> : null

  return (
    <>
      <Label htmlFor={id} className=" no-select hand-on-hover">
        {props.label}
        {hint}
      </Label>
      <TagInput initialTags={props.initialTags} onTagsUpdate={onTagUpdate} />
      {error}
    </>
  )
}

export default FormikDefaultInputField
