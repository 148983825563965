import { adminHttpClient } from '../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'
import { NamedItemInt } from 'application/commons/namedItem'

export interface RoomTypeDto {
  id?: string
  name: string
  friendlyUrl: string
  description: string
  status: string
  createdAt: string
}
export enum RoomTypeStatusEnum {
  Draft = 0,
  Active = 1,
}
export const RoomTypeStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
  ]
}
const getRoomTypes = (filters: OrderedPageFilter): Promise<PagedList<RoomTypeDto>> => {
  return adminHttpClient.get(`/SiteDashboard/RoomType`, filters)
}

export default getRoomTypes
