import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface NetProfitRequest {
  startDate?: string | null
  endDate?: string | null
}

export interface NetProfitResponse {
  totalPrice: number
  profit: number
  currency: string
}
const getNetProfit = (filter: NetProfitRequest): Promise<NetProfitResponse> => {
  return adminHttpClient.get('/admindashboard/reports/netProfit', filter)
}

export default getNetProfit
