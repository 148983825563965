import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface OrderStatusToUpdate {
  status: number
  id: string
  shippingInstructions?: string
}

export const updateOrderStatus = (orderStatusToUpdate: OrderStatusToUpdate): Promise<any> => {
  return adminHttpClient.post('/admindashboard/orders', orderStatusToUpdate)
}

export default updateOrderStatus
