import { adminHttpClient } from '../commons/adminHttpClient'

import { Permissions as Permission } from './permissions'
import { UserRoles as Role } from './userRoles'
import { StoreType } from './storeType'
import { Module } from '../../domain/store/module'

export interface User {
  userId: string
  firstName: string
  lastName: string
  storeId: string
  storeName: string
  currency: string
  weightUnit: string
  language: string
  isSetupCompleted: boolean
  storeType: StoreType
  googleAnalyticsClientId: string
  googleAnalyticsViewId: string
  siteUrl: string
  roles: Role[]
  permissions: Permission[]
  modules: StoreModulesResponse[]
}

export interface StoreModulesResponse {
  module: Module
  children: Module[]
}

const getLoginUser = (): Promise<User> => {
  return adminHttpClient.get('/logininfo')
}

export default getLoginUser
