import { adminHttpClient } from '../../../commons/adminHttpClient'

export const getBlogCategoriesForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/AdminDashboard/BlogCategory/ForSave/${id}`)
  }
  return adminHttpClient.get(`/AdminDashboard/BlogCategory/ForSave`)
}

const saveBlogCategory = (data: any): Promise<any> => {
  return adminHttpClient.post(`/AdminDashboard/BlogCategory`, data)
}

export default saveBlogCategory
