import { adminHttpClient } from '../commons/adminHttpClient'
import { PagedList } from 'application/commons'
import PagedFilter from '../commons/pagedFilter'

export type CompanyRequest = Omit<PagedFilter, 'searchValue'>

export type CompanyResponse = {
  id: string
  name: string
  identificationCode: string
  phoneNumber: string
  createdAt: string
}

const getCompanies = (req: CompanyRequest): Promise<PagedList<CompanyResponse>> =>
  adminHttpClient.get('/Companies', req)

export default getCompanies
