import { adminHttpClient } from '../../../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from '../../../commons/orderedPageFilter'
import PagedList from '../../../commons/pagedList'

export interface PagedDiscountCodeOrders extends PagedList<DiscountCodeOrdersDto> {}

export interface DiscountCodeOrdersDto {
  id: string
  code: string
  discount: string
  createdAt: Moment
  orders: number
  totalPrice: number
  currency: string
}

const getDiscountCodeOrders = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/reports/discountCodeOrders', filter)
}

export default getDiscountCodeOrders
