import { adminHttpClient } from '../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from '../commons/pagedList'

export interface Theme {
  id: string
  name: string
  isPublic: boolean
}
export interface ThemeForSave {
  id: string | null
  name: string
  isPublic: boolean
}
export const ThemeInitialThemeValues: ThemeForSave = {
  id: '',
  name: '',
  isPublic: true,
}
export enum ThemeIsPublic {
  true = 'true',
  false = 'false',
}

export const ThemeIsPublicMap = [
  { value: ThemeIsPublic.false, label: false },
  { value: ThemeIsPublic.true, label: true },
]
export const getThemes = async (payload: OrderedPageFilter): Promise<PagedList<Theme>> => {
  return await adminHttpClient.get(`/themes`, payload)
}

export const getThemesForSave = (id: string | undefined): Promise<ThemeForSave> => {
  if (id) {
    return adminHttpClient.get(`/themes/ForSave/${id}`)
  }
  return adminHttpClient.get(`/themes/ForSave`)
}

export const saveThemes = (values: ThemeForSave): Promise<any> => {
  return adminHttpClient.post('/themes', values)
}
