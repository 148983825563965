import React from 'react'
import { Card, Col, notification, PageHeader, Row, Select as AntSelect, Skeleton } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import useQueryRequest from 'common/hooks/useQueryRequest'
import useCommandRequest from 'common/hooks/useCommandRequest'
import saveAppointment, {
  AppointmentForSave,
  getAppointmentsForSave,
} from 'core/application/appointments/manageAppointments'
import DatePicker from 'common/components/dataEntry/formik/FormikDatePickerField'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import Select from '../../common/components/dataEntry/formik/FormikSelectField'
import moment from 'moment'
import Checkbox from '../../common/components/dataEntry/formik/FormikCheckboxField'
import FormSaveCancelButtons from '../../common/components/forms/SaveCancelFormButtons'
import Textarea from '../../common/components/dataEntry/formik/FormikTextAreaField'
import AppointmentStatus from 'core/domain/appointments/AppointmentStatus'

const { Option } = AntSelect

export default function ManageAppointments() {
  const { t } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [appointment] = useQueryRequest<AppointmentForSave | null>(null, () => getAppointmentsForSave(id))
  const [mutation, isSubmitting] = useCommandRequest()

  const handleSubmit = async (request: AppointmentForSave) => {
    const result = await mutation(() =>
      saveAppointment({
        id: id,
        ...request,
        status: AppointmentStatus.Confirmed,
      }),
    )

    if (result.completed) {
      notification.success({ message: `Appointment ${id ? 'edited' : 'saved'} successfully!` })
      history.goBack()
    }
  }

  // @ts-ignore
  return (
    <Card
      title={
        <PageHeader
          className="p-0"
          title={id ? `Edit appointment` : `Create appointment`}
          onBack={() => history.goBack()}
        />
      }
    >
      <Skeleton active={true} loading={!appointment}>
        {appointment && (
          <Formik
            enableReinitialize={true}
            initialValues={appointment}
            validationSchema={formValidator(t)}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <Form>
                {appointment && (
                  <>
                    <Row gutter={20}>
                      <Col xs={24} md={12} lg={12}>
                        <DatePicker
                          defaultValue={moment(appointment.date)}
                          minuteStep={30}
                          showTime
                          label={'Date'}
                          name="date"
                          allowClear={false}
                          placeholder={'Date'}
                          format={'DD/MM/YYYY HH:mm'}
                          disabledTime={() => ({
                            disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 20, 21, 22, 23, 24],
                          })}
                          disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day')) || d.weekday() == 0}
                          className="full_width"
                        />
                        <Input className="mb-0" label="First Name" name="firstName" placeholder="First Name" />
                        <Input className="mb-0" label="Last Name" name="lastName" placeholder="Last Name" />
                        <Input className="mb-0" label="Phone Number" name="phoneNumber" placeholder="Phone Number" />

                        <Input
                          className="mb-0"
                          label="Email"
                          type={'email'}
                          name="email"
                          placeholder="Email"
                          showOptionalLabel={true}
                        />

                        <DatePicker
                          value={values.birthDate ? moment(values.birthDate) : null}
                          label={'BirthDate'}
                          name="birthDate"
                          allowClear={false}
                          format={'DD/MM/YYYY'}
                          placeholder={'BirthDate'}
                          disabledDate={(d) => !d || d.isAfter(moment().subtract(1, 'day'))}
                          className="full_width"
                        />
                      </Col>
                      <Col xs={24} md={12} lg={12} className={'mb-16'}>
                        <Select
                          showOptionalLabel={true}
                          label={'Doctor'}
                          name="teamMemberId"
                          placeholder={'Doctor'}
                          allowClear
                          showArrow
                          showSearch
                          defaultValue={appointment.teamMemberId}
                        >
                          {appointment.availableTeamMembers.map(({ id, name }) => (
                            <option key={id} value={id}>
                              {name}
                            </option>
                          ))}
                        </Select>
                        <Select
                          label="Service"
                          name="serviceId"
                          showOptionalLabel={true}
                          allowClear
                          showArrow
                          showSearch
                          defaultValue={appointment?.serviceId}
                          placeholder="Service"
                        >
                          {appointment.availableServices.map(({ id, name }) => (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ))}
                          s
                        </Select>
                        <Select
                          label="Payment Method"
                          name="paymentType"
                          showOptionalLabel={true}
                          defaultValue={appointment?.paymentType as any}
                          placeholder="Payment Method"
                          allowClear
                        >
                          {appointment.availablePaymentMethods.map(({ id, name }) => (
                            <Option key={id} value={id}>
                              {name}
                            </Option>
                          ))}
                        </Select>

                        <Row gutter={16}>
                          <Col span={4} xs={10} md={4}>
                            <Select
                              label="Currency"
                              name="currencyId"
                              defaultValue={[appointment?.currencyId]}
                              placeholder="Currency"
                              allowClear
                            >
                              {appointment.availableCurrencies.map(({ id, name }) => (
                                <Option key={id} value={id}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                          <Col span={20} xs={14} md={20}>
                            <InputNumber className="mb-0" name="price" label="Price" placeholder="Price" />
                          </Col>
                        </Row>

                        <Textarea label={'Note'} name="note" placeholder={'Note'} rows={5} showOptionalLabel={true} />

                        <Col xs={24} className="mb-8 ps-0">
                          <Checkbox label={'Completed'} name="completed" defaultChecked={appointment?.completed} />
                        </Col>
                      </Col>
                    </Row>
                    <FormSaveCancelButtons
                      disabledSave={!formValidator(t).isValidSync(values)}
                      onButtonClick={() => handleSubmit(values)}
                      loading={isSubmitting}
                    />
                  </>
                )}
              </Form>
            )}
          </Formik>
        )}
      </Skeleton>
    </Card>
  )
}
const formValidator = (translate: any) =>
  Yup.object({
    date: Yup.string().nullable().required(translate('general.required')),
    firstName: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    lastName: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    phoneNumber: Yup.string()
      .nullable()
      .trim()
      .required(translate('general.required'))
      .max(255, translate('general.max_length').replace('[]', 255)),
    email: Yup.string().nullable().email(),
  })
