/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import classnames from 'classnames'

import { Field, useField, useFormikContext } from 'formik'
import TreeSelect, { TreeSelectProps } from '../components/TreeSelect'
import { TreeSelect as $TreeSelect } from 'antd'
import Error from './FormItemError'
import FieldProps from './FieldProps'
import Label from '../components/Label'

type FormiKTreeSelectFieldProps = TreeSelectProps & FieldProps

const FormikSelectField: React.FC<FormiKTreeSelectFieldProps> = ({ onChange, ...props }) => {
  const [field, meta] = useField(props.name)
  const context = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>
  const id = props.id || props.name

  const onChangeHandler = (value: any, label: any, key: any): void => {
    const values = props.treeCheckStrictly ? value.map((c: { value: any }) => c.value) : value

    context.setFieldValue(props.name, values)

    if (onChange) {
      onChange(values, label, key)
    }
  }

  const onBlur = (): void => {
    context.setFieldTouched(props.name)
  }

  const inputClassname = classnames({ select_border_error: showError })

  return (
    <>
      <div className={'form-group'}>
        {props.label && (
          <Label htmlFor={props.id || props.name} className="formik-field__input-label no-select">
            {' '}
            {props.label}
          </Label>
        )}
        <TreeSelect
          {...props}
          className={`${inputClassname} input__no-autofill-color ${props.className}`}
          id={id}
          onChange={onChangeHandler}
          onBlur={onBlur}
        >
          {props.children}
        </TreeSelect>
        {showError && <div className="mt-4">{error}&nbsp;</div>}
      </div>
    </>
  )
}

export const FormikTreeSelect = ({
  name,
  defaultValue,
  label,
  validate,
  fast,
  onChange,
  onBlur,
  ...restProps
}: any) => (
  <Field name={name} validate={validate} fast={fast}>
    {({ field: { value }, meta, form }: any) => (
      <>
        {label && (
          <Label htmlFor={restProps.id || name} className="formik-field__input-label no-select">
            {label}
          </Label>
        )}
        <$TreeSelect
          value={value}
          defaultValue
          onBlur={(event) => {
            form.setFieldTouched(name)
            onBlur && onBlur(event)
          }}
          onChange={(value, node, extra) => {
            form.setFieldValue(name, value)
            onChange && onChange(value, node, extra)
          }}
          {...restProps}
        />
        {meta.touched && meta.error && <div className="mt-4">{meta.error}&nbsp;</div>}
      </>
    )}
  </Field>
)

export default FormikSelectField
