import { Col, Row } from 'antd'
import React from 'react'

import './OrderDetails.less'

interface ItemProps {
  translate: (key: string) => string
  title: string
  text: string | number | null | undefined
  className?: string
}

const Item: React.FC<ItemProps> = ({ translate, title, text, className }) => {
  return (
    <Row className="mb-8">
      <Col span={10} className="color-gray-7">
        {title}
      </Col>
      {text || text == 0 ? (
        <Col span={14}>{text}</Col>
      ) : (
        <Col span={14} className="color-gray-6">
          {translate('general.not_set')}
        </Col>
      )}
    </Row>
  )
}

export default Item
