import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import Input from 'common/components/dataEntry/formik/FormikInputField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'
import DatePicker from 'common/components/dataEntry/formik/FormikDatePickerField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'

import getDiscountCode, {
  DiscountCode,
  DiscountCodeToSave,
  saveDiscountCode,
} from 'core/application/dashboard/discountCodes/manageDiscountCode'
import { DiscountType } from 'core/domain/product/offer'
import { NamedItemInt } from 'core/application/commons/namedItem'
import { User } from 'core/application/account/loginUser'
import useLoginUser from '../../../common/hooks/useLoginUser'

export const MANAGE_DISCOUNT_CODE_PATH = 'manage-discount-code'

const formValidator = (translate: any) =>
  Yup.object({
    code: Yup.string().trim().required(translate('general.required')),
    type: Yup.number()
      .required(translate('general.required'))
      .nullable()
      .typeError(translate('general.required'))
      .oneOf([DiscountType.FixedRate, DiscountType.Percentage], translate('general.required')),
    discount: Yup.number()
      .required(translate('general.required'))
      .typeError(translate('general.required'))
      .min(1, translate('general.minimun_required')),
    startDate: Yup.date().required(translate('general.required')).nullable().typeError(translate('general.required')),
    endDate: Yup.date().required(translate('general.required')).nullable().typeError(translate('general.required')),
  })

const getDiscountCodeTypes = (translate: (key: string) => string): NamedItemInt[] => {
  var types: NamedItemInt[] = []
  types.push({
    id: DiscountType.Percentage,
    name: translate('dashboard.offers.percentage'),
  })
  types.push({
    id: DiscountType.FixedRate,
    name: translate('dashboard.offers.fixedRate'),
  })
  return types
}

const getDiscountSymbol = (type: DiscountType, user: User | null) => {
  if (type === DiscountType.Percentage) return '(%)'
  else if (type === DiscountType.FixedRate && user !== null) return `(${user.currency})`
  else return ''
}

interface ManageDiscountCodeProps {}

const ManageDiscountCode: React.FC<ManageDiscountCodeProps> = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [item, setItem] = useState<DiscountCode | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [user] = useLoginUser()

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getDiscountCode(id))
    if (id) {
      setItem(result)
    } else {
      setItem({
        ...result,
        type: null,
        endDate: null,
        startDate: null,
        productIds: [],
      })
    }
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: DiscountCode) => {
    setLoading(true)
    let discountCode: DiscountCodeToSave = {
      id: id ? id : null,
      discount: values.discount,
      code: values.code,
      startDate: values.startDate,
      endDate: values.endDate,
      type: values.type,
      applyToProductsInOffer: true,
    }
    const result = await commandRequest(() => saveDiscountCode(discountCode))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.the_discount_code')),
        type: 'success',
      })
      history.goBack()
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={id ? translate('dashboard.discount_codes.edit') : translate('dashboard.discount_codes.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.discount_codes.code')}
                        name="code"
                        placeholder={translate('dashboard.discount_codes.code')}
                        onBlur={(e: any) => formik.setFieldValue('code', e.currentTarget.value.replace(/\s/g, ''))}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Select
                        name="type"
                        showSearch={true}
                        label={`${translate('dashboard.discount_codes.type')} ${getDiscountSymbol(
                          formik.values.type,
                          user,
                        )}`}
                        placeholder={translate('dashboard.discount_codes.type')}
                        defaultValue={id ? [item.type] : []}
                      >
                        {getDiscountCodeTypes(translate).map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.discount_codes.discount')}
                        name="discount"
                        placeholder={translate('dashboard.discount_codes.discount')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24} lg={12}>
                      <DatePicker
                        label={translate('dashboard.discount_codes.startDate')}
                        name="startDate"
                        allowClear={false}
                        value={formik.values.startDate ? moment(formik.values.startDate) : null}
                        placeholder={translate('dashboard.discount_codes.startDate')}
                        disabledDate={(d) => !d || d.isBefore(moment().subtract(1, 'day'))}
                        className="full_width"
                      />
                    </Col>

                    <Col xs={24} lg={12}>
                      <DatePicker
                        label={translate('dashboard.discount_codes.endDate')}
                        name="endDate"
                        allowClear={false}
                        value={formik.values.endDate ? moment(formik.values.endDate) : null}
                        placeholder={translate('dashboard.discount_codes.endDate')}
                        disabledDate={(d) => !d || d.isBefore(moment(formik.values.startDate))}
                        className="full_width"
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>
              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageDiscountCode
