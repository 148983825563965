import { adminHttpClient } from '../../commons/adminHttpClient'

export interface RoomTypeForSaveDto {
  id: string
  name: string
  friendlyUrl: string
  description: string
  price: number
  priority: number
  status: any
  photoIds: string[]
  thumbnailId: string
}
export const getRoomTypeForSave = (id: string | undefined) => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/RoomType/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/RoomType/ForSave`)
}

const saveRoomType = (data: any) => {
  return adminHttpClient.post(`/SiteDashboard/RoomType`, data)
}
export default saveRoomType
