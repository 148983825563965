import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import saveRoomType, {
  getRoomTypeForSave,
  RoomTypeForSaveDto,
} from 'core/application/dashboard/roomTypes/manageRoomType'
import { RoomTypeStatus } from 'core/application/dashboard/roomTypes/roomTypes'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Notification } from 'common/genericNotification'
import Input from '../../common/components/dataEntry/formik/FormikInputField'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from '../../common/components/dataEntry/formik/upload/FormikUploaderField'
import Card from '../../common/components/dataDisplay/Card'
import { Formik, Form as FormikForm } from 'formik'
import FormSaveCancelButtons from '../../common/components/forms/SaveCancelFormButtons'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import Textarea from 'common/components/dataEntry/formik/FormikTextAreaField'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import { Col, Row, Skeleton } from 'antd'
import { formValidator } from './RoomTypeValidator'
import { FormikSeoFields } from 'common/components/dataEntry/formik/FormikSeoFields'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'

export const MANAGE_ROOM_TYPE_PATH = 'manage-room-type'
const ManageRoomTypes: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const [item, setItem] = useState<RoomTypeForSaveDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getRoomTypeForSave(id)))
    setLoading(false)
  }
  const onSubmit = async (values: any) => {
    const result = await commandRequest(() => saveRoomType(values))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.room_types.name')))
  }
  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.room_types.edit') : translate('dashboard.room_types.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.name')}
                        name="name"
                        placeholder={translate('general.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.description')}
                        name={'description'}
                        placeholder={translate('dashboard.description')}
                        value={item?.description}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikTextAreaField
                        label={translate('dashboard.short_description')}
                        name={'shortDescription'}
                        placeholder={translate('dashboard.short_description')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.price')}
                        name="price"
                        placeholder={translate('dashboard.price')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}`}
                        initialValues={item.photoIds}
                        name="photoIds"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoIds"
                        optimizedResize={true}
                        mode="multiple"
                        maxUploads={5}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.thumbnail')}`}
                        initialValues={[item.thumbnailId]}
                        name="thumbnailId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="thumbnailId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [item.status] : []}
                      >
                        {RoomTypeStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <FormikSeoFields />
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageRoomTypes
