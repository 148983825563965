import { adminHttpClient } from '../../../commons/adminHttpClient'

interface OrderForExportDto {
  searchValue?: string | null
  priceRangeStart?: number | null
  priceRangeEnd?: number | null
  createdDateStart?: string | null
  createdDateEnd?: string | null
  status?: number | null
  createdAt?: string | null
  orderNumber?: string | null
}
export const getOrdersForExport = (filters: OrderForExportDto): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/OrdersForExport`, filters)
}
