import { adminHttpClient } from '../../../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from 'application/commons/pagedList'

export interface BlogCategoryDto {}
export interface PagedBlogCategoryDto extends PagedList<BlogCategoryDto> {}

const getBlogCategories = (filter: OrderedPageFilter): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/BlogCategory`, filter)
}

export default getBlogCategories
