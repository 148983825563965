import { AnyAction } from 'redux'

export const SharedStateAction = 'SharedStateAction'
export const SharedPersistedStateAction = 'SharedPersistedStateAction'

export type SharedStateActionActionPayload = {
  name: string
  value: any
}

export const sharedStateAction = (payload: SharedStateActionActionPayload): AnyAction => ({
  type: SharedStateAction,
  payload,
})

export const sharedPersistedStateAction = (payload: SharedStateActionActionPayload): AnyAction => ({
  type: SharedPersistedStateAction,
  payload,
})

export default {}
