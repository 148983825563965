import React from 'react'
import { useTranslation } from 'react-i18next'

import Icon from '../../general/Icon'

import './DataEntryStyles.less'

export interface ContentProps {
  nrOfcharsIcon: boolean
  lowercaseIcon: boolean
  uppercaseIcon: boolean
  oneNumberIcon: boolean
  specialCharIcon: boolean
  translate: any
}

const resolvePasswordRequirementIcon = (requirementMet: boolean) => {
  return requirementMet ? (
    <Icon type="far fa-check-circle" className="color-green-5 mr-8" />
  ) : (
    <Icon type="far fa-times-circle" className="color-red-6 mr-8" />
  )
}

const PasswordPopoverContent: React.FC<ContentProps> = (props) => {
  let contentProps =
    props !== undefined
      ? props
      : {
          nrOfcharsIcon: false,
          lowercaseIcon: false,
          oneNumberIcon: false,
          specialCharIcon: false,
          uppercaseIcon: false,
        }

  return (
    <div className="password-popover-content">
      <p>
        {resolvePasswordRequirementIcon(contentProps.nrOfcharsIcon)}
        {props.translate('password.at_least_8_chars')}
      </p>
      <p>
        {resolvePasswordRequirementIcon(contentProps.lowercaseIcon)}
        {props.translate('password.at_least_one_lowercase')}
      </p>
      <p>
        {resolvePasswordRequirementIcon(contentProps.uppercaseIcon)}
        {props.translate('password.at_least_one_uppercase')}
      </p>
      <p>
        {resolvePasswordRequirementIcon(contentProps.oneNumberIcon)}
        {props.translate('password.at_least_one_number')}
      </p>
      <p>
        {resolvePasswordRequirementIcon(contentProps.specialCharIcon)}
        {props.translate('password.at_least_one_special_char')}
      </p>
    </div>
  )
}

export default PasswordPopoverContent
