import React, { useRef } from 'react'
import { Input } from 'antd'

import './DataEntryStyles.less'

export interface ClearableInputProps {
  className?: string
  placeHolder?: string
  onValueUpdate: (e: any) => void
}

const ClearableInput: React.FC<ClearableInputProps> = (props: ClearableInputProps) => {
  const clearableInputRef = useRef(null)

  const inputKeyDown = (e: any) => {
    const val = e.target.value
    if (e.key === 'Enter' && val) {
      props.onValueUpdate(e)
      e.preventDefault()
      if (clearableInputRef !== null && clearableInputRef.current !== null) {
        //@ts-ignore
        clearableInputRef.current.state.value = null
        //@ts-ignore
        HTMLInputElement.current = null
      }
    }
  }

  return (
    <Input
      type="text"
      onKeyDown={inputKeyDown}
      onKeyPress={inputKeyDown}
      ref={clearableInputRef}
      placeholder={props.placeHolder}
      className={props.className}
    />
  )
}

export default ClearableInput
