import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton } from 'antd'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import Input from '../../../common/components/dataEntry/formik/FormikInputField'
import InputNumber from '../../../common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from '../../../common/components/dataEntry/formik/upload/FormikUploaderField'
import { commandRequest, queryRequest } from '../../../common/RequestUtils'
import Card from '../../../common/components/dataDisplay/Card'
import notification from '../../../common/components/feedback/Notification'
import useUrlQueryParam from '../../../common/hooks/useUrlQueryParam'
import { ADMIN_DASHBOARD_PATH } from '../../routes/StoreRoutes'

import getBannerItem, { BannerItem, saveBannerItem } from 'core/application/dashboard/banners/manageBannerItem'
import { BANNERS_PATH } from '../Banners'
import FormSaveCancelButtons from '../../../common/components/forms/SaveCancelFormButtons'
import TextArea from '../../../common/components/dataEntry/formik/FormikTextAreaField'

export const MANAGE_BANNER_ITEM_PATH = 'manage-banner-item'

const formValidator = (translate: any) =>
  Yup.object({
    photoId: Yup.string().trim().required(translate('general.required')),
  })

const ManageBannerItem: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id }: any = useParams()
  const parentId = useUrlQueryParam('parentId')
  const parentName = useUrlQueryParam('parentName')
  const history = useHistory()
  const [item, setItem] = useState<BannerItem | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    if (id) {
      const result = await queryRequest(() => getBannerItem(id))
      setItem(result)
    } else {
      setItem({
        id: '',
        name: '',
        priority: 0,
        photoId: '',
        url: '',
        bannerId: '',
        mobilePhotoId: '',
        shortDescription: '',
      })
    }
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  useEffect(() => {
    if (!parentId && !id) {
      history.push(ADMIN_DASHBOARD_PATH)
    }
  }, [parentId])

  const onSubmit = async (values: BannerItem) => {
    setLoading(true)
    let bannerItem: BannerItem = {
      id: id ? id : null,
      name: values.name,
      priority: values.priority,
      bannerId: parentId ? parentId : values.bannerId,
      photoId: values.photoId,
      url: values.url,
      mobilePhotoId: values.mobilePhotoId,
      shortDescription: values.shortDescription,
    }

    const result = await commandRequest(() => saveBannerItem(bannerItem))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.the_banner_item')),
        type: 'success',
      })
      history.push(`${ADMIN_DASHBOARD_PATH}${BANNERS_PATH}?parentName=${parentName}&parentId=${parentId}`)
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                title={
                  id
                    ? `${translate('dashboard.bannerItems.edit')}${parentName}`
                    : `${translate('dashboard.bannerItems.add')}${parentName}`
                }
              >
                <FormikForm>
                  <Row className={'mb-16'}>
                    <Col span={24}>
                      <Uploader
                        label={`${translate('dashboard.photo')}(1030x500)`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1940}
                        resizeToHeight={1940}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.bannerItems.optional_title')}
                        name="name"
                        placeholder={translate('dashboard.title')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.bannerItems.optional_url')}
                        name="url"
                        placeholder={translate('dashboard.url')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <TextArea
                        label={translate('dashboard.short_description')}
                        name="shortDescription"
                        placeholder={translate('dashboard.short_description')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Uploader
                        label={translate('dashboard.mobile_photo')}
                        initialValues={[item.mobilePhotoId]}
                        name="mobilePhotoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="mobilePhotoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                        showOptionalLabel={true}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageBannerItem
