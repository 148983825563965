import { adminHttpClient } from '../commons/adminHttpClient'
import { Moment } from 'moment'
import OrderedPageFilter from 'application/commons/orderedPageFilter'

export interface PagedEmails {
  items: EmailInfo[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}

export interface EmailInfo {
  id: string
  name: string
  createdAt: Moment
}

export type EmailForSave = {
  id: string | null
  name: string
  subject: string
  body: string
}

export const getEmails = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/emails', filter)
}

export const getEmailForSave = (id: string | undefined): Promise<EmailForSave> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/emails/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/emails/forsave`)
}

export const deleteEmail = (id: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/emails', {
    data: { id: id },
  })
}

export const saveEmail = (data: EmailForSave) => {
  return adminHttpClient.post('/admindashboard/emails', data)
}

export type SendEmailToSubscriptionsRequest = {
  emailId: string
  sendToSpecificSubscriptions: boolean
  specificSubscriptionIds: string[]
}

export const getEmailsForSend = (): Promise<EmailInfo[]> => {
  return adminHttpClient.get('/AdminDashboard/Emails/ForSend')
}

export const sendEmailToSubscriptions = (data: SendEmailToSubscriptionsRequest) => {
  return adminHttpClient.post('/admindashboard/emails/SendEmailToSubscribers', data)
}
