import { adminHttpClient } from '../commons/adminHttpClient'
import { createQueryParamsForArray } from '../../common/urlUtils'

export type GenerateProductUnitsResponse = {
  id: string
  name: string
  price: number
  stock: number
  productPredefineAttributeValues: string[]
}

const generateProductUnitsForPredefinedAttributes = (categoryIds: string[]) => {
  const queryParams = createQueryParamsForArray('categoryIds', categoryIds)

  return adminHttpClient.get<GenerateProductUnitsResponse[]>(
    `/Products/PredefinedAttributes/GenerateProductUnits?${queryParams}`,
  )
}

export default generateProductUnitsForPredefinedAttributes
