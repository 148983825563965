import React, { useState, useRef, useEffect } from 'react'
import { Input } from 'antd'
import { useTranslation } from 'react-i18next'

import Icon from 'common/components/general/Icon'

import './DataEntryStyles.less'

export interface TagInputProps {
  initialTags: string[]
  onTagsUpdate: (tags: string[]) => void
}

const TagInput: React.FC<TagInputProps> = ({ initialTags, onTagsUpdate }) => {
  const [allTags, setAllTags] = useState<string[]>(initialTags)
  const { t: translate } = useTranslation()

  const tagInputRef = useRef(null)

  const removeTag = (i: any) => {
    const newTags = [...allTags]
    newTags.splice(i, 1)
    setAllTags(newTags)
    onTagsUpdate(newTags)
  }

  const inputKeyDown = (e: any) => {
    const val = e.target.value
    if (e.key === 'Enter' && val) {
      if (allTags.find((tag) => tag.toLowerCase() === val.toLowerCase())) {
        return
      }
      const tagsAfterAppend = [...allTags, val]
      setAllTags(tagsAfterAppend)
      onTagsUpdate(tagsAfterAppend)

      e.preventDefault()
      if (tagInputRef !== null && tagInputRef.current !== null) {
        //@ts-ignore
        tagInputRef.current.state.value = null
        //@ts-ignore
        HTMLInputElement.current = null
      }
    } else if (e.key === 'Backspace' && !val) {
      removeTag(allTags.length - 1)
      e.preventDefault()
    }
  }

  return (
    <div className="input-tag">
      <ul className="input-tag__tags">
        {allTags.map((tag, i) => (
          <li key={tag}>
            {tag}
            <Icon
              type="far fa-times"
              className="color-gray-6 ml-8"
              onClick={() => {
                removeTag(i)
              }}
            />
          </li>
        ))}
        <li className="input-tag__tags__input">
          <Input
            type="text"
            onKeyDown={inputKeyDown}
            onKeyPress={inputKeyDown}
            ref={tagInputRef}
            placeholder={translate('dashboard.add')}
          />
        </li>
      </ul>
    </div>
  )
}

export default TagInput
