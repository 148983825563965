import React, { ReactNode } from 'react'

interface LabelProps {
  htmlFor?: string | undefined
  children: ReactNode
  className?: string
}

const Label: React.FC<LabelProps> = (props) => {
  return <label {...props}>{props.children}</label>
}

export default Label
