import React from 'react'

import { TimePicker as AntTimePicker } from 'antd'
import { TimePickerProps as AntTimePickerProps } from 'antd/lib/time-picker/index'

import './DataEntryStyles.less'

export type TimePickerProps = AntTimePickerProps

const TimePicker: React.FC<AntTimePickerProps> = (props) => {
  return <AntTimePicker {...props} className={`input_height round_border ${props.className}`} />
}

export default TimePicker
