import React, { Fragment } from 'react'
import { Field, FieldProps } from 'formik'
import Input from '../components/Input'
import Label from '../components/Label'
import Uploader from './upload/FormikUploaderField'
import Error from './FormItemError'
import { useTranslation } from 'react-i18next'
import TextArea from '../components/TextArea'
import './FormikStyles.less'

const labelClassName = 'formik-field__input-label no-select hand-on-hover'
const validateMaxLength = (message: string) => (value: string) =>
  value === null || value.length < 255 ? undefined : message

export interface Props {
  title: string
  description: string
  image: string
}
export const FormikSeoFields = ({
  title = 'seoTitle',
  description = 'seoDescription',
  image = 'seoImage',
}: Partial<Props>) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <Field name={image}>
        {({ field: { value } }: FieldProps) => (
          <Uploader
            name={image}
            label={`${t('seoImage')}(1030x500)`}
            resizeToWidth={1940}
            resizeToHeight={1940}
            fieldName={image}
            initialValues={[value]}
            optimizedResize={true}
            mode="single"
            maxUploads={1}
          />
        )}
      </Field>
      <Field name={title}>
        {({ field, meta: { touched, error } }: FieldProps) => (
          <div className={'form-group w-100'}>
            <Label className={labelClassName}>{t('seoTitle')}</Label>
            <Input {...field} type="text" placeholder={t('seoTitle')} />
            {touched && error && <Error>{error}</Error>}
          </div>
        )}
      </Field>
      <Field name={description} validate={validateMaxLength('Seo description should contain less then 255 chars.')}>
        {({ field, meta: { touched, error } }: FieldProps) => (
          <div className={'form-group w-100'}>
            <Label className={labelClassName}>{t('seoDescription')}</Label>
            <TextArea {...field} placeholder={t('seoDescription')} />
            {touched && error && <Error>{error}</Error>}
          </div>
        )}
      </Field>
    </Fragment>
  )
}
