import { adminHttpClient } from '../../../commons/adminHttpClient'
import { NamedItemInt } from '../../../commons/namedItem'
import { NamedItemChildren } from '../../../commons/namedItemChildren'

export interface JobPositionForSave {
  id: string | null
  name: string
  friendlyUrl: string
  photoId: string
  location: string
  priority: number
  benefits: string
  requirements: string
  description: string
  shortDescription: string
  featured: boolean
  status: JobPositionStatusEnum
  type: JobTypeStatusEnum
  seoTitle: string
  seoDescription: string
  seoImage: string
  categoryIds: string[]
  availableCategories: NamedItemChildren[]
}

export enum JobPositionStatusEnum {
  Draft = 0,
  Active = 1,
  Archived = 2,
}

export const JobPositionStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
    { id: 2, name: translate('general.archived') },
  ]
}

export enum JobTypeStatusEnum {
  FullTime = 0,
  PartTime = 1,
}

export const JobTypeStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.jobs.position.full_time') },
    { id: 1, name: translate('dashboard.jobs.position.part_time') },
  ]
}

const getJobPositionForSave = (id: string | undefined): Promise<JobPositionForSave> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/jobPositions/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/jobPositions/forsave`)
}

export interface JobPositionToSave {
  id: string | null
  name: string
  friendlyUrl: string
  photoId: string
  location: string
  priority: number
  benefits: string
  requirements: string
  description: string
  shortDescription: string
  featured: boolean
  status: JobPositionStatusEnum
  type: JobTypeStatusEnum
  seoTitle: string
  seoDescription: string
  seoImage: string
  categoryIds: string[]
}

export const saveJobPosition = (jobPosition: JobPositionToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/jobPositions', jobPosition)
}

export default getJobPositionForSave
