import useQueryRequestV2 from './useQueryRequestV2'
import { Feature } from 'core/domain/featureFlags/feature'
import isFeaturedEnabled from 'core/application/featureFlags/IsFeaturedEnabled'

const useFeatureFlag = (feature: Feature) => {
  const { data } = useQueryRequestV2({
    initialState: false,
    queryFn: () => isFeaturedEnabled(feature),
  })

  return data ?? false
}

export default useFeatureFlag
