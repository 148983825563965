import React from 'react'
import { Table } from 'antd'
import { AnalyticsReport } from 'core/application/dashboard/home/analytics/userCount'

interface PageViewTableProps {
  records?: AnalyticsReport
  translate: (key: string) => string
}

const PageViewsTable: React.FC<PageViewTableProps> = ({ translate, records }) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.analytics.page'),
        dataIndex: 'key',
        key: 'key',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.key}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.analytics.views'),
        dataIndex: 'value',
        key: 'value',
        responsive: ['md'],
        sorter: (a: any, b: any) => a.value - b.value,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.value}</span>
            </div>
          </span>
        ),
      },
    ]

    return columns
  }

  return (
    <Table
      rowKey={(record) => record.key}
      dataSource={records?.metrics}
      columns={getColumns()}
      scroll={{ x: 'max-content' }}
    />
  )
}

export default PageViewsTable
