import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Services from './Services'
import ManageServices from './ManageServices'
import NotFound from '../../errorPages/NotFound'
import ROUTE_PATHS from '../../common/routePaths'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${ROUTE_PATHS.SERVICES}/manage-services/:id?`} component={ManageServices} />
      <Route path={`${ROUTE_PATHS.SERVICES}`} component={Services} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
