import React from 'react'
import classnames from 'classnames'

import { useField, useFormikContext } from 'formik'
import Select, { SelectProps } from '../components/Select'
import Error from './FormItemError'
import FieldProps from './FieldProps'
import Label from '../components/Label'
import { useTranslation } from 'react-i18next'

type FormiKSelectFieldProps = SelectProps &
  FieldProps & {
    showOptionalLabel?: boolean
    allowClear?: boolean
  }

const FormikSelectField: React.FC<FormiKSelectFieldProps> = ({ onChange, ...props }) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props.name)
  const context = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>
  const id = props.id || props.name

  const onChangeHandler = (value: any, key: any): void => {
    context.setFieldValue(props.name, value)

    if (onChange) {
      onChange(value, key)
    }
  }

  const onBlur = (e: any): void => {
    context.setFieldTouched(props.name)
    props?.onBlur && props?.onBlur(e)
  }

  const inputClassname = classnames({ select_border_error: showError })

  return (
    <>
      <div className={'form-group'}>
        {props.label && (
          <Label htmlFor={props.id || props.name} className="formik-field__input-label no-select">
            {props.label}
            {props.showOptionalLabel ? `(${t('optional')})` : ''}
          </Label>
        )}
        <Select
          {...props}
          {...field}
          className={`${inputClassname} input__no-autofill-color ${props.className}`}
          id={id}
          filterOption={(input, option) =>
            option != null && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          placeholder={props.placeholder ?? props.label}
          onChange={onChangeHandler}
          onBlur={onBlur}
        >
          {props.children}
        </Select>
        {showError && <div className="mt-4">{error}&nbsp;</div>}
      </div>
    </>
  )
}

export default FormikSelectField
