const config: Map<string, any> = new Map<string, any>()

const env: string = process.env.REACT_APP_ENVIRONMENT || 'development'

if (env === 'development') {
  config.set('development', {
    apiEndpoint: 'https://localhost:6001',
  })
}

if (env === 'staging') {
  config.set('staging', {
    apiEndpoint: 'https://api.hermex.xyz',
  })
}

if (env === 'production') {
  config.set('production', {
    apiEndpoint: 'https://api.hermex.io',
  })
}

export default config.get(env)
