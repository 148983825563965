import React, { useCallback, useState } from 'react'
import { Card, Col, PageHeader, Row, Space, Table, Tag, Tooltip } from 'antd'
import { getThemes, Theme, PagedList, PagedFilter, defaultFilterThemes } from 'core'
import useQueryRequest from 'common/hooks/useQueryRequest'
import Button from '../../../common/components/general/Button'
import { Link, useHistory, useParams } from 'react-router-dom'
import { EditFilled, UnorderedListOutlined } from '@ant-design/icons'
import TableDeleteButton from '../../../common/components/tables/TableDeleteButton'
import TableEditButton from '../../../common/components/tables/TableEditButton'

const { Column } = Table
export const THEMES_PATH = 'themes'
export const ManagementThemes: React.FC = () => {
  const history = useHistory()
  const { themeId } = useParams<any>()
  const [filter, setFilter] = useState<PagedFilter>(defaultFilterThemes)
  const getThemesList = useCallback(() => getThemes(filter), [filter])
  const [themes, isLoading, query] = useQueryRequest<PagedList<Theme> | null>(null, getThemesList, {
    variables: filter,
  })

  return (
    <Card
      title={<PageHeader className="p-0" title="List of themes in hermex" />}
      extra={
        <Button onClick={() => history.push(`/manage-theme`)} type="primary">
          Add theme
        </Button>
      }
    >
      <Table
        rowKey={(record) => record.id}
        loading={isLoading}
        dataSource={themes?.items}
        onChange={(pagination: any, filter, sorter: any) => {
          setFilter({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || 'name',
            orderDescending: sorter.order !== 'ascend',
          })
        }}
        pagination={{
          total: themes?.totalItemCount,
          pageSize: themes?.pageSize,
          current: themes?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      >
        <Column title="Id" dataIndex="id" key="id" sorter sortDirections={['ascend']} />

        <Column title="Name" dataIndex="name" key="name" sorter sortDirections={['ascend']} />

        <Column
          title="Is public"
          dataIndex="isPublic"
          key="isPublic"
          sorter
          sortDirections={['ascend']}
          render={(value) => value.toString()}
        />

        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(value, record: any) => (
            <Space>
              <TableEditButton editUrl={`/manage-theme/${record.id}`} />
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}

export default ManagementThemes
