import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Category from './categories/Category'
import ManageDynamicCategory from './categories/ManageDynamicCategory'
import DynamicEntity from './DynamicEntity'
import ManageDynamicEntity from './ManageDynamicEntity'

export * from './DynamicEntity'

export default function Routes() {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${path}/dynamic-entity`}>
        <DynamicEntity />
      </Route>
      <Route exact path={`${path}/dynamic-entity/manage/:id?`}>
        <ManageDynamicEntity />
      </Route>
      <Route exact path={`${path}/dynamic-category`}>
        <Category />
      </Route>
      <Route exact path={`${path}/dynamic-category/manage/:id?`}>
        <ManageDynamicCategory />
      </Route>
    </Switch>
  )
}
