import React, { ReactNode, CSSProperties } from 'react'
import { notification } from 'antd'
import { NotificationPlacement, IconType } from 'antd/lib/notification/index'
import './NotificationStyles.less'

const classNameMappingForEachType = new Map<string, string>([
  ['error', 'notification_error'],
  ['success', 'notification_success'],
  ['info', 'notification_info'],
  ['warning', 'notification_warning'],
])

interface NotificationProps {
  message: ReactNode
  description?: ReactNode
  btn?: ReactNode
  key?: string
  onClose?: () => void
  duration?: number | null
  icon?: ReactNode
  placement?: NotificationPlacement
  style?: CSSProperties
  readonly type?: IconType
  onClick?: () => void
  top?: number
  bottom?: number
  closeIcon?: ReactNode
  className?: string
}

class Feedback extends React.Component {
  static open = (args: NotificationProps) => {
    let props = { ...args }
    props.className = `${props.className} ${classNameMappingForEachType.get(props.type ?? 'info')}`
    return notification.open({ ...props })
  }
  static close = (key: string) => {
    return notification.close(key)
  }

  static success = (message: string) => {
    return notification.open({
      message,
      type: 'success',
    })
  }

  static error = (message: string) => {
    return notification.open({
      message,
      type: 'error',
    })
  }
}

export default Feedback
