import React, { useEffect, useState } from 'react'
import './InitialStoreSetup.less'
import * as Yup from 'yup'
import { Formik, Form as FormikForm } from 'formik'
import { useTranslation } from 'react-i18next'
import Card from '../common/components/dataDisplay/Card'
import { Col, Row, Skeleton } from 'antd'

import Input from '../common/components/dataEntry/formik/FormikInputField'
import { NamedItemInt } from 'core/application/commons/namedItem'
import countriesApi from 'core/application/countries/countriesApi'
import FormikSelectField from '../common/components/dataEntry/formik/FormikSelectField'
import Button from '../common/components/general/Button'
import regionApi from 'core/application/countries/regionApi'
import { commandRequest } from '../common/RequestUtils'
import updateStoreAddressApi from 'core/application/dashboard/storeSettings/updateStoreAddressApi'
import notification from '../common/components/feedback/Notification'
import { ADMIN_DASHBOARD_PATH } from '../dashboard/routes/StoreRoutes'
import { numberOnlyRegx } from 'common/components/utils/Regx'
import useLoginUser from '../common/hooks/useLoginUser'

export const INITIAL_STORE_SETUP = 'setup'

const formValidator = (translate: any) =>
  Yup.object({
    firstName: Yup.string().trim().required(translate('general.required')),
    lastName: Yup.string().trim().required(translate('general.required')),
    address: Yup.string().trim().required(translate('general.required')),
    city: Yup.string().trim().required(translate('general.required')),
    countryId: Yup.string().trim().required(translate('general.required')),
    postalCode: Yup.string()
      .trim()
      .required(translate('general.required'))
      .matches(numberOnlyRegx, translate('general.invalid_postal_code')),
    phoneNumber: Yup.string().trim().required(translate('general.required')),
  })

const initialValues = {}

interface InitialStoreSetupProps {}

const InitialStoreSetup: React.FC<InitialStoreSetupProps> = () => {
  const { t: translate } = useTranslation()
  const [countries, setCountries] = useState<NamedItemInt[]>([])
  const [regions, setRegions] = useState<NamedItemInt[]>([])
  const [loading, setLoading] = useState(false)
  const [submiting, setSubmiting] = useState(false)
  const [user] = useLoginUser()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      if (user) {
        if (user.isSetupCompleted) {
          window.location.href = '/'
        }
        setCountries(await countriesApi.getAll())
        setLoading(false)
      }
    })()
  }, [user])

  const onSubmit = async (values: any) => {
    setSubmiting(true)
    const result = await commandRequest(() => updateStoreAddressApi.updateStoreAddress(values))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.the_product')),
        type: 'success',
      })
      window.location.href = `${ADMIN_DASHBOARD_PATH}`
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
    setSubmiting(false)
  }

  return (
    <div className="setup-container">
      <div className={'text-center mb-16'}>
        <img
          src="https://hermex.s3.eu-central-1.amazonaws.com/assets/hermex-logo-admin.svg"
          alt="Hermex Logo"
          width={150}
        />
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={formValidator(translate)}
        onSubmit={() => {}}
      >
        {(formik) => (
          <FormikForm>
            <Card bordered={true}>
              <Skeleton loading={loading}>
                <div className={'text-center mb-32'}>
                  <h4>{translate('dashboard.setup.title')}</h4>
                  <p>{translate('dashboard.setup.subtitle')}</p>
                </div>
                <div>
                  <Row gutter={16}>
                    <Col xs={12}>
                      <Input
                        label={translate('dashboard.orders.order_details.name')}
                        name="firstName"
                        placeholder={translate('dashboard.orders.order_details.name')}
                      />
                    </Col>
                    <Col xs={12}>
                      <Input
                        label={translate('dashboard.orders.order_details.last_name')}
                        name="lastName"
                        placeholder={translate('dashboard.orders.order_details.last_name')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.orders.order_details.address')}
                        name="address"
                        placeholder={translate('dashboard.orders.order_details.address')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.orders.order_details.aptSuite')}
                        name="aptSuite"
                        placeholder={translate('dashboard.orders.order_details.aptSuite')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={24}>
                      <Input
                        label={translate('dashboard.orders.order_details.city')}
                        name="city"
                        placeholder={translate('dashboard.orders.order_details.city')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={12}>
                      <FormikSelectField
                        showSearch={true}
                        label={translate('dashboard.orders.order_details.country_name')}
                        defaultValue={''}
                        name={'countryId'}
                        onChange={async (value) => {
                          if (typeof value === 'number') {
                            setRegions(await regionApi.getByCountry(value))
                          } else {
                            setRegions([])
                          }
                        }}
                      >
                        <option key={'-1'} value={''}>
                          {translate('dashboard.orders.order_details.select_country')}
                        </option>
                        {countries.map((c) => (
                          <option key={c.id} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </FormikSelectField>
                    </Col>
                    <Col xs={12}>
                      {regions.length > 0 && (
                        <FormikSelectField
                          showSearch={true}
                          label={translate('dashboard.orders.order_details.region')}
                          defaultValue={''}
                          name={'regionId'}
                        >
                          <option key={''} value={undefined}>
                            {translate('dashboard.orders.order_details.select_region')}
                          </option>
                          {regions.map((c) => (
                            <option key={c.id} value={c.id}>
                              {c.name}
                            </option>
                          ))}
                        </FormikSelectField>
                      )}
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col xs={12}>
                      <Input
                        label={translate('dashboard.orders.order_details.postal_code')}
                        name="postalCode"
                        placeholder={translate('dashboard.orders.order_details.postal_code')}
                      />
                    </Col>
                    <Col xs={12}>
                      <Input
                        label={translate('dashboard.orders.order_details.phone_number')}
                        name="phoneNumber"
                        placeholder={translate('dashboard.orders.order_details.phone_number')}
                      />
                    </Col>
                  </Row>
                </div>
              </Skeleton>
            </Card>

            <div className={'text-center mb-16'}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!formValidator(translate).isValidSync(formik.values)}
                onClick={() => onSubmit(formik.values)}
                loading={submiting}
              >
                {translate('dashboard.save')}
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  )
}

export default InitialStoreSetup
