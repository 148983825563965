import React from 'react'

import './OrderDetails.less'

interface CategoryProps {
  title: string
  description: string | null
}

const Category: React.FC<CategoryProps> = ({ title, description }) => {
  return (
    <div>
      <h5>{title}</h5>
      <p className="color-gray-7 mb-4">{description}</p>
    </div>
  )
}

export default Category
