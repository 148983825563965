import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, notification, Row, Skeleton } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Formik, Form as FormikForm } from 'formik'
import {
  getSupportedCurrenciesForSave,
  saveSupportedCurrencies,
  SupportedCurrencyDto,
} from 'core/application/dashboard/storeSettings/storeSupportedCurrencies'
import * as Yup from 'yup'
import SupportedCurrenciesTable from './SupportedCurrenciesTable'
import Button from 'common/components/general/Button'

export const STORE_SUPPORTED_CURRENCIES_PATH = 'supported-currencies'

export const formValidator = (translate: any) =>
  Yup.object({
    supportedCurrencies: Yup.array().of(
      Yup.object().shape({
        currencyId: Yup.string().required(translate('general.required')).nullable(),
        rate: Yup.number()
          .typeError(translate('general.number_required'))
          .required(translate('general.required'))
          .moreThan(0, translate('general.minimun_required')),
        enabled: Yup.boolean().nullable().notRequired(),
      }),
    ),
  })
const SupportedCurrencies: React.FC = () => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [item, setItem] = useState<any>({})

  useEffect(() => {
    updateForm()
  }, [])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getSupportedCurrenciesForSave()))
    setLoading(false)
  }

  const onDeleteSupportedCurrency = (i: number, values: any) => {
    var currencyId = values.supportedCurrencies[i].currencyId
    var supportedCurrencies = values.supportedCurrencies.filter((currency: SupportedCurrencyDto) => {
      return currency.currencyId !== currencyId
    })
    var updatedItem = {
      ...item,
      supportedCurrencies: supportedCurrencies,
    }
    setItem(updatedItem)
  }

  const onAddSupportedCurrency = (values: any) => {
    values.supportedCurrencies.push({
      currencyId: null,
      rate: null,
      enabled: false,
    })
    var updatedItem = {
      ...item,
      supportedCurrencies: [...values.supportedCurrencies],
    }
    setItem(updatedItem)
  }

  const onSubmit = async (values: any) => {
    const request = {
      supportedCurrencies: values.supportedCurrencies,
    }
    const result = await commandRequest(() => saveSupportedCurrencies(request))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace(
          '[]',
          translate('dashboard.store_settings.supported_currencies.name'),
        ),
        type: 'success',
      })
      updateForm()
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item || {}}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card bordered={false} title={translate('dashboard.store_settings.supported_currencies.name')}>
                <FormikForm>
                  <Row>
                    <Col xs={24}>
                      <div className="text-left mb-8">
                        <Button
                          icon="far fa-plus"
                          type="primary"
                          onClick={() => onAddSupportedCurrency(formik.values)}
                          disabled={item.supportedCurrencies?.length >= item.availableCurrencies?.length}
                        >
                          {translate('dashboard.add')}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <SupportedCurrenciesTable
                        defaultCurrency={item.defaultCurrency}
                        supportedCurrencies={item.supportedCurrencies}
                        availableCurrencies={item.availableCurrencies}
                        translate={translate}
                        name="supportedCurrencies"
                        formik={formik}
                        onDeleteSupportedCurrency={onDeleteSupportedCurrency}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default SupportedCurrencies
