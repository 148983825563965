import React from 'react'
import { Typography } from 'antd'

import Icon from '../../general/Icon'

const { Text } = Typography

const FormItemError: React.FC = (props) => {
  return (
    <Text type="danger" className="font_15">
      {Object.keys(props).length > 0 && props.children !== ' ' && (
        <Icon type="fas fa-exclamation-circle" className="mr-8" />
      )}
      {props.children}
    </Text>
  )
}
export default FormItemError
