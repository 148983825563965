import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Projects from './Projects'
import { MANAGE_PROJECTS_PATH } from './ManageProjects'
import ManageProjects from './ManageProjects'
import NotFound from '../../errorPages/NotFound'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_PROJECTS_PATH}/:id?`} component={ManageProjects} />
      <Route path={`${match.path}`} component={Projects} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
