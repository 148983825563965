import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import Gallery from './Gallery'
import NotFound from '../../errorPages/NotFound'
import ManageGallery, { MANAGE_GALLERY_PATH } from './ManageGallery'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_GALLERY_PATH}/:id?`} component={ManageGallery} />
      <Route path={`${match.path}`} component={Gallery} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
