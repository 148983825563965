import React from 'react'
import { useDrag, useDrop } from 'react-dnd'

const DND_ITEM_TYPE = 'row'

export const DragableRow = ({ row, index, moveRow, ...rest }: any) => {
  const ref = React.useRef()
  const [{ isOver }, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || ({} as any)
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: any) => {
      moveRow(item.index, index)
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: DND_ITEM_TYPE,
    item: () => {
      return { index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  drag(drop(ref))
  const background = isOver ? '#c7d2fe' : 'white'
  const opacity = isDragging ? 0 : 1
  return <tr ref={ref} {...rest} style={{ cursor: 'move', opacity, background: background }} />
}
