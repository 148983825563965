import React, { useCallback, useEffect, useState } from 'react'
import { Card, Col, PageHeader, Row, Space, Table, Tag, Tooltip } from 'antd'
import getFutureAppointments from 'core/application/appointments/futureAppointments'
import { Appointment, PagedList, PagedFilter, defaultFilterAppointments } from 'core'
import useQueryRequest from 'common/hooks/useQueryRequest'
import TableDeleteButton from '../../../common/components/tables/TableDeleteButton'
import { formatDateTimeEu } from 'common/dateFormatters'
import Icon from 'common/components/general/Icon'
import { formatMoney } from 'common/moneyFormatter'
import AppointmentStatus from 'core/domain/appointments/AppointmentStatus'

import { useTranslation } from 'react-i18next'
import TableEditButton from '../../../common/components/tables/TableEditButton'
import ROUTE_PATHS from '../../../common/routePaths'
import deleteAppointments from 'core/application/appointments/deleteAppointments'
import Button from '../../../common/components/general/Button'
const { Column } = Table
export const FUTURE_APPOINTMENTS_PATH = 'future-appointments'

export const getAppointmentStatus = (translate: (key: string) => string): Map<AppointmentStatus, string> => {
  return new Map<AppointmentStatus, string>([
    [AppointmentStatus.Confirmed, 'Confirmed'],
    [AppointmentStatus.New, 'New'],
  ])
}

export const appointmentStatusTagColor = new Map<AppointmentStatus, string>([
  [AppointmentStatus.Confirmed, 'green'],
  [AppointmentStatus.New, 'red'],
])

export const FutureAppointments: React.FC = () => {
  const { t: translate } = useTranslation()
  const [filter, setFilter] = useState<PagedFilter>(defaultFilterAppointments)
  const [appointments, isLoading, sendQueryRequest] = useQueryRequest<PagedList<Appointment> | null>(null)

  useEffect(() => {
    ;(async () => {
      await sendQueryRequest(() => getFutureAppointments(filter))
    })()
  }, [filter])

  return (
    <Card
      bordered={true}
      title={
        <Row>
          <Col xs={16}>
            <h4>{'List of web future appointments'}</h4>
          </Col>
        </Row>
      }
    >
      <Table
        rowKey={(record) => record.id}
        loading={isLoading}
        dataSource={appointments?.items}
        scroll={{ x: true }}
        onChange={(pagination: any, filter, sorter: any) => {
          setFilter({
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || 'date',
            orderDescending: sorter.order !== 'ascend',
          })
        }}
        pagination={{
          total: appointments?.totalItemCount,
          pageSize: appointments?.pageSize,
          current: appointments?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      >
        <Column
          title="Date"
          dataIndex="date"
          key="date"
          sorter={true}
          sortDirections={['ascend', 'descend']}
          defaultSortOrder="ascend"
          render={(date: any) => formatDateTimeEu(date)}
        />

        <Column
          title="First Name"
          dataIndex="firstName"
          key="firstName"
          sorter={true}
          sortDirections={['ascend', 'descend']}
        />

        <Column
          title="Last Name"
          dataIndex="lastName"
          key="lastName"
          sorter={true}
          sortDirections={['ascend', 'descend']}
        />

        <Column title="Phone Number" dataIndex="phoneNumber" key="phoneNumber" />

        <Column
          title="Price"
          dataIndex="price"
          key="price"
          sorter={true}
          sortDirections={['ascend', 'descend']}
          render={(text: string, record: any) => formatMoney(record.currency, record.price)}
        />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(status: AppointmentStatus, record: any) => (
            <Tag color={appointmentStatusTagColor.get(status)}>{getAppointmentStatus(translate).get(status)}</Tag>
          )}
        />

        <Column
          title="Completed"
          dataIndex="completed"
          key="completed"
          sorter={true}
          sortDirections={['ascend', 'descend']}
          align={'center'}
          render={(value) => (
            <Space>
              {value ? (
                <Icon type="far fa-check-circle" className="color-green-5 mr-8" />
              ) : (
                <Icon type="far fa-ban" className="color-yellow-7 mr-8" />
              )}
            </Space>
          )}
        />

        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(value, record: any) => (
            <Space>
              <TableEditButton editUrl={`${ROUTE_PATHS.APPOINTMENTS}/manage-appointment/${record.id}`} />
              <TableDeleteButton
                onDelete={async () => await deleteAppointments(record.id)}
                onDeleted={async () => await sendQueryRequest(() => getFutureAppointments(filter))}
              />
            </Space>
          )}
        />
      </Table>
    </Card>
  )
}

export default FutureAppointments
