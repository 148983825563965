import React, { useState } from 'react'
import Input from '../Input'
import Label from '../Label'
import Uploader from '../upload'
import { Col, Row } from 'antd'

type InputType = 'text' | 'image' | 'link'

export type InputDescriptor = {
  name: string
  type: InputType
  element: Element
  onChange: (val: string, update?: (element: Element) => void) => void
}

type Props = { [K in keyof InputDescriptor]: InputDescriptor[K] }

const parser = new DOMParser()

const findLinkTextElement = (element: Element): Element | undefined => {
  const document = parser.parseFromString(element.innerHTML, 'text/html')
  const parsedElements = Array.from(document.querySelectorAll('*'))
  return parsedElements.length === 1
    ? parsedElements[0]
    : parsedElements.find((el) => {
        const innerHTML = el.innerHTML.trim()
        return innerHTML.length > 0 && !/<[^>]+>/.test(innerHTML)
      })
}

const DynamicInput = ({ name, type, element, onChange }: Props) => {
  const label = name.charAt(0).toUpperCase() + name.slice(1).replaceAll(/[-_]/g, ' ')

  switch (type) {
    case 'text':
      return (
        <>
          <Label>{label}</Label>
          <Input placeholder="content" value={element.innerHTML} onChange={(e) => onChange(e.currentTarget.value)} />
        </>
      )
    case 'link': {
      const textElement = findLinkTextElement(element)

      if (!textElement) {
        return (
          <>
            <Label>{`${label} Url`}</Label>
            <Input
              value={element.getAttribute('href') ?? ''}
              placeholder="Ex: www.google.com or /about"
              onChange={(e) =>
                onChange(e.currentTarget.value, (element) => element.setAttribute('href', e.currentTarget.value))
              }
            />
          </>
        )
      }

      return (
        <Row gutter={16}>
          <Col span={12}>
            <Label>{label}</Label>
            <Input value={textElement.innerHTML} onChange={(e) => onChange(e.currentTarget.value)} />
          </Col>
          <Col span={12}>
            <Label>{`${label} Url`}</Label>
            <Input
              value={element.getAttribute('href') ?? ''}
              placeholder="Ex: www.google.com or /about"
              onChange={(e) =>
                onChange(e.currentTarget.value, (element) => element.setAttribute('href', e.currentTarget.value))
              }
            />
          </Col>
        </Row>
      )
    }
    case 'image': {
      const initialValue = element.getAttribute('src')?.split('/').slice(3).join('/') ?? ''
      console.debug(initialValue)
      return (
        <ImageInput
          label={label}
          initialValue={initialValue}
          onChange={(v) => onChange(v, (element) => element.setAttribute('src', v))}
        />
      )
    }
    default:
      return null
  }
}

const ImageInput = ({
  label,
  initialValue,
  onChange,
}: {
  label: string
  initialValue: string
  onChange: (v: string) => void
}) => {
  const [image, setImage] = useState({ filename: '', fileUrl: '' })

  return (
    <Uploader
      mode="single"
      label={label}
      value={image}
      initialValues={[initialValue]}
      onChange={(v) => (setImage(v), onChange(v.fileUrl))}
    />
  )
}

export default DynamicInput
