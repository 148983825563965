import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router-dom'
import { Skeleton, Row, Col } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import { getContactRequestById, ContactRequest } from 'core/application/dashboard/contacts/contactRequests'

import { queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import { formatDateTime } from 'common/dateFormatters'

export const CONTACT_REQUEST_DETAILS_PATH = 'contact-request-details'

const ContactRequestDetails: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const [item, setItem] = useState<ContactRequest | null>(null)
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getContactRequestById(id)))
    setLoading(false)
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Card
          bordered={true}
          title={
            <Row>
              <Col md={24} xs={24}>
                <div className="d-flex">
                  <Button type="default" className="mr-8" onClick={() => history.goBack()}>
                    <LeftOutlined />
                  </Button>
                  <h4>{translate('dashboard.contact_requests.details')}</h4>
                </div>
              </Col>
            </Row>
          }
        >
          <p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.name')}:</span> {item.name}
            </p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.email')}:</span> {item.email}
            </p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.email_sent_at')}:</span>{' '}
              {formatDateTime(item.emailSentAt)}
            </p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.phone_number')}:</span>{' '}
              {item.phone ?? '--'}
            </p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.subject')}:</span> {item.subject}
            </p>
            <p>
              <span className="text-bold">{translate('dashboard.contact_requests.message')}:</span> {item.message}
            </p>
            {item.additionalFields && Object.entries(item.additionalFields).length > 0 && (
              <>
                <br />
                <p>
                  <span className="text-bold">{translate('dashboard.contact_requests.additional_fields')}:</span>{' '}
                </p>

                {Object.entries(item.additionalFields).map((entry) => {
                  return (
                    <p>
                      <strong>{entry[0]}:</strong> {entry[1]}
                    </p>
                  )
                })}
              </>
            )}
          </p>
        </Card>
      )}
    </Skeleton>
  )
}

export default ContactRequestDetails
