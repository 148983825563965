import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import NotFound from '../../errorPages/NotFound'
import { DealOfDayWrapper, DEAL_OF_DAY_PATH } from './DealOfTheDayOrders'
import { DiscountCodeWrapper, DISCOUNT_CODE_PATH } from './DiscountCodeOrders'
import { OfferOrdersWrapper, OFFERS_ORDER_PATH } from './OfferOrders'
import Reports from './Reports'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Reports} />
      <Route exact path={`${match.path}/${DISCOUNT_CODE_PATH}`} component={DiscountCodeWrapper} />
      <Route exact path={`${match.path}/${OFFERS_ORDER_PATH}`} component={OfferOrdersWrapper} />
      <Route exact path={`${match.path}/${DEAL_OF_DAY_PATH}`} component={DealOfDayWrapper} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
