import HttpClient from './httpClient'
import store from './localStorage'

class AdminHttpClient extends HttpClient {
  protected getHeaders() {
    return store.getToken() ? { Authorization: `Bearer ${store.getToken()}` } : {}
  }
}

export default AdminHttpClient

const adminHttpClient = new AdminHttpClient()
export { adminHttpClient }
