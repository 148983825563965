import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { parseZoneFormat } from '../../../common/dateFormatters'
import { Table } from 'antd'
import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import Card from '../../../common/components/dataDisplay/Card'
import Button from '../../../common/components/general/Button'
import notification from '../../../common/components/feedback/Notification'
import getAbandonCheckouts, { PagedAbandonCheckouts } from 'core/application/dashboard/orders/getAbandonCheckouts'
import sendAbandonCheckoutEmail from 'core/application/dashboard/orders/sendAbandonCheckoutEmail'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { ABANDON_CHECKOUT_DETAILS_PATH } from './abandonCheckoutDetails'
import { useHistory } from 'react-router'
import { commandRequest } from 'common/RequestUtils'

const Component: React.FC = ({}) => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const [pagedItems, setPagedItems] = useState<PagedAbandonCheckouts>({
    items: [],
    totalItemCount: 0,
    pageSize: 1,
    pageCount: 0,
    pageNumber: 1,
  })
  const [loading, setLoading] = useState<boolean>(false)
  const [filter, setFilter] = useState<OrderedPageFilter>({
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: true,
  } as OrderedPageFilter)

  const goToAbandonCheckoutDetails = (id: string) => {
    history.push(`${ADMIN_DASHBOARD_PATH}${ABANDON_CHECKOUT_DETAILS_PATH}/${id}`)
  }

  const onSendEmailToClient = async (id: string) => {
    const result = await commandRequest(() => sendAbandonCheckoutEmail(id))
    if (!result.errors) {
      notification.open({
        message: translate('dashboard.email_sent'),
        type: 'success',
      })
      updatePagedItems()
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.id'),
        dataIndex: 'id',
        key: 'id',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="cursor_pointer" onClick={() => goToAbandonCheckoutDetails(record.id)}>
              <span>{record.id}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.clients_table.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
      },
      {
        title: translate('dashboard.clients_table.phoneNumber'),
        dataIndex: 'phone',
        key: 'phone',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: translate('dashboard.email_sent_at'),
        dataIndex: 'emailSentAt',
        key: 'emailSentAt',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        responsive: ['md'],
        render: (text: string, record: any) => (record.emailSentAt ? parseZoneFormat(record.emailSentAt) : ''),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        responsive: ['md'],
        render: (text: string, record: any) => parseZoneFormat(record.createdAt),
      },
      {
        title: translate('dashboard.action'),
        dataIndex: '',
        key: 'action',
        sorter: false,
        responsive: ['md'],
        render: (text: string, record: any) => (
          <Button
            disabled={!record.canSendEmail}
            className={'mr-16'}
            icon="fal fa-envelope"
            iconPadding={false}
            onClick={() => {
              onSendEmailToClient(record.id)
            }}
          ></Button>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const updatePagedItems = async () => {
    setLoading(true)
    const result = await getAbandonCheckouts(filter)
    setPagedItems(result)
    setLoading(false)
  }

  useEffect(() => {
    ;(async () => {
      await updatePagedItems()
    })()
  }, [filter])

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilter({
      ...filter,
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Card bordered={true} title={translate('general.abandonCheckouts')}>
      <Table
        rowKey={(record) => record.id}
        dataSource={pagedItems.items}
        onChange={onTableChange}
        loading={loading}
        columns={getColumns()}
        scroll={{ x: true, scrollToFirstRowOnChange: false }}
        pagination={{
          total: pagedItems.totalItemCount,
          pageSize: pagedItems.pageSize,
          current: pagedItems.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </Card>
  )
}

export default Component
