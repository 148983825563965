import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

import CategoriesTable from './CategoriesTable'
import { queryRequest } from '../../common/RequestUtils'
import Button from '../../common/components/general/Button'
import Card from '../../common/components/dataDisplay/Card'
import { MANAGE_CATEGORY_PATH } from './ManageCategory'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getCategories, { PagedCategories } from 'core/application/dashboard/categories/categories'
import deleteCategory from 'core/application/dashboard/categories/deleteCategory'
import { LanguageDto, getSupportedLanguages } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'

export const CATEGORIES_PATH = 'categories'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'createdAt',
  orderDescending: true,
}

const Categories: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false)
  const [categories, setCategories] = useState<PagedCategories>(pagedColumnDefaultValues)
  const [languages, setLanguages] = useState<LanguageDto[]>([])
  const history = useHistory()

  useEffect(() => {
    getLanguages()
    updateCategoriesTable()
  }, [searchFilter])

  const updateCategoriesTable = async () => {
    setLoadingCategories(true)
    const result = await queryRequest(() => getCategories(searchFilter))
    setCategories(result)
    setLoadingCategories(false)
  }

  const getLanguages = async () => {
    const result = await queryRequest(() => getSupportedLanguages())
    setLanguages(result)
  }

  return (
    <Card
      bordered={true}
      title={
        <Row>
          <Col xs={14} md={16}>
            <h4>{translate('dashboard.categories.title')}</h4>
          </Col>
          <Col xs={10} md={8}>
            <div className={'text-right'}>
              <Button
                icon="far fa-plus"
                type="primary"
                onClick={() => {
                  history.push(`${history.location.pathname}/${MANAGE_CATEGORY_PATH}`)
                }}
              >
                {translate('dashboard.add')}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <CategoriesTable
        setOrderFilter={setSearchFilter}
        filter={searchFilter}
        categories={categories}
        loadingCategories={loadingCategories}
        history={history}
        translate={translate}
        onDeleteCategory={deleteCategory}
        updateCategoriesTable={updateCategoriesTable}
        languages={languages}
      />
    </Card>
  )
}

export default Categories
