import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import RoomType from './RoomType'
import NotFound from '../../errorPages/NotFound'
import { MANAGE_ROOM_TYPE_PATH } from './ManageRoomTypes'
import ManageRoomTypes from './ManageRoomTypes'

const Routes: React.FC = () => {
  let match = useRouteMatch()

  return (
    <Switch>
      <Route path={`${match.path}/${MANAGE_ROOM_TYPE_PATH}/:id?`} component={ManageRoomTypes} />
      <Route path={`${match.path}`} component={RoomType} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Routes
