import { adminHttpClient } from '../../../commons/adminHttpClient'
import OrderedPageFilter from '../../../commons/orderedPageFilter'

const getOfferOrders = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/reports/dealOfTheDayOrders', filter)
}

export default getOfferOrders
