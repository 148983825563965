import React from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ROUTE_PATHS from '../../common/routePaths'
import NotFound from '../../errorPages/NotFound'
import Home from '../home'
import OrderDetails, { ORDER_DETAILS_PATH } from '../orders/OrderDetails'
import { CATEGORIES_PATH } from '../categories/Categories'
import Categories from '../categories'
import Products from '../products'
import { PRODUCTS_PATH } from '../products/Products'
import Banners from '../banners'
import { BANNERS_PATH } from '../banners/Banners'
import ChangePassword, { CHANGE_PASSWORD_PATH } from '../../account/profile/ChangePassword'
import { SETTINGS_PATH } from '../storeSettings/Routes'
import StoreSettings from '../storeSettings'
import { EMAIL_SUBSCRIPTIONS_PATH } from '../emailSubscriptions/Routes'
import EmailSubscriptions from '../emailSubscriptions'
import { DISCOUNTS_PATH } from '../discounts/Routes'
import Discounts from '../discounts'
import Clients, { CLIENTS_PATH } from '../clients/Clients'
import Orders from '../orders'
import { ORDERS_PATH } from '../orders'
import AbandonCheckoutDetails, {
  ABANDON_CHECKOUT_DETAILS_PATH,
} from '../orders/abandonCheckouts/abandonCheckoutDetails'
import { ARTICLES_PATH } from '../blog/Articles'
import Articles from '../blog'
import BlogCategory from '../blog/categories'
import { BLOG_CATEGORY_PATH } from '../blog/categories/BlogCategory'
import { GALLERY_PATH } from '../galleries/Gallery'
import Gallery from '../galleries'
import { GALLERY_ITEM_PATH } from '../galleries/galleryItems/GalleryItem'
import GalleryItem from '../galleries/galleryItems'
import { BRANDS_PATH } from '../brands/Brands'
import Brands from '../brands'
import Files from '../files'
import ContactRequests from '../contacts'
import Services from '../services'
import Testimonials from '../testimonials'
import TeamMembers from '../teamMembers'
import Projects from '../projects'
import { REPORTS_PATH } from 'dashboard/reports/Reports'
import Reports from 'dashboard/reports'
import Companies from '../companies'
import ManageCompanies from 'dashboard/companies/ManageCompanies'
import PageSection from '../pageSections/PageSection'
import ManagePageSection from '../pageSections/ManagePageSection'

export const ADMIN_DASHBOARD_PATH = '/'

const StoreRoutes: React.FC = () => {
  let match = useRouteMatch()
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={Home} />
      <Route exact path={`${match.path}${ORDER_DETAILS_PATH}/:id`} component={OrderDetails} />
      <Route exact path={`${match.path}${ABANDON_CHECKOUT_DETAILS_PATH}/:id`} component={AbandonCheckoutDetails} />
      <Route path={`${match.path}${PRODUCTS_PATH}`} component={Products} />
      <Route path={`${match.path}${CATEGORIES_PATH}`} component={Categories} />
      <Route path={`${match.path}${BRANDS_PATH}`} component={Brands} />
      <Route path={`${match.path}${CLIENTS_PATH}`} component={Clients} />
      <Route path={`${match.path}${BANNERS_PATH}`} component={Banners} />
      <Route path={`${match.path}${DISCOUNTS_PATH}`} component={Discounts} />
      <Route path={`${match.path}${SETTINGS_PATH}`} component={StoreSettings} />
      <Route path={`${match.path}${EMAIL_SUBSCRIPTIONS_PATH}`} component={EmailSubscriptions} />
      <Route path={`${match.path}${ORDERS_PATH}`} component={Orders} />
      <Route path={`${match.path}${ARTICLES_PATH}`} component={Articles} />
      <Route path={`${match.path}${BLOG_CATEGORY_PATH}`} component={BlogCategory} />
      <Route path={`${match.path}${GALLERY_PATH}`} component={Gallery} />
      <Route path={`${match.path}${GALLERY_ITEM_PATH}`} component={GalleryItem} />
      <Route path={ROUTE_PATHS.FILES} component={Files} />
      <Route path={ROUTE_PATHS.CONTACT_REQUESTS} component={ContactRequests} />
      <Route path={ROUTE_PATHS.SERVICES} component={Services} />
      <Route path={ROUTE_PATHS.TESTIMONIALS} component={Testimonials} />
      <Route path={ROUTE_PATHS.TEAM_MEMBERS} component={TeamMembers} />
      <Route path={ROUTE_PATHS.PROJECTS} component={Projects} />
      <Route path={`${match.path}${REPORTS_PATH}`} component={Reports} />
      <Route exact path={ROUTE_PATHS.COMPANIES} component={Companies} />
      <Route exact path={`${ROUTE_PATHS.COMPANIES_MANAGE}/:id?`} component={ManageCompanies} />
      <Route path={`${match.path}${CHANGE_PASSWORD_PATH}`} component={ChangePassword} />

      <Route exact path={`${ROUTE_PATHS.PAGE_SECTIONS_MANAGE}/:id?`} component={ManagePageSection} />
      <Route exact path={ROUTE_PATHS.PAGE_SECTIONS} component={PageSection} />

      <Route component={NotFound} />
    </Switch>
  )
}

export default StoreRoutes
