import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { sharedPersistedStateAction, sharedStateAction } from 'core/application/commons/sharedState/actions'

export const SharedStateKeys = {
  LoginUser: 'login-user',
}

const useSharedState = <S = undefined>(
  name: string,
  initialState?: S | (() => S),
  store?: boolean,
): [S, (newState: S) => void] => {
  const dispatch = useDispatch()

  const storedValue = useSelector((s: any) => (store ? s.sharedPersistedState[name] : s.sharedState[name]))
  const [value, setValue] = useState<S>(storedValue ?? initialState)

  const setState = (newValue: S) => {
    if (value === newValue) return

    const action = store
      ? sharedPersistedStateAction({
          value: newValue,
          name: name,
        })
      : sharedStateAction({
          value: newValue,
          name: name,
        })

    dispatch(action)
  }

  useEffect(() => {
    if (storedValue != undefined && value !== storedValue) {
      setValue(storedValue)
    }
  }, [storedValue])

  return [value, setState]
}

export default useSharedState
