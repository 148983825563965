import { adminHttpClient } from '../../commons/adminHttpClient'

export const deletePageTranslation = (pageId: string, languageId: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/pagestranslations', {
    data: { pageId: pageId, languageId: languageId },
  })
}

export interface PageTranslation {
  pageId: string
  languageId: string
  title: string | null
  defaultTitle: string
  content: string | null
}

export const getPageTranslation = (pageId: string, languageId: string): Promise<PageTranslation> => {
  return adminHttpClient.get(`/admindashboard/pagestranslations/forsave/${pageId}/${languageId}`)
}

export interface PageTranslationToSave {
  pageId: string
  languageId: string
  title: string
  content: string
}

export const savePageTranslation = (translation: PageTranslationToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/pagestranslations', translation)
}
