import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface OrdersStatistics {
  new: number
  processing: number
  delivering: number
  completed: number
  aborted: number
}

export interface StoreStatistics {
  sales: number
  clients: number
  orders: OrdersStatistics
  products: number
}

const getStoreStatistics = (): Promise<StoreStatistics[]> => {
  return adminHttpClient.get('/admindashboard/reports/storestatistics')
}

export default getStoreStatistics
