import React from 'react'
import { Popconfirm } from 'antd'

import Icon from '../../general/Icon'

import './Galleryitem.less'
import { isCss } from 'common/components/dataEntry/formik/upload/fileUtils'
import DisplayCss from 'common/components/dataEntry/formik/upload/DisplayCss'

export interface Attachment {
  fileUrl: string
}

interface ImageProps {
  attachment: Attachment
  setAttachmentToPreview: (image: Attachment) => void
  deleteAttachment: (id: string) => void
  translate: (key: string) => string
}

const GalleryItem: React.FC<ImageProps> = ({ attachment, setAttachmentToPreview, deleteAttachment, translate }) => {
  return (
    <div className="content_wrapper">
      <div className="">
        {isCss(attachment.fileUrl) ? (
          <DisplayCss fileUrl={attachment.fileUrl} />
        ) : (
          <img src={attachment.fileUrl} alt="photo" className="existing_image" />
        )}
      </div>
      <div className="existing_image_action">
        <div
          onClick={(e) => {
            setAttachmentToPreview(attachment)
          }}
        >
          <Icon type="far fa-eye" className="preview_image_icon cursor_pointer" />
        </div>
        <Popconfirm
          title={
            isCss(attachment.fileUrl) ? translate('general.delete_css_alert') : translate('general.delete_image_alert')
          }
          onConfirm={() => {
            deleteAttachment(attachment.fileUrl)
          }}
          okText={translate('general.ok_text')}
          cancelText={translate('general.cancel_text')}
        >
          <div>
            <Icon type="far fa-trash-alt" className="delete_image_icon cursor_pointer" />
          </div>
        </Popconfirm>
      </div>
    </div>
  )
}

export default GalleryItem
