import React from 'react'
import DatePicker, { DatePickerProps } from '../components/DatePicker'
import Error from './FormItemError'
import { useField, useFormikContext } from 'formik'
import Label from '../components/Label'
import moment from 'moment'
import FieldProps from './FieldProps'
import { useTranslation } from 'react-i18next'

type FormikDatePickerProps = DatePickerProps & FieldProps & { showOptionalLabel?: boolean; showTime?: boolean }

const FormikDatePickerField: React.FC<FormikDatePickerProps> = (props) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props.name)
  const context = useFormikContext()
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>

  const onDateChange = (date: any, dateString: string) => {
    const format = props.showTime ? 'yyyy-MM-DDTHH:mm' : 'YYYY-MM-DD'

    if (date) {
      context.setFieldValue(props.name, date.format(format))
    } else {
      context.setFieldValue(props.name, moment.utc(dateString).format(format))
    }
  }

  const onBlur = (): void => {
    context.setFieldTouched(props.name)
  }

  return (
    <>
      <div className={'form-group'}>
        <div>
          {props.label && (
            <Label htmlFor={props.id || props.name} className=" formik-field__input-label no-select hand-on-hover">
              {props.label}
              {props.showOptionalLabel ? `(${t('optional')})` : ''}
            </Label>
          )}
        </div>
        <div>
          <DatePicker onChange={onDateChange} {...props} className={` ${props.className}`} onBlur={onBlur} />
        </div>
        {showError && <div className="mt-4">{error}&nbsp;</div>}
      </div>
    </>
  )
}

export default FormikDatePickerField
