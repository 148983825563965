import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import getGalleryItem, { GalleryItemDto } from 'core/application/dashboard/galleries/galleryItems/galleryItem'
import { queryRequest } from 'common/RequestUtils'
import { formatDate } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { MANAGE_GALLERY_ITEM_PATH } from './ManageGalleryItem'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import deleteGalleryItem from 'core/application/dashboard/galleries/galleryItems/deleteGalleryItem'
import { Col, Row, Table } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import Button from 'common/components/general/Button'
import PagedList from 'core/application/commons/pagedList'
import OrderedPageFilter, { defaultFilter } from 'core/application/commons/orderedPageFilter'
import { LeftOutlined } from '@ant-design/icons'
import { getGalleryForSave } from 'core/application/dashboard/galleries/manageGallery'
import { GalleryDto } from 'core/application/dashboard/galleries/gallery'

export const GALLERY_ITEM_PATH = 'gallery-item'
const GalleryItem = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<PagedList<GalleryItemDto>>({} as PagedList<GalleryItemDto>)
  const [gallery, setGallery] = useState<GalleryDto | null>(null)
  const history = useHistory()

  useEffect(() => {
    ;(async () => {
      await updateTable()
      await getGallery()
    })()
  }, [searchFilter])
  const getGallery = async () => {
    setLoading(true)
    setGallery(await queryRequest(() => getGalleryForSave(id)))
    setLoading(false)
  }
  const updateTable = async () => {
    setLoading(true)
    const cloneSearchFilter: any = { ...searchFilter }
    cloneSearchFilter.galleryId = id
    setItems(await queryRequest(() => getGalleryItem(cloneSearchFilter)))
    setLoading(false)
  }
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.title'),
        dataIndex: 'title',
        key: 'title',
        width: '50%',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <Row align="middle">
              <Col md={4} xs={0} className="mr-8">
                <img src={record.fileThumbnailUrl} alt={record.title} style={{ maxWidth: '100%' }} />
              </Col>
              <Col span={6}>
                <b>{record.title}</b>
              </Col>
            </Row>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        width: '30%',
        render: (text: string, record: any) => formatDate(record.createdAt),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        width: '20%',
        sorter: false,
        render: (text: string, record: any) => (
          <div className="flex-center">
            <TableEditButton
              className="mr-16"
              editUrl={`${ADMIN_DASHBOARD_PATH}${GALLERY_ITEM_PATH}/${MANAGE_GALLERY_ITEM_PATH}/${id}/${record.id}`}
            />
            <TableDeleteButton
              disableDeleted={record.children?.length > 0}
              onDelete={() => deleteGalleryItem(record.id)}
              onDeleted={() => updateTable()}
            />
          </div>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col md={16} xs={16}>
              <div className="d-flex">
                <Button type="default" className="mr-8" onClick={() => history.goBack()}>
                  <LeftOutlined />
                </Button>
                <h4>
                  {(gallery && `${translate('dashboard.galleries.name')} / ${gallery.name}`) ||
                    translate('dashboard.galleries.name')}
                </h4>
              </div>
            </Col>
            <Col md={8} xs={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`${ADMIN_DASHBOARD_PATH}${GALLERY_ITEM_PATH}/${MANAGE_GALLERY_ITEM_PATH}/${id}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          dataSource={items.items}
          loading={loading}
          onChange={onTableChange}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: items.totalItemCount,
            pageSize: items.pageSize,
            current: items.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default GalleryItem
