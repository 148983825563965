import React, { useEffect, useState } from 'react'
import { message, Upload, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useField, useFormikContext } from 'formik'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'

import Icon from '../../../general/Icon'
import Error from '../FormItemError'
import FieldProps from '../FieldProps'
import GalleryItem, { Attachment } from '../../../dataDisplay/galleryItem/GalleryItem'
import { queryRequest } from 'common/RequestUtils'
import Modal from '../../../feedback/Modal'
import fileService from 'core/application/files/files'
import { isImage, isVideo, isAudio, isPdf, isWord, isZip, isRar } from './fileUtils'
import { uploadImage, uploadFile } from './uploadImage'

import './Upload.less'
import DisplayVideo from './DisplayVideo'
import DisplayPdf from './DisplayPdf'
import DisplayAudio from './DisplayAudio'
import DisplayWord from './DisplayWord'
import { v4 } from 'uuid'
import DisplayZipFile from './DisplayZip'
import { useIsMounted } from 'common/hooks/useIsMounted'

interface UploaderProps extends FieldProps {
  fieldName: string
  initialValues: string[]
  resizeToWidth: number
  resizeToHeight: number
  optimizedResize: boolean
  mode: 'single' | 'multiple'
  maxUploads: number
  showOptionalLabel?: boolean
}

interface FileDto {
  filename: string
  fileUrl: string
}
const validFiles = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'video/mp4',
  'video/webm',
  'text/css',
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'audio/mpeg',
  'application/zip',
  'application/x-rar',
  'application/x-rar-compressed',
  'application/vnd.rar',
  'application/octet-stream',
  'application/x-zip-compressed',
  'multipart/x-zip',
]
const Uploader: React.FC<UploaderProps> = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState<FileDto[]>([])
  const [_, meta] = useField(props)
  const [fileToPreview, setFileToPreview] = useState<Attachment | null>(null)
  const { t: translate } = useTranslation()
  const context = useFormikContext()
  const isMounted = useIsMounted()

  const updateFileUrls = async () => {
    if (!props.initialValues) return

    let result: FileDto[] = []
    for (let i = 0; i < props.initialValues.length; i++) {
      if (props.initialValues[i] !== null && props.initialValues[i] !== '') {
        const fileUrl = await queryRequest(() => fileService.getFileUrl(props.initialValues[i]))
        result.push({ filename: props.initialValues[i], fileUrl })
      }
    }
    setFiles(result)
  }

  useEffect(() => {
    if (!isMounted()) return
    ;(async () => {
      setLoading(true)
      await updateFileUrls()
      setLoading(false)
    })()
  }, [isMounted])

  const handleCustomRequest = async (file: any) => {
    setLoading(true)
    try {
      let result = null
      if (isImage(file.file.name)) {
        result = await uploadImage(file.file, {
          optimizedResize: props.optimizedResize ?? false,
          resizeToHeight: props.resizeToHeight ?? 720,
          resizeToWidth: props.resizeToWidth ?? 1080,
        })
      } else if (
        isVideo(file.file.name) ||
        isAudio(file.file.name) ||
        isPdf(file.file.name) ||
        isWord(file.file.name) ||
        isZip(file.file.name) ||
        isRar(file.file.name)
      ) {
        result = await uploadFile(file.file)
      } else {
        notification.open({ type: 'error', message: translate('general.not_allowed_format') })
        return
      }

      if (props.mode === 'single') {
        setFiles([{ filename: result.fileId, fileUrl: result.fileUrl }])
        context.setFieldValue(props.fieldName, result.fileId)
      } else {
        setFiles([...files, { filename: result.fileId, fileUrl: result.fileUrl }])
        let actualValues = context.getFieldProps(props.fieldName).value
        if (actualValues) {
          actualValues = [...actualValues, result.fileId]
        } else {
          actualValues = [result.fileId]
        }
        context.setFieldValue(props.fieldName, actualValues)
      }

      setLoading(false)
    } catch (errMessage) {
      message.error(errMessage as string)
      setLoading(false)
    }
  }

  const deleteFile = (filename: string) => {
    const result = files.filter((s) => s.filename !== filename)
    setFiles(result)

    if (props.mode == 'single') {
      context.setFieldValue(props.fieldName, '')
    } else {
      context.setFieldValue(
        props.fieldName,
        result.map((s) => s.filename),
      )
    }
  }

  const handleBeforeUpload = (file: any) => {
    if (!file.type.length) {
      message.error(translate('general.invalid_file_type'))
      return false
    }
    const isValidFile = validFiles.includes(file.type.toLowerCase())
    if (!isValidFile) {
      message.error(translate('general.invalid_file_type'))
    }
    const expectedSizeInMegaBytes = 100
    const actualSizeInMegaBytes = file.size / 1024 / 1024
    const isSizeValid = actualSizeInMegaBytes < expectedSizeInMegaBytes
    if (!isSizeValid) {
      message.error(translate('general.file_too_large').replace('[]', `${expectedSizeInMegaBytes}`))
    }
    return isValidFile && isSizeValid
  }

  return (
    <div>
      <label className="formik-field__input-label no-select">
        {props.label}
        {props.showOptionalLabel ? `(${t('optional')})` : ''}
      </label>
      <div className="flex flex__wrap">
        {files.map((file) => {
          return (
            <div key={v4()}>
              {file.fileUrl && isImage(file.fileUrl) && (
                <div className="gallery_item mr-8">
                  <GalleryItem
                    attachment={{
                      fileUrl: file.fileUrl,
                    }}
                    setAttachmentToPreview={() => {
                      setFileToPreview({
                        fileUrl: file.fileUrl,
                      })
                    }}
                    deleteAttachment={() => {
                      deleteFile(file.filename)
                    }}
                    translate={translate}
                  />
                </div>
              )}

              {file.fileUrl && isPdf(file.fileUrl) && (
                <DisplayPdf deleteAttachment={() => deleteFile(file.filename)} fileUrl={file.fileUrl} />
              )}
              {file.fileUrl && isAudio(file.fileUrl) && <DisplayAudio fileUrl={file.fileUrl} />}
              {file.fileUrl && isVideo(file.fileUrl) && (
                <DisplayVideo
                  deleteAttachment={() => {
                    deleteFile(file.filename)
                  }}
                  fileUrl={file.fileUrl}
                />
              )}
              {file.fileUrl && isWord(file.fileUrl) && <DisplayWord fileUrl={file.fileUrl} />}
              {file.fileUrl && isZip(file.fileUrl) && <DisplayZipFile fileUrl={file.fileUrl} />}
              {file.fileUrl && isRar(file.fileUrl) && <DisplayZipFile fileUrl={file.fileUrl} />}
            </div>
          )
        })}

        {fileToPreview && (
          <Modal
            visible={fileToPreview !== null}
            footer={null}
            width={props.resizeToWidth}
            onCancel={() => setFileToPreview(null)}
          >
            <img src={fileToPreview.fileUrl} className="fit_modal" alt="" />
          </Modal>
        )}
        {files.length < props.maxUploads && (
          <div>
            <Upload
              name="avatar"
              listType="picture-card"
              className={props.className}
              showUploadList={false}
              beforeUpload={handleBeforeUpload}
              customRequest={handleCustomRequest}
            >
              <div>
                <Icon type={loading ? 'loading' : 'plus'} />

                {loading ? <LoadingOutlined /> : <PlusOutlined />}

                <div className="ant-upload-text">{translate('general.upload')}</div>
              </div>
            </Upload>
          </div>
        )}
        {meta.touched && meta.error ? <Error>{meta.error}</Error> : null}
      </div>
    </div>
  )
}

export default Uploader
