import React, { useState, useEffect, FC } from 'react'
import { Card, Table } from 'antd'
import { useTranslation } from 'react-i18next'

import { queryRequest } from 'common/RequestUtils'
import DateRangePicker from 'common/components/dataEntry/components/DateRange'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import getOfferOrders, { PagedOfferOrders } from 'core/application/dashboard/home/statistics/offerOrders'

import { formatDate } from 'common/dateFormatters'
import { formatMoney } from 'common/moneyFormatter'

export const OFFERS_ORDER_PATH = 'offers'

const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}

const defaultFilter = {
  pageNumber: 1,
  pageSize: 10,
  orderColumn: 'startDate',
  orderDescending: true,
}

const OfferOrders: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [offers, setOffers] = useState<PagedOfferOrders>(pagedColumnDefaultValues)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      const result = await queryRequest(() => getOfferOrders(searchFilter))
      setOffers(result)
      setLoading(false)
    })()
  }, [searchFilter])

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'code',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.offers.startDate'),
        dataIndex: 'startDate',
        key: 'startDate',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.startDate)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.offers.endDate'),
        dataIndex: 'endDate',
        key: 'endDate',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.endDate)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.discount_codes.discount'),
        dataIndex: 'discount',
        key: 'discount',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.discount}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.orders.title'),
        dataIndex: 'orders',
        key: 'orders',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.count}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.price'),
        dataIndex: 'totalPrice',
        key: 'totalPrice',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatMoney(record.totalPrice, record.currency)}</span>
            </div>
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'startDate',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const onDateChange = (dates: any, dateStrings: any) => {
    setSearchFilter({ ...searchFilter, createdDateStart: dateStrings[0], createdDateEnd: dateStrings[1] })
  }

  return (
    <>
      <DateRangePicker
        onChange={onDateChange}
        placeholder={[translate('general.start_date'), translate('general.end_date')]}
        className="mb-16"
      />
      <Table
        dataSource={offers.items}
        onChange={onTableChange}
        loading={loading}
        columns={getColumns()}
        scroll={{ x: true }}
        pagination={{
          total: offers.totalItemCount,
          pageSize: offers.pageSize,
          current: offers.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </>
  )
}

export const OfferOrdersWrapper: FC = () => {
  const { t } = useTranslation()
  return (
    <Card bordered={true} title={t('dashboard.offers.title')}>
      <OfferOrders />
    </Card>
  )
}
export default OfferOrders
