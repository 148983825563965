import React from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import Card from '../../common/components/dataDisplay/Card'

import useLoginUser from '../../common/hooks/useLoginUser'
import TopProductsList from './TopProductsList'
import AverageOrderPrice from './AverageOrderPrice'
import NetProfit from './NetProfit'

export const REPORTS_PATH = 'reports'

interface HomeProps {}

const Reports: React.FC<HomeProps> = () => {
  const { t: translate } = useTranslation()
  const [user] = useLoginUser()

  if (!user) return null

  return (
    <>
      <Row gutter={10}>
        <Col xs={24} md={24} lg={8} className="flex">
          <Card bordered={true} title={translate('dashboard.products.topProductsSold')}>
            <TopProductsList />
          </Card>
        </Col>
        <Col xs={24} md={24} lg={8} className="flex">
          <Card bordered={true} title={translate('dashboard.products.averageOrderPrice')}>
            <AverageOrderPrice />
          </Card>
        </Col>
        <Col xs={24} md={24} lg={8} className="flex">
          <Card bordered={true} title={translate('dashboard.products.net_profit')}>
            <NetProfit />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Reports
