import React, { useEffect, useState, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import { Dropdown, Menu } from 'antd'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'

import Avatar from '../dataDisplay/Avatar'
import Icon from '../../../common/components/general/Icon'

import localStorage from 'core/application/commons/localStorage'
import { User } from 'core/application/account/loginUser'

import { LOGIN_PATH } from '../../../account/Login'
import { ADMIN_DASHBOARD_PATH } from '../../../dashboard/routes/StoreRoutes'
import { CHANGE_PASSWORD_PATH } from '../../../account/profile/ChangePassword'

import './HeaderStyles.less'

interface HeaderUserSectionProps {
  user: User
}

const HeaderUserSection: React.FC<HeaderUserSectionProps> = ({ user }) => {
  const [path, setPath] = useState<string>('')
  let history = useHistory()
  const { t: translate } = useTranslation()
  let location = useLocation()

  useEffect(() => {
    let pathname = location.pathname
    setPath(pathname.split('/')[1])
  }, [location])

  const logout = () => {
    localStorage.setToken('')
    history.push(`${ADMIN_DASHBOARD_PATH}${LOGIN_PATH}`)
  }

  const goToPath = (path: string) => {
    history.push(path)
  }

  const userDropdownMenu = (
    <Menu>
      <Menu.Item key="1" onClick={() => goToPath(`${ADMIN_DASHBOARD_PATH}${CHANGE_PASSWORD_PATH}`)}>
        <Icon type="far fa-key" className="mr-8" />
        <span>{translate('account.login.change_password')}</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => logout()}>
        <Icon type="far fa-sign-out" className="mr-8" />
        <span>{translate('account.log_out')}</span>
      </Menu.Item>
    </Menu>
  )

  return user ? (
    <>
      <Dropdown overlay={userDropdownMenu} trigger={['hover']} placement="bottomLeft">
        <a className="ant-dropdown-link grey-link" onClick={(e) => e.stopPropagation()}>
          <Avatar shape="circle" size="default" text={resolveAvatarText(user)} className="header-user-icon" />
        </a>
      </Dropdown>
    </>
  ) : null
}

const resolveAvatarText = (user: User): string => {
  let avatarText = user.firstName && user.firstName.length > 0 ? user.firstName[0] : ''
  if (user.lastName && user.lastName.length > 0) {
    avatarText += user.lastName[0]
  }
  return avatarText
}

export default HeaderUserSection
