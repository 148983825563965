import React, { useState } from 'react'
import { Col, Row } from 'antd'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'

import Layout from '../common/components/layout/Layout'

import Button from '../common/components/general/Button'
import Input from '../common/components/dataEntry/formik/FormikInputField'
import PasswordInput from '../common/components/dataEntry/formik/FormikPasswordField'
import Alert from '../common/components/feedback/Alert'
import { commandRequest } from '../common/RequestUtils'

import auth from 'core/application/auth/auth'
import store from 'core/application/commons/localStorage'

import { store as localStorage } from 'core/application/commons/localStorage'

import getLoginUser from 'core/application/account/loginUser'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { FORGOT_PASSROWD_PATH } from './ForgotPassword'
import './AccountStyles.less'
import useSharedState from '../common/hooks/useSharedState'
import { NamedItem } from 'core/application/commons/namedItem'

export const LOGIN_PATH = 'login'

const formValidator = (t: any) =>
  Yup.object({
    email: Yup.string().email(t('worng_email')).required(t('general.required')),
    password: Yup.string().required(t('general.required')),
  })

interface FormValues {
  email: string
  password: string
  rememberMe: boolean
}

const initialValues: FormValues = {
  email: '',
  password: '',
  rememberMe: false,
}

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [submiting, setSubmiting] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const { t: translate } = useTranslation()
  const [, setSelectedBanner] = useSharedState<NamedItem | null>('selected_banner', null, true)
  const [, setDefaultMenuId] = useSharedState<string>('settings-menu', '', true)

  const onSubmit = async (values: FormValues) => {
    setSubmiting(true)
    const res = await commandRequest(() => auth(values.email, values.password))
    if (!res.errors) {
      store.setToken(res.token)
      setSubmiting(false)
      const user = await getLoginUser()
      localStorage.set('i18nextLng', user.language)
      setSelectedBanner(null)
      setDefaultMenuId('')
      window.location.href = '/'
    } else {
      setSubmiting(false)
      setErrors(res.errors)
    }
  }

  const errorDescription = (
    <>
      <span className="block">
        {translate('account.login.forgot_password')}
        <a href={`${ADMIN_DASHBOARD_PATH}${FORGOT_PASSROWD_PATH}`} className="info-primary-color pl-8">
          {translate('account.login.change_password')}
        </a>
      </span>
    </>
  )

  return (
    <Layout>
      <Row>
        <Col xs={24} md={24} lg={12}>
          <div className="login login_container__content_center">
            <Formik
              enableReinitialize={true}
              validationSchema={() => formValidator(translate)}
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <Row>
                    <Col xs={24} sm={24} md={24}>
                      <Row>
                        <Col>
                          <div className="p-16">
                            <AppLogo />
                          </div>
                        </Col>
                      </Row>

                      <Row className="p-16">
                        <Col span={24}>
                          <Row className="form">
                            <Col span={24}>
                              <div>
                                <h3>{translate('account.login.login')}</h3>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={24}>
                              {errors.length > 0 && (
                                <Alert
                                  type="error"
                                  message={translate('account.login.password_alert')}
                                  description={errorDescription}
                                  showIcon
                                />
                              )}
                            </Col>
                          </Row>

                          <Row className="mt-8">
                            <Col xs={24} sm={24} md={24}>
                              <Input label={translate('account.login.email')} name="email"></Input>
                            </Col>
                          </Row>
                          <Row className="mt-8">
                            <Col xs={24} span={24}>
                              <PasswordInput
                                label={translate('account.login.password')}
                                name="password"
                                className="input-with-suffix__border-botton-only"
                              />
                            </Col>
                          </Row>

                          <Row className="mt-16">
                            <Col span={24}>
                              <Button
                                htmlType="submit"
                                type="primary"
                                className="button__large full_width"
                                loading={submiting}
                                disabled={!formValidator(translate).isValidSync(formik.values)}
                              >
                                {translate('account.login.login')}
                              </Button>
                              <div className="py-2">
                                <a href={`${ADMIN_DASHBOARD_PATH}${FORGOT_PASSROWD_PATH}`}>
                                  {translate('account.login.forgot_password')}
                                </a>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </form>
              )}
            </Formik>
          </div>
        </Col>

        <Col lg={12} md={0}>
          <div className="background_primary"></div>
        </Col>
      </Row>
    </Layout>
  )
}
export const StoreLogo = new Map([
  ['hermex', 'https://hermex.s3.eu-central-1.amazonaws.com/assets/hermex-logo-admin.svg'],
  [
    'empyrewebs',
    'https://hermex-stage.s3.eu-central-1.amazonaws.com/5046db9e-f282-4675-9737-ce9e5faa36f2/2022/01/cc33707ffa604c4cbc9a9c7fb091d66d.svg',
  ],
])
export const AppLogo: React.FC = () => {
  const [, domain] = window.location.host.split('.')

  return <img src={StoreLogo.get(domain) ?? StoreLogo.get('hermex')} alt="Logo" width={150} />
}
export default Login
