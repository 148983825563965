import React from 'react'
import { useField } from 'formik'
import classnames from 'classnames'

import Error from './FormItemError'
import TextArea from '../components/TextArea'
import FieldProps from './FieldProps'
import Label from '../components/Label'
import { useTranslation } from 'react-i18next'

interface TextAreaProps extends FieldProps {
  cols?: number
  rows?: number
  wrap?: string
  disabled?: boolean
  maxLength?: number
  minLength?: number
  hint?: string
  showOptionalLabel?: boolean
}

const FormikTextAreaField: React.FC<TextAreaProps> = ({ label, ...props }) => {
  const { t } = useTranslation()
  const [field, meta] = useField(props.name)
  const showError = meta.touched && meta.error
  const error = showError ? <Error>{meta.error}</Error> : <Error></Error>
  const id = props.id || props.name

  const hint = props.hint ? <span className="formik-field__span"> {props.hint}</span> : null

  const inputClassname = classnames({ border_error: showError })

  return (
    <div className={'form-group'}>
      <Label htmlFor={id} className="formik-field__input-label no-select hand-on-hover">
        {label}
        {props.showOptionalLabel ? `(${t('optional')})` : ''}
        {hint}
      </Label>
      <TextArea {...field} {...props} id={id} className={inputClassname} />
      {showError && <div className="mt-4">{error}&nbsp;</div>}
    </div>
  )
}

export default FormikTextAreaField
