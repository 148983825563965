import { adminHttpClient } from '../../commons/adminHttpClient'

export interface Banner {
  id: string
  name: string
  code: string
}

const getBanners = (): Promise<Banner> => {
  return adminHttpClient.get('/admindashboard/banners')
}

export default getBanners
