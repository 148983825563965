import { adminHttpClient } from '../commons/adminHttpClient'
import { OrderedPageFilter, PagedList } from '../commons'
import { EntityStatus } from '../../domain'

export interface SaveMenuPayload {
  id: string | undefined
  name: string
  parentId: string | null
  status: EntityStatus
  priority: number
}

export const getMenues = async (filter: OrderedPageFilter): Promise<PagedList<any>> => {
  return await adminHttpClient.get(`/Restaurants/Menus`, filter)
}

export const getMenuForSave = async (id: string | undefined) => {
  if (id) {
    return await adminHttpClient.get(`/Restaurants/Menus/ForSave/${id}`)
  }
  return await adminHttpClient.get(`/Restaurants/Menus/ForSave`)
}

export const saveMenu = async (payload: SaveMenuPayload): Promise<string> => {
  return await adminHttpClient.post(`/Restaurants/Menus`, payload)
}

export const deleteMenu = async (id: string) => {
  return await adminHttpClient.delete(`/Restaurants/Menus/${id}`, null)
}
