import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItemInt } from 'application/commons/namedItem'

export interface ProjectForSaveDto {
  id?: string
  name: string
  friendlyUrl: string
  description: string
  shortDescription: string
  priority: number
  photoIds: string[]
  status: string
  seoTitle: string
  fileId: string
  seoDescription: string
  serviceId: string
  availableServices: NamedItemInt[]
  thumbnailId: string | null
  bannerPhotoId: string
  seoImage: string
}
export enum ProjectStatusEnum {
  Draft = 0,
  Active = 1,
}
export const ProjectStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('dashboard.draft') },
    { id: 1, name: translate('general.active') },
  ]
}

export const getProjectForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/SiteDashboard/Projects/ForSave/${id}`)
  }
  return adminHttpClient.get(`/SiteDashboard/Projects/ForSave`)
}

const saveProject = (data: any): Promise<any> => {
  return adminHttpClient.post(`/SiteDashboard/Projects`, data)
}

export default saveProject
