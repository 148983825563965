import React, { useState } from 'react'

import Input from '../../common/components/dataEntry/components/Input'
import Icon from '../../common/components/general/Icon'
import Button from '../../common/components/general/Button'

import './Products.less'
import { useTranslation } from 'react-i18next'
import Switch from '../../common/components/dataEntry/components/Switch'
import { Col, Row } from 'antd'
import useFeatureFlag from '../../common/hooks/useFeatureFlag'
import { Feature } from 'core/domain/featureFlags/feature'

interface SearchInputProps {
  initialValues: SearchValues
  onSearch: (value: SearchValues) => void
  loading: boolean
}

export type SearchValues = {
  inputSearch: string | null
  outOfStock: boolean
  showOnlyArchived: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({ initialValues, onSearch, loading }) => {
  const { t: translate } = useTranslation()
  const [inputSearchValue, setInputSearchValue] = useState<any>(initialValues.inputSearch)
  const [outOfStock, setOutOfStock] = useState<any>(initialValues.outOfStock)
  const [showOnlyArchived, setShowOnlyArchived] = useState<any>(initialValues.showOnlyArchived)

  const showOnlyArchivedProductsOnAdmin = useFeatureFlag(Feature.ShowOnlyArchivedProductsOnAdmin)

  const ENTER_KEY_CODE = 13
  const search = (e: any) => {
    const value = e.target.value
    setInputSearchValue(value ?? null)
    if (e.keyCode === ENTER_KEY_CODE) {
      onSearch({
        inputSearch: inputSearchValue,
        outOfStock: outOfStock,
        showOnlyArchived: showOnlyArchived,
      })
    }
  }

  return (
    <div>
      <div className="product-search-wrap">
        <Row gutter={10}>
          <Col md={10} xs={24}>
            <Input
              placeholder={translate('dashboard.products.search_by_text')}
              suffix={searchIcon}
              defaultValue={inputSearchValue}
              onKeyUp={search}
              className="mr-8 mb-8"
            />
          </Col>
          <Col md={4} xs={24}>
            <div className="d-flex flex_space_between__center h-100">
              <div className="mb-8">
                <span className={'mr-8'}>Show out of stock?</span>
                <Switch
                  defaultChecked={outOfStock}
                  onChange={(val) => {
                    setOutOfStock(val)
                  }}
                />
              </div>
            </div>
          </Col>
          {showOnlyArchivedProductsOnAdmin && (
            <Col md={4} xs={24}>
              <div className="d-flex flex_space_between__center h-100">
                <div className="mb-8">
                  <span className={'mr-8'}>Show only archived?</span>
                  <Switch
                    defaultChecked={showOnlyArchived}
                    onChange={(val) => {
                      setShowOnlyArchived(val)
                    }}
                  />
                </div>
              </div>
            </Col>
          )}
          <Col md={4} xs={24}>
            <div className="mb-8">
              <Button
                type="default"
                onClick={() =>
                  onSearch({
                    inputSearch: inputSearchValue,
                    outOfStock: outOfStock,
                    showOnlyArchived: showOnlyArchived,
                  })
                }
                loading={loading}
                className="height_40 filter_button"
                size="large"
              >
                {translate('general.search')}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

const searchIcon = <Icon type="fal fa-search" />

export default SearchInput
