import useLoginUser from 'common/hooks/useLoginUser'
import React from 'react'

export const StoreProps = new Map([
  [
    'hermex',
    {
      logo: 'https://hermex.s3.eu-central-1.amazonaws.com/assets/hermex-logo-admin.svg',
      favicon: 'https://hermex.s3.eu-central-1.amazonaws.com/assets/admin-favicon/favicon-32x32.png',
    },
  ],
  [
    'empyrewebs',
    {
      logo: 'https://hermex-stage.s3.eu-central-1.amazonaws.com/5046db9e-f282-4675-9737-ce9e5faa36f2/2022/01/cc33707ffa604c4cbc9a9c7fb091d66d.svg',
      favicon:
        'https://hermex-stage.s3.eu-central-1.amazonaws.com/5046db9e-f282-4675-9737-ce9e5faa36f2/2022/01/03b26f585e5247319b0f9fc959104946.png',
    },
  ],
])
export const StoreContext = React.createContext({})

export const StoreProvider: React.FC = ({ children }) => {
  const store = useStoreResolverByDomain()
  const [loginUser] = useLoginUser()

  React.useEffect(() => {
    let favicon = document.getElementById('favicon') as any
    favicon.href = store.favicon
    if (loginUser) {
      window.document.title = `${loginUser.storeName} | Admin`
    }
  }, [store, loginUser])

  return <StoreContext.Provider value={[store]}>{children}</StoreContext.Provider>
}

export const useStoreContext = () => {
  const store = React.useContext(StoreContext)
  if (!store) {
    throw new Error('useStoreContext should be used inside a StoreProvider')
  }
  return store
}

export const useStoreResolverByDomain = () => {
  const [store, setStore] = React.useState<any>(StoreProps.get('hermex'))

  React.useEffect(() => {
    const [, domain] = window.location.host.split('.')
    setStore(StoreProps.get(domain ?? 'hermex'))
  }, [])

  return store
}
