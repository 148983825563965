import React from 'react'
import { Table } from 'antd'

import { formatDateTime } from 'common/dateFormatters'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedContactRequests } from 'core/application/dashboard/contacts/contactRequests'
import Button from 'common/components/general/Button'
import { ZoomInOutlined } from '@ant-design/icons'
import { CONTACT_REQUEST_DETAILS_PATH } from './ContactRequestDetails'

interface ContactRequestsTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  contactRequests: PagedContactRequests
  loading: boolean
  history: any
  translate: (key: string) => string
  updateContactRequestsTable: () => void
}

const ContactRequestsTable: React.FC<ContactRequestsTableProps> = ({
  setOrderFilter,
  contactRequests,
  loading,
  history,
  translate,
  filter,
  updateContactRequestsTable,
}) => {
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('dashboard.contact_requests.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.contact_requests.email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.email}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.contact_requests.email_sent_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <ZoomInOutlined
              onClick={() => {
                history.push(`${history.location.pathname}/${CONTACT_REQUEST_DETAILS_PATH}/${record.id}`)
              }}
            />
          </span>
        ),
      },
    ]
    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      dataSource={contactRequests.items}
      onChange={onTableChange}
      loading={loading}
      columns={getColumns()}
      scroll={{ x: true }}
      pagination={{
        total: contactRequests.totalItemCount,
        pageSize: contactRequests.pageSize,
        current: contactRequests.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default ContactRequestsTable
