import { Col, Row, Skeleton } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Formik, Form as FormikForm } from 'formik'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import Editor from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import getPaymentMethodForSave, {
  PaymentMethodForSaveDto,
  FeeType,
  PaymentTypes,
  savePaymentMethodForSave,
} from 'core/application/dashboard/storeSettings/storePaymentMethodSettings'
import { Notification } from 'common/genericNotification'
import { formValidator } from './PaymentMethodValidator'

export const MANAGE_PAYMENT_METHOD_PATH = 'manage-payment-method'

const ManagePaymentMethod: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [loading, setLoading] = useState<boolean>(false)
  const [item, setItem] = useState<PaymentMethodForSaveDto>({} as PaymentMethodForSaveDto)

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getPaymentMethodForSave(id)))
    setLoading(false)
  }

  useEffect(() => {
    updateForm()
  }, [])

  const resolvePaymentTypeSelection = (paymentTypes: any, type: string) => {
    if (type) {
      const payment = paymentTypes.filter((c: any) => c.id === type).map((c: any) => c.id)[0]
      switch (payment) {
        case PaymentTypes.BankTransfer:
          return (
            <Col span={24}>
              <Editor
                name="data"
                value={id ? item.data : ''}
                label={translate('dashboard.store_settings.payment_method.bankTransfer')}
              />
            </Col>
          )
          break
        case PaymentTypes.Stripe:
          return (
            <Col span={24}>
              <Row gutter={10}>
                <Col span={12}>
                  <Input
                    name="stripeKeys.publishableKey"
                    label={translate('dashboard.store_settings.payment_method.publishableKey')}
                    placeholder={translate('dashboard.store_settings.payment_method.publishableKey')}
                  />
                </Col>
                <Col span={12}>
                  <Input
                    name="stripeKeys.secretKey"
                    label={translate('dashboard.store_settings.payment_method.secretKey')}
                    placeholder={translate('dashboard.store_settings.payment_method.secretKey')}
                  />
                </Col>
              </Row>
            </Col>
          )
          break
        case PaymentTypes.Paypal:
          return (
            <Col span={24}>
              <Input
                name="data"
                label={translate('dashboard.store_settings.payment_method.paypalKey')}
                placeholder={translate('dashboard.store_settings.payment_method.paypalKey')}
              />
            </Col>
          )
          break
      }
    }
  }

  const onSubmit = async (values: PaymentMethodForSaveDto) => {
    const request = {
      paymentMethodId: values.paymentMethodId,
      data: values.data,
      stripeKeys: values.stripeKeys,
      active: values.active,
      priority: values.priority,
      fee: values.fee,
      feeType: values.feeType,
      restrictToCountries: values.restrictToCountries,
    }
    const result = await commandRequest(() => savePaymentMethodForSave(request))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.store_settings.the_page')))
  }

  return (
    <Skeleton active loading={loading} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={
                  id
                    ? translate('dashboard.store_settings.payment_method.edit')
                    : translate('dashboard.store_settings.payment_method.add')
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="paymentMethodId"
                        placeholder={translate('dashboard.store_settings.payment_method.paymentMethodId')}
                        label={translate('dashboard.store_settings.payment_method.paymentMethodId')}
                        defaultValue={id ? [item.paymentMethodId] : []}
                        disabled={!!id}
                      >
                        {item.availablePaymentMethods &&
                          item.availablePaymentMethods.map((payment) => (
                            <option key={payment.id} value={payment.id}>
                              {payment.name}
                            </option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    {item.availablePaymentMethods &&
                      resolvePaymentTypeSelection(item.availablePaymentMethods, formik.values.paymentMethodId)}
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="restrictToCountries"
                        placeholder={translate('dashboard.store_settings.payment_method.restrictToCountries')}
                        label={translate('dashboard.store_settings.payment_method.restrictToCountries')}
                        defaultValue={item.restrictToCountries ? item.restrictToCountries : []}
                        showArrow={true}
                        mode="multiple"
                      >
                        {item.availableCountries &&
                          item.availableCountries.map((payment) => (
                            <option key={payment.id} value={payment.id}>
                              {payment.name}
                            </option>
                          ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col span={12}>
                      <Select
                        name="feeType"
                        placeholder={translate('dashboard.store_settings.payment_method.feeType')}
                        label={translate('dashboard.store_settings.payment_method.feeType')}
                        defaultValue={id ? [item.feeType] : []}
                      >
                        {FeeType.map((type: any) => (
                          <option key={type.id} value={type.id}>
                            {type.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={12}>
                      <InputNumber
                        name="fee"
                        label={translate('dashboard.store_settings.payment_method.fee')}
                        placeholder={translate('dashboard.store_settings.payment_method.fee')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        name="priority"
                        label={translate('dashboard.store_settings.payment_method.priority')}
                        placeholder={translate('dashboard.store_settings.payment_method.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Checkbox
                        name="active"
                        label={translate('dashboard.store_settings.payment_method.active')}
                        placeholder={translate('dashboard.store_settings.payment_method.active')}
                        defaultChecked={item.active}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManagePaymentMethod
