import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import NotFound from './errorPages/NotFound'
import AdminDashboard from './dashboard'
import Login, { LOGIN_PATH } from './account/Login'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import InitialStoreSetup, { INITIAL_STORE_SETUP } from './setup/InitialStoreSetup'
import ForgotPassword, { FORGOT_PASSROWD_PATH } from 'account/ForgotPassword'
import ResetPassword, { RESET_PASSWORD_PATH } from 'account/ResetPassword'

const Routes: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route exact path={`${ADMIN_DASHBOARD_PATH}${LOGIN_PATH}`} component={Login} />
        <Route exact path={`${ADMIN_DASHBOARD_PATH}${FORGOT_PASSROWD_PATH}`} component={ForgotPassword} />
        <Route exact path={`${ADMIN_DASHBOARD_PATH}${RESET_PASSWORD_PATH}`} component={ResetPassword} />
        <Route exact path={`${ADMIN_DASHBOARD_PATH}${INITIAL_STORE_SETUP}`} component={InitialStoreSetup} />
        <Route path={`${ADMIN_DASHBOARD_PATH}`} component={AdminDashboard} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default Routes
