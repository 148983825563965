import React, { useState } from 'react'

interface ShowMoreTextProps {
  text: string
  length: number
  translate: (key: string) => string
  className?: string
}

const ShowMoreText = (props: ShowMoreTextProps) => {
  const [displayText, setDisplayText] = useState<string>(
    props.text.length > props.length ? props.text.substring(0, props.length) : props.text,
  )
  const [showMore, setShowMore] = useState<boolean>(props.text.length > props.length)
  const [showLess, setShowLess] = useState<boolean>(false)

  const clickShowMore = () => {
    setShowMore(false)
    setShowLess(true)
    setDisplayText(props.text)
  }

  const clickShowLess = () => {
    setShowMore(true)
    setShowLess(false)
    setDisplayText(props.text.substring(0, props.length))
  }

  return (
    <>
      <div className={props.className}>
        {displayText}
        {showMore && (
          <span>
            ...{' '}
            <a href="#" onClick={clickShowMore}>
              {props.translate('general.show_more')}
            </a>
          </span>
        )}
        {showLess && (
          <span>
            {' '}
            <a href="#" onClick={clickShowLess}>
              {props.translate('general.show_less')}
            </a>
          </span>
        )}
      </div>
    </>
  )
}

export default ShowMoreText
