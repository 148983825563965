import OrderedPageFilter, { defaultFilter } from 'core/application/commons/orderedPageFilter'
import PagedList from 'core/application/commons/pagedList'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getRoomTypes, {
  RoomTypeDto,
  RoomTypeStatusEnum,
  RoomTypeStatus,
} from 'core/application/dashboard/roomTypes/roomTypes'
import { useHistory } from 'react-router-dom'
import { queryRequest } from 'common/RequestUtils'
import { formatDate } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import Tag from 'common/components/dataDisplay/Tag'
import Card from 'common/components/dataDisplay/Card'
import { Col, Row, Table } from 'antd'
import Button from 'common/components/general/Button'
import { MANAGE_ROOM_TYPE_PATH } from './ManageRoomTypes'
import deleteRoomType from 'core/application/dashboard/roomTypes/deleteRoomType'

export const ROOM_TYPE_PATH = 'room-type'
const roomTypeTagColor = new Map<RoomTypeStatusEnum, string>([
  [RoomTypeStatusEnum.Draft, '#fbbc06'],
  [RoomTypeStatusEnum.Active, '#23af47'],
])
const RoomType: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>({
    ...defaultFilter,
    orderColumn: 'priority',
    orderDescending: false,
  })
  const [item, setitem] = useState<PagedList<RoomTypeDto>>({} as PagedList<RoomTypeDto>)
  const [loading, setloading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    updateTable()
  }, [searchFilter])

  const updateTable = async () => {
    setloading(true)
    setitem(await queryRequest(() => getRoomTypes(searchFilter)))
    setloading(false)
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.priority'),
        dataIndex: 'priority',
        key: 'priority',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.priority}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <Tag color={roomTypeTagColor.get(record.status)}>
                {RoomTypeStatus(translate).find((c) => c.id === record.status).name}
              </Tag>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        responsive: ['md'],
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${ROOM_TYPE_PATH}/${MANAGE_ROOM_TYPE_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteRoomType(record.id)}
                onDeleted={() => updateTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }
  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }
  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={16}>
              <h4>{translate('dashboard.room_types.name')}</h4>
            </Col>
            <Col xs={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => history.push(`${history.location.pathname}/${MANAGE_ROOM_TYPE_PATH}`)}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table
          rowKey={(record: any) => record.id}
          dataSource={item.items}
          onChange={onTableChange}
          loading={loading}
          columns={getColumns()}
          scroll={{ x: true }}
          pagination={{
            total: item.totalItemCount,
            pageSize: item.pageSize,
            current: item.pageNumber,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '40'],
          }}
        />
      </Card>
    </>
  )
}

export default RoomType
