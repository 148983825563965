import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import saveTestimonials, {
  getTestimonialsForSave,
  TestimonialsForSaveDto,
  TestimonialsStatus,
} from 'core/application/dashboard/testimonials/manageTestimonials'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import { Notification } from 'common/genericNotification'
import { Row, Col, Skeleton } from 'antd'
import Input from '../../common/components/dataEntry/formik/FormikInputField'
import InputNumber from '../../common/components/dataEntry/formik/FormikInputNumberField'
import Uploader from '../../common/components/dataEntry/formik/upload/FormikUploaderField'
import Card from '../../common/components/dataDisplay/Card'
import { Formik, Form as FormikForm } from 'formik'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import FormSaveCancelButtons from '../../common/components/forms/SaveCancelFormButtons'
import { formValidator } from './TestimonialsValidator'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'

export const MANAGE_TESTIMONIALS_PATH = 'manage-testimonials'
const ManageTestimonials: React.FC = () => {
  const { t: translate } = useTranslation()
  const { id } = useParams<any>()
  const [item, setItem] = useState<TestimonialsForSaveDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getTestimonialsForSave(id)))
    setLoading(false)
  }
  const onSubmit = async (values: any) => {
    const result = await commandRequest(() => saveTestimonials(values))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.testimonials.name')))
  }
  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          validate={(v) => console.log(v.description)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.testimonials.edit') : translate('dashboard.testimonials.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.author')}
                        name="author"
                        placeholder={translate('general.author')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('dashboard.priority')}
                        name="priority"
                        placeholder={translate('dashboard.priority')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        name="description"
                        value={item?.description}
                        label={translate('dashboard.description')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={`${translate('general.title_company')} (Optional)`}
                        name="titleCompany"
                        placeholder={translate('general.title_company')}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Uploader
                        label={`${translate('dashboard.photo')}`}
                        initialValues={[item.photoId]}
                        name="photoId"
                        resizeToWidth={1080}
                        resizeToHeight={720}
                        fieldName="photoId"
                        optimizedResize={true}
                        mode="single"
                        maxUploads={1}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Select
                        name="status"
                        showSearch={true}
                        label={`${translate('dashboard.status')}`}
                        placeholder={translate('dashboard.status')}
                        defaultValue={id ? [item.status] : []}
                      >
                        {TestimonialsStatus(translate).map((item) => (
                          <option key={`${item.id}`} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <InputNumber
                        label={translate('general.rate')}
                        name="rate"
                        placeholder={translate('general.rate')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageTestimonials
