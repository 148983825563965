import { adminHttpClient } from '../../../commons/adminHttpClient'

export interface AverageOrderPriceRequest {
  startDate?: string | null
  endDate?: string | null
}

export interface AverageOrderPriceResponse {
  price: number
  currency: string
}
const getAverageOrderPrice = (filter: AverageOrderPriceRequest): Promise<AverageOrderPriceResponse> => {
  return adminHttpClient.get('/admindashboard/reports/averageOrderPrice', filter)
}

export default getAverageOrderPrice
