import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Table, Row, Col, notification, PageHeader } from 'antd'
import * as Yup from 'yup'
import { getMenuItems, saveMenuItem } from 'core'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Formik } from 'formik'
import FormikInputField from 'common/components/dataEntry/formik/FormikInputField'
import { PlusOutlined, DeleteOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import useCommandRequest from 'common/hooks/useCommandRequest'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import useUrlQueryParam from 'common/hooks/useUrlQueryParam'
import FormikTextAreaField from 'common/components/dataEntry/formik/FormikTextAreaField'
import { useTranslation } from 'react-i18next'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import update from 'immutability-helper'
import { queryRequest } from 'common/RequestUtils'
import { DragableRow } from './DragableRow'

const { Column } = Table

const initialValues = { id: null, name: '', description: '', price: null, priority: 0 }

const formValidator = (t: Function) =>
  Yup.object({
    menuItems: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t('general.required')).nullable(),
        price: Yup.number().min(0, t('general.minimun_required')).nullable(),
        description: Yup.string().required(t('general.required')).nullable(),
      }),
    ),
  })

export default function MenuItems() {
  const history = useHistory()
  const { t } = useTranslation()
  const { menuId }: any = useParams()
  const menuName = useUrlQueryParam('menu-name')
  const [menues, setMenues] = useState<any>([initialValues])
  const [isLoading, setIsLoading] = useState(false)
  const [mutation, isSubmitting] = useCommandRequest()

  const getMenuItemsQuery = useCallback(async (menuId: string) => {
    setIsLoading(true)
    const result = await queryRequest(() => getMenuItems(menuId))
    setMenues(result)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    ;(async () => {
      await getMenuItemsQuery(menuId)
    })()
  }, [getMenuItemsQuery, menuId])

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = menues[dragIndex]
    const updatedMenues = update(menues, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragRecord],
      ],
    })
    const orderedMenues = updatedMenues.map((menu: any, index: number) => ({ ...menu, priority: index }))
    setMenues(orderedMenues)
  }

  const submitHandler = async (request: any, { resetForm }: any) => {
    const result = await mutation(() => saveMenuItem(request))
    if (result.errors) resetForm()
    if (result.completed) {
      notification.success({ message: t('general.saved').replace('[]', t('dashboard.menues.menu_item.title')) })
      await getMenuItemsQuery(menuId)
    }
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Formik
        enableReinitialize
        validationSchema={() => formValidator(t)}
        initialValues={{ menuItems: menues, restaurantMenuId: menuId }}
        onSubmit={submitHandler}
      >
        {({ values, setValues }) => (
          <Form>
            <Card
              title={
                <PageHeader
                  className="p-0"
                  onBack={() => history.goBack()}
                  title={`${t('dashboard.menues.menu_item.title')}, ${menuName}`}
                />
              }
              extra={
                <Row gutter={10}>
                  <Col>
                    <Button
                      icon={<PlusOutlined />}
                      loading={isSubmitting}
                      onClick={() => {
                        const newMenuItems = [...values.menuItems]
                        setValues({ ...values, menuItems: [...newMenuItems, { ...initialValues, id: null }] })
                      }}
                    >
                      {t('dashboard.add')}
                    </Button>
                  </Col>
                  <Col>
                    <Button htmlType="submit" type="primary">
                      {t('general.save')}
                    </Button>
                  </Col>
                </Row>
              }
            >
              <Table
                rowKey={(record: any) => record?.id || record}
                loading={isLoading}
                dataSource={values.menuItems}
                scroll={{ x: 'max-content' }}
                onRow={(record, rowIndex, ...rest) => {
                  return {
                    row: record,
                    index: rowIndex,
                    moveRow: moveRow,
                    ...rest,
                  } as any
                }}
                components={{
                  body: {
                    row: DragableRow,
                  },
                }}
                pagination={{
                  pageSize: 50,
                }}
              >
                <Column
                  title={'Move'}
                  dataIndex="move"
                  align="center"
                  render={() => <FullscreenExitOutlined size={3} style={{ cursor: 'pointer' }} />}
                />
                <Column
                  title={t('general.name')}
                  dataIndex="name"
                  render={(values, record, index) => {
                    return <FormikInputField name={`menuItems[${index}].name`} placeholder={t('general.name')} />
                  }}
                />
                <Column
                  title={t('dashboard.price')}
                  dataIndex="price"
                  render={(values, record, index) => (
                    <InputNumber name={`menuItems[${index}].price`} placeholder={t('dashboard.price')} />
                  )}
                />
                <Column
                  title={t('dashboard.priceText')}
                  dataIndex="priceText"
                  render={(values, record, index) => (
                    <FormikInputField name={`menuItems[${index}].priceText`} placeholder={t('dashboard.priceText')} />
                  )}
                />
                <Column
                  title={t('dashboard.priority')}
                  dataIndex="priority"
                  render={(values, record, index) => (
                    <InputNumber name={`menuItems[${index}].priority`} placeholder={t('dashboard.priority')} />
                  )}
                />
                <Column
                  title={t('dashboard.description')}
                  dataIndex="description"
                  render={(values, record, index) => (
                    <FormikTextAreaField
                      name={`menuItems[${index}].description`}
                      placeholder={t('dashboard.description')}
                    />
                  )}
                />
                <Column
                  title="Action"
                  dataIndex="action"
                  render={(val, record, index) => (
                    <Button
                      className="mb-3"
                      onClick={() => {
                        const updatedValues = [...values.menuItems]
                        updatedValues.splice(index, 1)
                        setValues({ ...values, menuItems: updatedValues })
                      }}
                      icon={<DeleteOutlined />}
                    />
                  )}
                />
              </Table>
            </Card>
          </Form>
        )}
      </Formik>
    </DndProvider>
  )
}

export const RESTAURANT_MENU_ITEMS_PATH = 'restaurant/menu-items/:menuId'
