import { adminHttpClient } from '../../commons/adminHttpClient'

export type StoreAdminPanelSettingsProps = {
  showStatistics: boolean
}
export const getStoreAdminPanelSettings = (): Promise<StoreAdminPanelSettingsProps> => {
  return adminHttpClient.get('/admindashboard/AdminPanelSettings')
}
export const updateStoreAdminPanelSettings = (data: StoreAdminPanelSettingsProps) => {
  return adminHttpClient.put('/admindashboard/AdminPanelSettings', data)
}
