import React from 'react'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import Button from 'common/components/general/Button'
import { SupportedCurrencyDto } from 'core/application/dashboard/storeSettings/storeSupportedCurrencies'
import Checkbox from 'common/components/dataEntry/formik/FormikCheckboxField'
import { Table } from 'antd'
import { useFormikContext } from 'formik'

interface SupportedCurrenciesTableProps {
  defaultCurrency: string
  supportedCurrencies: SupportedCurrencyDto[]
  translate: (key: string) => string
  name: string
  availableCurrencies: any[]
  onDeleteSupportedCurrency: (i: number, values: any) => any
  formik: any
}
const SupportedCurrenciesTable: React.FC<SupportedCurrenciesTableProps> = ({
  defaultCurrency,
  supportedCurrencies,
  translate,
  name,
  availableCurrencies,
  onDeleteSupportedCurrency,
  formik,
}) => {
  const { values, setFieldValue } = useFormikContext()

  const getCurrencyConversionText = (i: number, values: any) => {
    var supportedCurrency = values.supportedCurrencies[i]
    if (supportedCurrency && supportedCurrency.rate && supportedCurrency.rate > 0 && supportedCurrency.currencyId) {
      return `${supportedCurrency.rate} ${supportedCurrency.currencyId}`
    }
    return '?'
  }

  const getColumns = () => {
    const columns = [
      {
        title: translate('dashboard.store_settings.supported_currencies.currency'),
        dataIndex: 'currencyId',
        key: 'currencyId',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => (
          <Select
            className="mt-16"
            name={`${name}[${i}].currencyId`}
            showSearch={true}
            placeholder={translate('dashboard.store_settings.supported_currencies.currency')}
            value={(values as any).supportedCurrencies[i].currencyId}
          >
            {availableCurrencies.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.id}
              </option>
            ))}
          </Select>
        ),
      },
      {
        title: `${translate('dashboard.store_settings.supported_currencies.rate')} (${defaultCurrency})`,
        dataIndex: 'rate',
        key: 'rate',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => (
          <InputNumber
            label={`1 ${defaultCurrency} = ${getCurrencyConversionText(i, values)}`}
            name={`${name}[${i}].rate`}
            placeholder={translate('dashboard.store_settings.supported_currencies.rate')}
          />
        ),
      },
      {
        title: translate('dashboard.store_settings.supported_currencies.enabled'),
        dataIndex: 'enabled',
        key: 'enabled',
        sorter: false,
        editable: true,
        render: (text: string, record: any, i: number) => (
          <span className="text_center mt-16">
            <div>
              <Checkbox
                name={`${name}[${i}].enabled`}
                onChange={() => setFieldValue(`${name}[${i}].enabled`, !(values as any).supportedCurrencies[i].enabled)}
                defaultChecked={record.enabled}
              />
            </div>
          </span>
        ),
      },
      {
        title: '',
        dataIndex: 'actions',
        key: 'actions',
        sorter: false,
        render: (text: string, record: any, i: number) => (
          <div className="form-group mt-16">
            <Button
              className={'error-button'}
              icon="far fa-trash-alt"
              onClick={() => {
                onDeleteSupportedCurrency(i, values)
              }}
            />
          </div>
        ),
      },
    ]

    return columns
  }

  return <Table dataSource={supportedCurrencies} columns={getColumns()} pagination={false} />
}

export default SupportedCurrenciesTable
