import { adminHttpClient } from '../../commons/adminHttpClient'
import { NamedItemInt } from '../../commons/namedItem'
import { NamedItemChildren } from '../../commons/namedItemChildren'

export interface Category {
  id: string | null
  name: string
  friendlyUrl: string
  photoId: string
  priority: number
  parentId: string
  status: number
  availableParents: NamedItemChildren[]
}

export enum CategoryStatusEnum {
  Active = 0,
  Archived = 1,
  Draft = 2,
}
export const CategoryStatus = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('general.active') },
    { id: 1, name: translate('general.archived') },
    { id: 2, name: translate('dashboard.draft') },
  ]
}

const manageProduct = (id: string | undefined): Promise<Category> => {
  if (id) {
    return adminHttpClient.get(`/admindashboard/categories/forsave/${id}`)
  }
  return adminHttpClient.get(`/admindashboard/categories/forsave`)
}

export interface CategoryToSave {
  id?: string | null
  name: string
  friendlyUrl: string
  photoId: string
  priority: number
  status: number
  parentId: string | null
}

export const saveCategory = (category: CategoryToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/categories', category)
}

export default manageProduct
