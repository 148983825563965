import React from 'react'
import { Table, Row } from 'antd'

import { formatDateTime } from '../../common/dateFormatters'
import TableDeleteButton from '../../common/components/tables/TableDeleteButton'
import TableEditButton from '../../common/components/tables/TableEditButton'
import { ADMIN_DASHBOARD_PATH } from '../routes/StoreRoutes'
import { CATEGORIES_PATH } from './Categories'
import { MANAGE_CATEGORY_PATH } from './ManageCategory'

import OrderedPageFilter from 'core/application/commons/orderedPageFilter'
import { PagedCategories } from 'core/application/dashboard/categories/categories'
import { LanguageDto } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import Tag from 'common/components/dataEntry/components/Tag'
import Display from 'common/components/dataDisplay/Tag'
import { MANAGE_CATEGORY_TRANSLATION_PATH } from './ManageCategoryTranslation'

import '../Dashboard.less'
import { CategoryStatus, CategoryStatusEnum } from 'core/application/dashboard/categories/manageCategory'

interface CategoriesTableProps {
  setOrderFilter: (value: OrderedPageFilter) => void
  filter: OrderedPageFilter
  categories: PagedCategories
  loadingCategories: boolean
  history: any
  translate: (key: string) => string
  onDeleteCategory: (id: string) => void
  updateCategoriesTable: () => void
  languages: LanguageDto[]
}

export const categoriesTagColor = new Map<CategoryStatusEnum, string>([
  [CategoryStatusEnum.Draft, '#e94437'],
  [CategoryStatusEnum.Active, '#23af47'],
  [CategoryStatusEnum.Archived, '#fbbc06'],
])

const CategoriesTable: React.FC<CategoriesTableProps> = ({
  setOrderFilter,
  filter,
  categories,
  loadingCategories,
  history,
  translate,
  onDeleteCategory,
  updateCategoriesTable,
  languages,
}) => {
  const getCategories = () => {
    return categories.items.map((item: any) => {
      if (Array.isArray(item.children))
        if (item.children.length) {
          item.children = item.children.map((subItem: any) => {
            subItem.children = Array.isArray(subItem.children) && subItem.children.length ? subItem.children : null
            return subItem
          })
        }
      return item
    })
  }
  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        responsive: ['md'],
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <Display color={categoriesTagColor.get(record.status)}>
                {CategoryStatus(translate).find((c) => c.id === record.status)?.name}
              </Display>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.priority'),
        dataIndex: 'priority',
        key: 'priority',
        align: 'center',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.priority}</span>
            </div>
          </span>
        ),
      },
      languages.length > 0
        ? {
            title: translate('dashboard.translations'),
            dataIndex: 'translations',
            key: 'translations',
            align: 'center',
            responsive: ['md'],
            render: (text: string, record: any) => {
              return (
                <span>
                  <div>
                    <Row className="translation-row">
                      {languages.map((val: LanguageDto, index: number) => (
                        <Tag
                          className="translation-tag"
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/${MANAGE_CATEGORY_TRANSLATION_PATH}/${record.id}/${val.id}`,
                            )
                          }}
                        >
                          {val.name}
                        </Tag>
                      ))}
                    </Row>
                  </div>
                </span>
              )
            },
          }
        : {},
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${CATEGORIES_PATH}/${MANAGE_CATEGORY_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => onDeleteCategory(record.id)}
                onDeleted={() => updateCategoriesTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === filter.orderColumn)
    columnToSort.sortOrder = filter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setOrderFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  return (
    <Table
      rowKey={(record) => record.id}
      dataSource={getCategories()}
      onChange={onTableChange}
      loading={loadingCategories}
      columns={getColumns()}
      pagination={{
        total: categories.totalItemCount,
        pageSize: categories.pageSize,
        current: categories.pageNumber,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40'],
      }}
    />
  )
}

export default CategoriesTable
