import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Col, Row, Table } from 'antd'
import Card from 'common/components/dataDisplay/Card'

import {
  defaultFilter,
  defaultPagedColumns,
  GetPagedBidsRequest,
  getProductAuctionBids,
  PagedBidResponse,
  PagedList,
} from 'core'
import { useQueryRequestV2 } from 'common/hooks'

const ProductAuction = () => {
  const { productId } = useParams<{ productId: string }>()

  const [filter, setFilter] = useState<GetPagedBidsRequest>({
    ...defaultFilter,
    orderColumn: 'BidAmount',
    productId: null,
  })

  const { data, isLoading, sendQuery } = useQueryRequestV2<PagedList<PagedBidResponse>>({
    initialState: defaultPagedColumns,
  })

  useEffect(() => {
    setFilter((f: GetPagedBidsRequest) => {
      sendQuery(() => getProductAuctionBids({ ...f, productId }))
      return { ...f, productId }
    })
  }, [productId])

  return (
    <Card>
      <Row>
        <Col span={24}>
          <Table />
        </Col>
      </Row>
    </Card>
  )
}

export default ProductAuction
