import React from 'react'
import { Provider } from 'react-redux'
import Store from './Store'
import Routes from './Routes'
import { StoreProvider } from 'common/providers/StoreProvider'

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <Provider store={Store}>
      <StoreProvider>
        <Routes />
      </StoreProvider>
    </Provider>
  )
}

export default App
