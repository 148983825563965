import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Skeleton } from 'antd'

import StoreDashboardWrapper from './StoreDashboardWrapper'
import { LOGIN_PATH } from '../account/Login'
import StoreRoutes, { ADMIN_DASHBOARD_PATH } from './routes/StoreRoutes'
import ManagementRoutes from './routes/ManagementRoutes'
import getLoginUser, { User } from 'core/application/account/loginUser'
import { Permissions } from 'core/application/account/permissions'
import { INITIAL_STORE_SETUP } from '../setup/InitialStoreSetup'
import { StoreType } from 'core/application/account/storeType'
import SiteRoutes from './routes/SiteRoutes'
import useLoginUser from '../common/hooks/useLoginUser'

interface AdminDashboardProps {}

const AdminDashboard: React.FC<AdminDashboardProps> = () => {
  const history = useHistory()
  const [user, setUser] = useLoginUser()

  useEffect(() => {
    getLoginUser().then((user: User | null) => {
      if (!user) {
        history.push(`${ADMIN_DASHBOARD_PATH}${LOGIN_PATH}`)
        return
      }
      setUser(user)
      if (!userHasPermission(user)) {
        history.push(`${ADMIN_DASHBOARD_PATH}${LOGIN_PATH}`)
        return
      }
      if (!user.isSetupCompleted) {
        history.push(`${ADMIN_DASHBOARD_PATH}${INITIAL_STORE_SETUP}`)
        return
      }
    })
  }, [])

  if (!user) return <Skeleton loading />

  return <CurrentComponent storeType={user.storeType} />
}
export const CurrentComponent: React.FC<{ storeType: StoreType }> = ({ storeType }) => {
  switch (storeType) {
    case StoreType.Store:
      return (
        <StoreDashboardWrapper>
          <StoreRoutes />
        </StoreDashboardWrapper>
      )
    case StoreType.Site:
      return (
        <StoreDashboardWrapper>
          <SiteRoutes />
        </StoreDashboardWrapper>
      )
    case StoreType.Management:
      return (
        <StoreDashboardWrapper>
          <ManagementRoutes />
        </StoreDashboardWrapper>
      )
    default:
      return null
  }
}
function userHasPermission(user: User) {
  return [Permissions.AccessAdminDashboard, Permissions.SuperAdminPermission].some((role: Permissions) =>
    user.permissions.includes(role),
  )
}
export default AdminDashboard
