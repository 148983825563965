import React, { useCallback, useState } from 'react'
import { Card, Col, PageHeader, Row, Table, Tag, Tooltip, Input, Space } from 'antd'
import { getStores, Store, PagedList, PagedFilter, defaultFilter, StoreStatus, StoreType } from 'core'
import useQueryRequest from 'common/hooks/useQueryRequest'
import { formatDateTime } from 'common/dateFormatters'
import { EditFilled, UnorderedListOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

const { Column } = Table
const { Search } = Input

const storeStatus = new Map([
  [StoreStatus.Active, { title: 'Active', color: 'green' }],
  [StoreStatus.Inactive, { title: 'Inactive', color: 'red' }],
  [StoreStatus.AwaitingForApproval, { title: 'AwaitingForApproval', color: 'orange' }],
])

const storeType = new Map([
  [StoreType.Store, { title: 'Store', color: 'green' }],
  [StoreType.Site, { title: 'Site', color: 'orange' }],
  [StoreType.Management, { title: 'Management', color: 'blue' }],
])

export const ManagementHome: React.FC = () => {
  const history = useHistory()
  const [filter, setFilter] = useState<PagedFilter>(defaultFilter)
  const getStoresList = useCallback(() => getStores(filter), [filter])
  const [stores, isLoading] = useQueryRequest<PagedList<Store> | null>(null, getStoresList, {
    variables: filter,
  })

  const handleSearch = async (searchValue: string) => {
    setFilter({ ...filter, searchValue })
  }

  return (
    <Card
      title={<PageHeader className="p-0" title="List of shops in hermex" />}
      extra={
        <Search
          className="search-input"
          onSearch={handleSearch}
          enterButton="Search"
          placeholder="Search store"
          allowClear
        />
      }
    >
      <Table
        rowKey={(record) => record.id}
        loading={isLoading}
        dataSource={stores?.items}
        onChange={(pagination: any, filter, sorter: any) => {
          setFilter({
            searchValue: '',
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            orderColumn: sorter.field || 'createdAt',
            orderDescending: sorter.order !== 'ascend',
          })
        }}
        pagination={{
          total: stores?.totalItemCount,
          pageSize: stores?.pageSize,
          current: stores?.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      >
        <Column title="Name" dataIndex="name" key="name" sorter sortDirections={['ascend', 'descend']} />
        <Column title="Email" dataIndex="email" key="email" sorter sortDirections={['ascend', 'descend']} />
        <Column
          title="Custom domain"
          dataIndex="customDomain"
          key="customDomain"
          render={(value: string) => value ?? 'N/A'}
        />
        <Column
          title="Sub domain"
          dataIndex="subDomain"
          key="subDomain"
          sorter
          sortDirections={['ascend', 'descend']}
        />
        <Column
          title="Status"
          dataIndex="status"
          sorter
          sortDirections={['ascend', 'descend']}
          key="status"
          render={(value) => <Tag color={storeStatus.get(value)?.color as any}>{storeStatus.get(value)?.title}</Tag>}
        />
        <Column
          title="Type"
          dataIndex="type"
          key="type"
          sorter
          sortDirections={['ascend', 'descend']}
          render={(value) => <Tag color={storeType.get(value)?.color as any}>{storeType.get(value)?.title}</Tag>}
        />
        <Column title="Language" dataIndex="languageId" key="languageId" />
        <Column title="Currency" dataIndex="currencyId" key="currencyId" />
        <Column
          title="Created at"
          dataIndex="createdAt"
          key="createdAt"
          sorter
          defaultSortOrder="descend"
          sortDirections={['ascend', 'descend']}
          render={(value) => formatDateTime(value)}
        />
        <Column
          title="Action"
          dataIndex="action"
          key="action"
          render={(value, record: any) => (
            <Row gutter={10}>
              <Col>
                <Tooltip title="Modules">
                  <Link to={`/modules/${record.id}/?store=${record.name}`}>
                    <UnorderedListOutlined />
                  </Link>
                </Tooltip>
              </Col>
              <Col>
                <EditFilled type="button" color="blue" onClick={() => history.push(`/manage-store/${record.id}`)} />
              </Col>
            </Row>
          )}
        />
      </Table>
    </Card>
  )
}

export default ManagementHome
