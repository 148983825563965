import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'

import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { GalleryDto } from 'core/application/dashboard/galleries/gallery'
import saveGallery, { getGalleryForSave } from 'core/application/dashboard/galleries/manageGallery'
import { Formik, Form as FormikForm } from 'formik'
import { Row, Col, Skeleton } from 'antd'

import Input from '../../common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from '../../common/RequestUtils'
import Card from '../../common/components/dataDisplay/Card'
import generateFriendlyUrl from '../../common/generateFriendlyUrl'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { Notification } from 'common/genericNotification'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().nullable().trim().notRequired(),
    friendlyUrl: Yup.string().nullable().trim().required(translate('general.required')),
  })
export const MANAGE_GALLERY_PATH = 'manage-gallery'
const ManageGallery: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id } = useParams<any>()
  const [item, setItem] = useState<GalleryDto | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  useEffect(() => {
    updateForm()
  }, [id])

  const updateForm = async () => {
    setLoading(true)
    setItem(await queryRequest(() => getGalleryForSave(id)))
    setLoading(false)
  }

  const onSubmit = async (data: any) => {
    const request = {
      id: id,
      name: data.name,
      friendlyUrl: data.friendlyUrl,
    }
    const result = await commandRequest(() => saveGallery(request))
    Notification(result, translate('general.saved').replace('[]', translate('dashboard.galleries.name')))
  }
  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div className="form_center">
              <Card
                bordered={true}
                title={id ? translate('dashboard.galleries.edit') : translate('dashboard.galleries.add')}
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('general.name')}
                        name="name"
                        placeholder={translate('general.name')}
                        onBlur={(e: any) => {
                          if (id) return
                          formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                        }}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.friendly_url')}
                        name="friendlyUrl"
                        placeholder={translate('dashboard.friendly_url')}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageGallery
