import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface CancelButtonProps {
  title?: string
  backUrl?: string
}
const CancelButton: React.FC<CancelButtonProps> = ({ title, backUrl }) => {
  const history = useHistory()
  const { t: translate } = useTranslation()

  const handleCancelClick = () => {
    if (backUrl) {
      history.push(backUrl)
    } else {
      history.goBack()
    }
  }

  return (
    <>
      <Button onClick={handleCancelClick}>{title ? title : translate('dashboard.cancel')}</Button>
    </>
  )
}

export default CancelButton
