import { NamedItemInt } from 'application/commons/namedItem'
import { adminHttpClient } from '../../../commons/adminHttpClient'
import OrderedPageFilter from '../../../commons/orderedPageFilter'
import PagedList from '../../../commons/pagedList'

export interface GalleryItemDto {
  id: string
  title: string
  fileUrl: string
  type: any
  galleryName: string
}
export const getGalleryItemTypes = (translate: (key: string) => string): NamedItemInt[] => {
  return [
    { id: 0, name: translate('general.photo') },
    { id: 1, name: translate('general.video') },
  ]
}
const getGalleryItem = (filter: OrderedPageFilter): Promise<PagedList<GalleryItemDto>> => {
  return adminHttpClient.get(`/AdminDashboard/GalleryItem`, filter)
}

export default getGalleryItem
