import React from 'react'
import { FileZipOutlined } from '@ant-design/icons'
import FileDisplayProps from './FileDisplayProps'

interface DisplayZipFileProps extends FileDisplayProps {}

export const DisplayZipFile: React.FC<DisplayZipFileProps> = ({ fileUrl }) => (
  <div className={'mb-16'}>
    <a href={fileUrl} target="_blank">
      <FileZipOutlined style={{ fontSize: '64px' }} />
    </a>
  </div>
)

export default DisplayZipFile
