import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Card from 'common/components/dataDisplay/Card'
import { Col, Row, Table } from 'antd'
import Button from 'common/components/general/Button'
import OrderedPageFilter, { defaultFilter } from 'core/application/commons/orderedPageFilter'
import getArticles, { ArticleStatus } from 'core/application/dashboard/blog/articles'
import { queryRequest } from 'common/RequestUtils'
import { formatDateTime } from 'common/dateFormatters'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { MANAGE_ARTICLE_PATH } from './ManageArticle'
import Tag from 'common/components/dataDisplay/Tag'
import EditTag from 'common/components/dataEntry/components/Tag'
import deleteArticle from 'core/application/dashboard/blog/deleteArticle'
import { LanguageDto, getSupportedLanguages } from 'core/application/dashboard/storeSettings/storeSupportedLanguages'
import { MANAGE_ARTICLE_TRANSLATION_PATH } from './ManageArticleTranslation'
export const ARTICLES_PATH = 'articles'
const pagedColumnDefaultValues = {
  items: [],
  pageCount: 1,
  pageNumber: 1,
  pageSize: 10,
  totalItemCount: 0,
}
export const getArticleStatus = (translate: (key: string) => string): Map<ArticleStatus, string> => {
  return new Map<ArticleStatus, string>([
    [ArticleStatus.Draft, translate('dashboard.blog.articles.draft')],
    [ArticleStatus.Published, translate('dashboard.blog.articles.published')],
  ])
}
export const articleTagColor = new Map<ArticleStatus, string>([
  [ArticleStatus.Draft, '#fbbc06'],
  [ArticleStatus.Published, '#23af47'],
])
const Articles: React.FC = () => {
  const { t: translate } = useTranslation()
  const [searchFilter, setSearchFilter] = useState<OrderedPageFilter>(defaultFilter)
  const [loading, setLoading] = useState<boolean>(false)
  const [item, setitem] = useState<any>(pagedColumnDefaultValues)
  const [languages, setLanguages] = useState<LanguageDto[]>([])
  const history = useHistory()

  useEffect(() => {
    getLanguages()
    updateTable()
  }, [searchFilter])

  const updateTable = async () => {
    setLoading(true)
    setitem(await queryRequest(() => getArticles(searchFilter)))
    setLoading(false)
  }

  const getLanguages = async () => {
    const result = await queryRequest(() => getSupportedLanguages())
    setLanguages(result)
  }

  const onTableChange = (pagination: any, filters: any, sorter: any) => {
    setSearchFilter({
      pageNumber: pagination.current,
      pageSize: pagination.pageSize,
      orderColumn: sorter.field || 'createdAt',
      orderDescending: sorter.order !== 'ascend',
    })
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.title}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        align: 'center',
        render: (text: string, record: any) => {
          return (
            <>
              <div>
                <Tag color={articleTagColor.get(record.status.key)}>
                  {getArticleStatus(translate).get(record.status.key)}
                </Tag>
              </div>
            </>
          )
        },
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        responsive: ['md'],
        sorter: true,
        sortDirections: ['descend', 'ascend'],
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDateTime(record.created)}</span>
            </div>
          </span>
        ),
      },
      languages.length > 0
        ? {
            title: translate('dashboard.translations'),
            dataIndex: 'translations',
            key: 'translations',
            align: 'center',
            responsive: ['md'],
            render: (text: string, record: any) => {
              return (
                <span>
                  <div>
                    <Row className="translation-row">
                      {languages.map((val: LanguageDto, index: number) => (
                        <EditTag
                          className="translation-tag"
                          onClick={() => {
                            history.push(
                              `${history.location.pathname}/${MANAGE_ARTICLE_TRANSLATION_PATH}/${record.id}/${val.id}`,
                            )
                          }}
                        >
                          {val.name}
                        </EditTag>
                      ))}
                    </Row>
                  </div>
                </span>
              )
            },
          }
        : {},
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${ARTICLES_PATH}/${MANAGE_ARTICLE_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteArticle(record.id)}
                onDeleted={() => updateTable()}
              />
            </div>
          </span>
        ),
      },
    ]

    const columnToSort: any = columns.find((c) => c.key === searchFilter.orderColumn)
    columnToSort.sortOrder = searchFilter.orderDescending ? 'descend' : 'ascend'

    return columns
  }

  return (
    <Card
      bordered={true}
      title={
        <Row>
          <Col xs={14} md={16}>
            <h4>{translate('dashboard.blog.article')}</h4>
          </Col>
          <Col xs={10} md={8}>
            <div className={'text-right'}>
              <Button
                icon="far fa-plus"
                type="primary"
                onClick={() => {
                  history.push(`${history.location.pathname}/${MANAGE_ARTICLE_PATH}`)
                }}
              >
                {translate('dashboard.add')}
              </Button>
            </div>
          </Col>
        </Row>
      }
    >
      <Table
        rowKey={(record) => record.id}
        dataSource={item.items}
        onChange={onTableChange}
        loading={loading}
        columns={getColumns()}
        scroll={{ x: true }}
        pagination={{
          total: item.totalItemCount,
          pageSize: item.pageSize,
          current: item.pageNumber,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30', '40'],
        }}
      />
    </Card>
  )
}

export default Articles
