import { PagedFilter, PagedList } from 'application/commons'
import { adminHttpClient } from '../commons/adminHttpClient'
import { Module } from '../../domain/store/module'

export interface ModuleCommand {
  storeId: string
  moduleId: string
  parent: string | null
  priority: number
}
type Payload = PagedFilter & {
  storeId: string
}
export const getStoreModules = async (request: Payload): Promise<PagedList<any>> =>
  await adminHttpClient.get('/management/module', request)

export const getAvailableStoreModuleForSave = async (storeId: string, moduleId: string) => {
  if (moduleId) {
    return await adminHttpClient.get(`/management/module/forsave/${storeId}/${moduleId}`)
  }
  return await adminHttpClient.get(`/management/module/forsave/${storeId}`)
}

export const saveDefaultModules = async (storeId: string) => {
  return await adminHttpClient.post(`/management/module/defaultModules`, { storeId })
}

export const saveStoreModule = async (request: ModuleCommand): Promise<number> => {
  return await adminHttpClient.post(`/management/module`, request)
}

export const deleteStoreModule = async (storeId: string, module: Module): Promise<any> => {
  return adminHttpClient.delete(`/management/module`, {
    data: {
      storeId: storeId,
      module: module,
    },
  })
}
