import PagedList from '../../commons/pagedList'
import OrderedPageFilter from '../../commons/orderedPageFilter'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface PagedAbandonCheckouts extends PagedList<AbandonCheckout> {}

export interface AbandonCheckout {
  id: string
  email: string
  phone: string
  createdAt: string
  emailSentAt: string
  canSendEmail: boolean
}

const getAbandonCheckouts = (filter: OrderedPageFilter): Promise<PagedAbandonCheckouts> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/abandonCheckouts', filter)
}

export default getAbandonCheckouts
