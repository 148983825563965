import React, { useState, useEffect } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Row, Col, Skeleton } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import { commandRequest, queryRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'

import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { EmailForSave, saveEmail, getEmailForSave } from 'core/application/emailSubscriptions/emails'
import FormikHtmlEditorField from 'common/components/dataEntry/formik/FormikHtmlEditorField'
import { EMAILS_PATH } from '.'
import { EMAIL_SUBSCRIPTIONS_PATH } from '../Routes'

export const EMAILS_PATH_EDIT = 'email-edit'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().nullable().required(translate('general.required')),
    subject: Yup.string().nullable().trim().required(translate('general.required')),
    body: Yup.string().trim().nullable().required(translate('general.required')),
  })

const ManageEmail: React.FC = () => {
  const { t: translate } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()
  const [item, setItem] = useState<EmailForSave | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)

  const updateForm = async () => {
    setLoadingData(true)
    const result = await queryRequest(() => getEmailForSave(id))
    setItem(result)
    setLoadingData(false)
  }
  useEffect(() => {
    updateForm()
  }, [])

  const onSubmit = async (values: EmailForSave) => {
    let emailToSave: EmailForSave = {
      id: values.id === '00000000-0000-0000-0000-000000000000' ? null : values.id,
      name: values.name,
      subject: values.subject,
      body: values.body,
    }

    const result = await commandRequest(() => saveEmail(emailToSave))
    if (!result.errors) {
      notification.open({
        message: translate('general.saved').replace('[]', translate('dashboard.email_subscriptions.emails.the_email')),
        type: 'success',
      })
      history.push(`/${EMAIL_SUBSCRIPTIONS_PATH}/${EMAILS_PATH}`)
    } else {
      notification.open({
        message: result.errors[0],
        type: 'error',
      })
    }
  }

  return (
    <Skeleton active loading={loadingData} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {' '}
      {item && (
        <Formik
          enableReinitialize={true}
          initialValues={item}
          validationSchema={formValidator(translate)}
          onSubmit={() => {}}
        >
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={
                  id
                    ? translate('dashboard.email_subscriptions.emails.edit_email')
                    : translate('dashboard.email_subscriptions.emails.add_email')
                }
              >
                <FormikForm>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.email_subscriptions.emails.name')}
                        name="name"
                        placeholder={translate('dashboard.email_subscriptions.emails.name')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={translate('dashboard.email_subscriptions.emails.subject')}
                        name="subject"
                        placeholder={translate('dashboard.email_subscriptions.emails.subject')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <FormikHtmlEditorField
                        label={translate('dashboard.email_subscriptions.emails.body')}
                        name={'body'}
                        placeholder={translate('dashboard.email_subscriptions.emails.body')}
                        value={item?.body}
                      />
                    </Col>
                  </Row>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(translate).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageEmail
