import React from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'

import { Skeleton, Typography } from 'antd'

import { useTranslation } from 'react-i18next'
import { useParams, useHistory } from 'react-router'

import notification from 'common/components/feedback/Notification'
import Input from 'common/components/dataEntry/formik/FormikInputField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import Card from 'common/components/dataDisplay/Card'
import generateFriendlyUrl from 'common/generateFriendlyUrl'
import FormSaveCancelButtons from 'common/components/forms/SaveCancelFormButtons'
import { NamedItemInt } from 'core/application/commons/namedItem'
import { PageStatus } from 'core/domain/store/pageStatus'
import {
  StorePageForSave,
  StorePageToSave,
  saveStorePage,
  getStorePageForSave,
} from 'core/application/dashboard/storeSettings/storePageSettings'
import { SETTINGS_PATH } from '../Routes'
import { STORE_PAGE_SETTINGS_PATH } from '.'
import FormikCodeEditor from '../../../common/components/dataEntry/formik/FormikCodeEditor'
import useQueryRequest from '../../../common/hooks/useQueryRequest'
import useCommandRequest from '../../../common/hooks/useCommandRequest'
import { FormikSeoFields } from '../../../common/components/dataEntry/formik/FormikSeoFields'

export const STORE_PAGE_SETTINGS_PATH_EDIT = 'store-page-settings-edit'
const { Text } = Typography
export const getPageStatuses = (translate: (key: string) => string): NamedItemInt[] => {
  const statuses: NamedItemInt[] = []
  statuses.push({
    id: PageStatus.Active,
    name: translate('dashboard.store_settings.edit_page_form.statuses.active'),
  })
  statuses.push({
    id: PageStatus.Archived,
    name: translate('dashboard.store_settings.edit_page_form.statuses.archived'),
  })
  statuses.push({
    id: PageStatus.Draft,
    name: translate('dashboard.store_settings.edit_page_form.statuses.draft'),
  })
  return statuses
}

const formValidator = (t: any) =>
  Yup.object({
    title: Yup.string().trim().nullable().required(t('general.required')),
    friendlyUrl: Yup.string().nullable().trim().required(t('general.required')),
    content: Yup.string().trim().nullable().required(t('general.required')),
    status: Yup.number()
      .required(t('general.required'))
      .typeError(t('general.required'))
      .oneOf([PageStatus.Active, PageStatus.Archived, PageStatus.Draft], t('general.required')),
    template: Yup.string().trim().nullable().notRequired(),
  })

const ManageStorePage: React.FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id }: any = useParams()

  const [item] = useQueryRequest<StorePageForSave | undefined>(undefined, () => getStorePageForSave(id))
  const [sendCommandRequest, isCommandLoading] = useCommandRequest()

  if (!item) return <Skeleton loading />

  const onSubmit = async (values: StorePageForSave) => {
    let pageToSave: StorePageToSave = {
      ...values,
      id: id,
    }

    const result = await sendCommandRequest(() => saveStorePage(pageToSave))

    if (result.completed) {
      notification.success(t('general.saved').replace('[]', t('dashboard.store_settings.the_page')))
      history.push(`/${SETTINGS_PATH}/${STORE_PAGE_SETTINGS_PATH}`)
    }
  }

  return (
    <Skeleton active loading={!item} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik enableReinitialize={true} initialValues={item} validationSchema={formValidator(t)} onSubmit={() => {}}>
          {(formik) => (
            <div>
              <Card
                bordered={false}
                title={id ? t('dashboard.store_settings.edit_page') : t('dashboard.store_settings.add_page')}
              >
                <FormikForm>
                  <Input
                    label={t('dashboard.store_settings.edit_page_form.title')}
                    name="title"
                    onBlur={(e: any) => {
                      formik.setFieldValue('friendlyUrl', generateFriendlyUrl(e.currentTarget.value))
                    }}
                  />

                  <Input label={t('dashboard.store_settings.edit_page_form.friendlyUrl')} name="friendlyUrl" />

                  <Select
                    label={t('dashboard.store_settings.edit_page_form.status')}
                    name="status"
                    showArrow={true}
                    placeholder={t('dashboard.store_settings.edit_page_form.status')}
                  >
                    {getPageStatuses(t).map((status) => (
                      <option key={status.id} value={status.id}>
                        {status.name}
                      </option>
                    ))}
                  </Select>

                  {id && (
                    <Select
                      label="Code revision"
                      name="codeRevisionValue"
                      style={{ marginBottom: '10px' }}
                      onSelect={(value) => {
                        formik.setFieldValue('content', value)
                      }}
                      placeholder="Code Revisions"
                    >
                      {item.codeRevisions?.length &&
                        item.codeRevisions?.map((c, i) => (
                          <option key={i} value={c.content}>
                            {c.name}
                          </option>
                        ))}
                    </Select>
                  )}
                  <FormikCodeEditor name={'content'} label={t('dashboard.store_settings.edit_page_form.content')} />
                  {id && formik.values?.content !== item.content && (
                    <Text
                      style={{ cursor: 'pointer', color: '#096dd9' }}
                      underline
                      onClick={() => formik.setFieldValue('content', item.content)}
                    >
                      Return current content.
                    </Text>
                  )}
                  <div className={'mt-48'}>
                    <FormikSeoFields />
                  </div>
                </FormikForm>
              </Card>

              <div className={'text-center mb-16'}>
                <FormSaveCancelButtons
                  disabledSave={!formValidator(t).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values as any)}
                  loading={isCommandLoading}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default ManageStorePage
