import React from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LineChart, Line } from 'recharts'

import { MonthInfo } from 'core/application/dashboard/home/statistics/orderStatistics'

interface OrdersChartProps {
  items: MonthInfo[]
}

const OrdersChart: React.FC<OrdersChartProps> = ({ items }) => {
  return (
    <div style={{ width: '100%', height: 235, margin: 0 }}>
      <ResponsiveContainer width="100%" height={222}>
        <LineChart width={730} height={250} data={items} margin={{ top: 10, left: -15, right: 10, bottom: 0 }}>
          <CartesianGrid vertical={false} stroke="#F0F0F0" />
          <XAxis dataKey="month" stroke="#F0F0F0" tick={{ fill: '#707377' }} />
          <YAxis stroke="white" tick={{ fill: '#707377' }} />
          <Tooltip />
          <Line type="monotone" dataKey="sales" stroke="#25378b" strokeWidth={2} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default OrdersChart
