import React from 'react'
import { Card, Col, notification, PageHeader, Row, Select as AntSelect } from 'antd'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { Modules } from 'core/domain/store/module'
import Button from 'common/components/general/Button'
import InputNumber from 'common/components/dataEntry/formik/FormikInputNumberField'
import useCommandRequest from 'common/hooks/useCommandRequest'
import { getAvailableStoreModuleForSave, saveStoreModule } from 'core'
import useQueryRequest from 'common/hooks/useQueryRequest'
import { useTranslation } from 'react-i18next'

const { Option } = AntSelect
const initialValues = {
  availableModules: [],
  moduleId: null,
  parent: null,
  priority: 0,
}
const formValidator = (translate: any) =>
  Yup.object({
    moduleId: Yup.number().required(translate('general.required')),
    priority: Yup.number().moreThan(0).required(translate('general.required')),
  })
export default function ManageModule() {
  const { t } = useTranslation()
  const history = useHistory()
  const { storeId, moduleId }: any = useParams()
  const [mutation, isSubmitting] = useCommandRequest()
  const [module, isLoading] = useQueryRequest(initialValues, () => getAvailableStoreModuleForSave(storeId, moduleId))

  const submitHandler = async (values: any) => {
    const request = { ...values, storeId: storeId }
    const result = await mutation(() => saveStoreModule(request))
    if (result.completed) {
      notification.success({ message: 'Module saved successfully' })
      history.goBack()
    }
  }
  return (
    <Card
      loading={isLoading}
      title={<PageHeader className="p-0" title="Mange module" onBack={() => history.goBack()} />}
    >
      <Formik initialValues={module} onSubmit={submitHandler}>
        {({ values }) => (
          <Form>
            <Row>
              <Col span={12}>
                <Select label="Module" name="moduleId" defaultValue={[module.moduleId]} placeholder="Module" allowClear>
                  {module.availableModules.map((id: number) => (
                    <Option key={id} value={id}>
                      {Modules.get(id)}
                    </Option>
                  ))}
                </Select>
                <Select
                  label="Parent module"
                  name="parent"
                  defaultValue={[module.parent]}
                  placeholder="Parent module"
                  disabled={!values?.moduleId}
                  allowClear
                >
                  {module.availableModules.map((id: number) => (
                    <Option key={id} value={id} disabled={id === values.moduleId}>
                      {Modules.get(id)}
                    </Option>
                  ))}
                </Select>
                <InputNumber name="priority" label="Priority" placeholder="Priority" min={0} required />
                <Button
                  disabled={!formValidator(t).isValidSync(values)}
                  htmlType="submit"
                  loading={isSubmitting}
                  type="primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </Card>
  )
}

ManageModule.url = 'manage-module/:storeId/:moduleId?'
