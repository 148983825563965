import { Moment } from 'moment'
import OrderedPageFilter from '../commons/orderedPageFilter'
import { adminHttpClient } from '../commons/adminHttpClient'

export interface EmailSubscriptionsPaged {
  items: EmailSubscription[]
  totalItemCount: number
  pageCount: number
  pageNumber: number
  pageSize: number
}

export interface EmailSubscription {
  id: string
  name: string
  createdAt: Moment
  email: string
}

export const getEmailSubscriptions = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/AdminDashboard/Subscriptions', filter)
}

export const getEmailSubscriptionsForSend = (): Promise<EmailSubscription[]> => {
  return adminHttpClient.get('/AdminDashboard/Subscriptions/ForSend')
}
