import { adminHttpClient } from '../../commons/adminHttpClient'

export const deleteCategoryTranslation = (categoryId: string, languageId: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/categoriestranslations', {
    data: { categoryId: categoryId, languageId: languageId },
  })
}

export interface CategoryTranslation {
  categoryId: string
  languageId: string
  name: string | null
  defaultName: string
}

export const getCategoryTranslation = (categoryId: string, languageId: string): Promise<CategoryTranslation> => {
  return adminHttpClient.get(`/admindashboard/categoriestranslations/forsave/${categoryId}/${languageId}`)
}

export interface CategoryTranslationToSave {
  categoryId: string
  languageId: string
  name: string
}

export const saveCategoryTranslation = (translation: CategoryTranslationToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/categoriestranslations', translation)
}
