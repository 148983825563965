import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import getGalleries, { GalleryDto } from 'core/application/dashboard/galleries/gallery'
import deleteGallery from 'core/application/dashboard/galleries/deleteGallery'
import { useHistory } from 'react-router-dom'
import { queryRequest } from 'common/RequestUtils'
import { Col, Row, Table } from 'antd'
import Card from 'common/components/dataDisplay/Card'
import { formatDate } from 'common/dateFormatters'
import Button from 'common/components/general/Button'
import { MANAGE_GALLERY_PATH } from './ManageGallery'
import TableEditButton from 'common/components/tables/TableEditButton'
import TableDeleteButton from 'common/components/tables/TableDeleteButton'
import { ADMIN_DASHBOARD_PATH } from 'dashboard/routes/StoreRoutes'
import { UnorderedListOutlined } from '@ant-design/icons'
import { GALLERY_ITEM_PATH } from './galleryItems/GalleryItem'
export const GALLERY_PATH = 'galleries'
const Gallery: React.FC = () => {
  const { t: translate } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [items, setItems] = useState<GalleryDto[]>([] as GalleryDto[])
  const history = useHistory()

  useEffect(() => {
    updateTable()
  }, [])

  const updateTable = async () => {
    setLoading(true)
    setItems(await queryRequest(() => getGalleries()))
    setLoading(false)
  }

  const getColumns = (): any[] => {
    const columns = [
      {
        title: translate('general.name'),
        dataIndex: 'name',
        key: 'name',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{record.name}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.created_at'),
        dataIndex: 'createdAt',
        responsive: ['md'],
        key: 'createdAt',
        render: (text: string, record: any) => (
          <span>
            <div>
              <span>{formatDate(record.createdAt)}</span>
            </div>
          </span>
        ),
      },
      {
        title: translate('dashboard.action'),
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        sorter: false,
        render: (text: string, record: any) => (
          <span>
            <div className="flex-center">
              <Button
                className="mr-16"
                size="small"
                onClick={() => history.push(`${ADMIN_DASHBOARD_PATH}${GALLERY_ITEM_PATH}/${record.id}`)}
              >
                <UnorderedListOutlined />
              </Button>
              <TableEditButton
                className="mr-16"
                editUrl={`${ADMIN_DASHBOARD_PATH}${GALLERY_PATH}/${MANAGE_GALLERY_PATH}/${record.id}`}
              />
              <TableDeleteButton
                disableDeleted={record.children?.length > 0}
                onDelete={() => deleteGallery(record.id)}
                onDeleted={() => updateTable()}
              />
            </div>
          </span>
        ),
      },
    ]
    return columns
  }
  return (
    <>
      <Card
        bordered={true}
        title={
          <Row>
            <Col xs={16}>
              <h4>{translate('dashboard.galleries.name')}</h4>
            </Col>
            <Col xs={24} md={8}>
              <div className={'text-right'}>
                <Button
                  icon="far fa-plus"
                  type="primary"
                  onClick={() => {
                    history.push(`${history.location.pathname}/${MANAGE_GALLERY_PATH}`)
                  }}
                >
                  {translate('dashboard.add')}
                </Button>
              </div>
            </Col>
          </Row>
        }
      >
        <Table dataSource={items} loading={loading} columns={getColumns()} scroll={{ x: true }} />
      </Card>
    </>
  )
}

export default Gallery
