import { adminHttpClient } from '../commons/adminHttpClient'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import PagedList from '../commons/pagedList'
import { Module } from '../../domain/store/module'
import { NamedItem } from 'application/commons/namedItem'

export interface Store {
  id: string
  name: string
  customDomain: string | null
  subDomain: string
  status: StoreStatus
  type: StoreType
}
export interface StoreForSave {
  id?: string | null
  name?: string
  customDomain: string | null
  subDomain: string
  themeId: string | null | undefined
  status: StoreStatus | undefined
  type: StoreType | undefined
  languageId: string
  currencyId: string
  availableModules: Module[]
  availableThemes: NamedItem[]
  availableLanguages: NamedItem[]
  availableCurrencies: NamedItem[]
  modules: Module[]
}
export enum StoreStatus {
  Active,
  Inactive,
  AwaitingForApproval,
}
export enum StoreType {
  Store,
  Site,
  Management,
}
export const StoreInitialStoreValues: StoreForSave = {
  id: null,
  name: '',
  customDomain: '',
  subDomain: '',
  status: undefined,
  type: undefined,
  availableModules: [],
  modules: [],
  languageId: '',
  currencyId: '',
  availableLanguages: [],
  availableCurrencies: [],
  availableThemes: [],
  themeId: null,
}

export const StoreTypeMap = [
  { value: StoreType.Store, label: 'Store' },
  { value: StoreType.Site, label: 'Site' },
]
export const StoreStatusMap = [
  { value: StoreStatus.Active, label: 'Active' },
  { value: StoreStatus.Inactive, label: 'Inactive' },
  { value: StoreStatus.AwaitingForApproval, label: 'AwaitingForApproval' },
]
export const getStores = async (payload: OrderedPageFilter): Promise<PagedList<Store>> => {
  return await adminHttpClient.get(`/management/stores`, payload)
}

export const getStoreById = async (id: string): Promise<StoreForSave> => {
  if (id) {
    return await adminHttpClient.get(`/management/stores/forSave/${id}`)
  }
  return await adminHttpClient.get(`/members/stores/forSave`)
}

export const saveStoreCommand = async (request: StoreForSave) => {
  return await adminHttpClient.put(`/management/stores`, request)
}
