import { NamedItem, NamedItemInt } from 'application/commons/namedItem'
import OrderedPageFilter from 'application/commons/orderedPageFilter'
import { adminHttpClient } from '../../commons/adminHttpClient'

export interface PaymentMethodForSaveDto {
  paymentMethodId: string
  data: string
  stripeKeys: StripeKeysType
  active: boolean
  priority: number
  feeType: any
  fee: number
  restrictToCountries: number[]
  availablePaymentMethods: NamedItem[]
  availableCountries: NamedItem[]
}
export const FeeType: NamedItemInt[] = [
  { id: 0, name: 'No Fee' },
  { id: 1, name: 'Fixed' },
  { id: 2, name: 'Percentage' },
]
export enum PaymentTypes {
  Paypal = 1,
  Stripe = 2,
  TowCheckout = 3,
  BankTransfer = 4,
  PaymentAtStore = 5,
  CashOnDelivery = 6,
}
export type StripeKeysType = {
  publishableKey: string
  secretKey: string
}

export const getPaymentMethods = (filter: OrderedPageFilter): Promise<any> => {
  return adminHttpClient.get(`/AdminDashboard/StorePaymentMethods`, filter)
}
export const savePaymentMethodForSave = (data: any): Promise<any> => {
  return adminHttpClient.put(`/AdminDashboard/StorePaymentMethods`, data)
}
const getPaymentMethodForSave = (id: string | undefined): Promise<any> => {
  if (id) {
    return adminHttpClient.get(`/adminDashboard/storepaymentmethods/forsave/${id}`, { paymentMethodId: id })
  }
  return adminHttpClient.get(`/adminDashboard/storepaymentmethods/forsave/`)
}

export default getPaymentMethodForSave
