import { adminHttpClient } from '../../commons/adminHttpClient'

export const deleteMenuItemTranslation = (menuItemId: string, languageId: string): Promise<any> => {
  return adminHttpClient.delete('/admindashboard/menuitemstranslations', {
    data: { menuItemId: menuItemId, languageId: languageId },
  })
}

export interface MenuItemTranslation {
  menuItemId: string
  languageId: string
  title: string | null
  defaultTitle: string
}

export const getMenuItemTranslation = (menuItemId: string, languageId: string): Promise<MenuItemTranslation> => {
  return adminHttpClient.get(`/admindashboard/menuitemstranslations/forsave/${menuItemId}/${languageId}`)
}

export interface MenuItemTranslationToSave {
  menuItemId: string
  languageId: string
  title: string
}

export const saveMenuItemTranslation = (translation: MenuItemTranslationToSave): Promise<any> => {
  return adminHttpClient.post('/admindashboard/menuitemstranslations', translation)
}
