import React, { ReactNode } from 'react'
import { Tag } from 'antd'

interface TagProps {
  closable?: boolean
  onClose?: (e: React.MouseEvent<HTMLElement>) => void
  color?: string
  children: ReactNode
}

const Display = (props: TagProps) => {
  return <Tag {...props}>{props.children}</Tag>
}

export default Display
