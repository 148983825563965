import React, { useState } from 'react'
import { Formik, Form as FormikForm } from 'formik'
import * as Yup from 'yup'
import { Row, Col, Skeleton } from 'antd'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import Input from 'common/components/dataEntry/formik/FormikInputField'
import TimePicker from 'common/components/dataEntry/formik/FormikTimePickerField'
import TextArea from 'common/components/dataEntry/formik/FormikTextAreaField'
import Select from 'common/components/dataEntry/formik/FormikSelectField'
import { commandRequest } from 'common/RequestUtils'
import Card from 'common/components/dataDisplay/Card'
import notification from 'common/components/feedback/Notification'

import { urlRegx, publicUrlRegx, phoneNumberRegx } from 'common/components/utils/Regx'

import {
  getStoreGeneralSettings,
  updateStoreGeneralSettings,
  StoreGeneralSettings,
} from 'core/application/dashboard/storeSettings/storeGeneralSettings'

import FormSaveCancelButtons from '../../../common/components/forms/SaveCancelFormButtons'
import useLoginUser from '../../../common/hooks/useLoginUser'
import useQueryRequest from '../../../common/hooks/useQueryRequest'
import getLoginUser, { User } from 'core/application/account/loginUser'

export const STORE_GENERAL_SETTINGS_PATH = 'store-general-settings'

const formValidator = (translate: any) =>
  Yup.object({
    name: Yup.string().trim().required(translate('general.required')),
    subDomain: Yup.string()
      .trim()
      .matches(publicUrlRegx, translate('general.wrong_url'))
      .required(translate('general.required')),
    email: Yup.string().nullable().trim().email(translate('general.invalid_email')).notRequired(),
    address: Yup.string().trim().nullable().notRequired(),
    aptSuite: Yup.string().trim().nullable().notRequired(),
    city: Yup.string().trim().nullable().notRequired(),
    countryId: Yup.number().typeError(translate('general.required')).required(translate('general.required')),
    startHour: Yup.string().nullable().trim().notRequired(),
    endHour: Yup.string().nullable().trim().notRequired(),
    description: Yup.string().trim().nullable().notRequired(),
    postalCode: Yup.string().trim().required(translate('general.required')),
  })

interface StoreGeneralSettingsFormProps {}

const StoreGeneralSettingsForm: React.FC<StoreGeneralSettingsFormProps> = () => {
  const { t } = useTranslation()
  const [, , sendGetUserLoginRequest] = useQueryRequest<User | null>(null)
  const [, setUser] = useLoginUser()

  const [item] = useQueryRequest<StoreGeneralSettings | null>(null, () => getStoreGeneralSettings())
  const [loadingSave, setLoadingSave] = useState<boolean>(false)

  const onSubmit = async (values: StoreGeneralSettings) => {
    setLoadingSave(true)

    const generalSettings = {
      ...values,
      startHour: values.startHour ? moment.parseZone(values.startHour, 'HH:mm').format('HH:mm') : null,
      endHour: values.endHour ? moment.parseZone(values.endHour, 'HH:mm').format('HH:mm') : null,
    }

    const result = await commandRequest(() => updateStoreGeneralSettings(generalSettings))

    if (!result.errors) {
      const user = await sendGetUserLoginRequest(() => getLoginUser())
      setUser(user)

      notification.open({
        message: t('general.saved_plural').replace('[]', t('dashboard.store_settings.settings')),
        type: 'success',
      })
    } else {
      notification.open({ message: result.errors[0], type: 'error' })
    }
    setLoadingSave(false)
  }

  return (
    <Skeleton active loading={!item} paragraph={{ rows: 6, className: 'p-64 color-gray-5' }} title={false}>
      {item && (
        <Formik enableReinitialize={true} initialValues={item} validationSchema={formValidator(t)} onSubmit={() => {}}>
          {(formik) => (
            <div>
              <FormikForm>
                <Card bordered={false} title={t('dashboard.store_settings.general')}>
                  <Row gutter={16}>
                    <Col md={16} xs={24}>
                      <Input
                        label={t('dashboard.store_settings.general_settings_form.name')}
                        name="name"
                        placeholder={t('dashboard.store_settings.general_settings_form.name')}
                      />
                    </Col>
                    <Col md={8} xs={24} style={{ display: 'none' }}>
                      <Row>
                        <Col span={18}>
                          <Input
                            label={t('dashboard.store_settings.general_settings_form.subdomain')}
                            name="subDomain"
                            placeholder={t('dashboard.store_settings.general_settings_form.subdomain')}
                          />
                        </Col>
                        <Col span={6}>
                          <div style={{ paddingTop: '27px' }}>.hermex.al</div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24}>
                      <TextArea
                        label={t('dashboard.store_settings.general_settings_form.description')}
                        name="description"
                        placeholder={t('dashboard.store_settings.general_settings_form.description')}
                        rows={6}
                      />
                    </Col>
                  </Row>

                  <Row style={{ display: 'none' }} gutter={16}>
                    <Col span={12}>
                      <Select
                        label={t('dashboard.store_settings.general_settings_form.language')}
                        name="languageId"
                        placeholder={t('dashboard.store_settings.general_settings_form.language')}
                        defaultValue={[item.languageId]}
                      >
                        {item.availableLanguages.map((language) => (
                          <option key={language.id} value={language.id}>
                            {language.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={12}>
                      <Select
                        label={t('dashboard.store_settings.general_settings_form.currency')}
                        name="currencyId"
                        placeholder={t('dashboard.store_settings.general_settings_form.currency')}
                        defaultValue={[item.currencyId]}
                      >
                        {item.availableCurrencies.map((currency) => (
                          <option key={currency.id} value={currency.id}>
                            {currency.name}
                          </option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                </Card>

                <Card bordered={false} title={t('dashboard.store_settings.contact')}>
                  <Row gutter={16}>
                    <Col md={12} xs={24}>
                      <Input
                        label={t('dashboard.store_settings.general_settings_form.email')}
                        name="email"
                        placeholder={t('dashboard.store_settings.general_settings_form.email')}
                      />
                    </Col>

                    <Col md={12} xs={24}>
                      <Input
                        label={t('dashboard.store_settings.general_settings_form.phone_number')}
                        name="phoneNumber"
                        placeholder={t('dashboard.store_settings.general_settings_form.phone_number')}
                      />
                    </Col>
                  </Row>
                </Card>

                <Card bordered={false} title={t('dashboard.store_settings.address')}>
                  <Row>
                    <Col span={24}>
                      <Input
                        label={t('dashboard.store_settings.general_settings_form.address')}
                        name="address"
                        placeholder={t('dashboard.store_settings.general_settings_form.address')}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col span={24}>
                      <Input
                        label={t('dashboard.orders.order_details.aptSuite')}
                        name="aptSuite"
                        placeholder={t('dashboard.orders.order_details.aptSuite')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col md={8} xs={24}>
                      <Select
                        label={t('dashboard.store_settings.general_settings_form.country')}
                        name="countryId"
                        showArrow={true}
                        placeholder={t('dashboard.store_settings.general_settings_form.country')}
                        defaultValue={[item.countryId]}
                      >
                        {item.availableCountries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </Select>
                    </Col>

                    <Col md={8} xs={24}>
                      <Input
                        label={t('dashboard.store_settings.general_settings_form.city')}
                        name="city"
                        placeholder={t('dashboard.store_settings.general_settings_form.city')}
                      />
                    </Col>

                    <Col md={8} xs={24}>
                      <Input
                        label={t('dashboard.orders.order_details.postal_code')}
                        name="postalCode"
                        placeholder={t('dashboard.orders.order_details.postal_code')}
                      />
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col md={12} xs={24}>
                      <TimePicker
                        label={t('dashboard.store_settings.general_settings_form.startHour')}
                        name="startHour"
                        className="full_width"
                        format="HH:mm"
                        placeholder={t('dashboard.store_settings.general_settings_form.startHour')}
                        value={formik.values.startHour ? moment.parseZone(formik.values.startHour, 'HH:mm') : null}
                      />
                    </Col>
                    <Col md={12} xs={24}>
                      <TimePicker
                        label={t('dashboard.store_settings.general_settings_form.endHour')}
                        name="endHour"
                        format="HH:mm"
                        className="full_width"
                        placeholder={t('dashboard.store_settings.general_settings_form.endHour')}
                        value={formik.values.endHour ? moment.parseZone(formik.values.endHour, 'HH:mm') : null}
                      />
                    </Col>
                  </Row>
                </Card>

                <FormSaveCancelButtons
                  disabledSave={!formValidator(t).isValidSync(formik.values)}
                  onButtonClick={() => onSubmit(formik.values)}
                  loading={loadingSave}
                  showCancelButton={false}
                ></FormSaveCancelButtons>
              </FormikForm>
            </div>
          )}
        </Formik>
      )}
    </Skeleton>
  )
}

export default StoreGeneralSettingsForm
