import useSharedState, { SharedStateKeys } from './useSharedState'
import getLoginUser, { User } from 'core/application/account/loginUser'

const useLoginUser = (): [User, Function, Function] => {
  const [user, setUser] = useSharedState<User>(SharedStateKeys.LoginUser)

  return [user, setUser, getLoginUser]
}

export default useLoginUser
