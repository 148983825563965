import { SeoProps } from '../../commons/utils'
import { NamedItem, NamedItemWithChildren } from '../../commons/namedItem'

export interface DynamicEntityCategory {
  id?: string | undefined
  name: string
  friendlyUrl: string
  photoUrl: string
  createdAt: string | Date
  priority: number
  children: DynamicEntityCategory[]
  hasChildren: boolean
}
export interface DynamicCategoryForSave {
  id: string | undefined
  name: string
  friendlyUrl: string
  priority: number
  photoId: string
  fields: any
  parentId: string
  availableParents: NamedItem[]
}
export enum DynamicFieldType {
  Text,
  File,
  TextArea,
}
export const dynamicFieldTypeMap = [
  { label: 'Text', value: DynamicFieldType.Text },
  { label: 'File', value: DynamicFieldType.File },
  { label: 'TextArea', value: DynamicFieldType.TextArea },
]
export interface DynamicField {
  type: DynamicFieldType
  value: string
}

export interface DynamicEntity {
  id: string
  name: string
  friendlyUrl: string
  priority: number
  description: string
  shortDescription: string
  photoId: string
  photoIds: string[]
  thumbnailId: string
  bannerPhotoId: string
  iconId: string
  status: DynamicEntityStatus
  title: string
  seo: string
  seoDescription: string
  seoImage: string
  fields: any[]
  createdAt: Date | string
}

export interface DynamicEntityForSave extends Partial<SeoProps> {
  id?: string | undefined
  name: string
  friendlyUrl: string
  shortDescription?: string
  description: string
  priority: number
  photoId?: string | null
  photoIds?: string[]
  thmbnailId?: string
  bannerPhotoId?: string
  iconId?: string
  status: DynamicEntityStatus
  fields: DynamicField[] | any
  parentId?: string
  availableParents: NamedItem[]
  categoryIds: string[]
  availableCategories: NamedItemWithChildren[]
}
export enum DynamicEntityStatus {
  Active = 0,
  Archived = 1,
  Draft = 2,
}
export const dynamicEntityStatusMap = [
  { value: DynamicEntityStatus.Active, label: 'Active', color: 'green' },
  { value: DynamicEntityStatus.Archived, label: 'Archive', color: 'orange' },
  { value: DynamicEntityStatus.Draft, label: 'Draft', color: 'red' },
]
