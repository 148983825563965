import { adminHttpClient } from '../../commons/adminHttpClient'
import moment, { Moment } from 'moment'
import OrderedPageFilter from '../../commons/orderedPageFilter'
import PagedList from '../../commons/pagedList'

export interface PagedOffers extends PagedList<Offer> {}

export interface Offer {
  id: string
  name: string
  discount: string
  createdAt: Moment
  startDate: Moment
  product: OfferProduct
}

export interface OfferProduct {
  name: string
  stockKeepingUnit: string
  mainPhotoUrl: string
}

const getOffers = (filter: OrderedPageFilter): Promise<any> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/offers', filter)
}

export const getDealOfTheDayOffers = (filter: OrderedPageFilter): Promise<PagedOffers> => {
  filter = filter || {
    pageNumber: 1,
    pageSize: 10,
    orderColumn: 'createdAt',
    orderDescending: false,
  }

  return adminHttpClient.get('/admindashboard/offers/dealoftheday', filter)
}

export default getOffers
