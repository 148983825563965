import React from 'react'
import { useHistory } from 'react-router'

import Icon from '../general/Icon'

import './Navigation.less'

interface Props {
  title: string
  path?: string
}

const GoBack: React.FC<Props> = ({ title, path }) => {
  const history = useHistory()

  return (
    <div className="flex flex_baseline">
      <div className="go_back cursor_pointer" onClick={() => (path ? history.push(path) : history.goBack())}>
        <Icon type="far fa-arrow-left" />
      </div>
      <h4>{title}</h4>
    </div>
  )
}

export default GoBack
